import Web3 from 'web3';

import {
    getMiningOneSideBoostV2Contract, 
    getMiningOneSideBoostVeiZiContract,
} from '../../../../../utils/contractHelpers';
import { Contract } from 'web3-eth-contract';

import { RewardInfoResponse } from '../../../../../types/abis/farm/UniswapV3/oneSide/MiningOneSideBackup';

export const pendingReward = async(miningContract: Contract, tokenId: string): Promise<string[]> => {
    const poolEarned: string[] = await miningContract.methods.pendingReward(tokenId).call();
    return poolEarned;
};

export const pendingRewards = async (miningContract: Contract, account: string): Promise<string[]> => {
    const poolEarned: string[] = await miningContract.methods.pendingRewards(account).call();
    return poolEarned;
};

export interface RewardInfoResponse2Rewards {
    token0: string;
    provider0: string;
    tokenPerBlock0: string;
    token1: string;
    provider1: string;
    tokenPerBlock1: string;
}

export type RewardInfo = RewardInfoResponse

export interface GetMiningContractInfoResponse {
    uniToken_: string;
    lockToken_: string;
    fee_: string;
    lockBoostMultiplier_: string;

    veiZiAddress_?: string;
    iziTokenAddr_?: string;

    lastTouchBlock_: string;
    totalVLiquidity_: string;
    totalLock_: string;

    totalNIZI_?: string;
    totalValidVeiZi_?: string;

    startBlock_: string;
    endBlock_: string;
}

export interface RewardInfosResponse {
    rewardToken: string;
    provider: string;
    accRewardPerShare: string;
    rewardPerBlock: string;
}

export interface GetOraclePriceResponse {
    avgTick: string;
    avgSqrtPriceX96: string;
}

export interface MiningOneSideMetaInfo {
    miningContractInfo: GetMiningContractInfoResponse;
    rewardInfos: RewardInfosResponse[];
    oraclePrice: GetOraclePriceResponse;
}


export const getMiningOneSideBoostV2MetaInfo = async (miningAddress: string, web3: Web3): Promise<MiningOneSideMetaInfo> => {
    const miningContract = getMiningOneSideBoostV2Contract(miningAddress, web3);
    const miningContractInfo = await miningContract?.methods.getMiningContractInfo().call();
    const rewardInfosLen = await miningContract?.methods.rewardInfosLen().call();
    const rewardInfos = [] as RewardInfosResponse[];
    
    for (let i = 0; i < Number(rewardInfosLen); i ++) {
        const rewardInfo = await miningContract?.methods.rewardInfos(String(i)).call();
        rewardInfos.push({...rewardInfo});
    }
    const oneSideMetaInfo = {} as MiningOneSideMetaInfo;
    //console.log('MiningOneSideMetaInfo: ', miningContractInfo);
    oneSideMetaInfo.miningContractInfo = {...miningContractInfo};
    oneSideMetaInfo.rewardInfos = rewardInfos;
    const oraclePrice = await miningContract?.methods.getOraclePrice().call();
    oneSideMetaInfo.oraclePrice = {...oraclePrice};
    return oneSideMetaInfo;
};

export const getMiningOneSideBoostVeiZiMetaInfo = async (miningAddress: string, web3: Web3): Promise<MiningOneSideMetaInfo> => {
    const miningContract = getMiningOneSideBoostVeiZiContract(miningAddress, web3);
    const miningContractInfo = await miningContract?.methods.getMiningContractInfo().call();
    const rewardInfosLen = await miningContract?.methods.rewardInfosLen().call();
    const rewardInfos = [] as RewardInfosResponse[];
    
    for (let i = 0; i < Number(rewardInfosLen); i ++) {
        const rewardInfo = await miningContract?.methods.rewardInfos(String(i)).call();
        rewardInfos.push({...rewardInfo});
    }
    const oneSideMetaInfo = {} as MiningOneSideMetaInfo;
    //console.log('MiningOneSideMetaInfo: ', miningContractInfo);
    oneSideMetaInfo.miningContractInfo = {...miningContractInfo};
    oneSideMetaInfo.rewardInfos = rewardInfos;
    const oraclePrice = await miningContract?.methods.getOraclePrice().call();
    oneSideMetaInfo.oraclePrice = {...oraclePrice};
    return oneSideMetaInfo;
};

export const getMiningOneSideBoostMetaInfo = async (miningAddress: string, web3: Web3, contractVersion: string): Promise<MiningOneSideMetaInfo> => {
    if (contractVersion === 'V2') {
        // boost with iZi
        return getMiningOneSideBoostV2MetaInfo(miningAddress, web3);
    } else {
        // boost with veiZi
        return getMiningOneSideBoostVeiZiMetaInfo(miningAddress, web3);
    }
};