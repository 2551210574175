import { createModel } from '@rematch/core';
import { RootModel } from '../../../index';
import produce from 'immer';
import {
    decodeMethodResult,
    getMiningDynamicRangeVeiZiContract,
    getMiningFixRangeBoostContract,
    getMiningFixRangeBoostV2Contract,
    getPositionPoolContract,
    getVeiZiContract,
} from '../../../../../utils/contractHelpers';
import BigNumber from 'bignumber.js';
import { tokenAddr2Token, tokenSymbol2token } from '../../../../../config/tokens';
import {
    getPoolAPR,
    getPoolLiquidity,
    getPoolRewardRange,
    getPoolRewardRangeDecimal,
    getPositionLiquidity,
    getPositionPriceRange,
    getPositionPriceRangeDecimal,
    getPoolRewards,
    findPoolEntryByPoolKey,
    getPriceFromX96,
    getPriceDecimalFromX96,
    getPositionVLiquidity,
    getMiningContractInfo,
    pendingReward,
    veiZiBoostAPR
} from './funcs';
import { isAddress } from 'ethers/lib/utils';
import {
    FarmControl,
    FarmState,
    InitPoolListDataParams,
    InitPoolListMetaParams,
    InitPositionParams,
    MiningPoolData,
    MiningPoolMeta,
    MiningPoolUserData,
    PoolEntryState,
    PositionDetail,
    PositionEntry,
    RefreshPoolListDataAndPositionParams,
} from './types';
import { Slot0Response } from '../../../../../types/abis/UniswapV3/UniswapV3Pool';
import { ChainId, FarmFixRangeContractVersion, TokenSymbol } from '../../../../../types/mod';
import { parallelCollect} from '../../../../../net/contractCall/parallel';
import { getPositionPoolKey } from '../../../common/positionPoolHelper';
import { amount2Decimal } from '../../../../../utils/tokenMath';
import { getChain } from '../../../../../config/chains';
import { VEIZI_ADDRESS } from '../../../../../config/veizi/veiziContracts';
import { toContractFeeNumber, toFeeNumber } from '../../../../../utils/funcs';


export const farmFixRange = createModel<RootModel>()({
    state: {
        positionManagerContract: undefined,
        poolEntryList: [],
        farmView: {
            isFarmDataLoading: false,
            isUserDataLoading: false,
        },
        farmControl: {
            sortBy: undefined,
            stakedOnly: false,
            searchKey: undefined,
            type: 'live',
        }
    } as FarmState,
    reducers: {
        setFarmState: (state: FarmState, payload: FarmState) => {
            return { ...state, ...payload };
        },
        setFarmControl: (state: FarmState, farmControl: FarmControl) => produce(state, draft => {
            draft.farmControl = { ...farmControl };
        }),
        setPoolEntryList: (state: FarmState, payload: PoolEntryState[]) => produce(state, draft => {
            // void freeze object when initPoolListByMeta set meta state first
            draft.poolEntryList = JSON.parse(JSON.stringify(payload));
        }),
        setPoolEntryListMeta: (state: FarmState, { chainId, poolEntryList }: { chainId: ChainId, poolEntryList: PoolEntryState[] }) => {
            return { ...state, poolEntryList, currentFarmChainId: chainId };
        },
        setPoolEntryListData: (state: FarmState, payload: PoolEntryState[]) => produce(state, draft => {
            for (const poolEntry of payload) {
                const draftPoolEntry = findPoolEntryByPoolKey(draft.poolEntryList as unknown as any, poolEntry.meta.positionPoolKey);
                draftPoolEntry.data = poolEntry.data;
            }
        }),
        setPositionData: (state: FarmState, payload: PoolEntryState[]) => produce(state, draft => {
            const positionPoolKeySet = new Set();
            for (const poolEntry of payload) {
                const draftPoolEntry = findPoolEntryByPoolKey(draft.poolEntryList as unknown as any, poolEntry.meta.positionPoolKey);
                draftPoolEntry.userData = poolEntry.userData;
                draftPoolEntry.positionList = poolEntry.positionList;
                draftPoolEntry.stakedPositionList = poolEntry.stakedPositionList;

                positionPoolKeySet.add(poolEntry.meta.positionPoolKey);
            }
            // clean other
            draft.poolEntryList.filter(p => !positionPoolKeySet.has(p.meta.positionPoolKey)).forEach(p => {
                p.userData.earned = ['0', '0'];
                p.positionList = [];
                p.stakedPositionList = [];
            });
        }),
        setFarmDataLoading: (state: FarmState, isLoading: boolean) => produce(state, draft => {
            draft.farmView.isFarmDataLoading = isLoading;
        }),
        setUserDataLoading: (state: FarmState, isLoading: boolean) => produce(state, draft => {
            draft.farmView.isUserDataLoading = isLoading;
        }),
        togglePoolMetaInitialToggle: (state: FarmState, positionPoolKey: string) => produce(state, draft => {
            const draftPoolEntry = findPoolEntryByPoolKey(draft.poolEntryList as unknown as any, positionPoolKey);
            draftPoolEntry.meta.initialToggle = !draftPoolEntry.meta.initialToggle;
        }),
    },
    effects: (dispatch) => ({
        async initPoolListMeta(initPoolListMetaParams: InitPoolListMetaParams): Promise<void> {
            const { chainId, metaList } = initPoolListMetaParams;
            if (!chainId) { return; }
            const startTime = new Date();

            const poolEntryList = [] as PoolEntryState[];
            // TODO filter
            // TODO contract multicall or web3 batch request or parallel
            for (const configMeta of metaList ?? []) {
                const userData = { earned: ['0', '0'] } as MiningPoolUserData;
                const data = {} as MiningPoolData;
                const contractVersion = configMeta.contractVersion? configMeta.contractVersion : FarmFixRangeContractVersion.V2;
                const additionalKey = configMeta.additionalKey ?? '01';
                const positionPoolKey = getPositionPoolKey(configMeta.tokenA.address, configMeta.tokenB.address, configMeta.feeTier, contractVersion, additionalKey);
                const meta = { ...configMeta,  positionPoolKey, contractVersion} as MiningPoolMeta;

                const poolEntryState = { meta, data, userData } as PoolEntryState;
                poolEntryList.push(poolEntryState);
            }
            dispatch.farmFixRange.setPoolEntryListMeta({ chainId, poolEntryList });
            // sync init meta data for render basic view
            console.log(`initPoolListMeta end, ${(new Date()).getTime() - startTime.getTime()} ms`);
        },
        async initPoolListData(initPoolListDataParams: InitPoolListDataParams, rootState): Promise<void> {
            const { chainId, web3, positionFactoryContract } = initPoolListDataParams;
            if (!chainId || !web3 || !positionFactoryContract) { return; }
            if (rootState.farmFixRange.poolEntryList.length === 0) { return; }

            const blockDeltaU = getChain(chainId)?.blockDeltaU ?? 10;

            const poolEntryDataList = [] as PoolEntryState[];
            const asyncProcessList: Promise<void>[] = [];
            for (const poolEntry of rootState.farmFixRange.poolEntryList) {
                const { meta } = poolEntry;
                const data = {} as MiningPoolData;
                const poolEntryData = {
                    meta: { positionPoolKey: meta.positionPoolKey } as MiningPoolMeta,
                    data
                } as PoolEntryState;

                poolEntryDataList.push(poolEntryData);
                // const miningContract = getMiningFixRangeContract(meta.miningContract, web3);
                const miningContract = getMiningFixRangeBoostContract(meta.miningContract, web3, meta.contractVersion);

                const asyncProcess = async () => {
                    // 1. get mining contract info
                    const miningKeyInfo = await getMiningContractInfo(meta.miningContract, chainId, web3, meta.contractVersion);

                    // 2. get position pool slot0 info from pool factory contract
                    const positionPoolAddress = await positionFactoryContract.methods.getPool(meta.tokenA.address, meta.tokenB.address, toContractFeeNumber(meta.feeTier)).call();
                    const poolContract = getPositionPoolContract(positionPoolAddress, web3);
                    const slot0: Slot0Response = await poolContract?.methods.slot0().call();

                    // 3. get swap and reward token price and set price data
                    const [tokenPriceA, tokenPriceB, tokenPriceiZi] = await parallelCollect(
                        dispatch.token.fetchTokenPriceIfMissing(meta.tokenA),
                        dispatch.token.fetchTokenPriceIfMissing(meta.tokenB),
                        dispatch.token.fetchTokenPriceIfMissing(tokenSymbol2token(TokenSymbol.IZI, chainId))
                    );

                    data.isEnded = Number(miningKeyInfo.endBlock) < Number(rootState.block.currentBlock);
                    data.endBlock = Number(miningKeyInfo.endBlock);
                    data.secondsLeft = data.isEnded? 0: (Number(miningKeyInfo.endBlock) - Number(rootState.block.currentBlock)) * blockDeltaU;

                    data.vLiquidity = getPoolLiquidity(miningKeyInfo, slot0, { left: tokenPriceA, right: tokenPriceB });
                    data.capital = data.vLiquidity;
                    data.totalVLiquidity = parseFloat(miningKeyInfo.totalVLiquidity);
                    if (meta.contractVersion === FarmFixRangeContractVersion.V2) {
                        data.totalNiZi = parseFloat(await miningContract?.methods.totalNIZI().call());
                        data.totalValidVeiZi = 0;
                    } else {
                        data.totalValidVeiZi = parseFloat(miningKeyInfo.totalValidVeiZi ?? '0');
                        data.totalNiZi = 0;
                    }
                    data.tvl = data.vLiquidity + (amount2Decimal(new BigNumber(data.totalNiZi), tokenSymbol2token(TokenSymbol.IZI, chainId)) ?? 0) * tokenPriceiZi;

                    data.currentTick = parseFloat(slot0.tick);

                    let apr = 0;
                    let totalRewardWorthPeryear = 0;
                    data.reward = [];

                    for (const rewardTokenPart of miningKeyInfo.rewardTokenList) {
                        const rewardToken = rewardTokenPart.rewardToken;
                        const rewardTokenPrice = await dispatch.token.fetchTokenPriceIfMissing(rewardToken) ?? 0;
                        const ret = getPoolAPR(rewardTokenPart.rewardPerBlock, chainId, data.vLiquidity, { left: rewardToken, right: rewardTokenPrice });
                        apr += ret[0];
                        totalRewardWorthPeryear += ret[1];
                        data.reward.push([rewardToken, getPoolRewards(rewardTokenPart)]);
                    }

                    data.totalRewardWorthPerYear = totalRewardWorthPeryear;

                    if (meta.iZiBoost || meta.veiZiBoost) {
                        data.apr=[apr/2.5, apr];
                    } else {
                        data.apr = [apr];
                    }

                    data.rewardLowerTick = Number(miningKeyInfo.rewardLowerTick);
                    data.rewardUpperTick = Number(miningKeyInfo.rewardUpperTick);

                    const [priceLowerDecimal, priceUpperDecimal] = getPoolRewardRangeDecimal(miningKeyInfo);
                    data.rewardMinPriceDecimal = Math.min(priceLowerDecimal, priceUpperDecimal);
                    data.rewardMaxPriceDecimal = Math.max(priceLowerDecimal, priceUpperDecimal);

                    const [priceLower, priceUpper] = getPoolRewardRange(miningKeyInfo);
                    data.rewardMinPrice = Math.min(priceLower, priceUpper);
                    data.rewardMaxPrice = Math.max(priceLower, priceUpper);

                    // 4. set position data
                    data.positionPoolContract = positionPoolAddress;
                    data.positionSqrtPriceX96 = slot0.sqrtPriceX96;

                    data.priceAByB = parseFloat(getPriceFromX96(slot0.sqrtPriceX96));
                    data.priceAByBDecimal = parseFloat(
                        getPriceDecimalFromX96(
                            slot0.sqrtPriceX96,
                            {left: meta.tokenA, right: meta.tokenB},
                        )
                    );
                    data.positionTick = Number(slot0.tick);
                };
                asyncProcessList.push(asyncProcess());
            }
            await Promise.all(asyncProcessList);
            dispatch.farmFixRange.setPoolEntryListData(poolEntryDataList);
        },
        async initPoolList(initPoolListParams: InitPoolListMetaParams & InitPoolListDataParams): Promise<void> {
            await dispatch.farmFixRange.initPoolListMeta(initPoolListParams);
            await dispatch.farmFixRange.initPoolListData(initPoolListParams);
        },
        async initPosition(initPositionParams: InitPositionParams, rootState): Promise<void> {
            const { chainId, web3, positionManagerContract, account } = initPositionParams;
            // TODO if account not set, clean?
            if (!chainId || !web3 || !account || !positionManagerContract || !rootState.farmFixRange.poolEntryList) { return; }
            if (rootState.farmFixRange.poolEntryList.length === 0) { return; }
            // TODO condition query in model?
            // TODO type hint for contract method
            // TODO opt: parallelCollect or better promi pipeline or multicall or web3 batch request
            // TODO store contract object ? use rematch select cache contract object
            const startTime = new Date();

            // dispatch.farm.setUserDataLoading(true);
            // 1. get total nft by account
            const unstakedTokenTotal = await positionManagerContract.methods.balanceOf(account).call().then((unstakedTokenTotal: number) => unstakedTokenTotal);

            // 2. get unstaked tokenId list by unstakedTokenTotal
            const tokenIdMulticallData = [];
            for (let i = 0; i < unstakedTokenTotal; i++) {
                tokenIdMulticallData.push(positionManagerContract.methods.tokenOfOwnerByIndex(account, i).encodeABI());
            }
            const tokenIdList: string[] = await positionManagerContract.methods.multicall(tokenIdMulticallData).call().then((tokenIdList: string[]) => tokenIdList);
            const tokenIdNumberList: BigNumber[] = tokenIdList.map((tokId: string) => new BigNumber(tokId));

            // 4. get staked position nftid from mining contract
            const stakedTokenIdList: string[] = [];
            type miningPoolValueType = { tokenIdSet: Set<string>, address: string, poolEntry: PoolEntryState }
            const miningPoolData = {} as { [index: string]: miningPoolValueType };
            const asyncProcessList: Promise<void>[] = [];

            const veiZiAddress = VEIZI_ADDRESS[chainId];
            const veiZiContract = getVeiZiContract(veiZiAddress, web3);
            const stakingInfo = await veiZiContract?.methods.stakingInfo(account).call();

            const veiZi = Number(stakingInfo?.amount ?? 0);
            const veiZiNftId = stakingInfo?.nftId ?? '0';

            const veiZiDecimal = amount2Decimal(new BigNumber(stakingInfo?.amount?? 0), tokenSymbol2token(TokenSymbol.IZI,chainId)) ?? 0;

            for (const poolEntryState of rootState.farmFixRange.poolEntryList) {
                const asyncProcess = async () => {
                    const miningContract = getMiningFixRangeBoostContract(poolEntryState.meta.miningContract, web3, poolEntryState.meta.contractVersion);

                    const poolEarnedList = await miningContract.methods.pendingRewards(account).call();
                    const tokenIds = await miningContract.methods.getTokenIds(account).call();

                    const userData = {} as MiningPoolUserData;
                    userData.earned = poolEarnedList;
                    userData.veiZi = veiZi;
                    userData.veiZiNftId = veiZiNftId;
                    userData.veiZiDecimal = veiZiDecimal;
                    userData.vLiquidity = 0;
                    userData.capital = 0;

                    const positionPoolKey = poolEntryState.meta.positionPoolKey;
                    const twoRewards = poolEntryState.meta.twoRewards;
                    const contractVersion = poolEntryState.meta.contractVersion;

                    const poolEntry = {
                        meta: { positionPoolKey, twoRewards, contractVersion } as MiningPoolMeta,
                        userData: userData,
                        positionList: [] as PositionEntry[],
                        stakedPositionList: [] as PositionEntry[],
                    } as PoolEntryState;
                    miningPoolData[poolEntryState.meta.miningContract] = { tokenIdSet: new Set(tokenIds), address: poolEntryState.meta.miningContract, poolEntry };
                    stakedTokenIdList.push(...tokenIds);
                };
                asyncProcessList.push(asyncProcess());
            }
            await Promise.all(asyncProcessList);

            const stakedTokenIdNumberList: BigNumber[] = stakedTokenIdList.map(i => new BigNumber(i));

            // 5. get all positions data by tokenId list
            const allTokenId = [...tokenIdNumberList, ...stakedTokenIdNumberList];
            const positionMulticallData = allTokenId.map(tokId => positionManagerContract.methods.positions(tokId.toString()).encodeABI());
            const positionResult: string[] = await positionManagerContract.methods.multicall(positionMulticallData).call().then((positions: string[]) => positions);
            const positions: PositionDetail[] = positionResult.map((p, i) => {
                //position: positionResponse
                const position = decodeMethodResult(positionManagerContract, 'positions', p);
                position.token0 = tokenAddr2Token(position.token0, chainId);
                position.token1 = tokenAddr2Token(position.token1, chainId);
                position.fee = toFeeNumber(position.fee);
                position.tokenId = allTokenId[i];
                return position;
            });

            // 6. split positions into staked and unstaked
            const stakedTokenIdSet = new Set(stakedTokenIdNumberList);
            const stakedPositionList = positions.filter(p => stakedTokenIdSet.has(p.tokenId));

            // 7. get pendingReward for each stakedPosition
            const asyncRewardProcessList: Promise<void>[] = [];
            for (const stakedPosition of stakedPositionList) {
                const asyncRewardProcess = async () => {
                    const stakedTokenId = stakedPosition.tokenId.toString();
                    const miningPool = Object.values(miningPoolData).filter(d => d.tokenIdSet.has(stakedTokenId))[0];
                    if (miningPool.poolEntry.meta.contractVersion === FarmFixRangeContractVersion.V2) {
                        stakedPosition.earned = await pendingReward(stakedTokenId, miningPool.address, web3);
                    }
                };
                asyncRewardProcessList.push(asyncRewardProcess());
            }
            await Promise.all(asyncRewardProcessList);

            //console.log(positions, 'positions!!!!!');
            // 8. pure function calculate data
            for (const position of positions) {

                const isStaked = stakedTokenIdSet.has(position.tokenId);
                const miningPools: miningPoolValueType[] = [];

                if (isStaked) {
                    const miningPool = Object.values(miningPoolData).find(d => d.tokenIdSet.has(position.tokenId.toString())) as miningPoolValueType;
                    if (miningPool) {
                        miningPools.push(miningPool);
                    }
                } else {
                    const positionPoolKeyV2 = getPositionPoolKey(position.token0.address, position.token1.address, position.fee, FarmFixRangeContractVersion.V2);
                    const miningPoolV2 = Object.values(miningPoolData).find(d => d.poolEntry.meta.positionPoolKey === positionPoolKeyV2) as miningPoolValueType;
                    if (miningPoolV2) {
                        miningPools.push(miningPoolV2);
                    }

                    const positionPoolKeyVeiZi = getPositionPoolKey(position.token0.address, position.token1.address, position.fee, FarmFixRangeContractVersion.VEIZI);
                    const miningPoolVeiZi = Object.values(miningPoolData).find(d => d.poolEntry.meta.positionPoolKey === positionPoolKeyVeiZi) as miningPoolValueType;
                    if (miningPoolVeiZi) {
                        miningPools.push(miningPoolVeiZi);
                    }
                }

                for (const miningPool of miningPools) {

                    const { meta, data } = rootState.farmFixRange.poolEntryList.find(p => p.meta.miningContract === miningPool.address) as PoolEntryState;
                    const poolEntry = miningPool.poolEntry;
                    const [tokenPriceA, tokenPriceB] = await parallelCollect(
                        dispatch.token.fetchTokenPriceIfMissing(meta.tokenA),
                        dispatch.token.fetchTokenPriceIfMissing(meta.tokenB)
                    );
    
                    const positionEntry = { nftId: position.tokenId.toString(), isStaked } as PositionEntry;
    
    
                    positionEntry.tickLower = parseFloat(position.tickLower);
                    positionEntry.tickUpper = parseFloat(position.tickUpper);
                    positionEntry.liquidity = parseFloat(position.liquidity);
    
                    positionEntry.positionVLiquidity = getPositionVLiquidity(
                        data.rewardUpperTick,
                        data.rewardLowerTick,
                        positionEntry.tickUpper,
                        positionEntry.tickLower,
                        positionEntry.liquidity
                    );
    
                    positionEntry.liquidityBigNumber = new BigNumber(position.liquidity);
    
                    positionEntry.vLiquidity = getPositionLiquidity(
                        position,
                        data.positionTick,
                        chainId,
                        { left: tokenPriceA, right: tokenPriceB },
                        true,
                        { left: data.rewardLowerTick, right: data.rewardUpperTick}
                    );
                    positionEntry.capital = positionEntry.vLiquidity;
    
                    positionEntry.TVL = getPositionLiquidity(
                        position,
                        data.positionTick,
                        chainId,
                        { left: tokenPriceA, right: tokenPriceB },
                        true,
                        { left: positionEntry.tickLower, right: positionEntry.tickUpper }
                    );
    
                    const [priceLower, priceUpper] = getPositionPriceRange(position);
                    positionEntry.minPrice = Math.min(priceLower, priceUpper);
                    positionEntry.maxPrice = Math.max(priceLower, priceUpper);
    
                    const [priceLowerDecimal, priceUpperDecimal] = getPositionPriceRangeDecimal(position);
                    positionEntry.minPriceDecimal = Math.min(priceLowerDecimal, priceUpperDecimal);
                    positionEntry.maxPriceDecimal = Math.max(priceLowerDecimal, priceUpperDecimal);
    
                    positionEntry.earned = position.earned;
    
                    if (isStaked && meta.iZiBoost) {
                        const miningContract = getMiningFixRangeBoostV2Contract(meta.miningContract, web3);
    
                        const tokenStatus = await miningContract?.methods.tokenStatus(positionEntry.nftId).call();
                        positionEntry.niZi = parseFloat(tokenStatus.nIZI);
    
                        const vliquidityShare = positionEntry.positionVLiquidity / data.totalVLiquidity;
                        const iZiShare = (data.totalNiZi === 0) ? 1 : positionEntry.niZi / data.totalNiZi;
                      
                        positionEntry.currentAPR = data.apr[0] * (1 + (1.5 * iZiShare) / vliquidityShare);
                        if (positionEntry.currentAPR > data.apr[1]) {
                            positionEntry.currentAPR = data.apr[1];
                        }
                        //validVLiquidity / (0.4 * vLiquidity) * data.apr[0];
                        positionEntry.niZiDecimal = amount2Decimal(new BigNumber(tokenStatus.nIZI), tokenSymbol2token(TokenSymbol.IZI, chainId));
                    }
    
                    /// check if position is valid that the intersection between position and mining pool reward range is not empty.
                    if ((positionEntry.minPrice <= data.rewardMaxPrice && positionEntry.minPrice >= data.rewardMinPrice) ||
                        (positionEntry.maxPrice <= data.rewardMaxPrice && positionEntry.maxPrice >= data.rewardMinPrice) ||
                        (positionEntry.maxPrice >= data.rewardMaxPrice && positionEntry.minPrice <= data.rewardMinPrice)) {
    
                        if (isStaked) {
                            poolEntry.stakedPositionList.push(positionEntry);
                            // staked positionEntry will only occur once (either V2 or VEIZI)
                            poolEntry.userData.capital += positionEntry.capital;
                        } else {
                            poolEntry.positionList.push(positionEntry);
                        }
                    }
                }

            }

            // 9. miningContract is approved
            const miningContractList = Object.keys(miningPoolData);
            const isApprovedMulticallData = miningContractList.map(miningContract => positionManagerContract.methods.isApprovedForAll(account, miningContract).encodeABI());
            const isApprovedResult: boolean[] = await positionManagerContract.methods.multicall(isApprovedMulticallData).call()
                .then((isApprovedList: string[]) => isApprovedList.map(isApproved => Number(isApproved) !== 0));
            for (const i in miningContractList) {
                miningPoolData[miningContractList[i]].poolEntry.userData.isApprovedForAll = isApprovedResult[i];
                const { meta, data } = rootState.farmFixRange.poolEntryList.find(p => p.meta.miningContract === miningContractList[i]) as PoolEntryState;
                if (meta.contractVersion === FarmFixRangeContractVersion.VEIZI) {
                    const miningDynamicRangeBoostVeiZi = getMiningDynamicRangeVeiZiContract(miningContractList[i], web3);
                    const userStatus = await miningDynamicRangeBoostVeiZi?.methods.userStatus(account).call();
                    const userData = miningPoolData[miningContractList[i]].poolEntry.userData;
                    userData.vLiquidity = Number(userStatus.vLiquidity);
                    userData.validVeiZi = Number(userStatus.validVeiZi);
                    userData.apr = veiZiBoostAPR({data} as PoolEntryState, userData.vLiquidity, userData.capital, meta.veiZiBoost? userData.veiZi : 0);
                }
            }

            // TODO save positions and positionManagerContract address
            dispatch.farmFixRange.setPositionData(Object.values(miningPoolData).map(m => m.poolEntry));
            // dispatch.farm.setUserDataLoading(false);
            console.log(`initPosition end, ${(new Date()).getTime() - startTime.getTime()} ms`);
        },
        async refreshPoolListDataAndPosition(refreshPoolListDataAndPositionParams: RefreshPoolListDataAndPositionParams): Promise<void> {
            await dispatch.farmFixRange.initPoolListData(refreshPoolListDataAndPositionParams as InitPoolListDataParams);
            await dispatch.farmFixRange.initPosition(refreshPoolListDataAndPositionParams as InitPositionParams);
        },
        async cleanPositionIfExist(account: string | null | undefined, rootState): Promise<void> {
            if (!isAddress(account as string)) { return; }
            if (!rootState.farmFixRange.poolEntryList.find(p => p.positionList?.length || p.stakedPositionList?.length)) { return; }
            // clean user data
            dispatch.farmFixRange.setPositionData([]);
            console.info('cleanPosition end');
        },
    })
});
