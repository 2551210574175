import Web3 from 'web3';

import {
    getMiningDynamicRangeBoostContract, 
} from '../../../../../utils/contractHelpers';
import { Contract } from 'web3-eth-contract';

import { FarmDynamicRangeContractVersion } from '../../../../../types/mod';
import { TokenInfoFormatted } from '../../../../../hooks/useTokenListFormatted';
import { tokenAddr2Token } from '../../../../../config/tokens';

export const pendingReward = async(miningContract: Contract, tokenId: string): Promise<string[]> => {
    const poolEarned: string[] = await miningContract.methods.pendingReward(tokenId).call();
    return poolEarned;
};

export const pendingRewards = async (miningContract: Contract, account: string): Promise<string[]> => {
    const poolEarned: string[] = await miningContract.methods.pendingRewards(account).call();
    return poolEarned;
};

export interface MiningContractInfo {
    token0_: string;
    token1_: string;
    fee_: string;
    iziTokenAddr_?: string;
    veiZiAddress_?: string;
    lastTouchBlock_: string;
    totalVLiquidity_: string;
    totalToken0_: string;
    totalToken1_: string;
    totalNIZI_?: string;
    totalValidVeiZi_?: string;
    startBlock_: string;
    endBlock_: string;
}
export interface OraclePrice {
    avgTick: string;
    avgSqrtPriceX96: string;
}
export interface RewardInfos {
    rewardToken: TokenInfoFormatted;
    provider: string;
    accRewardPerShare: string;
    rewardPerBlock: string;
}
export interface MiningDynamicRangeMetaInfo {
    miningContractInfo: MiningContractInfo;
    rewardInfos: RewardInfos[];
    oraclePrice: OraclePrice;
    tickRangeLeft: number;
    tickRangeRight: number;
}


export const getMiningDynamicRangeMetaInfo = async (miningAddress: string, chainId: number, web3: Web3, contractVersion: FarmDynamicRangeContractVersion): Promise<MiningDynamicRangeMetaInfo> => {
    const miningContract = getMiningDynamicRangeBoostContract(miningAddress, web3, contractVersion);
    const miningContractInfo = await miningContract?.methods.getMiningContractInfo().call();
    const rewardInfosLen = await miningContract?.methods.rewardInfosLen().call();
    const rewardInfos = [] as RewardInfos[];
    
    for (let i = 0; i < Number(rewardInfosLen); i ++) {
        const rewardInfo = await miningContract?.methods.rewardInfos(String(i)).call();
        rewardInfos.push({...rewardInfo, rewardToken: tokenAddr2Token(rewardInfo.rewardToken, chainId)});
    }
    const dynamicRangeMetaInfo = {} as MiningDynamicRangeMetaInfo;

    dynamicRangeMetaInfo.miningContractInfo = {...miningContractInfo};
    dynamicRangeMetaInfo.rewardInfos = rewardInfos;
    const oraclePrice = await miningContract?.methods.getOraclePrice().call();
    dynamicRangeMetaInfo.oraclePrice = {...oraclePrice};
    dynamicRangeMetaInfo.tickRangeLeft = Number(await miningContract?.methods.tickRangeLeft().call());
    dynamicRangeMetaInfo.tickRangeRight = Number(await miningContract?.methods.tickRangeRight().call());

    return dynamicRangeMetaInfo;
};

