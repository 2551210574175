import BigNumber from 'bignumber.js';
import produce from 'immer';
import { createModel } from '@rematch/core';
import { TransactionReceipt } from 'ethereum-abi-types-generator';

import { MyPrizeControl } from './types';
import { RootModel } from '..';
import { GameEvents } from '../../../net/iZUMi-endpoints/src/restful/izumiGame';
import { PrizeContract } from '../../../types/abis/prize/Prize';

export interface PrizeState {
    balance: number;
    eventStateChange: boolean;
    endList: GameEvents[];
    control: MyPrizeControl;
}

export interface PrizeClaimParams {
    token: string;
    maxAmount: number;
    account: string;
    prizeContract: PrizeContract;
    decimal: number;
    onGoingCallback?: () => void;
}

export interface PrizeBalanceParams {
    address: string;
    token: string;
    prizeContract?: PrizeContract;
    decimal: number;
    // onGoingCallback?: () => void;
}

export const prize = createModel<RootModel>()({

    state: {
        balance: 0,
        eventStateChange: false,
        endList: [],
        control: {
            timeEnd: 0,
            category: -1,
        },
    } as PrizeState,

    reducers: {
        setControl: (state: PrizeState, control: MyPrizeControl) => produce(state, draft => {
            draft.control = { ...control };
        }),
        setEventState: (state: PrizeState, eventStateChange: boolean) => produce(state, draft => {
            draft.eventStateChange = eventStateChange;
        }),
        setEndListState: (state: PrizeState, endList: GameEvents[]) => produce(state, draft => {
            draft.endList = endList;
        }),
        setBalance: (state: PrizeState, balance: number) => produce(state, draft => {
            draft.balance = balance;
        }),
    },

    effects: (dispatch) => ({
        async getBalance(params: PrizeBalanceParams): Promise<void> {
            if (!params || !params.address || !params.token || !params.prizeContract || !params.decimal) {
                return new Promise<void>((_, reject) => reject('Check balance fail'));
            }
            const { address, token, prizeContract, decimal } = params;
            const contract = prizeContract as PrizeContract;
            return contract.methods.balance(address, token).call().then((balance: string) => {
                if (decimal) {
                    dispatch.prize.setBalance(Number(balance) / (10 ** decimal));
                }
            }).catch((e) => {
                console.log('error  ', e);
            });

        },
        async claim(params: PrizeClaimParams): Promise<TransactionReceipt> {
            if (!params || !params.account || !params.token || !params.maxAmount || !params.prizeContract || !params.decimal) {
                return new Promise<TransactionReceipt>((_, reject) => reject('claim fail'));
            }
            const { account, token, maxAmount, prizeContract, decimal, onGoingCallback } = params;

            const contract = prizeContract as PrizeContract;
            return contract.methods.claim(token,new BigNumber(maxAmount * (10 ** decimal)).toFixed()).send({ from: account }).on(
                'transactionHash',
                () => {
                    if (typeof onGoingCallback != 'undefined') {
                        onGoingCallback();
                    }
                }
            );
        }
    })

});