import { Accordion, Center, Heading, HStack, Image, useColorMode } from '@chakra-ui/react';
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { ToastType, useCustomToast } from '../../../components/Toast/Toast';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import CustomButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { ErrorInfo } from '../../../iZUMi-UI-toolkit/src/components/ErrorInfo/ErrorInfo';
import { LiquidityInfo, getLiquiditysInfo } from '../../../net/endpoints/izumiMonitor';
import { LiquiditySortBy, RangeEnum } from '../../../state/models/liquidity/liquidityState';
import { RootDispatch, RootState } from '../../../state/store';
import { i_text_copy_bold } from '../../../style';
import { getColorThemeSelector } from '../../../utils/funcs';
import PositionListEntry from './PositionListEntry';

const PoolList: React.FC = () => {
    const { liquidity } = useSelector((state: RootState) => state);
    const { liquidity: myLiquidity } = useSelector(
        (state: RootState) => ({
            liquidity: state.liquidityData,
        }),
        shallowEqual
    );
    const {
        liquidityData: { control },
    } = useSelector((state: RootState) => state);

    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const toast = useCustomToast();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    useEffect(() => {
        if (!_.isArray(liquidity.ids)) {
            return;
        }
        getLiquiditysInfo(liquidity.ids)
            .then((r) => {
                dispatch.liquidityData.setLiquidity(r.data.data);
            })
            .catch((e: Error) => {
                toast(ToastType.error, e.message);
            });
    }, [dispatch.liquidityData, liquidity.ids]);
    const liquiditysFiltered: LiquidityInfo[] = useMemo(() => {
        let resultList = [...myLiquidity.liquiditys];

        if (control.dex) {
            resultList = resultList.filter((pool) => {
                return pool.dex.toLowerCase().includes(control.dex!.toLowerCase());
            });
        }
        if (control.network) {
            resultList = resultList.filter((pool) => {
                return pool.chainId === control.network;
            });
        }
        if (control.sortBy) {
            if (control.sortBy === LiquiditySortBy.Liquidity) {
                resultList = resultList.sort((a, b) => {
                    return (
                        b.liquidity.tokenX * b.liquidity.tokenXValue +
                        b.liquidity.tokenY * b.liquidity.tokenYValue -
                        (a.liquidity.tokenX * a.liquidity.tokenXValue + a.liquidity.tokenY * a.liquidity.tokenYValue)
                    );
                });
            } else if (control.sortBy === LiquiditySortBy.Dex) {
                resultList = resultList.sort((a, b) => {
                    return a.dex.localeCompare(b.dex);
                });
            } else if (control.sortBy === LiquiditySortBy.Network) {
                resultList = resultList.sort((a, b) => {
                    return b.chainId - a.chainId;
                });
            }
        }
        if (control.searchKey) {
            resultList = resultList.filter((pool) => {
                return (
                    pool.poolInfo.tokenX.symbol.toLowerCase().includes(control.searchKey.toLowerCase()) ||
                    pool.poolInfo.tokenY.symbol.toLowerCase().includes(control.searchKey.toLowerCase())
                );
            });
        }
        if (control.rangeType) {
            if (control.rangeType.length > 0) {
                const inRangeresultList = resultList.filter((pool) => {
                    if (control.rangeType.includes(RangeEnum.InRange)) {
                        return pool.liquidity.tokenX !== 0 && pool.liquidity.tokenY !== 0;
                    }
                });
                const outOfRangeresultList = resultList.filter((pool) => {
                    if (control.rangeType.includes(RangeEnum.OutOfRange)) {
                        return (
                            (pool.liquidity.tokenX === 0 && pool.liquidity.tokenY !== 0) ||
                            (pool.liquidity.tokenX !== 0 && pool.liquidity.tokenY === 0)
                        );
                    }
                });
                const closeresultList = resultList.filter((pool) => {
                    if (control.rangeType.includes(RangeEnum.Closed)) {
                        return pool.liquidity.tokenX === 0 && pool.liquidity.tokenY === 0;
                    }
                });
                return (resultList = uniqueArrays(inRangeresultList, outOfRangeresultList, closeresultList));
            }
        }
        resultList = resultList.filter((liquidity, index, self) => index === self.findIndex((p) => p.id === liquidity.id));
        return resultList;
    }, [control.dex, control.network, control.rangeType, control.searchKey, control.sortBy, myLiquidity]);

    function uniqueArrays(...arrays: any) {
        const combinedArray = arrays.flat();
        return Array.from(new Set(combinedArray)) as any;
    }

    return (
        <Accordion allowMultiple={true} allowToggle={true} w="100%" pb="30px">
            {liquiditysFiltered.length > 0 ? (
                liquiditysFiltered.map((item, index) => {
                    if (_.isEmpty(item) || _.isEmpty(item.liquidity)) {
                        return (
                            <HStack mb="30px">
                                <ErrorInfo w="500px" content={'Error Recoed'} key={index}></ErrorInfo>

                                <CustomButton
                                    w="173px"
                                    h="30px"
                                    className={i_text_copy_bold}
                                    variant="orange"
                                    text="Delete"
                                    leftIconElement={
                                        <Image
                                            boxSize="13px"
                                            src={colorTheme('/assets/liquidity/delete.svg', '/assets/liquidity/darkDelete.svg')}
                                            fallbackSrc={colorTheme('/assets/liquidity/delete.svg', '/assets/liquidity/darkDelete.svg')}
                                            mr="10px"
                                        ></Image>
                                    }
                                    onClick={() => {
                                        // toast(ToastType.info, 'Ongoing');
                                        dispatch.liquidity.deleteId(item.id);
                                        toast(ToastType.success, 'Delete successful');
                                    }}
                                ></CustomButton>
                            </HStack>
                        );
                    } else {
                        return <PositionListEntry entry={item} key={index} mb="30px"></PositionListEntry>;
                    }
                })
            ) : (
                <Center>
                    <Heading size="lg" color="tertiary.100">
                        {'No position yet'} .
                    </Heading>
                </Center>
            )}
        </Accordion>
    );
};

export default PoolList;
