import Web3 from 'web3';
import { AbiItem } from 'web3-utils';
import { ChainId } from '../types/mod';

// iZiSwap abi
import LimitOrderManagerABI from '../config/abi/iZiSwap/LimitOrderManager.json';
import LimitOrderWithSwapManagerABI from '../config/abi/iZiSwap/LimitOrderWithSwapManager.json';
import LiquidityManagerABI from '../config/abi/iZiSwap/LiquidityManager.json';
import izumiSwapABI from '../config/abi/iZiSwap/Swap.json';
import izumiSwapSingleABI from '../config/abi/iZiSwap/SwapSingle.json';
import izumiQuoterABI from '../config/abi/iZiSwap/Quoter.json';
import izumiPoolABI from '../config/abi/iZiSwap/Pool.json';
import wrapTokenABI from '../config/abi/iZiSwap/WrapToken.json';
import boxABI from '../config/abi/iZiSwap/Box.json';

//Prize abi
import PrizeABI from '../config/abi/prize/Prize.json';

// ERC20
import erc20lABI from '../config/abi/erc20.json';

import { LimitOrderManagerContract } from '../types/abis/iZiSwap/LimitOrderManager';
import {
    LIQUIDITY_MANAGER_ADDRESS,
    LIMIT_ORDER_MANAGER_ADDRESSES,
    QUOTER_ADDRESS,
    SWAP_ADDRESS,
    BOX_ADDRESS,
    QUOTER_ADDRESS_LIMIT,
    LIMIT_ORDER_WITH_SWAP_MANAGER_ADDRESSES,
} from '../config/trade/tradeContracts';
import { LiquidityManagerContract } from '../types/abis/iZiSwap/LiquidityManager';

import { SwapContract } from '../types/abis/iZiSwap/Swap';
import { QuoterContract } from '../types/abis/iZiSwap/Quoter';
import { PoolContract } from '../types/abis/iZiSwap/Pool';
import { WrapTokenContract } from '../types/abis/iZiSwap/WrapToken';
import { BoxContract } from '../types/abis/iZiSwap/Box';
import { Erc20Contract } from '../types/abis/erc20';
import memoizeOne from 'memoize-one';
import { TokenInfoFormatted } from '../hooks/useTokenListFormatted';
import { useMemo } from 'react';
import { PRIZE_ADDRESSES } from '../config/prize/prize';
import { PrizeContract } from '../types/abis/prize/Prize';
import { LimitOrderWithSwapManagerContract } from '../types/abis/iZiSwap/LimitOrderWithSwapManager';
import { SwapSingleContract } from '../types/abis/iZiSwap/SwapSingle';

export const getContract = <T>(abi: any, address: string, web3: Web3): T => {
    return new web3.eth.Contract(abi as unknown as AbiItem, address, {}) as unknown as T;
};

type getContractFn = <T>(abi: any, address: string, web3: Web3) => T;
// TODO: memoize getContract fail
const memoizedGetContract = memoizeOne(getContract) as getContractFn;

// ERC20
export const getErc20TokenContract = (token: TokenInfoFormatted, chainId: ChainId, web3: Web3): Erc20Contract => {
    const contractAddress = token.address;
    if (!contractAddress) {
        throw new Error(`unsupported token address: ${token.symbol}, ${chainId} , ${contractAddress}`);
    }
    return memoizedGetContract<Erc20Contract>(erc20lABI, contractAddress, web3);
};

export const getErc20TokenContractByAddr = (address: string, chainId: ChainId, web3: Web3): Erc20Contract => {
    return memoizedGetContract<Erc20Contract>(erc20lABI, address, web3);
};

// TODO Memoize contract
// iZiSwap contract
export const getLiquidityManagerContract = (chainId: ChainId, web3: Web3): LiquidityManagerContract | undefined => {
    const contractAddress = LIQUIDITY_MANAGER_ADDRESS[chainId];
    if (!contractAddress || !web3) {
        return undefined;
    }
    return getContract<LiquidityManagerContract>(LiquidityManagerABI, contractAddress, web3);
};

export const useLiquidityManagerContract = (chainId: ChainId, web3: Web3) => {
    const contract = useMemo(() => {
        return getLiquidityManagerContract(chainId, web3);
    }, [chainId, web3]);
    return contract;
};

export const getLimitOrderManagerContract = (chainId: ChainId, web3: Web3): LimitOrderManagerContract | undefined => {
    const contractAddress = LIMIT_ORDER_MANAGER_ADDRESSES[chainId];
    if (!contractAddress || !web3) {
        return undefined;
    }
    return getContract<LimitOrderManagerContract>(LimitOrderManagerABI, contractAddress, web3);
};

export const getLimitOrderWithSwapManagerContract = (chainId: ChainId, web3: Web3): LimitOrderWithSwapManagerContract | undefined => {
    const contractAddress = LIMIT_ORDER_WITH_SWAP_MANAGER_ADDRESSES[chainId];
    if (!contractAddress || !web3) {
        return undefined;
    }
    return getContract<LimitOrderWithSwapManagerContract>(LimitOrderWithSwapManagerABI, contractAddress, web3);
};

export const getLiquidityManagerContractAddress = (chainId: ChainId): string | undefined => {
    return LIQUIDITY_MANAGER_ADDRESS[chainId];
};

export const getLimitOrderManagerAddress = (chainId: ChainId): string | undefined => {
    return LIMIT_ORDER_MANAGER_ADDRESSES[chainId];
};

export const getSwapContract = (chainId: ChainId, web3: Web3): SwapContract | undefined => {
    const contractAddress = SWAP_ADDRESS[chainId];
    if (!contractAddress || !web3) {
        return undefined;
    }

    return getContract<SwapContract>(izumiSwapABI, contractAddress, web3);
};

export const getQuoterContract = (chainId: ChainId, web3: Web3, limit = true): QuoterContract | undefined => {
    const contractAddress = limit ? QUOTER_ADDRESS_LIMIT[chainId] ?? QUOTER_ADDRESS[chainId] : QUOTER_ADDRESS[chainId];
    if (!contractAddress || !web3) {
        return undefined;
    }

    return getContract<QuoterContract>(izumiQuoterABI, contractAddress, web3);
};

export const getWrapTokenContract = (web3: Web3, contractAddress: string): WrapTokenContract | undefined => {
    if (!contractAddress || !web3) {
        return undefined;
    }

    return getContract<WrapTokenContract>(wrapTokenABI, contractAddress, web3);
};

export const getBoxContract = (chainId: ChainId, web3: Web3): BoxContract | undefined => {
    const contractAddress = BOX_ADDRESS[chainId];
    if (!contractAddress || !web3) {
        return undefined;
    }
    return getContract<BoxContract>(boxABI, contractAddress, web3);
};

export const getPoolContractByAddress = (chainId: ChainId, web3: Web3, contractAddress: string): PoolContract | undefined => {
    if (!contractAddress || !web3) {
        return undefined;
    }

    return getContract<PoolContract>(izumiPoolABI, contractAddress, web3);
};

export const getPoolContractByAddressConfirm = (web3: Web3, contractAddress: string): PoolContract => {
    return getContract<PoolContract>(izumiPoolABI, contractAddress, web3);
};

export const getPrizeContract = (chainId: ChainId, web3: Web3): PrizeContract | undefined => {
    const contractAddress = PRIZE_ADDRESSES[chainId];
    if (!contractAddress || !web3) {
        return undefined;
    }

    return getContract<PrizeContract>(PrizeABI, contractAddress, web3);
};
