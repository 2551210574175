import { ConnectorNames } from "../../../utils/connectors";
import { createModel } from '@rematch/core';
import { RootModel } from '../index';
import produce from 'immer';

export interface WalletConfigState {
  lastLogin: ConnectorNames
}

export const loginConfig = createModel<RootModel>()({
  state: {
    lastLogin:ConnectorNames.Injected,

  } as WalletConfigState,
  reducers: {
      setLoginConfig: (state: WalletConfigState, connector: ConnectorNames) => produce(state, draft => {
          draft.lastLogin = connector;
          console.info("set:  ", connector)
      }),
  },

});