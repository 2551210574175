import { ChainId } from "../../../../../types/mod";
import { Path, PreQueryResult } from "../utils"

export interface UniV3PreQueryResult extends PreQueryResult {
    lastChainId?: ChainId
    pathWithOutFee100: Path[]
    pathWithFee100: Path[]
    pool: Map<string, string>
    poolSqrtPriceX96: Map<string, string>
}

export const initUniV3PreResult = (chainId: ChainId): UniV3PreQueryResult => {
    return {
        lastChainId: chainId,
        pathWithFee100: [] as Path[],
        pathWithOutFee100: [] as Path[],
        pool: new Map<string, string>(),
        poolSqrtPriceX96: new Map<string, string>()
    } as UniV3PreQueryResult
}