import { createModel } from '@rematch/core';
import { RootModel } from '../../index';
import produce from 'immer';
import { TokenInfoFormatted } from '../../../../hooks/useTokenListFormatted';
import { ChainId } from '../../../../types/mod';
interface TokensState {
    chainId: ChainId,
    tokens: TokenInfoFormatted[]
}

export interface RecentTokensState {
    latestTokens: TokensState[];
    length: number;
}

export const latestTokens = createModel<RootModel>()({
    state: {
        latestTokens: [],
        length: 8,
    } as RecentTokensState,

    reducers: {
        addToken: (state: RecentTokensState, chainId: ChainId, addToken: TokenInfoFormatted) => produce(state, draft => {
            const findItem = state.latestTokens && state.latestTokens.find((i) => i.chainId === chainId);
            const findItemIndex = state.latestTokens && state.latestTokens.findIndex((i) => i.chainId === chainId);
            if (!findItem) {
                const newTokens = [...state.latestTokens];
                const tokens = new Array(addToken);
                newTokens.unshift({ chainId, tokens });
                draft.latestTokens = newTokens;
            } else {
                const newToken = [...findItem.tokens];
                const isExistedTokenIndex = newToken.findIndex((item) => {
                    return item.name === addToken.name;
                });
                if (isExistedTokenIndex !== -1) {
                    newToken.splice(isExistedTokenIndex, 1);
                    newToken.unshift(addToken);
                    draft.latestTokens[findItemIndex].tokens = newToken;
                }
                else {
                    if (newToken.length === latestTokens.state.length) {
                        newToken.pop();
                        newToken.unshift(addToken);
                    } else {
                        newToken.unshift(addToken);
                    }
                    draft.latestTokens[findItemIndex].tokens = newToken;
                }
            }
        }),
        clearToken: (state: RecentTokensState, chainId: ChainId ) => produce(state, draft => {
            const findItems = state.latestTokens && state.latestTokens.filter((i) => i.chainId !== chainId);
            draft.latestTokens=findItems;
        })
    },

});