import { tick2PriceSqrt } from '../../../../../../utils/tickMath';
import { MintForm } from './addLiquidity';

// TODO token symbol chainId decimals
export const calculateAmountDesired = (mintForm: MintForm, currentPrice: number): number[] => {
    let amount0DesiredNew = 0;
    let amount1DesiredNew = 0;
    let liquidity = 0;
    const priceLowerSqrt = tick2PriceSqrt(mintForm.tickLower);
    const priceUpperSqrt = tick2PriceSqrt(mintForm.tickUpper);
    const priceCurrentSqrt = currentPrice ** 0.5;

    console.log('calculateAmountDesired', priceCurrentSqrt, priceLowerSqrt, priceUpperSqrt);
    
    // amount0 -> amount1
    if (mintForm.isLockFirstTokenVolume) {
        amount0DesiredNew = mintForm.amount0Desired;

        if (priceCurrentSqrt > priceUpperSqrt) {
            console.log('Only tokenB is required. This should not happen.');
            amount0DesiredNew = 0;
        } else if ( priceCurrentSqrt < priceLowerSqrt) {
            console.log('Only tokenA is required. No need to calc tokenB.');
        } else {
            liquidity = mintForm.amount0Desired / (1 / priceCurrentSqrt - 1/priceUpperSqrt);
            amount1DesiredNew = liquidity * (priceCurrentSqrt - priceLowerSqrt);
        }
    // amount1 -> amount0
    } else { 
        amount1DesiredNew = mintForm.amount1Desired;

        if (priceCurrentSqrt < priceLowerSqrt) {
            console.log('Only tokenA is required. This should not happen.');
            amount1DesiredNew = 0;
        } else if (priceCurrentSqrt > priceUpperSqrt) {
            console.log('Only tokenB is required. No need to calc tokenA.');
        } else {
            liquidity = mintForm.amount1Desired / (priceCurrentSqrt - priceLowerSqrt);
            amount0DesiredNew = liquidity * (1 / priceCurrentSqrt - 1/priceUpperSqrt);
        }
    }

    return [amount0DesiredNew, amount1DesiredNew];
};
