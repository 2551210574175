export const MenuClose: React.FC = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="30" height="30" rx="7" fill="#140731" />
            <path
                d="M20.59 8L15 13.59L9.41 8L8 9.41L13.59 15L8 20.59L9.41 22L15 16.41L20.59 22L22 20.59L16.41 15L22 9.41L20.59 8Z"
                fill="#FAFAFA"
            />
        </svg>
    );
};
