import { TokenInfoFormatted } from "../../../../hooks/useTokenListFormatted"
import { DagNode, Path, PathQuery, PathQueryResult, PreQueryResult, SwapDirection } from "./utils"

export class BasePreQueryPlugin {

    /**
     * pre query data which is usefull for path searching, etc pair address of pancake, point of iZiSwap pool...
     * in implement of BasePreQueryPlugin's subclass, we can cache part of preQueryResult to speed up pre querying
     * each BasePreQueryPlugin's subclass, should extends field of PreQueryResult to record pre query data needed
     */
    protected preQueryResult: PreQueryResult

    public constructor(preQueryResult: PreQueryResult) {
        this.preQueryResult = preQueryResult
    }

    /**
     * get the dag graph of pre query callings which are needed before path querying for this exchange
     * for example, if we use pancake and want to query path from iZi to BNB, we may want to do following 6 queryings:
     * 0. pair address of iZi-BNB, 1. pair address of iZi-USDT, 2. pair address of USDT-BNB
     * 3. reserves0/1 of pair iZi-BNB if the pair exists, 4. reserves0/1 of pair iZi-USDT if the pair exists
     * 5. reserves0/1 of pair USDT-BNB if the pair exists
     * 
     * we can easily see that, before we query 3, we should finish querying and parse of 0-th calling.
     * And before query of 4, 1 should be finished. Also 2 should be finished before 5.
     * and we can use a dag graph to describe dependencies above
     * 
     *  (0) -----> (3)
     *  (1) -----> (4)
     *  (2) -----> (5)
     * 
     * to save interaction time with rpc node, when our controller does pre-query, 
     * it will combine [0],[1],[2] to a multicall and combine [3][4][5] to another multicall which is behind of previous one
     * the above work will done in controller, our plugin only need to provide 
     * dependencies, parse func and methods to get (calling/contract_address) of each node
     * 
     * in the above example, DagNode[0] should fill following field:
     * 1. an empty or undefined array of dependencies
     * 2. a parse function to parse pair address of pair calling and record the address in this plugin class
     *     DagNode[3] may need this address infomation (see following content)
     *     the function getQueryResult(...) may also need this address infomation
     * 3. calling string and targetAddress string (which is contract address)
     * 
     * DagNode[3] should fill following field:
     * 1. specify array [0] as its pre node,
     * 2. a parse function to parse getReserves calling and record reserves0/reserves1 in this plugin class
     *     the function getQueryResult(...) may need this reserves0/reserves1 infomation
     * 3. a function to provide calling string and targetAddress string, this function may need to know parsed results of
     *     0-th calling.
     * 
     * to know form of dependencies, parse func and calling/contractAddress, you can refer fields of DagNode to learn more
     * 
     * Also, if we have saved pair of iZi-BNB before and in pancake, and we know that the pair address will not change in pancake.
     * we can only provide DagNode of [1][2][3][4][5], which may save query time a little.
     * 
     * @param tokenA tokenIn or tokenOut of user's swap
     * @param tokenB opposite token of tokenA in the swap
     * @returns a DagNode array, which represent pre query calling needed before path querying
     */
    public getPreQueryDag(tokenA: TokenInfoFormatted, tokenB: TokenInfoFormatted) : DagNode[] {
        return []
    }

    /**
     * after controller calling plugin's getPreQueryDag(...) functions,
     * the controller will do some multicall and parsing works provided by these DagNodes,
     * as mentioned in the commit of 'getPreQueryDag(...)' function, each DagNode's parse function should record
     * parsed response data in the plugin.
     * in this function, we should transform those recorded parsed data of each DagNode to any form we like
     * and store them in PreQueryResult object.
     * ofcourse each plugin should extend PreQueryResult
     * 
     * We continue the example of pancake, we may need write a PancakePreQueryResult extends from PreQueryResult
     * and we may need to record a map which inflect a token symbol pair string(like 'bnb-izi') to an address string (like '0xa15f....')
     * and we may need to record another map which inflect token symbol pair to an object {reserves0, reserves1}
     * if you like, you can list some paths generated from pre-queried pairs in the PreQueryResult
     * 
     * the corresponding BasePathQueryPlugin will take use of PreQueryResult's fields to do path searching
     * @returns PreQueryResult object, 
     */
    public getQueryResult(): PreQueryResult {
        return undefined as unknown as PreQueryResult
    }

    public reverse(): PreQueryResult {
        return undefined as unknown as PreQueryResult
    }

}

export interface TokenSpenderInfo {
    tokenToPay: TokenInfoFormatted
    spenderAddress?: string
    depositSpenderAddress?: string
}

export class BasePathQueryPlugin {

    /**
     * cached pre query data which is useful for path searching
     */
    protected preQueryResult: PreQueryResult

    public constructor(preQueryResult: PreQueryResult) {
        this.preQueryResult = preQueryResult
    }

    /**
     * given data of PreQueryResult, PathQueryPlugin can get some candidates of swap path
     * each candidate is an object of PathQuery
     * 
     * PathQuery we returned should fill following field:
     * 1. path, etc corresponding path, path.tokenChain[0] is tokenIn, and path.tokenChain[path.tokenChain.length - 1] is tokenOut
     * 2. pathQueryCalling, PathQueryCalling | undefined, calling to query quoter contract to get acquired or payed amount
     * 3. pathQueryResult, PathQueryResult | undefined, if we could compute acquired or payed amount directly, we can fill such computed
     * infomation (acquired or payed amount, price impact, ...) in this object
     * 
     * for some swap like iZiSwap, uniswapV3, we should query quoter to get acquired or payed amount of the path
     * but for some other swaps like biswap and pancake, we can compute the acquired or payed amount directly if we have already known 
     * 'reserves0/1' infomation of each pair on the path.
     * 
     * so, the in this function, if we have to take use of quoter contract to query acquired or payed amount of the path, we should fill the field
     * 'PathQuery.pathQueryCalling', if we could compute the amount directly, we should fill the field 'PathQuery.pathQueryResult'
     * 
     * After the controller calling this function and get a list of PathQuery object, it will gather all pathQueryCallings which is not undefined from
     * each PathQuery object and do some multicalls to get response from corresponding quoter for those calling.
     * 
     * @param tokenIn token to pay in this exchange
     * @param tokenOut token to get after exchange
     * @param direction ExactIn to specify payed amount(undecimal) of tokenIn, ExactOut to specify desired amount(undecimal) of tokenOut
     * @param amount payed or desired undecimal amount, according to 'direction'
     * @returns path candidates, etc, a list of PathQuery object
     */
    public getPathQuery(tokenIn: TokenInfoFormatted, tokenOut: TokenInfoFormatted, direction: SwapDirection, amount: string): PathQuery[] {
        return []
    }

    /**
     * if the function PathQueryPlugin.getPathQuery(...) returned some PathQuery object with defined pathQueryCallings,
     * the controller will do multicalls for them and calling this function 'parseCallingResponse' to parse correspindg response
     * 
     * we should return a PathQueryResult object after parsing
     * the PathQueryResult obj will contain some infomations user wants to know, like acquired or payed amount, price impact, ...
     * you can refer to codes of PathQueryResult to learn more
     * @param path path of swap
     * @param direction ExactIn or ExactOut
     * @param amount payed or desired undecimal amount, according to 'direction'
     * @param result response from quoter
     * @returns PathQueryResult object after parsing
     */
    public parseCallingResponse(path: Path, direction: SwapDirection, amount: string, result: string): PathQueryResult {
        return undefined as unknown as PathQueryResult
    }

    public getTokenSpenderInfo(path: Path, direction: SwapDirection): TokenSpenderInfo {
        return undefined as unknown as TokenSpenderInfo
    }

    public getSwapTransaction(path: Path, direction: SwapDirection, amountIn: string, amountOut: string, account: string, maxDelay: number, slippagePercent: number) : {calling: any, options: any} {
        return undefined as unknown as {calling: any, options: any}
    }
    
}