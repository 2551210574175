import { BigNumber } from "bignumber.js";
import { PromiEvent, TransactionReceipt } from "ethereum-abi-types-generator";
import { isAddress } from "ethers/lib/utils";
import Web3 from "web3";
import { getChain, getTxLink } from "../../../../config/chains";
import { TokenInfoFormatted } from "../../../../hooks/useTokenListFormatted";
import { ToastLink } from "../../../../iZUMi-UI-toolkit/src/components/Toast/Toast";
import { StateResponse } from "../../../../types/abis/iZiSwap/Pool";
import { ChainId } from "../../../../types/mod";
import { getPoolContractByAddress } from "../../../../utils/contractFactory";
import { buildSendingParams } from "../../../../utils/contractHelpers";
import { toContractFeeNumber } from "../../../../utils/funcs";
import { getSortedTokenAddr, getSortedTokenWithWrap, getSwapTokenAddress } from "../../common/positionPoolHelper";
import { priceDecimal2Point, PriceRoundingType } from "../utils/priceMath";
import { CreatePoolParams, FetchPoolParams, PoolState } from "./types";

export const getiZiSwapPoolKey=(tokenA: TokenInfoFormatted, tokenB: TokenInfoFormatted, fee: FeeTier): string => {
    const tokenAAddr = getSwapTokenAddress(tokenA).toLowerCase()
    const tokenBAddr = getSwapTokenAddress(tokenB).toLowerCase()
    if (tokenAAddr < tokenBAddr) {
        return tokenAAddr + '-' + tokenBAddr + '-' + String(toContractFeeNumber(fee))
    } else {
        return tokenBAddr + '-' + tokenAAddr + '-' + String(toContractFeeNumber(fee))
    }
}

export const fetchPoolState = async(fetchPoolParams: FetchPoolParams): Promise<PoolState> => {
    const { chainId, web3, baseContract: liquidityManagerContract, tokenA, tokenB, fee } = fetchPoolParams;
    if (!chainId || !web3 || !tokenA.address || !tokenB.address || !fee || !liquidityManagerContract) { return undefined as unknown as PoolState; }

    const token0Address = tokenA.wrapTokenAddress ?? tokenA.address
    const token1Address = tokenB.wrapTokenAddress ?? tokenB.address

    const [tokenLowerAddress, tokenUpperAddress] = getSortedTokenAddr(token0Address, token1Address);

    const poolAddress = await liquidityManagerContract.methods.pool(tokenLowerAddress, tokenUpperAddress, toContractFeeNumber(fee)).call();

    if (!isAddress(poolAddress) || (new BigNumber(poolAddress)).isEqualTo(0)) { 
        return undefined as unknown as PoolState
    }

    const poolContract = getPoolContractByAddress(chainId, web3, poolAddress);
    const stateResponse = await poolContract!.methods.state().call();
    const poolKey = getiZiSwapPoolKey(tokenA, tokenB, fee)
    return {...stateResponse, poolAddress, poolKey} as PoolState
}

export const fetchPoolStateByAddress = async(address: string, chainId: ChainId, web3: Web3): Promise<StateResponse> => {
    const poolContract = getPoolContractByAddress(chainId, web3, address);
    return await poolContract!.methods.state().call();
}


export const createPool = (params: CreatePoolParams): Promise<TransactionReceipt> | PromiEvent<TransactionReceipt> => {
    if (!params || !params.account || !params.liquidityManagerContract || !params.chainId
        || !params.initPriceDecimalAByB || !params.tokenA.symbol || !params.tokenB.symbol || !params.fee) {
        return new Promise<TransactionReceipt>((_, reject) => reject('Check CreatePoolParams fail'));
    }
    const { account, liquidityManagerContract,onGoingCallback } = params;

    const tokenA = params.tokenA;
    const tokenB = params.tokenB;
    const [tokenLower, tokenUpper] = getSortedTokenWithWrap(tokenA, tokenB);

    const point = priceDecimal2Point(tokenA, tokenB, params.initPriceDecimalAByB, PriceRoundingType.PRICE_ROUNDING_NEAREST)

    const contractFeeNumber = toContractFeeNumber(params.fee);
    console.log('createPoolRequest', { tokenLower, tokenUpper, contractFeeNumber, point, price: params.initPriceDecimalAByB });
    
    const chain  = getChain(params.chainId);
    const toastLink = {} as ToastLink;

    const transactionReceipt: PromiEvent<TransactionReceipt> = liquidityManagerContract.methods.createPool(
        getSwapTokenAddress(tokenLower), 
        getSwapTokenAddress(tokenUpper), 
        contractFeeNumber, 
        point
    ).
    send(
        buildSendingParams(
            params.chainId, 
            { 
                from: account, 
                maxFeePerGas: params.gasPrice,
            },
            params.gasPrice
        ) as any
    ).
    on(
        'transactionHash',
        (hash: string) => {
            if (chain) {
                toastLink.title = "View on " + chain.name;
                toastLink.link = getTxLink(hash, chain);
            }
            if(typeof onGoingCallback !='undefined'){
            onGoingCallback(toastLink);
            }
        }
    );
    return transactionReceipt 
}