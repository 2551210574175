import axios from 'axios';
import { ChainId } from '../../types/mod';
import { NormalResponseDataWrapper, RequestNormal, Response } from '../iZUMi-endpoints/src/restful/apiUtils';
import { ENDPOINTS } from './apiEndpoints';

export enum Dex {
    iZiSwap = 'iZiSwap',
    UniswapV3 = 'UniswapV3',
    PancakeSwap = 'PancakeSwap',
}
export type Status = 'ALIVE' | 'PAUSE';

interface Token {
    symbol: string;
    address: string;
}

interface PoolInfo {
    tokenX: Token;
    tokenY: Token;
    feeRate: number;
}

interface Range {
    left: number;
    right: number;
}

interface Fees {
    tokenX: number;
    tokenXValue: number;
    tokenY: number;
    tokenYValue: number;
}

interface Liquidity {
    tokenX: number;
    tokenY: number;
    tokenXValue: number;
    tokenYValue: number;
}

export interface LiquidityInfo {
    id: number;
    dex: Dex;
    chainId: ChainId;
    lpid: number;
    owner: string;
    poolInfo: PoolInfo;
    range: Range;
    fees: Fees;
    liquidity: Liquidity;
    status: Status;
}

export interface LiquidityRequest {
    dex: Dex;
    chainId: ChainId;
    lpid: number;
}
export interface ResponsePoolInfo {
    id: number;
    dex: string;
    chainId: number;
    address: string;
    tokenX: {
        symbol: string;
        address: string;
    };
    tokenY: {
        symbol: string;
        address: string;
    };
    fee: number;
}
export interface ResponseChartInfo {
    liqDist: number[][];
    currentTickIdx: number;
    currentTick: number;
    leftTick: number;
    tickSpacing: number;
    amountList: number[][];
}

export interface ID {
    id: number;
}

export const addLiquidity: RequestNormal<LiquidityRequest, ID> = async (params) => {
    return axios.post(ENDPOINTS.monitor.add, { params });
};

export const refreshLiquidity: RequestNormal<ID, LiquidityInfo> = async (params) => {
    return axios.post(ENDPOINTS.monitor.refresh, { params });
};

export const stopLiquidity: RequestNormal<ID, string> = async (params) => {
    return axios.post(ENDPOINTS.monitor.stop, { params });
};

export const getLiquiditysInfo: RequestNormal<number[], LiquidityInfo[]> = (ids) => {
    const listParams = new URLSearchParams();
    ids.forEach((s) => listParams.append('id', String(s)));
    return axios.get(ENDPOINTS.monitor.get, { params: listParams });
};

export const searchLiquidityInfo: RequestNormal<LiquidityRequest, LiquidityInfo> = async (params) => {
    return axios.get(ENDPOINTS.monitor.search, { params });
};

export const getAllLiquidityChartInfo = async (): Response<NormalResponseDataWrapper<ResponsePoolInfo[]>> => {
    return axios.get(ENDPOINTS.monitor.poolList);
};

export const getLiquidityChartInfo: RequestNormal<ID, ResponseChartInfo> = async (params) => {
    return axios.get(ENDPOINTS.monitor.poolInfo, { params });
};

export const refreshLiquidityChartInfo: RequestNormal<ID, ResponseChartInfo> = async (params) => {
    return axios.post(ENDPOINTS.monitor.refreshPool, { params });
};
