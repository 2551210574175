import { createModel } from '@rematch/core';
import BigNumber from 'bignumber.js';
import { isAddress } from 'ethers/lib/utils';
import produce from 'immer';

import { RootModel } from '../../..';
import { tokenSymbol2token } from '../../../../../config/tokens';
import { parallelCollect, parallelCollectPair } from '../../../../../net/contractCall/parallel';
import { Slot0Response } from '../../../../../types/abis/UniswapV3/UniswapV3Pool';
import { ChainId, FarmDynamicRangeContractVersion, FarmOneSideContractVersion, TokenSymbol } from '../../../../../types/mod';
import { decodeMethodResult, getMiningDynamicRangeBoostContract, getMiningDynamicRangeV2Contract, getMiningDynamicRangeVeiZiContract, getPositionPoolContract, getVeiZiContract } from '../../../../../utils/contractHelpers';
import { amount2Decimal } from '../../../../../utils/tokenMath';
import { getMiningDynamicRangeMetaInfo, MiningDynamicRangeMetaInfo, pendingReward, pendingRewards } from './miningDynamicRangeContractHelper';
import { getPositionPoolKey } from '../../../common/positionPoolHelper';
import { addIZIBoostAPR, findPoolEntryByPoolKey, getABAmountInUniswap, getPoolAPR, getPriceAByB, getPriceAByBDecimal, getTokenStatus, symbol2Decimal, veiZiBoostAPR } from './funcs';
import { getChain } from '../../../../../config/chains';

import {
    FarmDynamicRangeState,
    FarmControl,
    PoolEntryState,
    InitPoolListMetaParams,
    MiningPoolData,
    MiningPoolUserData,
    InitPoolListDataParams,
    MiningPoolMeta,
    InitPositionParams,
    PositionEntry,
    PositionDetail,
    RefreshPoolListDataAndPositionParams,
    TokenStatusResponse
} from './types';
import { VEIZI_ADDRESS } from '../../../../../config/veizi/veiziContracts';
import { toContractFeeNumber } from '../../../../../utils/funcs';

export const farmDynamicRange = createModel<RootModel>()({
    state: {
        positionManagerContract: undefined,
        poolEntryList: [],
        farmView: {
            isFarmDataLoading: false,
            isUserDataLoading: false,
        },
        farmControl: {
            sortBy: undefined,
            stakedOnly: false,
            searchKey: undefined,
            type: 'live',
        }
    } as FarmDynamicRangeState,
    reducers: {
        setFarmState: (state: FarmDynamicRangeState, payload: FarmDynamicRangeState) => {
            return { ...state, ...payload };
        },
        setFarmControl: (state: FarmDynamicRangeState, farmControl: FarmControl) => produce(state, draft => {
            draft.farmControl = { ...farmControl };
        }),
        setPoolEntryList: (state: FarmDynamicRangeState, payload: PoolEntryState[]) => produce(state, draft => {
            // void freeze object when initPoolListByMeta set meta state first
            draft.poolEntryList = JSON.parse(JSON.stringify(payload));
        }),
        setPoolEntryListMeta: (state: FarmDynamicRangeState, { chainId, poolEntryList }: { chainId: ChainId, poolEntryList: PoolEntryState[] }) => {
            return { ...state, poolEntryList, currentFarmChainId: chainId };
        },
        setPoolEntryListData: (state: FarmDynamicRangeState, payload: PoolEntryState[]) => produce(state, draft => {
            for (const poolEntry of payload) {
                const draftPoolEntry = findPoolEntryByPoolKey(draft.poolEntryList, poolEntry.meta.positionPoolKey);
                draftPoolEntry.data = poolEntry.data;
            }
        }),
        setPositionData: (state: FarmDynamicRangeState, payload: PoolEntryState[]) => produce(state, draft => {
            const positionPoolKeySet = new Set();
            for (const poolEntry of payload) {
                const draftPoolEntry = findPoolEntryByPoolKey(draft.poolEntryList, poolEntry.meta.positionPoolKey);
                draftPoolEntry.userData = poolEntry.userData;
                draftPoolEntry.positionList = poolEntry.positionList;
                draftPoolEntry.stakedPositionList = poolEntry.stakedPositionList;

                positionPoolKeySet.add(poolEntry.meta.positionPoolKey);
            }
            // clean other
            draft.poolEntryList.filter(p => !positionPoolKeySet.has(p.meta.positionPoolKey)).forEach(p => {
                if (p.meta.twoRewards) {
                    p.userData.earned = ['0', '0'];
                } else {
                    p.userData.earned = ['0'];
                }
                p.positionList = [];
                p.stakedPositionList = [];
            });
        }),
        setFarmDataLoading: (state: FarmDynamicRangeState, isLoading: boolean) => produce(state, draft => {
            draft.farmView.isFarmDataLoading = isLoading;
        }),
        setUserDataLoading: (state: FarmDynamicRangeState, isLoading: boolean) => produce(state, draft => {
            draft.farmView.isUserDataLoading = isLoading;
        }),
        togglePoolMetaInitialToggle: (state: FarmDynamicRangeState, positionPoolKey: string) => produce(state, draft => {
            const draftPoolEntry = findPoolEntryByPoolKey(draft.poolEntryList, positionPoolKey);
            draftPoolEntry.meta.initialToggle = !draftPoolEntry.meta.initialToggle;
        }),
    },
    effects: (dispatch) => ({

        async initPoolListMeta(initPoolListMetaParams: InitPoolListMetaParams): Promise<void> {
            const { chainId, metaList } = initPoolListMetaParams;
            if (!chainId) { return; }
            const startTime = new Date();

            const poolEntryList = [] as PoolEntryState[];
            // TODO filter
            // TODO contract multicall or web3 batch request or parallel
            for (const configMeta of metaList ?? []) {
                const data = {} as MiningPoolData;
                const contractVersion = configMeta.contractVersion? configMeta.contractVersion: FarmDynamicRangeContractVersion.V2;
                const additionalKey = configMeta.additionalKey ?? '01';
                const positionPoolKey = getPositionPoolKey(configMeta.tokenA.address, configMeta.tokenB.address, configMeta.feeTier, contractVersion, additionalKey);

                const meta = { ...configMeta, positionPoolKey, contractVersion: contractVersion } as MiningPoolMeta;

                const userData = {} as MiningPoolUserData;
                if (meta.twoRewards) {
                    userData.earned = ['0', '0'];
                } else {
                    userData.earned = ['0'];
                }
                const poolEntryState = { meta, data, userData } as PoolEntryState;
                poolEntryList.push(poolEntryState);
            }
            dispatch.farmDynamicRange.setPoolEntryListMeta({ chainId, poolEntryList });
            // sync init meta data for render basic view
            console.log(`initPoolListMeta end, ${(new Date()).getTime() - startTime.getTime()} ms`);
        },
        async initPoolListData(initPoolListDataParams: InitPoolListDataParams, rootState): Promise<void> {
            const { chainId, web3, positionFactoryContract } = initPoolListDataParams;
            if (!chainId || !web3 || !positionFactoryContract) { return; }
            if (rootState.farmDynamicRange.poolEntryList.length === 0) { return; }

            const blockDeltaU = getChain(chainId)?.blockDeltaU ?? 10;

            const poolEntryDataList = [] as PoolEntryState[];
            const asyncProcessList: Promise<void>[] = [];
            for (const poolEntry of rootState.farmDynamicRange.poolEntryList) {
                const { meta } = poolEntry;
                const data = {} as MiningPoolData;
                const poolEntryData = {
                    meta: { positionPoolKey: meta.positionPoolKey } as MiningPoolMeta,
                    data
                } as PoolEntryState;

                poolEntryDataList.push(poolEntryData);

                const asyncProcess = async () => {
                    // 1. get mining contract info
                    const miningDynamicRangeMetaInfo: MiningDynamicRangeMetaInfo = await getMiningDynamicRangeMetaInfo(meta.miningContract, chainId, web3, meta.contractVersion);
                    // 2. get position pool slot0 info from pool factory contract
                    const positionPoolAddress = await positionFactoryContract.methods.getPool(meta.tokenA.address, meta.tokenB.address, toContractFeeNumber(meta.feeTier)).call();
                    const poolContract = getPositionPoolContract(positionPoolAddress, web3);
                    const slot0: Slot0Response = await poolContract?.methods.slot0().call();

                    // 3. get swap and reward token price and set price data
                    const tokenPriceAB = await parallelCollect(
                        dispatch.token.fetchTokenPriceIfMissing(meta.tokenA),
                        dispatch.token.fetchTokenPriceIfMissing(meta.tokenB),
                        dispatch.token.fetchTokenPriceIfMissing(tokenSymbol2token(TokenSymbol.IZI, chainId))
                    );
                    const tokenPriceB: number = tokenPriceAB[1];
                    const tokenPriceA: number = tokenPriceAB[0];
                    const tokenPriceiZi: number = tokenPriceAB[2];

                    data.isEnded = Number(miningDynamicRangeMetaInfo.miningContractInfo.endBlock_) < Number(rootState.block.currentBlock);
                    data.endBlock = Number(miningDynamicRangeMetaInfo.miningContractInfo.endBlock_);
                    data.secondsLeft = data.isEnded? 0: (Number(miningDynamicRangeMetaInfo.miningContractInfo.endBlock_) - Number(rootState.block.currentBlock)) * blockDeltaU;

                    data.tokenPriceIZIDecimal = await dispatch.token.fetchTokenPriceIfMissing(tokenSymbol2token(TokenSymbol.IZI, chainId));

                    data.tokenAPriceDecimal = tokenPriceA;
                    data.tokenBPriceDecimal = tokenPriceB;

                    data.iZiPriceDecimal = await dispatch.token.fetchTokenPriceIfMissing(tokenSymbol2token(TokenSymbol.IZI, chainId));

                    const tokenAAmount = new BigNumber(miningDynamicRangeMetaInfo.miningContractInfo.totalToken0_);
                    const tokenBAmount = new BigNumber(miningDynamicRangeMetaInfo.miningContractInfo.totalToken1_);

                    data.tokenAAmountDecimal = amount2Decimal(tokenAAmount, meta.tokenA) ?? 0;
                    data.tokenBAmountDecimal = amount2Decimal(tokenBAmount, meta.tokenB) ?? 0;

                    data.vLiquidity = Number(miningDynamicRangeMetaInfo.miningContractInfo.totalVLiquidity_);
                    data.totalNIZI = Number(miningDynamicRangeMetaInfo.miningContractInfo.totalNIZI_ ?? 0);
                    data.totalValidVeiZi = Number(miningDynamicRangeMetaInfo.miningContractInfo.totalValidVeiZi_ ?? 0);

                    data.tokenAWorth = data.tokenAAmountDecimal * data.tokenAPriceDecimal;
                    data.tokenBWorth = data.tokenBAmountDecimal * data.tokenBPriceDecimal;

                    data.capital = data.tokenAWorth + data.tokenBWorth;
                    data.tvl = data.capital + (amount2Decimal(new BigNumber(data.totalNIZI), tokenSymbol2token(TokenSymbol.IZI, chainId)) ?? 0) * tokenPriceiZi;

                    let apr = 0;
                    data.reward = [];
                    data.rewardTokens = [];
                    data.rewardTokenPrice = [];
                    for (const rewardInfo of miningDynamicRangeMetaInfo.rewardInfos) {
                        const rewardToken = rewardInfo.rewardToken;

                        const rewardTokenPrice = await dispatch.token.fetchTokenPriceIfMissing(rewardToken);
                        if (data.capital > 0) {
                            apr += getPoolAPR(rewardInfo.rewardPerBlock, chainId, data.capital, { left: rewardToken.symbol, right: rewardTokenPrice });
                        }
                        // data.reward.push([rewardTokenSymbol, getPoolRewards(rewardTokenPart, chainId)]);
                        const rewardDecimal = (amount2Decimal(
                            new BigNumber(rewardInfo.rewardPerBlock),
                            rewardToken
                        ) ?? 0);
                        data.reward.push([rewardToken, Number(rewardDecimal)]);
                        data.rewardTokens.push(rewardToken);
                        data.rewardTokenPrice.push(rewardTokenPrice);
                    }

                    if (meta.iZiBoost || meta.veiZiBoost) {
                        data.apr=[apr/2.5, apr];
                    } else{
                        data.apr = [apr];
                    }

                    // 4. set position data
                    data.positionPoolContract = positionPoolAddress;
                    data.positionSqrtPriceX96 = slot0.sqrtPriceX96;
                    data.positionTick = Number(slot0.tick);
                    data.currentTick = Number(slot0.tick);
                    data.priceAByBDecimal = getPriceAByBDecimal(meta.tokenA, meta.tokenB, slot0.sqrtPriceX96);
                    data.priceAByB = getPriceAByB(slot0.sqrtPriceX96);


                    const oracleTick = Number(miningDynamicRangeMetaInfo.oraclePrice.avgTick);
                    data.oracleTick = oracleTick;
                    
                    data.tickLeft = oracleTick - miningDynamicRangeMetaInfo.tickRangeLeft;
                    data.tickRight = oracleTick + miningDynamicRangeMetaInfo.tickRangeRight;

                    data.oraclePriceAByB = getPriceAByB(miningDynamicRangeMetaInfo.oraclePrice.avgSqrtPriceX96);
                    data.oraclePriceAByBDecimal = getPriceAByBDecimal(meta.tokenA, meta.tokenB, miningDynamicRangeMetaInfo.oraclePrice.avgSqrtPriceX96);

                };
                asyncProcessList.push(asyncProcess());
            }
            await Promise.all(asyncProcessList);

            dispatch.farmDynamicRange.setPoolEntryListData(poolEntryDataList);

        },
        async initPoolList(initPoolListParams: InitPoolListMetaParams & InitPoolListDataParams): Promise<void> {
            await dispatch.farmDynamicRange.initPoolListMeta(initPoolListParams);
            await dispatch.farmDynamicRange.initPoolListData(initPoolListParams);
        },
        async initPosition(initPositionParams: InitPositionParams, rootState): Promise<void> {

            const { chainId, web3, positionManagerContract, account } = initPositionParams;

            if (!chainId || !web3 || !account || !positionManagerContract || !rootState.farmDynamicRange.poolEntryList) { return; }
            if (rootState.farmDynamicRange.poolEntryList.length === 0) { return; }

            // dispatch.farm.setUserDataLoading(true);
            // 1. get total nft by account
            const unstakedTokenTotal = await positionManagerContract.methods.balanceOf(account).call().then((unstakedTokenTotal: number) => unstakedTokenTotal);

            // 2. get unstaked tokenId list by unstakedTokenTotal
            const unstakedTokenIdMulticallData = [];
            for (let i = 0; i < unstakedTokenTotal; i++) {
                unstakedTokenIdMulticallData.push(positionManagerContract.methods.tokenOfOwnerByIndex(account, i).encodeABI());
            }

            const stakedTokenIdList: string[] = [];
            const stakedTokenStatusList: TokenStatusResponse[] = [];

            type miningPoolValueType = { tokenIdSet: Set<string>, address: string, poolEntry: PoolEntryState }
            const miningPoolData = {} as { [index: string]: miningPoolValueType };
            const asyncProcessList: Promise<void>[] = [];

            const veiZiAddress = VEIZI_ADDRESS[chainId];
            const veiZiContract = getVeiZiContract(veiZiAddress, web3);
            const stakingInfo = await veiZiContract?.methods.stakingInfo(account).call();

            const veiZi = Number(stakingInfo?.amount ?? 0);
            const veiZiNftId = stakingInfo?.nftId ?? '0';

            const veiZiDecimal = amount2Decimal(new BigNumber(stakingInfo?.amount?? 0), tokenSymbol2token(TokenSymbol.IZI, chainId)) ?? 0;

            // get staked tokenIds and corresponding tokenStatus

            for (const poolEntryState of rootState.farmDynamicRange.poolEntryList) {
                const asyncProcess = async () => {
                    const miningContract = getMiningDynamicRangeBoostContract(poolEntryState.meta.miningContract, web3, poolEntryState.meta.contractVersion);
                    const {left: poolEarnedList, right: tokenIds} = await parallelCollectPair(
                        pendingRewards(miningContract as unknown as any, account),
                        miningContract?.methods.getTokenIds(account).call() as Promise<string[]>
                    );

                    const userData = {} as MiningPoolUserData;
                    userData.earned = poolEarnedList;
                    userData.veiZi = veiZi;
                    userData.veiZiNftId = veiZiNftId;
                    userData.veiZiDecimal = veiZiDecimal;
                    userData.vLiquidity = 0;
                    userData.capital = 0;

                    const positionPoolKey = poolEntryState.meta.positionPoolKey;
                    const twoRewards = poolEntryState.meta.twoRewards;
                    const contractVersion = poolEntryState.meta.contractVersion;

                    const poolEntry = {
                        meta: { positionPoolKey, twoRewards, contractVersion } as MiningPoolMeta,
                        userData: userData,
                        positionList: [] as PositionEntry[],
                        stakedPositionList: [] as PositionEntry[],
                    } as PoolEntryState;
                    const tokenStatus: TokenStatusResponse[] = await getTokenStatus(miningContract, tokenIds);

                    miningPoolData[poolEntryState.meta.miningContract] = { tokenIdSet: new Set(tokenIds), address: poolEntryState.meta.miningContract, poolEntry };

                    stakedTokenIdList.push(...tokenIds);
                    stakedTokenStatusList.push(...tokenStatus);

                };
                asyncProcessList.push(asyncProcess());
            }

            await Promise.all(asyncProcessList);

            // all positions are staked position !!!!!
            const allTokenId = [...stakedTokenIdList];
            const allTokenStatus = [...stakedTokenStatusList];
            const positionMulticallData = allTokenId.map(tokId => positionManagerContract.methods.positions(tokId).encodeABI());
            const positionResult: string[] = await positionManagerContract.methods.multicall(
                positionMulticallData
            ).call().then((positions: string[]) => positions);
            const positions: PositionDetail[] = positionResult.map((p, i) => {
                const position: PositionDetail = decodeMethodResult(positionManagerContract, 'positions', p);
                position.tokenId = allTokenId[i];
                position.tokenStatus = allTokenStatus[i];
                return position;
            });

            const stakedPositionList = positions;

            // 7. get pendingReward for each stakedPosition
            const asyncRewardProcessList: Promise<void>[] = [];
            for (const stakedPosition of stakedPositionList) {
                const asyncRewardProcess = async () => {
                    const stakedTokenId = stakedPosition.tokenId;
                    const miningPool = Object.values(miningPoolData).filter(d => d.tokenIdSet.has(stakedTokenId))[0];
                    if (miningPool.poolEntry.meta.contractVersion === FarmOneSideContractVersion.V2) {
                        const miningContract = getMiningDynamicRangeV2Contract(miningPool.address, web3);
                        stakedPosition.earned = await pendingReward(miningContract as unknown as any, stakedTokenId);
                    }
                };
                asyncRewardProcessList.push(asyncRewardProcess());
            }
            await Promise.all(asyncRewardProcessList);

            // 8. pure function calculate data
            for (const position of positions) {

                // let miningPool: miningPoolValueType;
                const miningPool = Object.values(miningPoolData).find(d => d.tokenIdSet.has(position.tokenId.toString())) as miningPoolValueType;

                const { meta, data } = rootState.farmDynamicRange.poolEntryList.find(p => p.meta.miningContract === miningPool.address) as PoolEntryState;
                const poolEntry = miningPool.poolEntry;
                const [tokenPriceA, tokenPriceB] = await parallelCollect(
                    dispatch.token.fetchTokenPriceIfMissing(meta.tokenA),
                    dispatch.token.fetchTokenPriceIfMissing(meta.tokenB)
                );
                // let amountLock = 0;
                const positionEntry = { nftId: position.tokenId, isStaked: true } as PositionEntry;

                positionEntry.vLiquidity = Number(position.tokenStatus.vLiquidity);
                const tokenAAmount = new BigNumber(position.tokenStatus.amount0);
                const tokenBAmount = new BigNumber(position.tokenStatus.amount1);
                positionEntry.tokenAAmountDecimal = amount2Decimal(tokenAAmount , meta.tokenA) ?? 0;
                positionEntry.tokenBAmountDecimal = amount2Decimal(tokenBAmount , meta.tokenB) ?? 0;

                positionEntry.tokenAWorth = positionEntry.tokenAAmountDecimal * tokenPriceA;
                positionEntry.tokenBWorth = positionEntry.tokenBAmountDecimal * tokenPriceB;

                positionEntry.capital = positionEntry.tokenAWorth + positionEntry.tokenBWorth;

                positionEntry.liquidity = position.liquidity;
                positionEntry.tickLower = position.tickLower;
                positionEntry.tickUpper = position.tickUpper;

                positionEntry.minPrice = Math.pow(1.0001, Number(position.tickLower));
                positionEntry.maxPrice = Math.pow(1.0001, Number(position.tickUpper));
                const tokenADecimal: number = symbol2Decimal(chainId, meta.tokenA.symbol) ?? 0;
                const tokenBDecimal: number = symbol2Decimal(chainId, meta.tokenB.symbol) ?? 0;

                positionEntry.minPriceDecimal = positionEntry.minPrice * ((10 ** tokenADecimal) / (10 ** tokenBDecimal));
                positionEntry.maxPriceDecimal = positionEntry.maxPrice * ((10 ** tokenADecimal) / (10 ** tokenBDecimal));

                positionEntry.earnedDecimal = [];
                positionEntry.earnedWorth = [];

                positionEntry.earned = [];
                if (miningPool.poolEntry.meta.contractVersion === FarmOneSideContractVersion.V2) {
                    for (const idx in data.rewardTokens) {
                        const rewardToken = data.rewardTokens[idx];
                        const earnedDecimal = amount2Decimal(new BigNumber(position.earned[idx]), rewardToken) ?? 0;
                        positionEntry.earned.push(Number(position.earned[idx]));
                        positionEntry.earnedDecimal.push(earnedDecimal);
                        const rewardTokenPrice = data.rewardTokenPrice[idx];
                        const earnedWorth: number = rewardTokenPrice * earnedDecimal;
                        positionEntry.earnedWorth.push(earnedWorth);
                    }
                }

                positionEntry.niZi = Number(position.tokenStatus.nIZI ?? 0);
                positionEntry.niZiDecimal = amount2Decimal(new BigNumber(position.tokenStatus.nIZI ?? 0), tokenSymbol2token(TokenSymbol.IZI, chainId)) ?? 0;
                positionEntry.iZiWorth = positionEntry.niZiDecimal * data.iZiPriceDecimal;

                // veiZi version has no apr for each nft
                if (positionEntry.vLiquidity > 0 && meta.contractVersion === FarmDynamicRangeContractVersion.V2) {
                    positionEntry.apr = addIZIBoostAPR({data} as PoolEntryState, chainId, positionEntry.vLiquidity, positionEntry.capital, positionEntry.niZi, 0);
                    // todo: more fine calculate
                    // if (positionEntry.apr < data.apr[0]) {
                    //     positionEntry.apr = data.apr[0];
                    // }
                } else {
                    positionEntry.apr = 0;
                }

                const [amountAInUniswap, amountBInUniswap] = getABAmountInUniswap(position, data.positionTick);
                positionEntry.amountAInUniswapDecimal = amount2Decimal(new BigNumber(amountAInUniswap), meta.tokenA) ?? 0;
                positionEntry.amountBInUniswapDecimal = amount2Decimal(new BigNumber(amountBInUniswap), meta.tokenB) ?? 0;
                positionEntry.worthAInUniswap = positionEntry.amountAInUniswapDecimal * tokenPriceA;
                positionEntry.worthBInUniswap = positionEntry.amountBInUniswapDecimal * tokenPriceB;


                // const [fee0InUniswap, fee1InUniswap] = get
                poolEntry.stakedPositionList.push(positionEntry);
                poolEntry.userData.capital += positionEntry.capital;
                poolEntry.userData.vLiquidity += positionEntry.vLiquidity;

            }

            // 9. miningContract is approved
            const miningContractList = Object.keys(miningPoolData);
            const isApprovedMulticallData = miningContractList.map(miningContract => positionManagerContract.methods.isApprovedForAll(account, miningContract).encodeABI());
            const isApprovedResult: boolean[] = await positionManagerContract.methods.multicall(isApprovedMulticallData).call()
                .then((isApprovedList: string[]) => isApprovedList.map(isApproved => Number(isApproved) !== 0));
            for (const i in miningContractList) {
                miningPoolData[miningContractList[i]].poolEntry.userData.isApprovedForAll = isApprovedResult[i];
                const { meta, data } = rootState.farmDynamicRange.poolEntryList.find(p => p.meta.miningContract === miningContractList[i]) as PoolEntryState;
                if (meta.contractVersion === FarmOneSideContractVersion.VEIZI) {
                    const miningDynamicRangeBoostVeiZi = getMiningDynamicRangeVeiZiContract(miningContractList[i], web3);
                    const userStatus = await miningDynamicRangeBoostVeiZi?.methods.userStatus(account).call();
                    const userData = miningPoolData[miningContractList[i]].poolEntry.userData;
                    userData.vLiquidity = Number(userStatus.vLiquidity);
                    userData.validVeiZi = Number(userStatus.validVeiZi);
                    userData.apr = veiZiBoostAPR({data} as PoolEntryState, chainId, userData.vLiquidity, userData.capital, meta.veiZiBoost? userData.veiZi : 0);
                }
            }

            // TODO save positions and positionManagerContract address
            dispatch.farmDynamicRange.setPositionData(Object.values(miningPoolData).map(m => m.poolEntry));
        },
        async refreshPoolListDataAndPosition(refreshPoolListDataAndPositionParams: RefreshPoolListDataAndPositionParams): Promise<void> {
            await dispatch.farmDynamicRange.initPoolListData(refreshPoolListDataAndPositionParams as InitPoolListDataParams);
            await dispatch.farmDynamicRange.initPosition(refreshPoolListDataAndPositionParams as InitPositionParams);
        },
        async cleanPositionIfExist(account: string | null | undefined, rootState): Promise<void> {
            if (!isAddress(account as string)) { return; }
            if (!rootState.farmDynamicRange.poolEntryList.find(p => p.positionList?.length || p.stakedPositionList?.length)) { return; }
            // clean user data
            dispatch.farmDynamicRange.setPositionData([]);
            console.info('cleanPosition end');
        },
    })
});