import React, { useState } from 'react';
import { Flex, useColorMode, Menu, MenuButton, MenuItem, MenuList, Image, BoxProps } from '@chakra-ui/react';
import { useStore } from '../../../hooks/useLanguageStore';
import { observer } from 'mobx-react';
import { getColorThemeSelector } from '../../../utils/funcs';
import { i_text_d } from '../../../style';
import { useHover } from '../../../hooks/useHover';
import useIsMobile from '../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import { LayoutStateEnum } from '../../../state/models/pageLayout/pageLayout';
import { useIsFixedHeader } from '../../../hooks/useLayoutType';

const LanguageSelect: React.FC<BoxProps> = (rest) => {
    const store = useStore();

    const setToEnglish = () => {
        store.setLanguage('en');
    };

    const setToChinese = () => {
        store.setLanguage('zh-CN');
    };

    const setToTW = () => {
        console.log('setToTW');
        store.setLanguage('zh-TW');
    };
    const isMobile = useIsMobile();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const [ref, isHovering] = useHover<HTMLDivElement>();
    const [isOpenList, setOpenList] = useState(false);
    const { pageLayout } = useSelector((state: RootState) => state);
    const currentLayout = pageLayout.currentLayout;
    const isFixedHeader = useIsFixedHeader();

    return (
        <Flex
            direction="row"
            w="30px"
            h="30px"
            alignItems="center"
            justifyContent="start"
            fontFamily="Lato"
            fontWeight="bold"
            fontSize="12px"
            {...rest}
        >
            <Flex
                onClick={() => {
                    setOpenList(!isOpenList);
                }}
                onMouseOver={() => {
                    !isMobile && setOpenList(true);
                }}
                onMouseLeave={() => {
                    setOpenList(false);
                }}
                ref={ref}
            >
                <Flex mt={currentLayout === LayoutStateEnum.sidebar ? '-10px' : '-12px'} pt="12px">
                    <Image
                        boxSize="28px"
                        src={isHovering ? '/assets/header/HoverLanguageSelect.svg' : '/assets/header/LanguageSelect.svg'}
                        fallbackSrc="/assets/header/LanguageSelect.svg"
                        //isHovering={isHovering}
                    ></Image>
                </Flex>
                <Flex zIndex="3">
                    <Menu isOpen={isOpenList}>
                        <MenuButton></MenuButton>
                        <MenuList
                            minW="0px"
                            w="130px"
                            className={i_text_d}
                            bg={colorTheme('#FBFBFB', '#100C1E')}
                            position="absolute"
                            bottom={isFixedHeader ? '-90px' : pageLayout.currentLayout === LayoutStateEnum.sidebar ? '40px' : '-90px'}
                            left="-20px"
                            fontSize="13px"
                            transformOrigin={'0 bottom'}
                        >
                            <MenuItem
                                transformOrigin={'0 bottom'}
                                justifyContent="center"
                                onClick={() => {
                                    setToEnglish();
                                }}
                            >
                                English
                            </MenuItem>
                            <MenuItem
                                justifyContent="center"
                                onClick={() => {
                                    setToTW();
                                }}
                            >
                                繁體中文
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default observer(LanguageSelect);
