import React, { useState, useCallback, useContext, useRef } from 'react';
import {
    useDisclosure,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
} from '@chakra-ui/react';
import CustomButton from '../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';

interface Toast {
    show: (title: string, text: string) => void;
}

const ToastContext = React.createContext<Toast>(undefined!);

export const useToast = () => {
    return useContext(ToastContext);
};

const ToastProvider: React.FC<{}> = ({ children }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const okButton = useRef();

    const [toastData, setToastData] = useState<{
        title: string;
        text: string;
    }>();

    const show = useCallback(
        (title: string, text: string) => {
            setToastData({
                title: title,
                text: text,
            });
            onOpen();
        },
        [onOpen]
    );

    return (
        <ToastContext.Provider
            value={{
                show: show,
            }}
        >
            <AlertDialog
                // @ts-ignore
                leastDestructiveRef={okButton}
                onClose={onClose}
                isOpen={isOpen}
                isCentered={true}
            >
                <AlertDialogOverlay />

                <AlertDialogContent>
                    <AlertDialogHeader>{toastData?.title || '...'}</AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>{toastData?.text || '...'}</AlertDialogBody>
                    <AlertDialogFooter>
                        <CustomButton onClick={onClose} text="Ok" variant="none" radiusType="lg" />
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
            {children}
        </ToastContext.Provider>
    );
};

export default ToastProvider;
