import { TokenInfoFormatted } from "../../../hooks/useTokenListFormatted";

export const num2Hex = (n: number): string => {
    if (n < 10) {
        return String(n);
    }
    const str = 'ABCDEF';
    return str[n - 10];
}

export const fee2Hex = (fee: number): string => {
    const n0 = fee % 16;
    const n1 = Math.floor(fee / 16) % 16;
    const n2 = Math.floor(fee / 256) % 16;
    const n3 = Math.floor(fee / 4096) % 16;
    const n4 = 0;
    const n5 = 0;
    return '0x' + num2Hex(n5) + num2Hex(n4) + num2Hex(n3) + num2Hex(n2) + num2Hex(n1) + num2Hex(n0);
}

export const appendHex = (hexString: string, newHexString: string): string => {
    return hexString + newHexString.slice(2);
}

export const getTokenChainPath = (tokenChain: TokenInfoFormatted[], feeChain: number[]): string => {
    let hexString = tokenChain[0].wrapTokenAddress ?? tokenChain[0].address
    for (let i = 0; i < feeChain.length; i++) {
        hexString = appendHex(hexString, fee2Hex(feeChain[i]))
        hexString = appendHex(hexString, tokenChain[i + 1].wrapTokenAddress ?? tokenChain[i + 1].address)
    }
    return hexString
}