import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    HStack,
    AccordionButton,
    AccordionPanel,
    AccordionItem,
    Box,
    AccordionIcon,
    BoxProps,
    Stack,
    Image,
    Text,
    useColorMode,
    Center,
    Button,
} from '@chakra-ui/react';
import { useBreakpointValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { getChain, getScanUrlPrefix } from '../../../config/chains';
import useIsMobile from '../../../hooks/useIsMobile';
import { TokenInfoFormatted, useTokenListFormatted } from '../../../hooks/useTokenListFormatted';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import Card from '../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { NFTId } from '../../../iZUMi-UI-toolkit/src/components/NFTId/NFTId';
import { RootDispatch, RootState } from '../../../state/store';
import { i_text_copy, i_text_copy_bold } from '../../../style';
import { Range } from '../../Search/components/Range';
import { FeeRate } from '../../Trade/components/FeeRate';
import { TokenIcons } from '../../Trade/components/TokenIcons';
import CardTagContainer from '../../components/CardTag/Container';
import { CardTag } from '../../components/CardTag/CardTag';
import { RangeTag } from '../../components/CardTag/RangeTag';
import { FeeGains } from './FeeGains';
import { Liquidity } from './Liquidity';
import { getColorThemeSelector, toFeeNumber } from '../../../utils/funcs';
import CustomButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import tokens, { EmptyToken, tokenSymbol2token } from '../../../config/tokens';
import { TokenSymbol } from '../../../types/mod';
import { LiquidityInfo, Dex } from '../../../state/models/liquidity/types';
import { getLiquidityChartInfo, refreshLiquidity, stopLiquidity } from '../../../net/endpoints/izumiMonitor';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import truncateWalletAddress from '../../../utils/truncateWalletAddress';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useCustomToast, ToastType } from '../../../components/Toast/Toast';

export type PositionListEntryProps = {
    entry: LiquidityInfo;
} & BoxProps;

export const PositionListEntry: React.FC<PositionListEntryProps> = (props) => {
    const { entry, ...rest } = props;
    const { t } = useTranslation();
    const toast = useCustomToast();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const [toggle, setToggle] = useState(false);
    const oneLineMode = useBreakpointValue({ base: false, xxl: true, '2xl': true })!;
    const { chainId } = useWeb3WithDefault();
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { liquidity } = useSelector((state: RootState) => state);
    const isMobile = useIsMobile();

    const { tokenList } = useTokenListFormatted(entry.chainId);
    const getToken = useCallback(
        (symbol: string) => {
            const tempToken = {
                ...tokenList.find((e) => e.symbol === symbol && e.chainId === entry.chainId),
            };
            return tempToken as TokenInfoFormatted;
        },
        [entry.chainId, tokenList]
    );

    const tokenX = useMemo(() => {
        return !_.isEmpty(getToken(entry.poolInfo.tokenX.symbol))
            ? getToken(entry.poolInfo.tokenX.symbol)
            : EmptyToken(entry.poolInfo.tokenX.symbol, entry.chainId, entry.poolInfo.tokenX.address);
    }, [entry.chainId, entry.poolInfo.tokenX.address, entry.poolInfo.tokenX.symbol, getToken]);
    const tokenY = useMemo(() => {
        return !_.isEmpty(getToken(entry.poolInfo.tokenY.symbol))
            ? getToken(entry.poolInfo.tokenY.symbol)
            : EmptyToken(entry.poolInfo.tokenY.symbol, entry.chainId, entry.poolInfo.tokenY.address);
    }, [entry.chainId, entry.poolInfo.tokenY.address, entry.poolInfo.tokenY.symbol, getToken]);
    const DetailsButton = (
        <AccordionButton
            px={{ base: '5px', sm: '15px' }}
            color="secondary.500"
            w={{ base: 'unset', sm: 'unset' }}
            h="40px"
            className={i_text_copy}
            fontSize="12px !important"
            ml={{ base: '0px !important', sm: '10px !important' }}
        >
            {!oneLineMode && <Box>{t('Details')}</Box>}
            <AccordionIcon />
        </AccordionButton>
    );

    const inRange = entry.liquidity.tokenX !== 0 && entry.liquidity.tokenY !== 0;
    const outOfRange = entry.liquidity.tokenX === 0 || entry.liquidity.tokenY === 0;
    const closed = entry.liquidity.tokenX === 0 && entry.liquidity.tokenY === 0;

    useEffect(() => {
        // getLiquidityChartInfo({dex:entry.dex,chainId:entry.chainId,address:entry.poolInfo.} as LiquidityChartRequest).then((r)=>{
        //     console.info('data  ',r.data.data)
        // })
    });

    // entry.isRange = '';
    return entry ? (
        isMobile ? (
            <Card {...rest} position="relative" border={inRange ? '1px' : '0'} borderColor="primary.300">
                <HStack spacing="6px" ml="17px">
                    <CardTagContainer>
                        {
                            <CardTag
                                variant="blue"
                                px="5px"
                                text={entry.dex}
                                icon={
                                    <Image
                                        boxSize="12px"
                                        src={
                                            entry.dex === Dex.iZiSwap
                                                ? '/assets/home/iziLogo/logo.svg'
                                                : entry.dex === Dex.UniswapV3
                                                ? '/assets/gallery/uniswap.svg'
                                                : '/assets/liquidity/cake.svg'
                                        }
                                    ></Image>
                                }
                            />
                        }
                    </CardTagContainer>
                    <CardTagContainer>
                        {inRange ? (
                            <RangeTag variant={'InRange'} px="5px"></RangeTag>
                        ) : closed ? (
                            <RangeTag variant="Closed" px="5px"></RangeTag>
                        ) : (
                            <RangeTag variant={'OutOfRange'} px="5px"></RangeTag>
                        )}
                    </CardTagContainer>
                    <CardTagContainer>
                        {
                            <CardTag
                                variant="grey"
                                px="5px"
                                text={getChain(entry.chainId)?.name}
                                icon={<Image boxSize="12px" src={getChain(entry.chainId)?.icon}></Image>}
                            />
                        }
                    </CardTagContainer>
                </HStack>

                <AccordionItem border="0">
                    <Stack
                        direction={{ base: 'column', sm: 'row' }}
                        pt={{ base: '20px', sm: '5px' }}
                        pb="17px"
                        px="15px"
                        spacing="15px"
                        minW={{ base: '100%', sm: '660px' }}
                        justifyContent="end"
                        alignItems={{ base: 'start', sm: 'center' }}
                    >
                        <HStack spacing={{ base: '4px', sm: '20px' }} ml={{ base: '0px !important', sm: '20px !important' }}>
                            <TokenIcons tokenA={tokenX} tokenB={tokenY} />
                            <FeeRate tokenA={tokenX} tokenB={tokenY} feeTier={toFeeNumber(entry.poolInfo.feeRate)} />
                            <NFTId id={String(entry.lpid)} link="" />
                            <Range
                                min={entry.range.left}
                                max={entry.range.right}
                                initialToggle={toggle}
                                onClick={() => {
                                    setToggle(!toggle);
                                }}
                            />
                        </HStack>

                        <FeeGains
                            w={{ base: '100%', sm: '250px' }}
                            tokenX={tokenX}
                            tokenY={tokenY}
                            remainTokenX={entry.fees.tokenX}
                            remainTokenY={entry.fees.tokenY}
                            totalValue={entry.fees.tokenX * entry.fees.tokenXValue + entry.fees.tokenY * entry.fees.tokenYValue}
                        />
                        <Liquidity
                            tokenX={tokenX}
                            tokenY={tokenY}
                            remainTokenX={entry.liquidity.tokenX}
                            remainTokenY={entry.liquidity.tokenY}
                            tokenXValue={entry.liquidity.tokenXValue}
                            tokenYValue={entry.liquidity.tokenYValue}
                            totalValue={
                                entry.liquidity.tokenX * entry.liquidity.tokenXValue + entry.liquidity.tokenY * entry.liquidity.tokenYValue
                            }
                        />
                        <Center w="100%">{DetailsButton}</Center>
                    </Stack>

                    <AccordionPanel pb="17px" pt="0px">
                        <Stack
                            direction={{ base: 'column', sm: 'row' }}
                            spacing={{ base: '10px', sm: '20px' }}
                            pl={{ base: '0px', sm: '34px' }}
                        >
                            <Stack w="100%" spacing="15px">
                                <HStack spacing="16px">
                                    <Stack
                                        bg={colorTheme('#F9F9FB', '#3A2F53')}
                                        px="30px"
                                        py="14px"
                                        cursor="pointer"
                                        _hover={{ bg: colorTheme('gray.200', 'whiteAlpha.300') }}
                                        onClick={() => {
                                            window.open(getScanUrlPrefix(entry.chainId) + entry.owner, '_blank');
                                        }}
                                    >
                                        <Text className={i_text_copy}>{truncateWalletAddress(entry.owner, 10, 8)}</Text>
                                    </Stack>
                                    <Button
                                        w={{ base: '30px', lg: '43px' }}
                                        h={'43px'}
                                        bg={colorTheme('#F9F9FB', '#3A2F53')}
                                        ml="auto !important"
                                        onClick={() => {
                                            if (entry.owner) {
                                                navigator.clipboard.writeText(entry.owner);
                                                toast(ToastType.success, 'Copied');
                                            } else {
                                                toast(ToastType.error, 'Account does not exist');
                                            }
                                        }}
                                    >
                                        <Image src={process.env.PUBLIC_URL + '/assets/wallet/copy.svg'}></Image>
                                    </Button>
                                </HStack>

                                <HStack w="100%" ml="auto !important">
                                    <CustomButton
                                        w="50%"
                                        h="30px"
                                        className={i_text_copy_bold}
                                        variant="primary2"
                                        text="Refresh"
                                        leftIconElement={
                                            <Image
                                                boxSize="13px"
                                                src={colorTheme('/assets/liquidity/refresh.svg', '/assets/liquidity/darkRefresh.svg')}
                                                fallbackSrc={colorTheme(
                                                    '/assets/liquidity/refresh.svg',
                                                    '/assets/liquidity/darkRefresh.svg'
                                                )}
                                                mr="10px"
                                            ></Image>
                                        }
                                        onClick={() => {
                                            toast(ToastType.info, 'Ongoing...');
                                            refreshLiquidity({ id: entry.id })
                                                .then((r) => {
                                                    dispatch.liquidityData.setSingleLiquidity(entry.id, r.data.data);
                                                    toast(ToastType.success, 'Refresh successful');
                                                })
                                                .catch((e: Error) => {
                                                    toast(ToastType.error, e.message);
                                                });
                                        }}
                                    ></CustomButton>
                                    <CustomButton
                                        w="50%"
                                        h="30px"
                                        className={i_text_copy_bold}
                                        variant="orange"
                                        text="Delete"
                                        leftIconElement={
                                            <Image
                                                boxSize="13px"
                                                src={colorTheme('/assets/liquidity/delete.svg', '/assets/liquidity/darkDelete.svg')}
                                                fallbackSrc={colorTheme('/assets/liquidity/delete.svg', '/assets/liquidity/darkDelete.svg')}
                                                mr="10px"
                                            ></Image>
                                        }
                                        onClick={() => {
                                            // toast(ToastType.info, 'Ongoing');
                                            dispatch.liquidity.deleteId(entry.id);
                                            dispatch.liquidityData.deleteSingleLiquidity(entry.id);
                                            toast(ToastType.success, 'Delete successful');

                                            // stopLiquidity({ id: entry.id })
                                            //     .then((r) => {
                                            //         dispatch.liquidity.deleteId(entry.id);
                                            //         toast(ToastType.success, 'Delete successful');
                                            //     })
                                            //     .catch((e) => {
                                            //         toast(ToastType.error, 'Error: ' + e);
                                            //     });
                                        }}
                                    ></CustomButton>
                                </HStack>
                            </Stack>
                        </Stack>
                    </AccordionPanel>
                </AccordionItem>
            </Card>
        ) : (
            <Card {...rest} position="relative" border={inRange ? '1px' : '0'} borderColor="primary.300">
                <HStack spacing="20px" ml="40px">
                    <CardTagContainer>
                        {
                            <CardTag
                                variant="blue"
                                text={entry.dex}
                                icon={
                                    <Image
                                        boxSize="12px"
                                        src={
                                            entry.dex === Dex.iZiSwap
                                                ? '/assets/home/iziLogo/logo.svg'
                                                : entry.dex === Dex.UniswapV3
                                                ? '/assets/gallery/uniswap.svg'
                                                : '/assets/liquidity/cake.svg'
                                        }
                                    ></Image>
                                }
                            />
                        }
                    </CardTagContainer>
                    <CardTagContainer>
                        {inRange ? (
                            <RangeTag variant={'InRange'}></RangeTag>
                        ) : closed ? (
                            <RangeTag variant="Closed"></RangeTag>
                        ) : (
                            <RangeTag variant={'OutOfRange'}></RangeTag>
                        )}
                    </CardTagContainer>
                    <CardTagContainer>
                        {
                            <CardTag
                                variant="grey"
                                text={getChain(entry.chainId)?.name}
                                icon={<Image boxSize="12px" src={getChain(entry.chainId)?.icon}></Image>}
                            />
                        }
                    </CardTagContainer>
                </HStack>

                <AccordionItem border="0">
                    <HStack
                        direction={{ base: 'column', sm: 'row' }}
                        pt={{ base: '20px', sm: '5px' }}
                        pb="17px"
                        pl="30px"
                        pr="20px"
                        spacing="20px"
                        minW={{ base: '100%', sm: '660px' }}
                        justifyContent="end"
                        alignItems={{ base: 'start', sm: 'center' }}
                    >
                        <HStack spacing={{ base: '4px', sm: '20px' }} ml={{ base: '0px !important', sm: '20px !important' }}>
                            <TokenIcons tokenA={tokenX} tokenB={tokenY} />
                            <FeeRate tokenA={tokenX} tokenB={tokenY} feeTier={toFeeNumber(entry.poolInfo.feeRate)} />
                            <NFTId id={String(entry.lpid)} link="" />
                            <Range
                                min={entry.range.left}
                                max={entry.range.right}
                                initialToggle={toggle}
                                onClick={() => {
                                    setToggle(!toggle);
                                }}
                            />
                        </HStack>

                        <FeeGains
                            w={{ base: '100%', sm: '280px' }}
                            tokenX={tokenX}
                            tokenY={tokenY}
                            remainTokenX={entry.fees.tokenX}
                            remainTokenY={entry.fees.tokenY}
                            totalValue={entry.fees.tokenX * entry.fees.tokenXValue + entry.fees.tokenY * entry.fees.tokenYValue}
                        />
                        <Liquidity
                            tokenX={tokenX}
                            tokenY={tokenY}
                            remainTokenX={entry.liquidity.tokenX}
                            remainTokenY={entry.liquidity.tokenY}
                            tokenXValue={entry.liquidity.tokenXValue}
                            tokenYValue={entry.liquidity.tokenYValue}
                            totalValue={
                                entry.liquidity.tokenX * entry.liquidity.tokenXValue + entry.liquidity.tokenY * entry.liquidity.tokenYValue
                            }
                        />
                        {DetailsButton}
                    </HStack>

                    <AccordionPanel pb="17px">
                        <Stack
                            direction={{ base: 'column', sm: 'row' }}
                            spacing={{ base: '10px', sm: '20px' }}
                            pl={{ base: '0px', sm: '34px' }}
                            pr="58px"
                        >
                            <HStack w="100%">
                                <HStack spacing="16px">
                                    <Text className={i_text_copy_bold}>Owner</Text>
                                    <Stack
                                        bg={colorTheme('#F9F9FB', '#3A2F53')}
                                        px="30px"
                                        py="14px"
                                        cursor="pointer"
                                        _hover={{ bg: colorTheme('gray.200', 'whiteAlpha.300') }}
                                        onClick={() => {
                                            window.open(getScanUrlPrefix(entry.chainId) + entry.owner, '_blank');
                                        }}
                                    >
                                        <Text className={i_text_copy}>{entry.owner}</Text>
                                    </Stack>
                                    <Button
                                        w={{ base: '30px', lg: '43px' }}
                                        h={'43px'}
                                        bg={colorTheme('#F9F9FB', '#3A2F53')}
                                        onClick={() => {
                                            if (entry.owner) {
                                                navigator.clipboard.writeText(entry.owner);
                                                toast(ToastType.success, 'Copied');
                                            } else {
                                                toast(ToastType.error, 'Account does not exist');
                                            }
                                        }}
                                    >
                                        <Image src={process.env.PUBLIC_URL + '/assets/wallet/copy.svg'}></Image>
                                    </Button>
                                </HStack>

                                <HStack ml="auto !important">
                                    <CustomButton
                                        w="173px"
                                        h="30px"
                                        className={i_text_copy_bold}
                                        variant="primary2"
                                        text="Refresh"
                                        leftIconElement={
                                            <Image
                                                boxSize="13px"
                                                src={colorTheme('/assets/liquidity/refresh.svg', '/assets/liquidity/darkRefresh.svg')}
                                                fallbackSrc={colorTheme(
                                                    '/assets/liquidity/refresh.svg',
                                                    '/assets/liquidity/darkRefresh.svg'
                                                )}
                                                mr="10px"
                                            ></Image>
                                        }
                                        onClick={() => {
                                            toast(ToastType.info, 'Ongoing...');
                                            refreshLiquidity({ id: entry.id })
                                                .then((r) => {
                                                    dispatch.liquidityData.setSingleLiquidity(entry.id, r.data.data);
                                                    toast(ToastType.success, 'Refresh successful');
                                                })
                                                .catch((e: Error) => {
                                                    toast(ToastType.error, e.message);
                                                });
                                        }}
                                    ></CustomButton>
                                    <CustomButton
                                        w="173px"
                                        h="30px"
                                        className={i_text_copy_bold}
                                        variant="orange"
                                        text="Delete"
                                        leftIconElement={
                                            <Image
                                                boxSize="13px"
                                                src={colorTheme('/assets/liquidity/delete.svg', '/assets/liquidity/darkDelete.svg')}
                                                fallbackSrc={colorTheme('/assets/liquidity/delete.svg', '/assets/liquidity/darkDelete.svg')}
                                                mr="10px"
                                            ></Image>
                                        }
                                        onClick={() => {
                                            // toast(ToastType.info, 'Ongoing');
                                            dispatch.liquidity.deleteId(entry.id);
                                            toast(ToastType.success, 'Delete successful');

                                            // stopLiquidity({ id: entry.id })
                                            //     .then((r) => {
                                            //         dispatch.liquidity.deleteId(entry.id);
                                            //         toast(ToastType.success, 'Delete successful');
                                            //     })
                                            //     .catch((e) => {
                                            //         toast(ToastType.error, 'Error: ' + e);
                                            //     });
                                        }}
                                    ></CustomButton>
                                </HStack>
                            </HStack>
                        </Stack>
                    </AccordionPanel>
                </AccordionItem>
            </Card>
        )
    ) : (
        <></>
    );
};

export default PositionListEntry;
