import { createModel } from '@rematch/core';
import { RootModel } from '../../../../index';
import produce from 'immer';
import { MintRequest, UniswapPositionManagerContract } from '../../../../../../types/abis/UniswapV3/UniswapPositionManager';
import {
    findPoolEntryByPoolKey,
    priceDecimal2Price,
    price2PriceDecimal,
} from '../funcs';
import {
    calculateAmountDesired,
} from './funcs';
import { UNISWAP_MINT_CONFIG } from '../../../../../../config/bizConfig';
import BigNumber from 'bignumber.js';
import { TransactionReceipt } from 'ethereum-abi-types-generator';
import { getSortedTokenAddr } from '../../../../common/positionPoolHelper';
import { price2NearestTick, uniswapV3feeToTickSpacingMapping } from '../../../../../../utils/tickMath';
import { buildSendingParams } from '../../../../../../utils/contractHelpers';
import { toContractFeeNumber } from '../../../../../../utils/funcs';
import { isGasToken } from '../../../../../../config/tokens';
import { TokenInfoFormatted } from '../../../../../../hooks/useTokenListFormatted';
import { ChainId } from '../../../../../../types/mod';
import { tick2Price } from '../../../../../../utils/tickMath';
import Web3 from 'web3';

export interface MintForm {
    token0: TokenInfoFormatted;
    token1: TokenInfoFormatted;
    fee: FeeTier;
    tickLower: number;
    tickUpper: number;

    amount0Desired: number;
    amount1Desired: number;
    amount0DecimalDesired: number;
    amount1DecimalDesired: number;

    tickLowerPrice: number;
    tickUpperPrice: number;
    tickLowerPriceDecimal: number;
    tickUpperPriceDecimal: number;

    defaultTickLowerPrice: number;
    defaultTickUpperPrice: number;
    defaultTickLowerPriceDecimal: number;
    defaultTickUpperPriceDecimal: number;

    isLockFirstTokenVolume: boolean;

    positionPoolKey: string;
    spacingMapping: {[index: number]: number};
}

export interface AddMintFormTickParams {
    stepPositive: boolean;
    isUpper: boolean;
    price: number;
}

export interface LiquidityState {
    mintForm: MintForm;
}

export interface MintLiquidityFromUniswapParams {
    account: string;
    mintForm: MintForm;
    positionManagerContract?: UniswapPositionManagerContract;
    chainId: ChainId;
    gas?: string;
    gasPrice: string | number;
}

export interface SetFormAmountDecimalDesiredParams {
    isDesired0: boolean;
    desiredAmountDecimal: number;
    price: number;
}

export interface SetMintFormTickPriceParams {
    tickPrice?: number;
    tickPriceDecimal: number;
    isUpper?: boolean;
    price: number;
}


export const farmFixRangeUniLiquidity = createModel<RootModel>()({
    state: {
        mintForm: {} as MintForm
    } as LiquidityState,
    reducers: {
        saveLiquidity: (state: LiquidityState, payload: LiquidityState) => {
            return { ...state, ...payload };
        },

        setWalletMintForm: (state: LiquidityState, mintForm: MintForm) => produce(state, draft => {
            draft.mintForm = mintForm;
        }),

        addMintFormTick: (state: LiquidityState, addMintFormTickParams: AddMintFormTickParams) => produce(state, ({ mintForm }) => {
            const tickStep = uniswapV3feeToTickSpacingMapping[mintForm.fee] * (addMintFormTickParams.stepPositive ? 1 : -1);
            if (addMintFormTickParams.isUpper) {
                mintForm.tickUpper += tickStep;
                mintForm.tickUpperPrice = tick2Price(mintForm.tickUpper);
                mintForm.tickUpperPriceDecimal = price2PriceDecimal(
                                tick2Price(mintForm.tickUpper),
                                {left: mintForm.token0, right: mintForm.token1}
                );
            } else {
                mintForm.tickLower += tickStep;
                mintForm.tickLowerPrice = tick2Price(mintForm.tickLower);
                mintForm.tickLowerPriceDecimal = price2PriceDecimal(
                                tick2Price(mintForm.tickLower),
                                {left: mintForm.token0, right: mintForm.token1}
                );
            }

            [mintForm.amount0Desired, mintForm.amount1Desired] = calculateAmountDesired(mintForm, addMintFormTickParams.price);
            mintForm.amount0DecimalDesired = mintForm.amount0Desired / 10 ** mintForm.token0.decimal;
            mintForm.amount1DecimalDesired = mintForm.amount1Desired / 10 ** mintForm.token1.decimal;
        }),

        setMintFormTickPrice: (state: LiquidityState, setMintFormTickPriceParams: SetMintFormTickPriceParams) => produce(state, ({ mintForm }) => {
            const tickPrice =  priceDecimal2Price(
                    setMintFormTickPriceParams.tickPriceDecimal ?? 0,
                    {left: mintForm.token0, right: mintForm.token1,}
            );

            const tick = price2NearestTick(tickPrice, mintForm.fee, uniswapV3feeToTickSpacingMapping);

            if (setMintFormTickPriceParams.isUpper) {
                mintForm.tickUpper = tick;
                mintForm.tickUpperPrice = tick2Price(tick);
                mintForm.tickUpperPriceDecimal = price2PriceDecimal(
                                tick2Price(mintForm.tickUpper),
                                {left: mintForm.token0, right: mintForm.token1}
                );
            } else {
                mintForm.tickLower = tick;
                mintForm.tickLowerPrice = tick2Price(tick);
                mintForm.tickLowerPriceDecimal = price2PriceDecimal(
                                tick2Price(mintForm.tickLower),
                                {left: mintForm.token0, right: mintForm.token1}
                );
            }

            [mintForm.amount0Desired, mintForm.amount1Desired] = calculateAmountDesired(mintForm, setMintFormTickPriceParams.price);
            mintForm.amount0DecimalDesired = mintForm.amount0Desired / 10 ** mintForm.token0.decimal;
            mintForm.amount1DecimalDesired = mintForm.amount1Desired / 10 ** mintForm.token1.decimal;
        }),

        setMintFormAmountDesired: (state: LiquidityState, { isDesired0, desiredAmountDecimal, price }: SetFormAmountDecimalDesiredParams) => produce(state, ({ mintForm }) => {
            if (isDesired0) {
                mintForm.isLockFirstTokenVolume = true;
                mintForm.amount0Desired = Number(desiredAmountDecimal) * 10 ** mintForm.token0.decimal;
            } else {
                mintForm.isLockFirstTokenVolume = false;
                mintForm.amount1Desired = Number(desiredAmountDecimal) * 10 ** mintForm.token1.decimal;
            }

            [mintForm.amount0Desired, mintForm.amount1Desired] = calculateAmountDesired(mintForm, price);
            mintForm.amount0DecimalDesired = mintForm.amount0Desired / 10 ** mintForm.token0.decimal;
            mintForm.amount1DecimalDesired = mintForm.amount1Desired / 10 ** mintForm.token1.decimal;
        }),

        setMintFormDefault: (state: LiquidityState, price: number) => produce(state, ({ mintForm }) => {
            mintForm.tickLower = price2NearestTick(mintForm.defaultTickLowerPrice, mintForm.fee, uniswapV3feeToTickSpacingMapping);
            mintForm.tickUpper = price2NearestTick(mintForm.defaultTickUpperPrice, mintForm.fee, uniswapV3feeToTickSpacingMapping);
            mintForm.tickLowerPrice = mintForm.defaultTickLowerPrice;
            mintForm.tickUpperPrice = mintForm.defaultTickUpperPrice;
            mintForm.tickLowerPriceDecimal = mintForm.defaultTickLowerPriceDecimal;
            mintForm.tickUpperPriceDecimal = mintForm.defaultTickUpperPriceDecimal;

            [mintForm.amount0Desired, mintForm.amount1Desired] = calculateAmountDesired(mintForm, price);
            mintForm.amount0DecimalDesired = mintForm.amount0Desired / 10 ** mintForm.token0.decimal;
            mintForm.amount1DecimalDesired = mintForm.amount1Desired / 10 ** mintForm.token1.decimal;
        }),

        setMintFormLockFirstTokenVolume: (state: LiquidityState, isLockFirstTokenVolume: boolean) => produce(state, ({ mintForm }) => {
            mintForm.isLockFirstTokenVolume = isLockFirstTokenVolume;
        }),

        toggleTokenOrder: (state: LiquidityState) => produce(state, ({ mintForm }) => {
            [mintForm.token0, mintForm.token1] = [mintForm.token1, mintForm.token0];
            [mintForm.tickLower, mintForm.tickUpper] = [-mintForm.tickUpper, -mintForm.tickLower];

            [mintForm.amount0Desired, mintForm.amount1Desired] = [mintForm.amount1Desired, mintForm.amount0Desired];
            [mintForm.amount0DecimalDesired, mintForm.amount1DecimalDesired] = [mintForm.amount1DecimalDesired, mintForm.amount0DecimalDesired];

            [mintForm.tickLowerPrice, mintForm.tickUpperPrice] = [1 / mintForm.tickUpperPrice, 1 / mintForm.tickLowerPrice];
            [mintForm.tickLowerPriceDecimal, mintForm.tickUpperPriceDecimal] = [1 / mintForm.tickUpperPriceDecimal, 1 / mintForm.tickLowerPriceDecimal];

            [mintForm.defaultTickLowerPrice, mintForm.defaultTickUpperPrice] = [1 / mintForm.defaultTickUpperPrice, 1 / mintForm.defaultTickLowerPrice];
            [mintForm.defaultTickLowerPriceDecimal, mintForm.defaultTickUpperPriceDecimal] = [1 / mintForm.defaultTickUpperPriceDecimal, 1 / mintForm.defaultTickLowerPriceDecimal];

            mintForm.isLockFirstTokenVolume = !mintForm.isLockFirstTokenVolume;
        }),
    },
    effects: (dispatch) => ({
        _getMintLiquidityFromUniswapCall(params: MintLiquidityFromUniswapParams): any {
            if (!params || !params.mintForm || !params.account || !params.positionManagerContract || !params.chainId) {
                return new Promise<TransactionReceipt>((_, reject) => reject('Check mintLiquidityFromUniswapParams fail'));
            }
            const { mintForm, account, positionManagerContract, gas, gasPrice, chainId } = params;
            const sortedToken = getSortedTokenAddr(mintForm.token0.address, mintForm.token1.address);
            const isFlipped = sortedToken[0] !== mintForm.token0.address;

            console.log('mintForm', mintForm);

            const amount0 = isFlipped ? mintForm.amount1Desired : mintForm.amount0Desired;
            const amount1 = isFlipped ? mintForm.amount0Desired : mintForm.amount1Desired;

            const amount0Desired = new BigNumber(amount0);
            const amount1Desired = new BigNumber(amount1);

            const mintRequest = {
                token0: sortedToken[0],
                token1: sortedToken[1],
                fee: toContractFeeNumber(mintForm.fee),
                tickLower: isFlipped ? - mintForm.tickUpper : mintForm.tickLower,
                tickUpper: isFlipped ? - mintForm.tickLower : mintForm.tickUpper,
                amount0Desired: amount0Desired.toFixed(0),
                amount1Desired: amount1Desired.toFixed(0),
                amount0Min: (amount0Desired.multipliedBy(UNISWAP_MINT_CONFIG.DESIRED_AMOUNT_TO_MIN_AMOUNT_FACTOR)).toFixed(0),
                amount1Min: (amount1Desired.multipliedBy(UNISWAP_MINT_CONFIG.DESIRED_AMOUNT_TO_MIN_AMOUNT_FACTOR)).toFixed(0),
                recipient: account,
                deadline: (Date.now() / 1000 + UNISWAP_MINT_CONFIG.DEADLINE_OFFSET_MINUTES * 60).toFixed(0),
            } as MintRequest;

            let value = '0';
            if (isGasToken(mintForm.token0, chainId)) {
                value = isFlipped ? mintRequest.amount1Desired : mintRequest.amount0Desired;
            }
            if (isGasToken(mintForm.token1, chainId)) {
                value = isFlipped ? mintRequest.amount0Desired : mintRequest.amount1Desired;
            }
            console.log('mintRequest', mintRequest, value);

            const mintMultiCall = [];
            mintMultiCall.push(positionManagerContract.methods.mint(mintRequest).encodeABI());
            mintMultiCall.push(positionManagerContract.methods.refundETH().encodeABI());

            const options = buildSendingParams(chainId, {
                from: account,
                value,
                gas,
                maxFeePerGas: gasPrice,
            }, gasPrice);
            return [positionManagerContract.methods.multicall(mintMultiCall), options];
        },
        mintLiquidityFromUniswap(params: MintLiquidityFromUniswapParams): any {
            const [calling, options] = dispatch.farmFixRangeUniLiquidity._getMintLiquidityFromUniswapCall(params);
            return calling.send(options);
        },
        estimateMintGasLimit(params: MintLiquidityFromUniswapParams): any {
            const [calling, options] = dispatch.farmFixRangeUniLiquidity._getMintLiquidityFromUniswapCall(params);
            return calling.estimateGas(options);
        },
        async refreshWalletMintLiquidity(params: { positionPoolKey: string, chainId: any }, rootState): Promise<void> {
            const { positionPoolKey } = params;
            const poolEntry = findPoolEntryByPoolKey(rootState.farmFixRange.poolEntryList, positionPoolKey);
            if (!poolEntry) { return; }


            const fee = poolEntry.meta.feeTier;
            const defaultTickLowerPriceDecimal = poolEntry.data.rewardMinPriceDecimal;
            const defaultTickLowerPrice = priceDecimal2Price(
                defaultTickLowerPriceDecimal,
                { left: poolEntry.meta.tokenA, right: poolEntry.meta.tokenB},
            );

            const defaultTickUpperPriceDecimal = poolEntry.data.rewardMaxPriceDecimal;
            const defaultTickUpperPrice = priceDecimal2Price(
                defaultTickUpperPriceDecimal,
                { left: poolEntry.meta.tokenA, right: poolEntry.meta.tokenB },
            );

            const mintForm = {
                token0: poolEntry.meta.tokenA,
                token1: poolEntry.meta.tokenB,
                fee: fee,

                // true means floor
                tickLower: poolEntry.data.rewardLowerTick,
                tickUpper: poolEntry.data.rewardUpperTick,

                tickLowerPrice: defaultTickLowerPrice,
                tickUpperPrice: defaultTickUpperPrice,
                tickLowerPriceDecimal: defaultTickLowerPriceDecimal,
                tickUpperPriceDecimal: defaultTickUpperPriceDecimal,

                defaultTickLowerPrice: defaultTickLowerPrice,
                defaultTickUpperPrice: defaultTickUpperPrice,
                defaultTickLowerPriceDecimal: defaultTickLowerPriceDecimal,
                defaultTickUpperPriceDecimal: defaultTickUpperPriceDecimal,

                positionPoolKey: positionPoolKey,

            } as MintForm;
            dispatch.farmFixRangeUniLiquidity.setWalletMintForm(mintForm);
        }
    })
});
