import { ChevronDownIcon, SearchIcon } from '@chakra-ui/icons';
import {
    Flex,
    FormControl,
    FormLabel,
    HStack,
    Input,
    InputGroup,
    InputLeftElement,
    useColorMode,
    InputRightElement,
    Button,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    Image,
    Stack,
    MenuItemOption,
    MenuOptionGroup,
    useOutsideClick,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import useIsMobile from '../../../hooks/useIsMobile';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import { Dex } from '../../../net/endpoints/izumiMonitor';
import { RootState, RootDispatch } from '../../../state/store';
import { i_text_copy, i_text_copy_bold, i_text_d } from '../../../style';
import { getColorThemeSelector } from '../../../utils/funcs';
import NetworkSelect from './NetworkSelect';
import placeholder from '../../../assets/placeholder.png';
import { Filter } from '../../Farm/Gallery/components/Filter';
import React from 'react';
import './LiquidityControl.css';
import { RangeEnum, RangeSort, LiquiditySortBy } from '../../../state/models/liquidity/liquidityState';
const DashboardControls: React.FC = () => {
    const { t } = useTranslation();
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { liquidity: myLiquidity } = useSelector(
        (state: RootState) => ({
            liquidity: state.liquidityData,
        }),
        shallowEqual
    );

    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const isMobile = useIsMobile();

    const onSearchKeyChange = useCallback(
        (e: any) => {
            const control = { ...myLiquidity.control };
            control.searchKey = e.target.value;
            dispatch.liquidityData.setControl(control);
        },
        [myLiquidity.control, dispatch.liquidityData]
    );
    const onDexChange = useCallback(
        (e: any) => {
            const control = { ...myLiquidity.control };
            control.dex = e;
            dispatch.liquidityData.setControl(control);
        },
        [myLiquidity.control, dispatch.liquidityData]
    );

    const onSortByChange = useCallback(
        (e: any) => {
            const control = { ...myLiquidity.control };
            control.sortBy = e;
            dispatch.liquidityData.setControl(control);
        },
        [myLiquidity.control, dispatch.liquidityData]
    );
    const onStatusChange = useCallback(
        (e: any) => {
            const control = { ...myLiquidity.control };
            control.rangeType = e;
            control.rangeType.sort(RangeCompare);
            dispatch.liquidityData.setControl(control);
        },
        [myLiquidity.control, dispatch.liquidityData]
    );
    function RangeCompare(a: RangeEnum, b: RangeEnum) {
        const indexA = RangeSort.indexOf(a);
        const indexB = RangeSort.indexOf(b);
        return indexA - indexB;
    }
    const rangeType = myLiquidity.control.rangeType;

    const PlatFormBlock = () => (
        <FormControl display="flex" alignItems="center" w="unset">
            <FormLabel
                className={i_text_copy}
                w={{ base: '55px', xxl: '38px' }}
                textAlign={{ base: 'end', xxl: 'start' }}
                fontSize="12px"
                fontWeight="500"
                pt="6px"
            >
                {t('DEX')}
            </FormLabel>
            <Menu>
                {() => (
                    <>
                        <MenuButton
                            as={Button}
                            rightIcon={<ChevronDownIcon />}
                            className={i_text_d}
                            fontSize="13px"
                            w="88px"
                            leftIcon={
                                !myLiquidity.control.dex ? (
                                    <Image boxSize="21px" src={'/assets/filter/virtualAll.svg'}></Image>
                                ) : (
                                    <Image
                                        boxSize="21px"
                                        src={
                                            myLiquidity.control.dex === Dex.iZiSwap
                                                ? '/assets/home/iziLogo/logo.svg'
                                                : myLiquidity.control.dex === Dex.UniswapV3
                                                ? '/assets/gallery/uniswap.svg'
                                                : myLiquidity.control.dex === Dex.PancakeSwap
                                                ? '/assets/liquidity/cake.svg'
                                                : '/assets/filter/virtualAll.svg'
                                        }
                                    ></Image>
                                )
                            }
                            style={{
                                height: '40px',
                                width: '88px',
                                borderRadius: '6px',
                            }}
                        >
                            {/* {myLiquidity.control.dex ? myLiquidity.control.dex : 'Show All'} */}
                        </MenuButton>
                        <MenuList className={i_text_copy}>
                            <MenuItem
                                as={Button}
                                variant="light"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    borderRadius: '4px',
                                    overflow: 'hidden',
                                    fontSize: '12px',
                                }}
                                leftIcon={
                                    <Image src={'/assets/filter/virtualAll.svg'} boxSize="20px" mr="10px" fallbackSrc={placeholder}></Image>
                                }
                                onClick={() => onDexChange('')}
                            >
                                {t('Show All')}{' '}
                            </MenuItem>
                            <MenuItem
                                as={Button}
                                variant="light"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    borderRadius: '4px',
                                    overflow: 'hidden',
                                    fontSize: '12px',
                                }}
                                leftIcon={<Image src="/assets/home/iziLogo/logo.svg" boxSize="20px" mr="10px" fallbackSrc={placeholder} />}
                                onClick={() => onDexChange(Dex.iZiSwap)}
                            >
                                iZiSwap
                            </MenuItem>
                            <MenuItem
                                as={Button}
                                variant="light"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    borderRadius: '4px',
                                    overflow: 'hidden',
                                    fontSize: '12px',
                                }}
                                leftIcon={<Image src="/assets/gallery/uniswap.svg" boxSize="20px" mr="10px" fallbackSrc={placeholder} />}
                                onClick={() => onDexChange(Dex.UniswapV3)}
                            >
                                {' '}
                                UniSwapV3{' '}
                            </MenuItem>
                            <MenuItem
                                as={Button}
                                variant="light"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    borderRadius: '4px',
                                    overflow: 'hidden',
                                    fontSize: '12px',
                                }}
                                leftIcon={<Image src="/assets/liquidity/cake.svg" boxSize="20px" mr="10px" fallbackSrc={placeholder} />}
                                onClick={() => onDexChange(Dex.PancakeSwap)}
                            >
                                {' '}
                                PancakeSwap{' '}
                            </MenuItem>
                        </MenuList>
                    </>
                )}
            </Menu>
        </FormControl>
    );
    const NetworkBlock = () => (
        <Flex className={i_text_copy} alignItems="center">
            <Text w="55px" textAlign="end" mr="12px">
                {t('Network')}
            </Text>
            <NetworkSelect />
        </Flex>
    );

    const SortByBlock = () => (
        <FormControl display="flex" w={{ base: '100%', sm: '206px' }} alignItems="center">
            <FormLabel className={i_text_copy} w="55px" textAlign="end" fontSize="12px" fontWeight="500" pt="6px">
                {t('Sort by')}
            </FormLabel>
            <Menu>
                {() => (
                    <>
                        <MenuButton
                            as={Button}
                            rightIcon={<ChevronDownIcon />}
                            className={i_text_d}
                            fontSize="13px"
                            w={{ base: '178px', sm: '140px' }}
                        >
                            {t(myLiquidity.control.sortBy ? myLiquidity.control.sortBy : 'Show All')}
                        </MenuButton>
                        <MenuList className={i_text_copy}>
                            <MenuItem onClick={() => onSortByChange('')}> {t('Show All')}</MenuItem>
                            <MenuItem onClick={() => onSortByChange(LiquiditySortBy.Liquidity)}>{t(LiquiditySortBy.Liquidity)} </MenuItem>
                            <MenuItem onClick={() => onSortByChange(LiquiditySortBy.Dex)}>{t(LiquiditySortBy.Dex)} </MenuItem>
                            <MenuItem onClick={() => onSortByChange(LiquiditySortBy.Network)}>{t(LiquiditySortBy.Network)} </MenuItem>
                        </MenuList>
                    </>
                )}
            </Menu>
        </FormControl>
    );
    const inputFocusRef = React.useRef<any | null>(null);
    const [showPopOver, setShowPopOver] = useState(false);

    useOutsideClick({
        ref: inputFocusRef,
        handler: () => {
            setShowPopOver(false);
        },
    });
    const RangeBlock = () => (
        <FormControl display="flex" w={{ base: '100%', sm: 'unset' }} alignItems="center">
            <FormLabel className={i_text_copy} w="55px" textAlign="end" fontSize="12px" fontWeight="500" pt="6px">
                {t('Range')}
            </FormLabel>
            <Stack
                onClick={() => {
                    setShowPopOver(true);
                }}
            >
                <Menu isOpen={showPopOver} closeOnSelect={false}>
                    <MenuButton
                        as={Button}
                        rightIcon={<ChevronDownIcon />}
                        className={i_text_copy_bold}
                        fontFamily="Montserrat-Medium !important"
                        w="190px"
                        fontSize="12px !important"
                    >
                        {rangeType.includes(RangeEnum.InRange) &&
                        rangeType.includes(RangeEnum.OutOfRange) &&
                        rangeType.includes(RangeEnum.Closed)
                            ? 'Show All'
                            : rangeType.length === 0
                            ? 'None'
                            : rangeType.includes(RangeEnum.InRange) && rangeType.includes(RangeEnum.OutOfRange)
                            ? 'In/Out Range'
                            : rangeType.join('/')}
                    </MenuButton>
                    <MenuList ref={inputFocusRef} className={i_text_copy_bold} minWidth="190px">
                        <MenuOptionGroup type="checkbox">
                            <MenuItemOption
                                h="40px"
                                value={'Show All'}
                                icon={<></>}
                                color={
                                    rangeType.includes(RangeEnum.InRange) &&
                                    rangeType.includes(RangeEnum.OutOfRange) &&
                                    rangeType.includes(RangeEnum.Closed)
                                        ? colorTheme('#000000', '#F5F5F5')
                                        : colorTheme('#52466A', '#B7B1BE')
                                }
                                onClick={() => {
                                    onStatusChange([RangeEnum.InRange, RangeEnum.OutOfRange, RangeEnum.Closed]);
                                }}
                            >
                                <Text>Show All</Text>
                            </MenuItemOption>
                            <MenuItemOption
                                h="40px"
                                value={'None'}
                                icon={<></>}
                                color={rangeType.length === 0 ? colorTheme('#000000', '#F5F5F5') : colorTheme('#52466A', '#B7B1BE')}
                                onClick={() => {
                                    onStatusChange([]);
                                }}
                            >
                                <HStack>
                                    <Text>None</Text>
                                </HStack>
                            </MenuItemOption>
                            <MenuItemOption
                                h="40px"
                                value="InRange"
                                icon={<></>}
                                color={
                                    rangeType.includes(RangeEnum.InRange)
                                        ? colorTheme('#000000', '#F5F5F5')
                                        : colorTheme('#52466A', '#B7B1BE')
                                }
                                onClick={() => {
                                    if (myLiquidity.control.rangeType?.includes(RangeEnum.InRange)) {
                                        onStatusChange(myLiquidity.control.rangeType.filter((item) => item !== RangeEnum.InRange));
                                    } else {
                                        onStatusChange([...myLiquidity.control.rangeType, RangeEnum.InRange]);
                                    }
                                }}
                            >
                                <HStack spacing="18px">
                                    {myLiquidity.control.rangeType?.includes(RangeEnum.InRange) ? (
                                        <Image
                                            src={colorTheme('/assets/filter/check.svg', '/assets/filter/darkCheck.svg')}
                                            boxSize={'18px'}
                                        ></Image>
                                    ) : (
                                        <Image
                                            src={colorTheme('/assets/filter/unCheck.svg', '/assets/filter/darkUnCheck.svg')}
                                            boxSize={'18px'}
                                        ></Image>
                                    )}
                                    <Text>In Range</Text>
                                </HStack>
                            </MenuItemOption>
                            <MenuItemOption
                                h="40px"
                                value="Out Of Range"
                                icon={<></>}
                                color={
                                    rangeType.includes(RangeEnum.OutOfRange)
                                        ? colorTheme('#000000', '#F5F5F5')
                                        : colorTheme('#52466A', '#B7B1BE')
                                }
                                onClick={() => {
                                    if (myLiquidity.control.rangeType?.includes(RangeEnum.OutOfRange)) {
                                        onStatusChange(myLiquidity.control.rangeType.filter((item) => item !== RangeEnum.OutOfRange));
                                    } else {
                                        onStatusChange([...myLiquidity.control.rangeType, RangeEnum.OutOfRange]);
                                    }
                                }}
                            >
                                <HStack spacing="18px">
                                    {myLiquidity.control.rangeType?.includes(RangeEnum.OutOfRange) ? (
                                        <Image
                                            src={colorTheme('/assets/filter/check.svg', '/assets/filter/darkCheck.svg')}
                                            boxSize={'18px'}
                                        ></Image>
                                    ) : (
                                        <Image
                                            src={colorTheme('/assets/filter/unCheck.svg', '/assets/filter/darkUnCheck.svg')}
                                            boxSize={'18px'}
                                        ></Image>
                                    )}
                                    <Text>Out Of Range</Text>
                                </HStack>
                            </MenuItemOption>
                            <MenuItemOption
                                h="40px"
                                value={RangeEnum.Closed}
                                icon={<></>}
                                color={
                                    rangeType.includes(RangeEnum.Closed)
                                        ? colorTheme('#000000', '#F5F5F5')
                                        : colorTheme('#52466A', '#B7B1BE')
                                }
                                onClick={() => {
                                    if (myLiquidity.control.rangeType?.includes(RangeEnum.Closed)) {
                                        onStatusChange(myLiquidity.control.rangeType.filter((item) => item !== RangeEnum.Closed));
                                    } else {
                                        onStatusChange([...myLiquidity.control.rangeType, RangeEnum.Closed]);
                                    }
                                }}
                            >
                                <HStack spacing="18px">
                                    {myLiquidity.control.rangeType?.includes(RangeEnum.Closed) ? (
                                        <Image
                                            src={colorTheme('/assets/filter/check.svg', '/assets/filter/darkCheck.svg')}
                                            boxSize={'18px'}
                                        ></Image>
                                    ) : (
                                        <Image
                                            src={colorTheme('/assets/filter/unCheck.svg', '/assets/filter/darkUnCheck.svg')}
                                            boxSize={'18px'}
                                        ></Image>
                                    )}
                                    <Text>Closed</Text>
                                </HStack>
                            </MenuItemOption>
                        </MenuOptionGroup>
                    </MenuList>
                </Menu>
            </Stack>
        </FormControl>
    );

    return isMobile ? (
        <HStack w="100%" pt="16px" pb="8px" justifyContent="space-between">
            <InputGroup w="274px">
                <InputLeftElement boxSize="20px" mt="4px" ml="10px">
                    <SearchIcon boxSize={5} />
                </InputLeftElement>
                <Input
                    variant="filled"
                    placeholder={t('Search Tokens')}
                    size="lg"
                    h="30px"
                    className={i_text_copy_bold}
                    fontSize="12px"
                    onChange={(e) => onSearchKeyChange(e)}
                    value={myLiquidity.control.searchKey || ''}
                    bg={colorTheme('#FFFFFF', 'rgba(37, 32, 59, 0.5)')}
                    color="tertiary.300"
                    letterSpacing="0.02em"
                    fontFamily="Montserrat"
                />
            </InputGroup>
            <Filter
                filterTitle={t('Dashboard Filter')}
                filterElement={[
                    <PlatFormBlock key={1}></PlatFormBlock>,
                    <NetworkBlock key={2}></NetworkBlock>,
                    <SortByBlock key={3}></SortByBlock>,
                    <RangeBlock key={4}></RangeBlock>,
                ]}
            ></Filter>
        </HStack>
    ) : (
        <HStack w="100%" alignItems="start" pt={{ base: '12px', sm: '20px' }} pb={{ base: '20px', sm: '34px' }}>
            <InputGroup w={{ base: '275px', sm: '275px' }} h="41px" mt="-4px">
                <Input
                    variant="filled"
                    placeholder={t('Search Tokens')}
                    size="lg"
                    className={i_text_copy_bold}
                    fontSize="12px"
                    onChange={(e) => onSearchKeyChange(e)}
                    value={myLiquidity.control.searchKey || ''}
                />
                <InputRightElement h="100%" mt="4px">
                    <SearchIcon boxSize={5} />
                </InputRightElement>
            </InputGroup>
            <HStack spacing="30px" ml="auto !important">
                <PlatFormBlock></PlatFormBlock>
                <NetworkBlock></NetworkBlock>
                <SortByBlock></SortByBlock>
                <RangeBlock></RangeBlock>
            </HStack>
        </HStack>
    );
};

export default React.memo(DashboardControls);
