import React from 'react';
import { BoxProps, Box } from '@chakra-ui/react';
import Identicon from '../../Identicon';
import { useSelector } from 'react-redux';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import { RootDispatch, RootState } from '../../../state/store';

type Props = {
    iconSize?: number;
} & BoxProps;

const WalletButton: React.FC<Props> = (props) => {
    const { iconSize, ...rest } = props;
    const { wallet } = useSelector((state: RootState) => state);
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));

    const showNav = wallet.showNav;
    const setShowNav = dispatch.wallet.setShowNav;

    return (
        <Box
            onClick={() => {
                setShowNav(!showNav);
            }}
            boxSize="32px"
            {...rest}
        >
            <Identicon size={iconSize ?? 32} />
        </Box>
    );
};

export default WalletButton;
