import { ChevronDownIcon } from '@chakra-ui/icons';
import { useColorMode, Box, Menu, MenuButton, Button, MenuList, MenuItem, Image, Text, BoxProps } from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import placeholder from '../../../assets/placeholder.png';
import chains, { BaseChain } from '../../../config/chains';
import { RootDispatch, RootState } from '../../../state/store';
import { i_text_d } from '../../../style';
import { useTranslation } from 'react-i18next';
import { getColorThemeSelector } from '../../../utils/funcs';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import React from 'react';

type NetworkSelectProps = {
    menuButtonWidth?: string;
} & BoxProps;
const NetworkSelect: React.FC<NetworkSelectProps> = (props) => {
    const { menuButtonWidth } = props;

    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const bg2 = colorTheme('#F5F5F5', 'tertiary.800');

    const location = useLocation();
    const { pool } = useSelector((state: RootState) => state);

    const chainsFilter = useMemo(() => {
        let res = chains.all;
        const resSet = new Set(res);
        //console.log(resSet);
        res = Array.from(resSet);
        return res;
    }, [location]);

    const findChainById = (chainId: number) => {
        const chain = chains.all.find((e) => {
            return e.id === chainId;
        }) as BaseChain;
        return chain;
    };

    const onChainChange = useCallback(
        (e: any) => {
            const control = { ...pool.control };
            control.network = e;
            dispatch.pool.setControl(control);
        },
        [dispatch.pool, pool.control]
    );

    return (
        <Box>
            <Menu>
                <MenuButton
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                    leftIcon={
                        !pool.control.network ? (
                            <Image src={'/assets/filter/virtualAll.svg'} boxSize="20px" fallbackSrc={placeholder}></Image>
                        ) : (
                            <Image
                                src={pool.control.network ? findChainById(pool.control.network as number).icon : placeholder}
                                boxSize={'24px'}
                                fallbackSrc={placeholder}
                            />
                        )
                    }
                    style={{
                        height: '40px',
                        width: menuButtonWidth || '88px',
                        background: { bg2 } as unknown as string,
                        borderRadius: '6px',
                    }}
                >
                    <Text className={i_text_d} fontSize="13px" fontWeight="bold">
                        {/* {myLiquidity.control.network ? findChainById(myLiquidity.control.network as number).name : t('Show All')} */}
                    </Text>
                </MenuButton>

                <MenuList>
                    {
                        <MenuItem
                            as={Button}
                            variant="light"
                            text={t('Show All')}
                            size="md"
                            isActive={pool.control.network}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                borderRadius: '4px',
                                overflow: 'hidden',
                                fontSize: '12px',
                            }}
                            onClick={() => {
                                onChainChange('');
                            }}
                            leftIcon={<Image src={'/assets/filter/virtualAll.svg'} boxSize="20px" mr="10px" fallbackSrc={placeholder} />}
                        >
                            {t('Show All')}
                        </MenuItem>
                    }
                    {chainsFilter.map((chain) => (
                        <MenuItem
                            as={Button}
                            variant="light"
                            text={chain.name}
                            size="md"
                            key={chain.id}
                            isActive={chain.id === pool.control.network}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                borderRadius: '4px',
                                overflow: 'hidden',
                                fontSize: '12px',
                            }}
                            onClick={() => {
                                onChainChange(chain.id);
                            }}
                            leftIcon={
                                <Image src={process.env.PUBLIC_URL + chain.icon} boxSize="20px" mr="10px" fallbackSrc={placeholder} />
                            }
                        >
                            {chain.name}
                        </MenuItem>
                    ))}
                </MenuList>
            </Menu>
        </Box>
    );
};

export default NetworkSelect;
