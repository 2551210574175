import React from 'react';
import { BoxProps, HStack, IconButton } from '@chakra-ui/react';
import { RepeatIcon } from '@chakra-ui/icons';
import { formatNumber } from '../../../utils/tokenMath';
import { Text } from '../../../iZUMi-UI-toolkit/src/components/Typography/Typography';
import Info from '../../../iZUMi-UI-toolkit/src/components/Info/Info';
import { useTranslation } from 'react-i18next';

type RangeProps = {
    min: number;
    max: number;
    initialToggle?: boolean;
    onClick: any;
} & BoxProps;

export const Range: React.FC<RangeProps> = ({ min, max, initialToggle, onClick, ...rest }) => {
    const range =
        min === undefined && max === undefined
            ? '...'
            : formatNumber(initialToggle ? 1 / max : min, 2, 2, true) + '-' + formatNumber(initialToggle ? 1 / min : max, 2, 2, true);
    const { t } = useTranslation();

    return (
        <Info
            direction="column"
            label={t('Range')}
            labelIcon={<IconButton size="sm" isRound={true} aria-label="" icon={<RepeatIcon />} variant="ghost" onClick={onClick} />}
            value={
                <HStack whiteSpace="nowrap">
                    <Text variant="caption">{range}</Text>
                </HStack>
            }
            w="123px"
            {...rest}
        />
    );
};
