import { createModel } from '@rematch/core';
import { RootModel } from '../index';
import produce from 'immer';
import { Dex, LiquidityInfo } from '../../../net/endpoints/izumiMonitor';
import { ChainId } from '../../../types/mod';
import _ from 'lodash';
export enum LiquiditySortBy {
    Liquidity='Liquidity',
    Dex = 'Dex',
    Network = 'Network',
}
export enum RangeEnum{
    // All='All',
    // None='None',
    InRange='InRange',
    OutOfRange='OutOfRange' ,
    Closed='Closed',
} 
export const RangeSort=[RangeEnum.InRange,RangeEnum.OutOfRange,RangeEnum.Closed];
export interface LiquidityControl {
    sortBy: LiquiditySortBy|undefined;
    searchKey: any;
    dex: Dex | undefined;
    network:ChainId|undefined;
    rangeType:RangeEnum[];
}
export interface LiquidityState {
    liquiditys:LiquidityInfo[],
    control: LiquidityControl,
}

export const liquidityData = createModel<RootModel>()({
    state: {
        liquiditys:[],
        control: {
            sortBy: undefined,
            searchKey: undefined,
            dex: undefined,
            network:undefined,
            rangeType:[RangeEnum.InRange,RangeEnum.OutOfRange,RangeEnum.Closed],
        } as LiquidityControl,
    } as LiquidityState,
    reducers: {
        setLiquidity: (state: LiquidityState, liquiditys: LiquidityInfo[]) => produce(state, draft => {
            if(_.isEmpty(liquiditys)){
                draft.liquiditys=state.liquiditys ;
            }else{
                draft.liquiditys= [...liquiditys];
            }
        }),
        setSingleLiquidity: (state: LiquidityState, id: number,liquidity:LiquidityInfo) => produce(state, draft => {
           const index= state.liquiditys.findIndex((item)=>item.id===id);
           if(index!==-1){
            draft.liquiditys[index]=liquidity;
           }
        }),
        deleteSingleLiquidity: (state: LiquidityState, id: number) => produce(state, draft => {
            draft.liquiditys = state.liquiditys.filter(item => item.id !== id);
        }),
        setNetwork: (state: LiquidityState, network: ChainId|undefined) => produce(state, draft => {
            draft.control.network = network;
        }),
        setControl: (state: LiquidityState, control: LiquidityControl) => produce(state, draft => {
            draft.control = { ...control };
        }),
       
    },
    
});
