export const parallelCollect = async <T>(
    ...promiseList: Promise<T>[]
): Promise<T[]> => {
    const results: T[] = Array(promiseList.length);
    for (const i in promiseList) {
        if (!promiseList[i]) {
            continue;
        }
        promiseList[i].then((r: T) => (results[i] = r));
    }
    await Promise.all(promiseList);
    return results;
};

export const parallelCollectPair = async <T, K>(
    promiseLeft: Promise<T>,
    promiseRight: Promise<K>
): Promise<Pair<T, K>> => {
    const results = {} as Pair<T, K>;
    promiseLeft.then((r: T) => (results.left = r));
    promiseRight.then((r: K) => (results.right = r));
    await Promise.all([promiseLeft, promiseRight]);
    return results;
};
