import { ChevronDownIcon } from '@chakra-ui/icons';
import { Button, Menu, MenuButton, MenuItem, MenuList, Image, Text, useColorMode, HStack, BoxProps, Box } from '@chakra-ui/react';
import ArrowIcon from '../../../iZUMi-UI-toolkit/src/components/Icons/ArrowIcon/ArrowIcon';
import React, { useMemo } from 'react';
import chains, { BaseChain, getChain } from '../../../config/chains';
import { switchNetwork } from '../../../utils/metamaskWallet';
import { i_text_copy_bold, i_text_copy } from '../../../style';
import { getCurrentConnector, useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import { RootDispatch } from '../../../state/store';
import { getColorThemeSelector } from '../../../utils/funcs';
import { useLocation } from 'react-router-dom';
import placeholder from '../../../assets/placeholder.png';
import { ConnectorNames } from '../../../utils/connectors';
import useAuth from '../../../hooks/useAuth';
import { useToast } from '../../../providers/ToastProvider';
import { particle } from '../../../utils/particle';
import { useWeb3React } from '@web3-react/core';
import { ChainId } from '../../../types/mod';
import { tokenSymbol2token } from '../../../config/tokens';

type Props = {
    isHome?: boolean;
    showArrow?: boolean;
    networkID: ChainId;
    setNetworkID: any;
} & BoxProps;

const NetworkSelect: React.FC<Props> = ({ isHome, showArrow = true, networkID, setNetworkID, ...rest }) => {
    const { chainId, account, chainIcon, chainName } = useWeb3WithDefault();
    const { library } = useWeb3React();
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { login } = useAuth();
    const { show } = useToast();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const bg1 = colorTheme('#EBEAEC', 'tertiary.700');
    const bg2 = colorTheme('#F5F5F5', 'tertiary.800');

    const chain = getChain(networkID) as BaseChain;
    const location = useLocation();

    const chainsFilter = useMemo(() => {
        if (location.pathname.startsWith('/farm/uni')) {
            return chains.farmUni;
        }
        if (location.pathname.startsWith('/farm/iZi')) {
            return chains.farmiZi;
        }
        if (location.pathname.startsWith('/veiZi')) {
            return chains.veiZi;
        }
        if (location.pathname.startsWith('/trade')) {
            return chains.trade;
        }
        return chains.all;
    }, [location]);

    return (
        <Box>
            <Menu>
                <MenuButton
                    as={Button}
                    rightIcon={<ChevronDownIcon boxSize="24px" m="9px" background={bg1} borderRadius="5px" />}
                    leftIcon={
                        <Image
                            src={process.env.PUBLIC_URL + chain.icon}
                            boxSize="24px"
                            ml="17px"
                            mr="7px"
                            fallbackSrc={placeholder}
                            borderRadius="4px"
                        />
                    }
                    style={{
                        height: '40px',
                        width: '200px',
                        padding: '0',
                        background: { bg2 } as unknown as string,
                        borderRadius: '6px',
                    }}
                >
                    <Text ml="-10px" className={i_text_copy_bold}>
                        {chain.name}
                    </Text>
                </MenuButton>

                <MenuList>
                    {chainsFilter.map((chain) => (
                        <MenuItem
                            as={Button}
                            variant="light"
                            text={chain.name}
                            size="md"
                            key={chain.id}
                            isActive={chain.id === chainId}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                borderRadius: '4px',
                                overflow: 'hidden',
                                fontSize: '12px',
                            }}
                            onClick={() => {
                                setNetworkID(chain.id);
                            }}
                            leftIcon={
                                <Image
                                    src={process.env.PUBLIC_URL + chain.icon}
                                    boxSize="20px"
                                    mr="10px"
                                    fallbackSrc={placeholder}
                                    borderRadius="4px"
                                />
                            }
                        >
                            {chain.name}
                        </MenuItem>
                    ))}
                </MenuList>
            </Menu>
        </Box>
    );
};

export default NetworkSelect;
