import { SWAP_PROXY_ADDRESS } from "./config"
import swapProxyABI from '../../../../config/abi/swapProxy.json'
import { ChainId } from "../../../../types/mod"
import Web3 from "web3"
import { Contract } from 'web3-eth-contract'
import { getContract } from "../../../../utils/contractFactory"
import { Path } from "./utils"


export const reverse = (path: Path): Path => {
    return {
        ...path,
        tokenChain: path.tokenChain.slice().reverse(),
        feeRate: path.feeRate?.slice().reverse() ?? undefined,
        feeContractNumber: path.feeContractNumber.slice().reverse()
    }
}

export const getSwapProxyContract = (chainId: ChainId, web3: Web3): Contract => {
    const address = SWAP_PROXY_ADDRESS[chainId]
    return getContract<Contract>(swapProxyABI, address, web3)
}