import { BoxProps } from '@chakra-ui/react';
import React from 'react';

const InfoIcon: React.FC<React.PropsWithChildren<BoxProps>> = () => {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13 19.25C13.3542 19.25 13.6513 19.13 13.8913 18.89C14.1313 18.65 14.2508 18.3533 14.25 18V12.9688C14.25 12.6146 14.13 12.3229 13.89 12.0937C13.65 11.8646 13.3533 11.75 13 11.75C12.6458 11.75 12.3487 11.87 12.1087 12.11C11.8687 12.35 11.7492 12.6467 11.75 13V18.0312C11.75 18.3854 11.87 18.6771 12.11 18.9063C12.35 19.1354 12.6467 19.25 13 19.25ZM13 9.25C13.3542 9.25 13.6513 9.13 13.8913 8.89C14.1313 8.65 14.2508 8.35333 14.25 8C14.25 7.64583 14.13 7.34875 13.89 7.10875C13.65 6.86875 13.3533 6.74917 13 6.75C12.6458 6.75 12.3487 6.87 12.1087 7.11C11.8687 7.35 11.7492 7.64667 11.75 8C11.75 8.35417 11.87 8.65125 12.11 8.89125C12.35 9.13125 12.6467 9.25083 13 9.25ZM13 25.5C11.2708 25.5 9.64583 25.1717 8.125 24.515C6.60417 23.8583 5.28125 22.9679 4.15625 21.8438C3.03125 20.7188 2.14083 19.3958 1.485 17.875C0.829167 16.3542 0.500833 14.7292 0.5 13C0.5 11.2708 0.828333 9.64583 1.485 8.125C2.14167 6.60417 3.03208 5.28125 4.15625 4.15625C5.28125 3.03125 6.60417 2.14083 8.125 1.485C9.64583 0.829167 11.2708 0.500833 13 0.5C14.7292 0.5 16.3542 0.828333 17.875 1.485C19.3958 2.14167 20.7188 3.03208 21.8438 4.15625C22.9688 5.28125 23.8596 6.60417 24.5162 8.125C25.1729 9.64583 25.5008 11.2708 25.5 13C25.5 14.7292 25.1717 16.3542 24.515 17.875C23.8583 19.3958 22.9679 20.7188 21.8438 21.8438C20.7188 22.9688 19.3958 23.8596 17.875 24.5162C16.3542 25.1729 14.7292 25.5008 13 25.5ZM13 23C15.7708 23 18.1304 22.0263 20.0788 20.0788C22.0271 18.1313 23.0008 15.7717 23 13C23 10.2292 22.0263 7.86958 20.0788 5.92125C18.1313 3.97292 15.7717 2.99917 13 3C10.2292 3 7.86958 3.97375 5.92125 5.92125C3.97292 7.86875 2.99917 10.2283 3 13C3 15.7708 3.97375 18.1304 5.92125 20.0788C7.86875 22.0271 10.2283 23.0008 13 23Z"
                fill="#406AFF"
            />
        </svg>
    );
};

export default InfoIcon;
