import Web3 from 'web3';
import produce from 'immer';
import BigNumber from 'bignumber.js';

import { RootModel } from '../index';
import { createModel } from '@rematch/core';
import { ChainId } from '../../../types/mod';
import { getTokenAndSpenderKey } from './funcs';
import { getChain } from '../../../config/chains';
import { isGasToken } from '../../../config/tokens';
import { parallelCollect } from '../../../net/contractCall/parallel';
import { TokenInfoFormatted } from '../../../hooks/useTokenListFormatted';
import { getErc20TokenContract, getWrapTokenContract } from '../../../utils/contractFactory';

export interface AccountAndWeb3Params {
    account?: string;
    web3: Web3;
    chainId: ChainId;
}

export interface AccountTokenParams {
    account?: string;
    web3: Web3;
    chainId: ChainId;
    token: TokenInfoFormatted;
}

export interface FetchTokenAllowanceParams extends AccountTokenParams {
    spender: string;
    skipCheck?: boolean;
}

export interface FetchNftApprovedParams {
    account?: string;
    web3: Web3;
    chainId: ChainId;
    nftId: string;
    contractAddress: string;
    spender: string;
}

export interface TokenBalance {
    [token: string]: [number, TokenInfoFormatted];
}

export interface AccountDataKey {
    address: string;
    chainId: ChainId;
}

export interface AccountState {
    address: string;
    chainId: ChainId;
    name: string;
    offlineChainId: ChainId;
    ethBalance?: number;
    ethBalanceUSD?: number;
    tokenBalance: TokenBalance;
    tokenSpenderApproved: Set<string>;
    tokenSpenderDepositApproved: Set<string>;
    tokenSpenderAllowance: Map<string, string>;

    nftSpenderApproved: Set<string>;
}

export const account = createModel<RootModel>()({
    state: {
        offlineChainId: process.env.REACT_APP_ENV === 'production' ? ChainId.BSC : ChainId.BSCTestnet,
        tokenBalance: {
        },
        tokenSpenderApproved: new Set(),
        tokenSpenderDepositApproved: new Set(),
        tokenSpenderAllowance: new Map(),
    } as AccountState,
    reducers: {
        saveAccount: (state: AccountState, payload: AccountState) => {
            return { ...state, ...payload };
        },
        changeAccount: (state: AccountState, { address, chainId }: AccountDataKey) => produce(state, draft => {
            if (!address || !chainId || (address === draft.address && chainId === draft.chainId)) { return; }
            draft.address = address;
            draft.chainId = chainId;
            draft.tokenSpenderApproved = new Set();
            draft.tokenSpenderAllowance = new Map();
            draft.tokenSpenderDepositApproved = new Set();
            draft.tokenBalance = {};
            draft.ethBalance = undefined;
            draft.ethBalanceUSD = undefined;
        }),
        setEthBalance: (state: AccountState, ethBalance: number) => produce(state, draft => {
            draft.ethBalance = ethBalance;
        }),
        setTokenBalance: (state: AccountState, { token, balance }: { token: TokenInfoFormatted, balance: number }) => produce(state, draft => {
            draft.tokenBalance[token.symbol] = [balance, token];
        }),
        setTokenApproved: (state: AccountState, tokenAndSpenderKey: string) => produce(state, draft => {
            draft.tokenSpenderApproved.add(tokenAndSpenderKey);
        }),
        setTokenDepositApproved: (state: AccountState, tokenAndSpenderKey: string) => produce(state, draft => {
            draft.tokenSpenderDepositApproved.add(tokenAndSpenderKey)
        }),
        setTokenAllowance: (state: AccountState, tokenAndSpenderKey: string, allowance: string) => produce(state, draft => {
            draft.tokenSpenderAllowance.set(tokenAndSpenderKey, allowance);
        }),
        setOfflineChainId: (state: AccountState, chainId: ChainId) => produce(state, draft => {
            draft.offlineChainId = chainId;
        }),
    },
    effects: (dispatch) => ({
        async fetchEthBalance(accountAndWeb3Params: AccountAndWeb3Params, rootState): Promise<number> {
            accountAndWeb3Params.account = accountAndWeb3Params.account ?? rootState.account.address;
            if (!accountAndWeb3Params.web3 || !accountAndWeb3Params.account) { return 0; }

            const { account, web3 } = accountAndWeb3Params;
            const balanceResult: string = await web3.eth.getBalance(account).then((n: any) => n).catch((e: any)=>{
                console.info("error:   ", e.message)
                return 0
            });
            const balance = Number(balanceResult);
            // TODO fetch config decimals
            return balance / 10 ** 18;
        },

        async fetchEthBalanceIfMissing(accountAndWeb3Params: AccountAndWeb3Params, rootState): Promise<number> {
            accountAndWeb3Params.account = accountAndWeb3Params.account ?? rootState.account.address;
            if (!accountAndWeb3Params.web3 || !accountAndWeb3Params.account) { return 0; }
            // if (
            //     rootState.account.ethBalance === undefined 
            //     || accountAndWeb3Params.account !== rootState.account.address
            //     || accountAndWeb3Params.chainId !== rootState.account.chainId
            // ) {
            //     const ethBalance = await dispatch.account.fetchEthBalance(accountAndWeb3Params);
            //     dispatch.account.setEthBalance(ethBalance);
            //     return ethBalance;
            // }
            // return rootState.account.ethBalance;
            const ethBalance = await dispatch.account.fetchEthBalance(accountAndWeb3Params);
            dispatch.account.setEthBalance(ethBalance);
            return ethBalance;
        },

        async refreshTokenBalance(params: AccountTokenParams, rootState): Promise<void> {
            //console.log('refreshTokenBalance');
            const stateTokenSymbolList = Object.keys(rootState.account.tokenBalance);
            if (stateTokenSymbolList.length === 0) {
                return;
            }
            stateTokenSymbolList.forEach(s => dispatch.account.fetchTokenBalanceIfMissing({ ...params, token: rootState.account.tokenBalance[s][1], skipCache: true }));
        },

        async fetchEthBalanceAndUSDIfMissing(accountAndWeb3Params: AccountAndWeb3Params, rootState): Promise<Pair<number, number>> {
            const balanceAndUsd: Pair<number, number> = { left: 0, right: 0 };
            accountAndWeb3Params.account = accountAndWeb3Params.account ?? rootState.account.address;
            if (!accountAndWeb3Params.web3 || !accountAndWeb3Params.account || !accountAndWeb3Params.chainId) { return balanceAndUsd; }
            // hack! assure the web3 is provided by metamask
            //if (accountAndWeb3Params.web3._provider.host) { return balanceAndUsd; }

            const chainToken = getChain(accountAndWeb3Params.chainId)?.token;

            [balanceAndUsd.left, balanceAndUsd.right] = await parallelCollect(
                dispatch.account.fetchEthBalance(accountAndWeb3Params),
                dispatch.token.fetchTokenPriceIfMissing(chainToken as TokenInfoFormatted),
            );
            balanceAndUsd.right = balanceAndUsd.left * balanceAndUsd.right;

            dispatch.account.saveAccount({ ethBalance: balanceAndUsd.left, ethBalanceUSD: balanceAndUsd.right } as AccountState);
            return balanceAndUsd;
        },

        async fetchTokenBalance(params: AccountTokenParams, rootState): Promise<number> {
            params.account = params.account ?? rootState.account.address;
            if (!params.web3 || !params.account || !params.token || !params.token.symbol || !params.chainId) { return 0; }

            const { account, web3, token, chainId } = params;
            const tokenContract = getErc20TokenContract(token, chainId, web3);
            if (!tokenContract) return 0;

            const balanceResult = await tokenContract.methods.balanceOf(account)
                .call()
                .catch((e:any)=>{
                console.info("error:   ", e.message)
                return 0
            });
            const balance = Number(balanceResult);
            return balance / 10 ** token.decimal;
        },

        async fetchTokenBalanceIfMissing(params: AccountTokenParams & { skipCache?: boolean }, rootState): Promise<number> {
            params.account = params.account ?? rootState.account.address;
            if (!params.web3 || !params.account || !params.token || !params.token.symbol || !params.chainId) { return 0; }

            let tokenBalance = rootState.account.tokenBalance[params.token.symbol]?.[0];
            if (tokenBalance === undefined || params.skipCache) {
                if (isGasToken(params.token, params.chainId)) {
                    tokenBalance = await dispatch.account.fetchEthBalanceIfMissing(params);
                } else {
                    tokenBalance = await dispatch.account.fetchTokenBalance(params);
                }
                dispatch.account.setTokenBalance({ token: params.token, balance: tokenBalance });
            }
            return tokenBalance;
        },

        async fetchTokenAllowance(params: FetchTokenAllowanceParams, rootState): Promise<number> {
            params.account = params.account ?? rootState.account.address;
            if (!params.web3 || !params.account || !params.token || !params.chainId || !params.spender) { return 0; }

            const { account, web3, token, chainId, spender } = params;
            const tokenContract = getErc20TokenContract(token, chainId, web3);
            if (!tokenContract) { return 0; }
            const allowanceResult = await tokenContract.methods.allowance(account, spender).call();

            const allowance = Number(allowanceResult);
            return allowance;
        },

        async fetchTokenDepositAllowance(params: FetchTokenAllowanceParams, rootState): Promise<number> {
            params.account = params.account ?? rootState.account.address;
            if (!params.web3 || !params.account || !params.token || !params.spender) { return 0; }

            const { account, web3, token, spender } = params;
            if (!token.wrapTokenAddress) {
                return 0;
            }
            const tokenContract = getWrapTokenContract(web3, token.wrapTokenAddress)
            if (!tokenContract) { return 0; }
            const allowanceResult = await tokenContract.methods.depositAllowance(account, spender).call();

            const allowance = Number(allowanceResult);
            return allowance;
        },

        async fetchTokenApprovedIfMissing(params: FetchTokenAllowanceParams, rootState): Promise<boolean> {
            params.account = params.account ?? rootState.account.address;
            const skipCheck = params.skipCheck;
            if (!params.web3 || !params.account || !params.token || !params.token.symbol || !params.chainId || !params.spender) { return false; }

            const tokenAndSpenderKey = getTokenAndSpenderKey(params.token.symbol, params.spender);
            if (!rootState.account.tokenSpenderApproved.has(tokenAndSpenderKey)) {
                let isApproved;
                if (skipCheck) { //for chains like polygon, if check immediately after approving succeed, the allowance will return 0. A bug for the chain.
                    isApproved = true;
                } else {
                    if (isGasToken(params.token, params.chainId)) {
                        isApproved = true;
                    } else {
                        isApproved = await dispatch.account.fetchTokenAllowance(params).then(allowance => new BigNumber(allowance).gt(0));
                    }
                }
                if (isApproved) {
                    dispatch.account.setTokenApproved(tokenAndSpenderKey);
                }
                return isApproved;
            }
            return true;
        },

        async fetchTokenDepositApprovedIfMissing(params: FetchTokenAllowanceParams, rootState): Promise<boolean> {
            params.account = params.account ?? rootState.account.address;
            if (!params.web3 || !params.account || !params.token || !params.token.symbol || !params.spender) { return false; }

            const tokenAndSpenderKey = getTokenAndSpenderKey(params.token.symbol, params.spender);
            if (!params.token.wrapTokenAddress) {
                return false;
            }
            if (!rootState.account.tokenSpenderDepositApproved.has(tokenAndSpenderKey)) {
                const isApproved = await dispatch.account.fetchTokenDepositAllowance(params).then(allowance => new BigNumber(allowance).gt(0));
                if (isApproved) {
                    dispatch.account.setTokenDepositApproved(tokenAndSpenderKey);
                }
                return isApproved;
            }
            return true;
        },

        async fetchTokenAllowanceIfMissing(params: FetchTokenAllowanceParams, rootState): Promise<string> {
            params.account = params.account ?? rootState.account.address;
            if (!params.web3 || !params.account || !params.token || !params.token.symbol || !params.chainId || !params.spender) { return '0'; }

            const tokenAndSpenderKey = getTokenAndSpenderKey(params.token.symbol, params.spender);
            const allowance = await dispatch.account.fetchTokenAllowance(params).then(allowance => String(allowance));
            dispatch.account.setTokenAllowance(tokenAndSpenderKey, allowance);
            return allowance;
        },
    })
});
