import BigNumber from 'bignumber.js';
import { TokenInfoFormatted } from '../../../hooks/useTokenListFormatted';
import { TokenInfo } from '../trade/cusTokens/cusTokens';

export interface PositionPoolKeyInfo {
    lowerAddr: string;
    upperAddr: string;
    fee: FeeTier;
}

export interface CalAmountDesiredAble {
    tickLower: number;
    tickUpper: number;
    isLockFirstTokenVolume: boolean;
    amount0Desired: BigNumber;
    amount1Desired: BigNumber;
}

export const getSortedTokenAddr = (addr1: string, addr2: string): string[] => {
    return addr1.toLowerCase() < addr2.toLowerCase() ? [addr1, addr2] : [addr2, addr1];
};

export const getSortedToken = (token0: TokenInfoFormatted, token1: TokenInfoFormatted): TokenInfoFormatted[] => {
    return token0.address.toLowerCase() < token1.address.toLowerCase() ? [token0, token1] : [token1, token0];
};
export const getSortedTokenWithWrap = (token0: TokenInfoFormatted, token1: TokenInfoFormatted): TokenInfoFormatted[] => {
    const token0Address = token0.wrapTokenAddress ?? token0.address
    const token1Address = token1.wrapTokenAddress ?? token1.address
    if (token0Address.toLowerCase() < token1Address.toLowerCase()) {
        return [token0, token1]
    }
    return [token1, token0]
};

export const getSwapTokenAddress = (token: TokenInfoFormatted): string => {
    return token.wrapTokenAddress ?? token.address
}

export const getPositionPoolKey = (addr1: string, addr2: string, fee: FeeTier, contractVersion: string, additionalKey='01'): string => {
    const [addrLower, addrUpper] = getSortedTokenAddr(addr1, addr2);
    return `${addrLower.toLowerCase()}-${addrUpper.toLowerCase()}-${fee}-${contractVersion}-${additionalKey}`;
};

export const deconstructPositionPoolKey = (positionPoolKey: string): PositionPoolKeyInfo => {
    const infoArray = positionPoolKey.split('-');
    return {
        lowerAddr: infoArray[0],
        upperAddr: infoArray[1],
        fee: parseFloat(infoArray[2]),
    } as PositionPoolKeyInfo;
};
