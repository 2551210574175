import Web3 from 'web3';
import { NONFUNGIBLE_POSITION_MANAGER_ADDRESSES } from '../../config/miscellaneous/uniswapContracts';
import { getEVMContract } from '../../utils/contractHelpers';
import positionManagerABI from '../../config/abi/UniswapV3/UniswapPositionManager.json';
import { useMemo } from 'react';

export const getPositionManagerContract = (chainId?: number, web3?: Web3) => {
    if (!chainId || !web3) {
        return undefined;
    }

    try {
        const address = NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[chainId];
        return getEVMContract(positionManagerABI, address, web3);
    } catch (err) {
        console.error('Failed to get contract', err);
        return undefined;
    }
};

export const usePositionManagerContract = (chainId?: number, web3?: Web3) => {
    const contract = useMemo(() => {
        return getPositionManagerContract(chainId, web3);
    }, [chainId, web3]);
    return contract;
};
