
import { BigNumber } from 'bignumber.js'
import { TokenInfoFormatted } from '../../../../../hooks/useTokenListFormatted';
import { getSwapTokenAddress } from '../../../../../utils/tokenMath';
import { LiquidityDetail } from '../types';
import { 
    _getAmountX,
    _getAmountY,
    _liquidity2AmountXAtPoint,
    _liquidity2AmountYAtPoint,
    _calciZiLiquidityAmountY, 
    _calciZiLiquidityAmountX, 
    _getAmountXNoRound,
    _getAmountYNoRound
} from './amountMath'

export const calciZiLiquidityAmountDesired = (
    leftPoint: number,
    rightPoint: number,
    currentPoint: number,
    amount: BigNumber,
    amountIsTokenA: boolean,
    tokenA: TokenInfoFormatted,
    tokenB: TokenInfoFormatted,
): BigNumber => {
    if (amountIsTokenA) {
        if (getSwapTokenAddress(tokenA).toLowerCase() < getSwapTokenAddress(tokenB).toLowerCase()) {
            return _calciZiLiquidityAmountY(amount, leftPoint, rightPoint, currentPoint);
        } else {
            return _calciZiLiquidityAmountX(amount, leftPoint, rightPoint, currentPoint);
        }
    } else {
        if (getSwapTokenAddress(tokenA).toLowerCase() < getSwapTokenAddress(tokenB).toLowerCase()) {
            return _calciZiLiquidityAmountX(amount, leftPoint, rightPoint, currentPoint);
        } else {
            return _calciZiLiquidityAmountY(amount, leftPoint, rightPoint, currentPoint);
        }
    }
}

export const getLiquidityYRange = (
    leftPoint: number, rightPoint: number, currentPoint: number
): {leftPoint: number, rightPoint: number} => {
    const yRightPoint = Math.min(rightPoint, currentPoint + 1)
    return {leftPoint, rightPoint: yRightPoint}
}

export const getLiquidityXRange = (
    leftPoint: number, rightPoint: number, currentPoint: number
): {leftPoint: number, rightPoint: number} => {
    const xLeftPoint = Math.max(leftPoint, currentPoint + 1)
    return {leftPoint: xLeftPoint, rightPoint}
}


export const getLiquidityAmountForDeposit = (
    tokenA: TokenInfoFormatted,
    tokenB: TokenInfoFormatted,
    liquidity: BigNumber,
    leftPoint: number,
    rightPoint: number,
    currentPoint: number
): {amountA: BigNumber, amountB: BigNumber} => {
    const tokenAAddress = getSwapTokenAddress(tokenA).toLowerCase()
    const tokenBAddress = getSwapTokenAddress(tokenB).toLowerCase()
    const xRange = getLiquidityXRange(leftPoint, rightPoint, currentPoint)
    const yRange = getLiquidityYRange(leftPoint, rightPoint, currentPoint)
    const hasX = xRange.leftPoint < xRange.rightPoint
    const hasY = yRange.leftPoint < yRange.rightPoint
    const aIsX = tokenAAddress < tokenBAddress
    const sqrtRate = Math.sqrt(1.0001)
    let hasA = false
    let hasB = false
    let amountA = new BigNumber(0)
    let amountB = new BigNumber(0)
    if (aIsX) {
        hasA = hasX
        hasB = hasY
        const aRange = xRange
        const bRange = yRange
        if (hasA) {
            const sqrtPriceR = Math.sqrt(1.0001 ** aRange.rightPoint)
            amountA = _getAmountX(liquidity, aRange.leftPoint, aRange.rightPoint, sqrtPriceR, sqrtRate, true)
        }
        if (hasB) {
            const sqrtPriceL = Math.sqrt(1.0001 ** bRange.leftPoint)
            const sqrtPriceR = Math.sqrt(1.0001 ** bRange.rightPoint)
            amountB = _getAmountY(liquidity, sqrtPriceL, sqrtPriceR, sqrtRate, true)
        }
    } else {
        hasA = hasY
        hasB = hasX
        const aRange = yRange
        const bRange = xRange
        if (hasA) {
            const sqrtPriceL = Math.sqrt(1.0001 ** aRange.leftPoint)
            const sqrtPriceR = Math.sqrt(1.0001 ** aRange.rightPoint)
            amountA = _getAmountY(liquidity, sqrtPriceL, sqrtPriceR, sqrtRate, true)
        }
        if (hasB) {
            const sqrtPriceR = Math.sqrt(1.0001 ** bRange.rightPoint)
            amountB = _getAmountX(liquidity, bRange.leftPoint, bRange.rightPoint, sqrtPriceR, sqrtRate, true)
        }
    }
    return { amountA, amountB }
}


export const getLiquidityAmountForWithdrawTokenXY = (
    withdrawLiquidity: BigNumber,
    liquidityOfPool: BigNumber,
    liquidityXOfPool: BigNumber,
    leftPoint: number,
    rightPoint: number,
    currentPoint: number
): {amountX: BigNumber, amountY: BigNumber} => {
    
    const xRange = getLiquidityXRange(leftPoint, rightPoint, currentPoint)
    const yRange = getLiquidityYRange(leftPoint, rightPoint, currentPoint)
    const hasX = xRange.leftPoint < xRange.rightPoint
    const hasY = yRange.leftPoint < yRange.rightPoint
    const sqrtRate = Math.sqrt(1.0001)
    let amountX = new BigNumber(0)
    let amountY = new BigNumber(0)
    
    const aRange = xRange
    const bRange = yRange
    if (hasX) {
        const sqrtPriceR = Math.sqrt(1.0001 ** aRange.rightPoint)
        amountX = _getAmountX(withdrawLiquidity, aRange.leftPoint, aRange.rightPoint, sqrtPriceR, sqrtRate, true)
    }
    if (hasY) {
        const sqrtPriceL = Math.sqrt(1.0001 ** bRange.leftPoint)
        const coverCurrentPoint = bRange.rightPoint > currentPoint
        if (coverCurrentPoint) {
            bRange.rightPoint = currentPoint
        }
        const sqrtPriceR = Math.sqrt(1.0001 ** bRange.rightPoint)
        amountY = _getAmountY(withdrawLiquidity, sqrtPriceL, sqrtPriceR, sqrtRate, true)
        if (coverCurrentPoint) {
            const currentSqrtPrice = Math.sqrt(1.0001 ** currentPoint)
            const liquidityY = BigNumber.min(liquidityOfPool.minus(liquidityXOfPool), withdrawLiquidity)
            const liquidityX = withdrawLiquidity.minus(liquidityY)
            amountX = amountX.plus(
                _liquidity2AmountXAtPoint(liquidityX, currentSqrtPrice, false)
            )
            amountY = amountY.plus(
                _liquidity2AmountYAtPoint(liquidityY, currentSqrtPrice, false)
            )
        }
    }
        
    return { amountX, amountY }
};


export const getLiquidityAmountForWithdraw = (
    tokenA: TokenInfoFormatted,
    tokenB: TokenInfoFormatted,
    withdrawLiquidity: BigNumber,
    liquidityOfPool: BigNumber,
    liquidityXOfPool: BigNumber,
    leftPoint: number,
    rightPoint: number,
    currentPoint: number
): {amountA: BigNumber, amountB: BigNumber} => {
    
    const tokenAAddress = getSwapTokenAddress(tokenA)
    const tokenBAddress = getSwapTokenAddress(tokenB)

    if (tokenAAddress.toLowerCase() < tokenBAddress.toLowerCase()) {
        const {amountX: amountA, amountY: amountB} = getLiquidityAmountForWithdrawTokenXY(
            withdrawLiquidity, liquidityOfPool, liquidityXOfPool, leftPoint, rightPoint, currentPoint
        )
        return {amountA, amountB}
    } else {
        const {amountX: amountB, amountY: amountA} = getLiquidityAmountForWithdrawTokenXY(
            withdrawLiquidity, liquidityOfPool, liquidityXOfPool, leftPoint, rightPoint, currentPoint
        )
        return {amountA, amountB}
    }
};