import { TokenInfoFormatted } from "../../../../../hooks/useTokenListFormatted";
import { ChainId, TokenSymbol } from "../../../../../types/mod";
import { SWAP_PROXY_ADDRESS } from "../config";
import { PANCAKE_SWAP_ROUTER_ADDRESS, PANCAKE_TOKEN_BLACK_LIST } from "./config";


export const pancakeAvailable = (chainId: ChainId, tokenA: TokenInfoFormatted, tokenB: TokenInfoFormatted): boolean => {
    
    if (!PANCAKE_SWAP_ROUTER_ADDRESS[chainId] || !SWAP_PROXY_ADDRESS[chainId]) {
        return false
    }
    const blackList = PANCAKE_TOKEN_BLACK_LIST
    if (blackList.find((v: TokenSymbol)=>{return (v === tokenA.symbol || v === tokenB.symbol)})) {
        return false
    }
    return true
}