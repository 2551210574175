import { createModel } from '@rematch/core';
import { RootModel } from '../index';
import produce from 'immer';
import { TokenInfoFormatted } from '../../../hooks/useTokenListFormatted';
import { memGetIziSwapMetaRecord, ResponseIziPoolRecord, MetaRecordTypeEnum, iZiSwapMetaModeEnum } from '../../../net/iZUMi-endpoints/src/restful/izumiSwapBase';
import { getBaseQuoteOrder } from './proOrderFormState/funcs';
import { ChainId, TokenSymbol } from '../../../types/mod';
import tokens, { tokenSymbol2token } from '../../../config/tokens';
export const ReadableModeInterval: { [key: string]: string } = {
    DEFAULT: '',
    POOL_KLINE_M1: '1m',
    POOL_KLINE_M5: '5m',
    POOL_KLINE_M15: '15m',
    POOL_KLINE_H1: '1h',
    POOL_KLINE_H4: '4h',
    POOL_KLINE_DAY: 'D',
    POOL_KLINE_WEEK: 'W',
    POOL_KLINE_MONTH: 'M',
};
export interface setPoolState {
    tokenXSymbol: string;
    tokenYSymbol: string;
    fee: number;
    chainId: ChainId;
    tokenList: TokenInfoFormatted[];
}
interface poolParam {
    tokenX: TokenInfoFormatted;
    tokenY: TokenInfoFormatted;
    pool: string;
    currentFee: number;
    isReverseToken: boolean;
    isPoolChange: boolean;
    intervals: string[],
}
export interface ProtState {
    isShowAllOrders: boolean;
    tokenX: TokenInfoFormatted;
    tokenY: TokenInfoFormatted;
    pool: string;
    currentFee: number;
    isReverseToken: boolean;
    isPoolChange: boolean;
    intervals: string[],
}

export const pro = createModel<RootModel>()({

    state: {
        isShowAllOrders: false,
        tokenX: {} as TokenInfoFormatted,
        tokenY: {} as TokenInfoFormatted,
        pool: undefined as unknown as string,
        currentFee: 2000,
        isReverseToken: false,
        isPoolChange: false,
        intervals: [] as string[],
    } as ProtState,

    reducers: {
        setIsShowAllOrders: (state: ProtState, isShowAll: boolean) => produce(state, draft => {
            draft.isShowAllOrders = isShowAll;
        }),

        setPoolInfo: (state: ProtState, poolParam: poolParam) => produce(state, draft => {
            draft.pool = poolParam.pool;
            draft.tokenX = poolParam.tokenX;
            draft.tokenY = poolParam.tokenY;
            draft.currentFee = poolParam.currentFee;
            draft.isReverseToken = poolParam.isReverseToken;
            draft.isPoolChange = poolParam.isPoolChange;
            draft.intervals = poolParam.intervals;
        }),

        setPoolChange: (state: ProtState, isChange: boolean) => produce(state, draft => {
            draft.isPoolChange = isChange;
        }),

        setPoolIntervals: (state: ProtState, intervals: string[]) => produce(state, draft => {
            draft.intervals = intervals;
        })
    },
    effects: (dispatch) => ({

        async setPoolState(params: setPoolState): Promise<boolean> {
            const { chainId, tokenXSymbol, tokenYSymbol, fee, tokenList } = params;
            if (tokenXSymbol && tokenYSymbol) {
                const tokenX = getToken(tokenXSymbol, chainId, tokenList);
                const tokenY = getToken(tokenYSymbol, chainId, tokenList);
                if (!tokenX.address || !tokenY.address) {
                    return false;
                }

                memGetIziSwapMetaRecord<ResponseIziPoolRecord[]>({
                    chain_id: chainId,
                    type: MetaRecordTypeEnum.IZI_SWAP_POOL,
                    pool_tokenx_addr: tokenX.address.toLocaleLowerCase(),
                    pool_tokeny_addr: tokenY.address.toLocaleLowerCase(),
                    page_size: 100,
                }).then((r) => {
                    if (!r.data.is_success) {
                        dispatch.pro.setPoolIntervals(([]));
                        return false;
                    }
                    const result = r.data.data;
                    if (result.length === 0) {
                        memGetIziSwapMetaRecord<ResponseIziPoolRecord[]>({
                            chain_id: chainId,
                            type: MetaRecordTypeEnum.IZI_SWAP_POOL,
                            pool_tokenx_addr: tokenY.address && tokenY.address.toLocaleLowerCase(),
                            pool_tokeny_addr: tokenX.address && tokenX.address.toLocaleLowerCase(),
                            page_size: 100,
                        }).then((r) => {
                            if (!r.data.is_success) {
                                dispatch.pro.setPoolIntervals(([]));
                                return false;
                            }
                            const result = r.data.data;
                            if (result.length === 0) {
                                return true;
                            }
                            const tempResult = result.filter((i) => i.fee === fee);
                            const filteredResult = tempResult.sort((a, b) => a.dealTimestamp - b.dealTimestamp).reverse()[0];
                            if (filteredResult) {
                                const tokenA = getToken(filteredResult.tokenX, chainId, tokenList) as TokenInfoFormatted;
                                const tokenB = getToken(filteredResult.tokenY, chainId, tokenList) as TokenInfoFormatted;
                                const { quoteToken, baseToken } = getBaseQuoteOrder(tokenA, tokenB, chainId);
                                const isReverse = tokenA.symbol !== baseToken.symbol && tokenB.symbol !== quoteToken.symbol;

                                const result = isReverse
                                    ? {
                                        tokenX: baseToken,
                                        tokenY: quoteToken,
                                        pool: filteredResult.address,
                                        currentFee: fee,
                                        isReverseToken: isReverse,
                                        isPoolChange: true,
                                        intervals: getIntervals(filteredResult.mode),
                                    }
                                    : {
                                        tokenX: baseToken,
                                        tokenY: quoteToken,
                                        pool: filteredResult.address,
                                        currentFee: fee,
                                        isReverseToken: isReverse,
                                        isPoolChange: true,
                                        intervals: getIntervals(filteredResult.mode),

                                    };
                                dispatch.pro.setPoolInfo(result);
                            }
                        });

                        return true;
                    }
                    const tempResult = result.filter((i) => i.fee === fee);
                    const filteredResult = tempResult.sort((a, b) => a.dealTimestamp - b.dealTimestamp).reverse()[0];

                    if (filteredResult) {
                        const tokenA = getToken(filteredResult.tokenX, chainId, tokenList) as TokenInfoFormatted;
                        const tokenB = getToken(filteredResult.tokenY, chainId, tokenList) as TokenInfoFormatted;
                        const { quoteToken, baseToken } = getBaseQuoteOrder(tokenA, tokenB, chainId);
                        const isReverse = tokenA.symbol !== baseToken.symbol && tokenB.symbol !== quoteToken.symbol;

                        const result = isReverse
                            ? {
                                tokenX: baseToken,
                                tokenY: quoteToken,
                                pool: filteredResult.address,
                                currentFee: fee,
                                isReverseToken: isReverse,
                                isPoolChange: true,
                                intervals: getIntervals(filteredResult.mode),

                            }
                            : {
                                tokenX: baseToken,
                                tokenY: quoteToken,
                                pool: filteredResult.address,
                                currentFee: fee,
                                isReverseToken: isReverse,
                                isPoolChange: true,
                                intervals: getIntervals(filteredResult.mode),

                            };
                        dispatch.pro.setPoolInfo(result);
                    }
                });
            }
            return true;
        }
    })


});
export const getToken = (symbol: string, chainId: ChainId, tokenList: TokenInfoFormatted[]) => {
    const tempToken = {
        ...tokenList.find((e) => e.symbol === symbol),
    };
    if (tokens[symbol as TokenSymbol]) {
        const findToken =  tokenSymbol2token(symbol, chainId);
        if ( findToken.address) {
            return findToken;
        }
    }
    return tempToken;
};
const getIntervals = (MinimumInterval: iZiSwapMetaModeEnum) => {
    const minIndex = Object.values(iZiSwapMetaModeEnum).indexOf(MinimumInterval);
    const enumLength = Object.keys(iZiSwapMetaModeEnum).length;
    const result = minIndex === 0 ? [] : Object.keys(iZiSwapMetaModeEnum).slice(minIndex, enumLength);
    const readableResult = result.map((item) => {
        return ReadableModeInterval[item];
    });
    return readableResult;
};