import React from 'react';
import { Image, Center, VStack, useColorMode, Text } from '@chakra-ui/react';
import PageLayout from '../../components/layout/PageLayout';
import { getColorThemeSelector } from '../../utils/funcs';
import { i_h2, i_h3 } from '../../style';

const NotFound404: React.FC = () => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    return (
        <PageLayout
            header={<></>}
            body={
                <Center w="100%" h="100%" alignItems="center">
                    <VStack>
                        <Image src={process.env.PUBLIC_URL + '/assets/404.png'} w="273px" h="276px" />
                        <Text
                            className={i_h2}
                            fontSize="80px"
                            color={colorTheme('tertiary.700', 'tertiary.300')}
                            h="80px"
                            mt="100px !important"
                        >
                            404
                        </Text>

                        <Text className={i_h3} fontSize="24px" color={colorTheme('tertiary.500', 'tertiary.200')}>
                            Oops, page not found...
                        </Text>
                    </VStack>
                </Center>
            }
        />
    );
};

export default NotFound404;
