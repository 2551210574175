import { getPriceFromX96 } from '../state/models/farm/UniswapV3/fixRange/funcs';

export const tick2PriceDecimal = (tick: number, tokenADecimals: number, tokenBDecimals: number): number => {
    return Math.pow(1.0001, tick) * (10 ** tokenADecimals / 10 ** tokenBDecimals);
};

export const tick2Price = (tick: number): number => {
    return 1.0001 ** tick;
};

export const tick2PriceSqrt = (tick: number): number => {
    return (1.0001 ** tick) ** 0.5;
};

export const price2Tick = (price: number): number => {
    return Math.round(Math.log(price) / Math.log(1.0001));
};

export const liquidity2TokenAmount = (liquidity: number, lowerTick: number, upperTick: number, currentTick: number): [number, number] => {
    let tokenAAmount = 0;
    let tokenBAmount = 0;

    // only tokenA
    if (currentTick < lowerTick) {
        tokenAAmount = liquidity * (1 / tick2PriceSqrt(lowerTick) - 1 / tick2PriceSqrt(upperTick));
    } else if (currentTick > upperTick) {
        tokenBAmount = liquidity * (tick2PriceSqrt(upperTick) - tick2PriceSqrt(lowerTick));
    } else {
        tokenAAmount = liquidity * (1 / tick2PriceSqrt(currentTick) - 1 / tick2PriceSqrt(upperTick));
        tokenBAmount = liquidity * (tick2PriceSqrt(currentTick) - tick2PriceSqrt(lowerTick));
    }

    return [tokenAAmount, tokenBAmount];
};

export const liquidity2TokenAmountDecimal = (
    liquidity: number,
    lowerTick: number,
    upperTick: number,
    currentTick: number,
    decimals: Pair<number, number>,
    priceSqrt: string
): [number, number] => {
    let tokenAAmount = 0;
    let tokenBAmount = 0;

    const currentPriceSqrt = parseFloat(getPriceFromX96(priceSqrt)) ** 0.5;

    // only tokenA
    if (currentTick < lowerTick) {
        tokenAAmount = liquidity * (1 / tick2PriceSqrt(lowerTick) - 1 / tick2PriceSqrt(upperTick));
    } else if (currentTick > upperTick) {
        tokenBAmount = liquidity * (tick2PriceSqrt(upperTick) - tick2PriceSqrt(lowerTick));
    } else {
        tokenAAmount = liquidity * (1 / currentPriceSqrt - 1 / tick2PriceSqrt(upperTick));
        tokenBAmount = liquidity * (currentPriceSqrt - tick2PriceSqrt(lowerTick));
    }

    console.log(tokenAAmount, tokenBAmount);

    const tokenAAmountDecimal = tokenAAmount / 10 ** decimals.left;
    const tokenBAmountDecimal = tokenBAmount / 10 ** decimals.right;

    return [tokenAAmountDecimal, tokenBAmountDecimal];
};

export type SpacingMappingType = { [index: number]: number };

export const uniswapV3feeToTickSpacingMapping: SpacingMappingType = {
    0.01: 1,
    0.05: 10,
    0.3: 60,
    1: 200,
} as Record<FeeTier, number>;

export const izumiFeeToTickSpacingMapping: SpacingMappingType = {
    0.01: 1,
    0.04: 8,
    0.05: 10,
    0.2: 40,
    0.3: 50,
    1: 200,
};

export const price2UpperTick = (price: number, fee: FeeTier, spacingMapping: SpacingMappingType): number => {
    let base = Math.ceil(Math.log(price) / Math.log(1.0001));
    const delta = spacingMapping[fee];
    let mod = base % delta;
    if (mod < 0) {
        mod += delta;
    }
    if (mod !== 0) {
        base = base + delta - mod;
    }
    return base;
};

export const price2LowerTick = (price: number, fee: FeeTier, spacingMapping: SpacingMappingType): number => {
    let base = Math.floor(Math.log(price) / Math.log(1.0001));
    const delta = spacingMapping[fee];
    let mod = base % delta;
    if (mod < 0) {
        mod += delta;
    }
    if (mod !== 0) {
        base = base - mod;
    }
    return base;
};

export const price2NearestTick = (price: number, fee: FeeTier, spacingMapping: SpacingMappingType): number => {
    const base = Math.floor(Math.log(price) / Math.log(1.0001));
    console.log('fee: ', fee);
    const delta = spacingMapping[fee];
    const mod = base % delta;
    if (mod <= delta / 2) {
        return base - mod;
    } else {
        return base + delta - mod;
    }
};
