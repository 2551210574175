import { createModel } from '@rematch/core';
import { RootModel } from '../../index';
import produce from 'immer';
import { getErc20TokenContractByAddr } from '../../../../utils/contractFactory';
import Web3 from 'web3';
import { ChainId } from '../../../../types/mod';
import { TokenInfoFormatted } from '../../../../hooks/useTokenListFormatted';

export type TokenInfo = {
    chainId: number;
    decimal: number;
    name: string;
    symbol: string;
    address: string;
    icon?: string;
    addTime?: Date;
    custom?: boolean;
}

export type ModTokenInfoOperation = {
    tokenInfo: TokenInfo;
    isAdd: boolean; // add or remove
}

export interface CustomTokenState {
    tokens: Record<string, TokenInfo>;
    length: number;
}

export type FetchAndAddTokenParams = {
    tokenAddr: string,
    web3: Web3,
    chainId: ChainId,
}

export const tokenInfoKey = (tokenInfo: TokenInfo): string => `${tokenInfo.chainId}-${tokenInfo.address}`;

export const customTokens = createModel<RootModel>()({
    state: {
        tokens: {},
        length: 0,
    } as CustomTokenState,

    reducers: {
        modToken: (state: CustomTokenState, { tokenInfo, isAdd }: ModTokenInfoOperation) => produce(state, draft => {
            const key = tokenInfoKey(tokenInfo);
            if (isAdd) {
                draft.tokens[key] = tokenInfo;
            } else if (key in draft.tokens) {
                delete draft.tokens[key];
            }
            draft.length = Object.keys(draft.tokens).length;
        }),
    },

    effects: (dispatch) => ({
        async fetchAndAddToken(params: FetchAndAddTokenParams) {
            const { tokenAddr, web3, chainId } = params;
            try {
                const contract = getErc20TokenContractByAddr(tokenAddr, chainId, web3);
                const decimal = Number(await contract.methods.decimals().call());
                const symbol = await contract.methods.symbol().call();
                const name = await contract.methods.name().call();
                const tokenInfo: TokenInfoFormatted = {
                    name,
                    symbol,
                    chainId,
                    decimal,
                    icon: '/assets/tokens/default.svg',
                    custom: true,
                    address: tokenAddr
                };
                await dispatch.customTokens.modToken({ tokenInfo, isAdd: true });
                return tokenInfo;
            } catch (e) {
                console.log(e);
                return {} as TokenInfoFormatted;
            }
        }
    })
});