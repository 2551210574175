import { ChainId } from "../../../../../types/mod";
import { Path, PreQueryResult } from "../utils"

export interface iZiPreQueryResult extends PreQueryResult {
    lastChainId?: ChainId
    pathWithOutFee100: Path[]
    pathWithFee100: Path[]
    pool: Map<string, string>
    poolPoint: Map<string, number>
}

export const initiZiPreResult = (chainId: ChainId): iZiPreQueryResult => {
    return {
        lastChainId: chainId,
        pathWithFee100: [] as Path[],
        pathWithOutFee100: [] as Path[],
        pool: new Map<string, string>(),
        poolPoint: new Map<string, number>()
    } as iZiPreQueryResult
}