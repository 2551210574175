import { SwapTag } from "../aggregator/config";
import { PathQueryResult, PreQueryResult } from "../aggregator/utils";

export interface QueryConfig {
    swapTags: SwapTag[],
    preQueryResult: PreQueryResult[],
    pathQueryPluginConfig: {[swapTag: string]: any},
    longBatchSize?: number,
    shortBatchSize?: number,
    maxRate?: number,
}

export interface TapResult {
    liquidityA: string,
    liquidityB: string,
    liquidity: string,
    amountA: string,
    amountB: string,
    leftPoint: number,
    rightPoint: number,
    newPoint: number,

    pathQueryResult?: PathQueryResult,

    hasA: boolean,
    hasB: boolean,

    swapAmountIn: string,
    swapAmountOut: string,

    originAmountA: string,
    originAmountB: string,

    inputOriginAmountB: string,

}


export enum TapMode {
    NoTap = 'NoTap',
    Tap0To1 = 'Tap0To1',
    Tap1To0 = 'Tap1To0',
}

export enum TapModeStatus {
    Modified = 'Modified',
    // Calculating = 'Calculating',
    Complete = 'Complete'
}

export interface TapResultForMint {
    liquidity0: string,
    liquidity1: string,
    liquidity: string,
    amount0: string,
    amount1: string,
    leftPoint: number,
    rightPoint: number,
    newPoint: number,

    pathQueryResult?: PathQueryResult,

    has0: boolean,
    has1: boolean,

    tapMode: TapMode,
    swapAmountIn: string,
    swapAmountOut: string,
    originToken0Amount: string,
    originToken1Amount: string,
    tapResult: TapResult,

}
