import { createModel } from "@rematch/core";
import { RootModel } from "../..";
import { fetchOrderBook } from "./controllers";
import { FetchOrderBookParams, OrderBookData } from "./types";

export const proOrderBook = createModel<RootModel>()({
    state: {
        priceDecimalBaseByQuote: 0,
        upQuantity: [],
        lowQuantity: [],
        upPriceDecimal: [],
        lowPriceDecimal: []
    } as OrderBookData,
    reducers: {

        setOrderBook: (state: OrderBookData, payload: OrderBookData) => {
            return {...state, ...payload}
        }

    },
    effects: (dispatch) => ({

        async fetch(params: FetchOrderBookParams, rootState): Promise<OrderBookData> {
            const orderBookData = await fetchOrderBook(params)
            //console.log(orderBookData)
            dispatch.proOrderBook.setOrderBook(orderBookData)
            return orderBookData
        }

    })

});
