import { createModel } from '@rematch/core';
import produce from 'immer';
import { RootModel } from '../..';
import { ResponseAllFarmStat } from '../../../../net/iZUMi-endpoints/src/restful/izumiFarmStat';
import { FarmControl, FarmState } from './types';

export const farmGallery = createModel<RootModel>()({
    state: {
        farmControl: {
            sortBy: undefined,
            searchKey: undefined,
            status: 'LIVE',
            protocol: undefined,
            chainId: undefined,
            showedRow:2,
        },
        poolEntryList: [],
    } as FarmState,
    reducers: {
        setFarmState: (state: FarmState, payload: FarmState) => {
            return { ...state, ...payload };
        },
        setFarmControl: (state: FarmState, farmControl: FarmControl) => produce(state, draft => {
            draft.farmControl = { ...farmControl };
        }),
        setFarmShowedRow: (state: FarmState, showedRow: number) => produce(state, draft => {
            draft.farmControl.showedRow = showedRow;
        }),
        setPoolEntryList: (state: FarmState, payload: ResponseAllFarmStat[]) => produce(state, draft => {
            draft.poolEntryList = [...payload];
        }),
    },
  
});
