import { useCallback } from 'react';
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core';
import { NoBscProviderError } from '@binance-chain/bsc-connector';
import { NoEthereumProviderError, UserRejectedRequestError as UserRejectedRequestErrorInjected } from '@web3-react/injected-connector';
import {
    UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
    WalletConnectConnector,
} from '@web3-react/walletconnect-connector';
// import { setupNetwork } from 'utils/wallet'
// import useToast from './hooks/useToast'
// import { profileClear } from 'state/profile'
import { useTranslation } from 'react-i18next';
import connectors, { ConnectorNames, supportedChainIds } from '../utils/connectors';
import { useToast } from '../providers/ToastProvider';
import { clearUserCache } from '../utils/clearCache';
import { getProvider, setCurrentConnector } from './useWeb3WithDefault';
import { switchNetwork } from '../utils/metamaskWallet';
import { DEFAULT_CHAIN_ID, DEFAULT_CHAIN_ID_TEST } from '../config/bizConfig';

const useAuth = () => {
    const { t } = useTranslation();
    // const dispatch = useAppDispatch()
    const { activate, deactivate } = useWeb3React();
    const { show } = useToast();
    // const { toastError } = useToast()

    const login = useCallback(
        async (connectorID: ConnectorNames) => {
            const connector = connectors[connectorID];
            if (connectorID == ConnectorNames.OpenBlock || connectorID == ConnectorNames.BitKeep) {
                const provider = getProvider(connectorID);
                if (provider && provider.request) {
                    const DEFAULTCHAINID = process.env.REACT_APP_ENV == 'production' ? DEFAULT_CHAIN_ID : DEFAULT_CHAIN_ID_TEST;
                    provider
                        .request({
                            method: 'eth_requestAccounts',
                        })
                        .then((accounts: any) => {
                            switchNetwork(DEFAULTCHAINID, provider).then(() => {
                                provider
                                    .request({
                                        method: 'eth_chainId',
                                    })
                                    .then((chainId: any) => {
                                        if (!supportedChainIds.find((id) => id == chainId)) {
                                            show('Cannot connect', `The blockchain you've selected is not supported.`);
                                            return;
                                        }
                                        if (accounts.code == undefined) {
                                            setCurrentConnector(connectorID, accounts[0]);
                                        }
                                        console.info('accounts:   ', accounts);
                                    });
                            });
                        })
                        .catch((err: any) => {
                            console.error(err);
                        });
                }
            } else if (connector) {
                console.info('connector:  ', connector);
                activate(connector, async (error: Error) => {
                    if (error instanceof UnsupportedChainIdError) {
                        /*
                        const hasSetup = await setupNetwork()
                        if (hasSetup) {
                        activate(connector)
                        }
                        */
                        show(
                            'Cannot connect',
                            // eslint-disable-next-line quotes
                            `The blockchain you've selected is not supported.`
                        );
                        activate(connector);
                    } else {
                        // window.localStorage.removeItem(connectorLocalStorageKey)
                        if (error instanceof NoEthereumProviderError || error instanceof NoBscProviderError) {
                            // toastError(t('Provider Error'), t('No provider was found'))
                            console.log(t('Provider Error'), t('No provider was found'));
                        } else if (
                            error instanceof UserRejectedRequestErrorInjected ||
                            error instanceof UserRejectedRequestErrorWalletConnect
                        ) {
                            if (connector instanceof WalletConnectConnector) {
                                const walletConnector = connector as WalletConnectConnector;
                                walletConnector.walletConnectProvider = null;
                            }
                            // toastError(t('Authorization Error'), t('Please authorize to access your account'))
                            console.log(t('Authorization Error'), t('Please authorize to access your account'));
                        } else {
                            // toastError(error.name, error.message)
                            console.log(error);
                            console.log(error.name, error.message);
                        }
                    }
                });
                setCurrentConnector(connectorID);
            } else {
                // toastError(t('Unable to find connector'), t('The connector config is wrong'))
                console.log('not connector');
                console.log(t('Unable to find connector'), t('The connector config is wrong'));
            }
        },
        //[t, activate, toastError],
        [activate, t, show]
    );

    const logout = useCallback(() => {
        deactivate();
        clearUserCache();
    }, [deactivate]);

    return { login: login, logout: logout };
};

export default useAuth;
