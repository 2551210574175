import { BoxProps } from '@chakra-ui/react';
import React from 'react';

const ErrorIcon: React.FC<React.PropsWithChildren<BoxProps>> = () => {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13 3C10.3478 3 7.8043 4.05357 5.92893 5.92893C4.05357 7.8043 3 10.3478 3 13C3 15.6522 4.05357 18.1957 5.92893 20.0711C7.8043 21.9464 10.3478 23 13 23C15.6522 23 18.1957 21.9464 20.0711 20.0711C21.9464 18.1957 23 15.6522 23 13C23 10.3478 21.9464 7.8043 20.0711 5.92893C18.1957 4.05357 15.6522 3 13 3ZM0.5 13C0.5 6.09625 6.09625 0.5 13 0.5C19.9037 0.5 25.5 6.09625 25.5 13C25.5 19.9037 19.9037 25.5 13 25.5C6.09625 25.5 0.5 19.9037 0.5 13ZM7.74125 7.74125C7.97566 7.50691 8.29354 7.37527 8.625 7.37527C8.95646 7.37527 9.27434 7.50691 9.50875 7.74125L13 11.2325L16.4913 7.74125C16.6066 7.62186 16.7445 7.52663 16.897 7.46112C17.0495 7.39561 17.2135 7.36113 17.3795 7.35969C17.5455 7.35824 17.7101 7.38987 17.8637 7.45272C18.0173 7.51557 18.1569 7.60839 18.2742 7.72576C18.3916 7.84312 18.4844 7.98269 18.5473 8.13631C18.6101 8.28993 18.6418 8.45453 18.6403 8.6205C18.6389 8.78648 18.6044 8.9505 18.5389 9.10301C18.4734 9.25551 18.3781 9.39344 18.2588 9.50875L14.7675 13L18.2588 16.4913C18.4864 16.727 18.6124 17.0428 18.6096 17.3705C18.6067 17.6982 18.4753 18.0118 18.2435 18.2435C18.0118 18.4753 17.6982 18.6067 17.3705 18.6096C17.0428 18.6124 16.727 18.4864 16.4913 18.2588L13 14.7675L9.50875 18.2588C9.273 18.4864 8.95724 18.6124 8.6295 18.6096C8.30175 18.6067 7.98824 18.4753 7.75648 18.2435C7.52472 18.0118 7.39326 17.6982 7.39041 17.3705C7.38756 17.0428 7.51355 16.727 7.74125 16.4913L11.2325 13L7.74125 9.50875C7.50691 9.27434 7.37527 8.95646 7.37527 8.625C7.37527 8.29354 7.50691 7.97566 7.74125 7.74125Z"
                fill="#D73D33"
            />
        </svg>
    );
};

export default ErrorIcon;
