import { createModel, init } from "@rematch/core";
import { Contract } from 'web3-eth-contract'
import produce from "immer";
import Web3 from "web3";
import { TokenInfoFormatted } from "../../../../hooks/useTokenListFormatted";
import { ChainId } from "../../../../types/mod";
import { RootModel } from '../../index';
import { getPreQueryPlugins, getSwapTags, getTokenPairKey, initPreQueryResultList, SwapTag } from "./config";
import { doPreQuery } from "./controllers";
import { PreQueryResult } from "./utils";

export interface SwapPreQueryModel {
    chainId?: ChainId
    tokenPairKey: string
    swapTag: SwapTag[]
    preQueryResult: PreQueryResult[]
}

export interface PreQueryParams {
    chainId: ChainId
    web3: Web3
    tokenIn: TokenInfoFormatted
    tokenOut: TokenInfoFormatted
    multicall: Contract
}

export const swapPreQueryModel = createModel<RootModel>()({
    state: {
        chainId: undefined as unknown as ChainId,
        swapTag: [] as SwapTag[],
        preQueryResult: [] as PreQueryResult[],
        tokenPairKey: ''
    } as SwapPreQueryModel,

    reducers: {
        setChainId: (state: SwapPreQueryModel, chainId: ChainId) => produce(state, draft => {
            draft.chainId = chainId
        }),
        setSwapTag: (state: SwapPreQueryModel, swapTag: SwapTag[]) => produce(state, draft => {
            draft.swapTag = {...swapTag}
        }),
        setPreQueryResult: (state: SwapPreQueryModel, preQueryResult: PreQueryResult[]) => produce(state, draft => {
            draft.preQueryResult = {...preQueryResult}
        }),
        setSwapPreQueryModel: (state: SwapPreQueryModel, payload: SwapPreQueryModel) => {
            return {...state, ...payload}
        },
    },

    effects: (dispatch) => ({
        async preQuery(params: PreQueryParams, rootState): Promise<SwapPreQueryModel> {
            const {chainId, web3, tokenIn, tokenOut, multicall} = params
            if (!chainId || !web3 || !tokenIn || !tokenOut || !multicall) {
                return undefined as unknown as SwapPreQueryModel
            }
            let swapTag = rootState.swapPreQueryModel.swapTag
            let preQueryResult = rootState.swapPreQueryModel.preQueryResult
            const tokenPairKey = getTokenPairKey(tokenIn, tokenOut)
            if (chainId !== rootState.swapPreQueryModel.chainId || tokenPairKey !== rootState.swapPreQueryModel.tokenPairKey) {
                if (chainId === rootState.swapPreQueryModel.chainId) {
                    // only token pair differ
                    swapTag = getSwapTags(chainId, tokenIn, tokenOut)
                    const originSwapTag = rootState.swapPreQueryModel.swapTag
                    const knownPreQueryResult = [] as PreQueryResult[]
                    const knownSwapTag = [] as SwapTag[]
                    const unknownSwapTag = [] as SwapTag[]
                    swapTag.forEach(v=>{
                        const findIdx = originSwapTag.findIndex(tag=>{return v === tag})
                        if (findIdx >= 0) {
                            knownPreQueryResult.push(rootState.swapPreQueryModel.preQueryResult[findIdx])
                            knownSwapTag.push(v)
                        } else {
                            unknownSwapTag.push(v)
                        }
                    })
                    const unknownPreQueryResult = initPreQueryResultList(unknownSwapTag, chainId)
                    swapTag = [...knownSwapTag, ...unknownSwapTag]
                    preQueryResult = [...knownPreQueryResult, ...unknownPreQueryResult]
                } else {
                    // chainId differ
                    swapTag = getSwapTags(chainId, tokenIn, tokenOut)
                    preQueryResult = initPreQueryResultList(swapTag, chainId)
                }
            }
            const newPreQueryResult = await doPreQuery(swapTag, preQueryResult, chainId, web3, multicall, tokenIn, tokenOut)

            const newPreQueryModule = {
                chainId,
                swapTag,
                preQueryResult: newPreQueryResult
            } as SwapPreQueryModel

            dispatch.swapPreQueryModel.setSwapPreQueryModel(newPreQueryModule)
            return newPreQueryModule
        },

    })
})