import { ChainId } from '../../../types/mod';


export interface LiquidityControl {
    showByPair: any;
    searchKey: string;
    useWrappedGasToken: boolean;
}
export enum Dex {
    iZiSwap = 'iZiSwap',
    UniswapV3 = 'UniswapV3',
    PancakeSwap = 'PancakeSwap',
}
export type Status = 'ALIVE' | 'PAUSE';

interface Token {
    symbol: string;
    address: string;
}

interface PoolInfo {
    tokenX: Token;
    tokenY: Token;
    feeRate: number;
}

interface Range {
    left: number;
    right: number;
}

interface Fees {
    tokenX: number;
    tokenXValue: number;
    tokenY: number;
    tokenYValue: number;
}

interface Liquidity {
    tokenX: number;
    tokenY: number;
    tokenXValue: number;
    tokenYValue: number;
}

export interface LiquidityInfo {
    id: number;
    dex: Dex;
    chainId: ChainId;
    lpid: number;
    owner: string;
    poolInfo: PoolInfo;
    range: Range;
    fees: Fees;
    liquidity: Liquidity;
    status: Status;
}

