interface APIConfig {
    protocol: string;
    root: string;
    prefix: string;
    version: string;
}

const api_host_config: Record<string, string> = {
    development: 'portfolio-api.izumi.finance',
    // production: '813d-123-118-6-23.ngrok.io',
    production: 'portfolio-api.izumi.finance',

    local: 'localhost:8000',
};

const config: APIConfig = {
    protocol: process.env.REACT_APP_ENV === 'local' ? 'http://' : 'https://',
    root: api_host_config[process.env.REACT_APP_ENV as string],
    prefix: 'api',
    version: 'v1',
};

export const parentEndpoint = `${config.protocol}${config.root}/${config.prefix}/${config.version}`;

export const ENDPOINTS = {
    monitor: {
        add: `${parentEndpoint}/monitor/add/`,
        refresh: `${parentEndpoint}/monitor/refresh/`,
        stop: `${parentEndpoint}/monitor/stop/`,
        get: `${parentEndpoint}/monitor/get/`,
        search: `${parentEndpoint}/monitor/search/`,
        poolInfo: `${parentEndpoint}/snapshot/liquidity/`,
        poolList: `${parentEndpoint}/snapshot/poolList/`,
        refreshPool: `${parentEndpoint}/snapshot/refresh/`,
    },
};
