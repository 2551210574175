import { createModel } from '@rematch/core';
import { RootModel } from '../index';
import produce from 'immer';
export enum LayoutStateEnum {
    init='init',
    sidebar = 'sidebar',
    header = 'header',
}

export interface PageLayoutState {
    currentLayout: 'init'|'sidebar'|'header',
    inAnimation:boolean,
}

export const pageLayout = createModel<RootModel>()({
    state: {
        currentLayout:LayoutStateEnum.init,
        inAnimation:false,
    } as PageLayoutState,
    reducers: {
        setPageLayout: (state: PageLayoutState, layout: 'sidebar'|'header') => produce(state, draft => {
            draft.currentLayout = layout;
        }),
        setIsInAnimation: (state: PageLayoutState, isIn:boolean) => produce(state, draft => {
            draft.inAnimation = isIn;
        })
    },
	
});
