import React, { ReactElement } from 'react';
import { AccordionButton, AccordionIcon, Spacer, Tag, Text, useColorMode, Image, BoxProps, Flex } from '@chakra-ui/react';
import Info from '../../../iZUMi-UI-toolkit/src/components/Info/Info';
import { i_text_copy, i_text_copy_bold } from '../../../style';
import { getColorThemeSelector } from '../../../utils/funcs';
import { useTranslation } from 'react-i18next';
import { TokenInfoFormatted } from '../../../hooks/useTokenListFormatted';

export type FeeRateProps = {
    tokenA: TokenInfoFormatted;
    tokenB: TokenInfoFormatted;
    feeTier: FeeTier;
    initialToggle?: boolean;
    direction?: string;
    multipleFee?: boolean;
    variant?: string;
    type?: string;
    protocolElement?: ReactElement;
} & BoxProps;
type Variant = 'base' | 'purple';

export const FeeRate: React.FC<FeeRateProps> = (props) => {
    const { type, protocolElement, ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { t } = useTranslation();
    const { tokenA, tokenB, feeTier, initialToggle, direction, multipleFee, variant } = props;
    const themeColor = {
        base: {
            bg: colorTheme('primary.50', '#3D4375'),
            color: colorTheme('primary.500', 'primary.300'),
        },
        purple: {
            bg: colorTheme('primary.50', '#2E3162'),
            color: colorTheme('primary.500', 'primary.200'),
        },
    };

    const DetailsButton = (
        <>
            <Spacer />
            <AccordionButton color="secondary.500" w="27px" h="20px" px="0px" className={i_text_copy} fontSize="12px !important">
                <Image w="27px" h="20px" mr="5px" src={process.env.PUBLIC_URL + '/assets/orderHistory/detail.svg'}></Image>
                <AccordionIcon />
            </AccordionButton>
        </>
    ) as ReactElement;

    return type === 'gallery' ? (
        <Info
            direction={direction ? 'row' : 'column'}
            w="110px"
            whiteSpace="nowrap"
            label={
                <Flex w="110px">
                    <Text className={i_text_copy_bold} fontSize="12px">
                        {!initialToggle ? tokenA?.symbol + '/' + tokenB?.symbol : tokenB?.symbol + '/' + tokenA?.symbol}
                    </Text>
                    {protocolElement}
                </Flex>
            }
            value={
                multipleFee ? (
                    DetailsButton
                ) : (
                    <Tag
                        w="110px"
                        minH="19px"
                        m="0"
                        bg={variant ? themeColor[variant as Variant].bg : themeColor['base'].bg}
                        color={variant ? themeColor[variant as Variant].color : themeColor['base'].color}
                        className={i_text_copy}
                        fontSize="12px"
                    >
                        {!direction && t('Fee Rate')}
                        &nbsp;
                        <Text className={i_text_copy_bold} lineHeight="1.2">
                            {' '}
                            {Number(feeTier).toFixed(2)}%{' '}
                        </Text>
                    </Tag>
                )
            }
        />
    ) : (
        <Info
            direction={direction ? 'row' : 'column'}
            w={{ base: 'unset', sm: '153px' }}
            label={
                <Text className={i_text_copy_bold} fontSize="14px">
                    {!initialToggle ? tokenA?.symbol + '/' + tokenB?.symbol : tokenB?.symbol + '/' + tokenA?.symbol}
                </Text>
            }
            value={
                multipleFee ? (
                    DetailsButton
                ) : (
                    <Tag
                        m="0"
                        bg={variant ? themeColor[variant as Variant].bg : themeColor['base'].bg}
                        color={variant ? themeColor[variant as Variant].color : themeColor['base'].color}
                        className={i_text_copy}
                    >
                        {!direction && t('fee-rate')}
                        &nbsp;
                        <Text className={i_text_copy_bold} fontSize="14px">
                            {' '}
                            {Number(feeTier).toFixed(2)}%{' '}
                        </Text>
                    </Tag>
                )
            }
        />
    );
};
