import { Accordion, Center, Heading, HStack } from '@chakra-ui/react';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ToastType, useCustomToast } from '../../../components/Toast/Toast';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import { ErrorInfo } from '../../../iZUMi-UI-toolkit/src/components/ErrorInfo/ErrorInfo';
import { getAllLiquidityChartInfo, ResponsePoolInfo } from '../../../net/endpoints/izumiMonitor';
import { RootDispatch, RootState } from '../../../state/store';
import PositionListEntry from './PositionListEntry';

const PoolList: React.FC = () => {
    const { pool } = useSelector((state: RootState) => state);

    const {
        pool: { control },
    } = useSelector((state: RootState) => state);

    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const toast = useCustomToast();
    const [openList, setOpenList] = useState([]);
    useEffect(() => {
        getAllLiquidityChartInfo()
            .then((r) => {
                const poolsData = r.data.data as ResponsePoolInfo[];
                dispatch.pool.setPoolData(poolsData);
            })
            .catch((e) => {
                toast(ToastType.error, e.message);
            });
    }, [dispatch.pool]);
    const poolsFiltered: ResponsePoolInfo[] = useMemo(() => {
        let resultList = [...pool.pools];
        if (control.dex) {
            resultList = resultList.filter((pool) => {
                return pool.dex.toLowerCase().includes(control.dex!.toLowerCase());
            });
        }
        if (control.searchKey) {
            resultList = resultList.filter((pool) => {
                return (
                    pool.tokenX.symbol.toLowerCase().includes(control.searchKey.toLowerCase()) ||
                    pool.tokenY.symbol.toLowerCase().includes(control.searchKey.toLowerCase())
                );
            });
        }
        if (control.network) {
            resultList = resultList.filter((pool) => {
                return pool.chainId === control.network;
            });
        }
        return resultList;
    }, [control.dex, control.network, control.searchKey, pool.pools]);
    return (
        <Accordion allowMultiple={true} allowToggle={true} index={openList} w="100%" pb="30px">
            {poolsFiltered && poolsFiltered.length > 0 ? (
                poolsFiltered.map((item, index) => {
                    if (_.isEmpty(item)) {
                        return (
                            <HStack mb="30px">
                                <ErrorInfo w="500px" content={'Error Recoed'} key={index}></ErrorInfo>
                            </HStack>
                        );
                    } else {
                        return (
                            <PositionListEntry
                                entry={item}
                                key={index}
                                index={index}
                                openList={openList}
                                setOpenList={setOpenList}
                                mb="30px"
                            ></PositionListEntry>
                        );
                    }
                })
            ) : (
                <Center>
                    <Heading size="lg" color="tertiary.100">
                        {'No position yet'} .
                    </Heading>
                </Center>
            )}
        </Accordion>
    );
};

export default PoolList;
