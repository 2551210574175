import { BoxProps, useColorMode, Box } from '@chakra-ui/react';
import { getColorThemeSelector } from '../../utils/funcs';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import _ from 'lodash';
import { useMemo } from 'react';
import { formatNumber } from '../../utils/tokenMath';
import Card from '../../iZUMi-UI-toolkit/src/components/Card/Card';
import { $mobx } from 'mobx';
export type TimeNumberSeries = number[][];

export type NamedTimeNumberSeries = {
    name: string;
    data: TimeNumberSeries;
    amountList: number[][];
};

export type DataSeries = NamedTimeNumberSeries[];

type LiquidityChartProps = {
    height?: string;
    width?: string;
    variant?: string;
    series: DataSeries;

    currentTick?: number;
    tokenX: string;
    tokenY: string;
    tokenXDecimal: number;
    tokenYDecimal: number;
    tokenYPrice: number;
    leftTick: number;
    tickSpacing: number;
} & BoxProps;

export const LiquidityChart: React.FC<LiquidityChartProps> = (props) => {
    const {
        height,
        series: seriesRaw,
        variant,
        currentTick,
        width,
        tokenYDecimal,
        tokenXDecimal,
        tokenX,
        tokenY,
        tokenYPrice,
        leftTick,
        tickSpacing,
        ...rest
    } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const series = useMemo(() => {
        const seriesCopy = _.cloneDeep(seriesRaw);

        // const maxLiq = seriesCopy[0].data.reduce((max, d) => {
        //     if (d[1] > max) return d[1];
        //     return max;
        // }, 0);

        // if (seriesCopy[0].data.length) {
        //     if (Number(seriesCopy[0].data[currentTick!][1]) < maxLiq / 2) {
        //         seriesCopy[0].data[currentTick!][1] = maxLiq;
        //     }
        // }

        return seriesCopy;
    }, [seriesRaw]);

    const options: ApexOptions = useMemo(() => {
        return {
            stroke: {
                curve: 'smooth',
            },
            yaxis: {
                show: false,
                title: {
                    text: 'VeiZi Amount',
                    style: {
                        color: colorTheme('#3A2F53', '#9B8FAE'),
                    },
                },
                labels: {
                    style: {
                        colors: colorTheme('#3A2F53', '#9B8FAE'),
                    },
                },
            },
            colors: [
                ({ dataPointIndex }: any) => {
                    if (dataPointIndex === Number(currentTick)) {
                        return '#FF0000';
                    } else {
                        return colorTheme('#6A20B3', '#8B62FF');
                    }
                },
            ],
            xaxis: {
                axisBorder: {
                    show: false,
                    color: colorTheme('#52466A', '#8F84A0'),
                },
                labels: {
                    show: false,
                    style: {
                        colors: colorTheme('#3A2F53', '#9B8FAE'),
                    },
                },
                tickPlacement: 'on',
            },

            tooltip: {
                x: {
                    show: false,
                    format: 'dd MMM yyyy',
                },
                theme: colorTheme('light', 'dark'),
                custom: ({ dataPointIndex, w }) => {
                    // const series = w.config.series;
                    const seriesMaxIdx = series[0].data.length - 1;
                    const currentIdx = Math.min(dataPointIndex, seriesMaxIdx);
                    const tick = leftTick + currentIdx * tickSpacing;
                    const sqrtPrice = Math.sqrt(Math.pow(1.0001, tick));
                    const price = sqrtPrice * sqrtPrice;
                    const priceDecimal = (price * 10 ** tokenXDecimal) / 10 ** tokenYDecimal;

                    let amountXDecimal = 0;
                    let amountYDecimal = 0;

                    if (dataPointIndex >= (currentTick ?? 0)) {
                        amountXDecimal = series[0].amountList[currentIdx][0];
                    }

                    if (dataPointIndex < (currentTick ?? 0)) {
                        amountYDecimal = series[0].amountList[currentIdx][1];
                    }

                    return (
                        '<ul style="padding:15px">' +
                        `<li> <b> Price ${tokenX}/${tokenY}: ${formatNumber(priceDecimal, 2, 8)} </b> </li>` +
                        `<li> <b> Price ${tokenY}/${tokenX}: ${formatNumber(1 / (priceDecimal ?? 0.00001), 2, 8)} </b> </li>` +
                        `<li> <b> Tick:  ${String(tick)} </b> </li>` +
                        `<li> <b> ${tokenX} locked :  ${String(amountXDecimal)} </b> </li>` +
                        `<li> <b> ${tokenY} locked :  ${String(amountYDecimal)} </b> </li>` +
                        '</ul>'
                    );
                },
            },
            grid: {
                show: false,
                borderColor: colorTheme('#B7B1BE', '#52466A'),
            },
            chart: {
                toolbar: {
                    show: false,
                },

                events: {
                    mouseMove: (e, d, c) => {
                        // const series = c.config.series;
                        // const seriesMaxIdx = series[0].data.length - 1;
                        // // console.log('c: ', c);
                        // if (seriesMaxIdx === -1) {
                        // } else if (c.dataPointIndex === -1) {
                        //     const currentIdx = seriesMaxIdx;
                        //     const tick = leftTick + currentIdx * tickSpacing;
                        //     const sqrtPrice = Math.sqrt(Math.pow(1.0001, tick));
                        //     const amountY = series[0].data[currentIdx][1] * sqrtPrice;
                        //     const amountYDecimal = amountY / 10 ** tokenYDecimal;
                        //     const liquidityValue = amountYDecimal * tokenYPrice;
                        // } else {
                        //     const currentIdx = Math.min(c.dataPointIndex, seriesMaxIdx);
                        //     const tick = leftTick + currentIdx * tickSpacing;
                        //     const sqrtPrice = Math.sqrt(Math.pow(1.0001, tick));
                        //     const amountY = series[0].data[currentIdx][1] * sqrtPrice;
                        //     const amountYDecimal = amountY / 10 ** tokenYDecimal;
                        //     const liquidityValue = amountYDecimal * tokenYPrice;
                        // }
                    },
                },
            },
            dataLabels: {
                enabled: false,
            },
        };
    }, [colorTheme, currentTick, leftTick, series, tickSpacing, tokenX, tokenXDecimal, tokenY, tokenYDecimal]);

    return (
        <Card variant={variant ?? 'base'} w="100%" position="relative" {...rest}>
            {/* <Text position="absolute" left="16px" top="10px" className={i_text_copy} fontSize="16px" color="gray.400">
                Liquidity
            </Text> 

            {/* <Text position="absolute" left="16px" top="10px" className={i_text_copy} fontSize="16px" color="gray.400">
                Liquidity
            </Text> */}

            {/* <Text position="absolute" left="16px" top="30px" className={i_h2} fontSize="32px" color={colorTheme('gray.800', 'gray.0')}>
                ${formatNumber(value[1], 2, 2, 1)}
            </Text> */}

            <Box w="100%" mt="14px !important">
                <ReactApexChart options={options} series={series} height={height ?? '300px'} width={width ?? '100%'} type="bar" />
            </Box>
        </Card>
    );
};
