import { HStack, Heading, Stack } from '@chakra-ui/react';
import PageHeader from '../../components/PageHeader/PageHeader';
import NetworkSelect from '../../components/Select/NetworkSelect/NetworkSelect';
import PageLayout from '../../components/layout/PageLayout';
import SearchBlock from './SearchBlock';
import useIsMobile from '../../hooks/useIsMobile';

const Search: React.FC = () => {
    const isMobile = useIsMobile();

    return isMobile ? (
        <PageLayout
            body={
                <Stack w="100%" mt="15px">
                    <SearchBlock></SearchBlock>
                </Stack>
            }
            type="mobile"
        />
    ) : (
        <PageLayout
            header={
                <PageHeader
                    headerTitle={
                        <HStack spacing="20px">
                            <Heading level="3" fontSize={'24px'}>
                                {'Search'}
                            </Heading>
                        </HStack>
                    }
                    subtitle={'Search custom liquidity.'}
                    element={
                        <HStack spacing="10px">
                            <NetworkSelect />
                        </HStack>
                    }
                />
            }
            body={
                <Stack mt="12px">
                    <SearchBlock></SearchBlock>
                </Stack>
            }
        />
    );
};
export default Search;
