import React from 'react';
import { Text, useColorMode, BoxProps, Flex, Image, HStack, Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { TokenInfoFormatted } from '../../../hooks/useTokenListFormatted';
import { i_text_copy, i_text_copy_bold } from '../../../style';
import { getColorThemeSelector } from '../../../utils/funcs';
import { formatNumber } from '../../../utils/tokenMath';

type FeeGainsProps = {
    tokenX: TokenInfoFormatted;
    tokenY: TokenInfoFormatted;
    remainTokenX: number;
    remainTokenY: number;
    totalValue: number;
} & BoxProps;

export const FeeGains: React.FC<FeeGainsProps> = ({ tokenX, tokenY, remainTokenX, remainTokenY, totalValue, ...rest }) => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { t } = useTranslation();

    return (
        <Flex
            w={{ base: 'unset', sm: '250px' }}
            h="68px"
            bg={colorTheme('#F9F9FB', 'tertiary.700')}
            direction="row"
            px="18px"
            pt="14px"
            borderRadius="6px"
            {...rest}
        >
            <Stack>
                <Text className={i_text_copy}>{t('Fees')}</Text>
                <Text className={i_text_copy_bold}>{'$' + formatNumber(totalValue, 2, 4)}</Text>
            </Stack>

            <Stack flex="1" spacing="10px" alignItems="end">
                <HStack>
                    <Image w="16px" h="16px" borderRadius="4px" src={tokenX.icon} />
                    {/* <Text className={i_text_copy_bold} textAlign="left" ml="auto">
                        {tokenX.symbol}:
                    </Text> */}
                    <Text className={i_text_copy} textAlign="left" ml="auto">
                        {formatNumber(remainTokenX, 2, 4)}
                    </Text>
                </HStack>
                <HStack>
                    <Image w="16px" h="16px" borderRadius="4px" src={tokenY.icon} />
                    {/* <Text className={i_text_copy_bold} textAlign="left" ml="auto">
                        {tokenY.symbol}:
                    </Text> */}
                    <Text className={i_text_copy} textAlign="left" ml="auto">
                        {formatNumber(remainTokenY, 2, 4)}
                    </Text>
                </HStack>
            </Stack>
        </Flex>
    );
};
