import BigNumber from "bignumber.js";
import { _getAmountX, _getAmountY, _liquidity2AmountXAtPoint, _liquidity2AmountYAtPoint } from "./amountMath";
import { point2PoolPriceUndecimalSqrt } from "./price";

export const getLiquidityValue = (
    liquidity: string,
    leftPoint: number,
    rightPoint: number,
    currentPoint: number,
    currentLiqudity: string,
    currentLiqudityX: string
): {amountX: string, amountY: string} => {
    
    let amountX = new BigNumber(0);
    let amountY = new BigNumber(0);
    const liquid = liquidity;
    const sqrtRate = Math.sqrt(1.0001);
    const leftPtNum = Number(leftPoint);
    const rightPtNum = Number(rightPoint);
    // compute amountY without currentPt
    if (leftPtNum < currentPoint) {
        const rightPt: number = Math.min(currentPoint, rightPtNum);
        const sqrtPriceR = point2PoolPriceUndecimalSqrt(rightPt);
        const sqrtPriceL = point2PoolPriceUndecimalSqrt(leftPtNum);
        amountY = _getAmountY(new BigNumber(liquid), sqrtPriceL, sqrtPriceR, sqrtRate, false);
    }
    
    // compute amountX without currentPt
    if (rightPtNum > currentPoint + 1) {
        const leftPt: number = Math.max(currentPoint + 1, leftPtNum);
        const sqrtPriceR = point2PoolPriceUndecimalSqrt(rightPtNum);
        amountX = _getAmountX(new BigNumber(liquid), leftPt, rightPtNum, sqrtPriceR, sqrtRate, false);
    }

    // compute amountX and amountY on currentPt
    if (leftPtNum <= currentPoint && rightPtNum > currentPoint) {
        const liquidityValue = new BigNumber(liquidity);
        const maxLiquidityYAtCurrentPt = new BigNumber(currentLiqudity).minus(currentLiqudityX);
        const liquidityYAtCurrentPt = liquidityValue.gt(maxLiquidityYAtCurrentPt) ? maxLiquidityYAtCurrentPt : liquidityValue;
        const liquidityXAtCurrentPt = liquidityValue.minus(liquidityYAtCurrentPt);
        const currentSqrtPrice = point2PoolPriceUndecimalSqrt(currentPoint);
        amountX = amountX.plus(_liquidity2AmountXAtPoint(liquidityXAtCurrentPt, currentSqrtPrice, false));
        amountY = amountY.plus(_liquidity2AmountYAtPoint(liquidityYAtCurrentPt, currentSqrtPrice, false));
    }
    return {
        amountX: amountX.toFixed(0),
        amountY: amountY.toFixed(0)
    };
}