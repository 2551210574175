import { liquidity2TokenAmount } from '../../../../../utils/tickMath';
import { blocksPerDay } from '../../../../../config/chains';
import { ChainId } from '../../../../../types/mod';
import { amount2Decimal } from '../../../../../utils/tokenMath';
import BigNumber from 'bignumber.js';
import { PoolEntryState, PositionDetail, TokenStatus } from './types';
import { decodeMethodResult } from '../../../../../utils/contractHelpers';
import { TokenInfoFormatted } from '../../../../../hooks/useTokenListFormatted';

export const findPoolEntryByPoolKey = (poolEntryList: PoolEntryState[], poolKey: string): PoolEntryState => {
    return poolEntryList.find(p => p.meta.positionPoolKey === poolKey) as PoolEntryState;
};

export const getLiquidityAPR = (poolEntry: PoolEntryState, chainId: ChainId, vLiquidity: number, capital: number): number => {
    const totalVLiquidity = poolEntry.data.vLiquidity + vLiquidity;
    const rate = vLiquidity / totalVLiquidity;
    let totalReward = 0;
    for (let i = 0; i < poolEntry.data.rewardTokenPrice.length; i++) {
        const rewardDecimal = Number(poolEntry.data.reward[i][1]);
        const rewardTokenPrice = poolEntry.data.rewardTokenPrice[i];
        const rewardTimes = poolEntry.meta.useTimestamp ? 365 * 24 * 60 * 60 : blocksPerDay(chainId) * 365;
        const rewardPerYear = rewardTokenPrice * rewardDecimal * rewardTimes;
        totalReward += rewardPerYear;
    }
    const reward = totalReward * rate;
    return reward / capital;
};

export const getLiquidityIZIBoostAPR = (poolEntry: PoolEntryState, chainId: ChainId, vLiquidity: number, capital: number, iZiAmount: number): number => {
    if (capital === 0) {
        return 0;
    }
    const totalVLiquidity = poolEntry.data.vLiquidity + vLiquidity;
    const totalNIZI = poolEntry.data.totalNIZI + iZiAmount;
    let rate = 0;
    if (totalNIZI > 0) {
        const iZiVLiquidity = vLiquidity * 0.4 + iZiAmount / totalNIZI * totalVLiquidity * 0.6;
        rate = Math.min(vLiquidity, iZiVLiquidity) / totalVLiquidity;
    } else {
        rate = vLiquidity / totalVLiquidity;
    }
    let totalReward = 0;
    for (let i = 0; i < poolEntry.data.rewardTokenPrice.length; i++) {
        const rewardDecimal = Number(poolEntry.data.reward[i][1]);
        const rewardTokenPrice = poolEntry.data.rewardTokenPrice[i];
        const rewardTimes = poolEntry.meta.useTimestamp ? 365 * 24 * 60 * 60 : blocksPerDay(chainId) * 365;
        const rewardPerYear = rewardTokenPrice * rewardDecimal * rewardTimes;
        totalReward += rewardPerYear;
    }
    const reward = totalReward * rate;
    return reward / capital;
};

export const addIZIBoostAPR = (poolEntry: PoolEntryState, chainId: ChainId, vLiquidity: number, capital: number, originIZIAmount: number, iZiAmount: number): number => {
    if (capital === 0) {
        return 0;
    }
    const totalVLiquidity = poolEntry.data.vLiquidity;
    const totalNIZI = Number(poolEntry.data.totalNIZI) + Number(iZiAmount);

    const newNIZI = Number(originIZIAmount) + Number(iZiAmount);

    let rate = 0;
    if (totalNIZI > 0) {
        const iZiVLiquidity = vLiquidity * 0.4 + newNIZI / totalNIZI * totalVLiquidity * 0.6;
        rate = Math.min(vLiquidity, iZiVLiquidity) / totalVLiquidity;
    } else {
        rate = vLiquidity / totalVLiquidity;
    }
    let totalReward = 0;
    for (let i = 0; i < poolEntry.data.rewardTokenPrice?.length ?? 0; i++) {
        const rewardDecimal = Number(poolEntry.data.reward[i][1]);
        const rewardTokenPrice = poolEntry.data.rewardTokenPrice[i];
        const rewardTimes = poolEntry.meta.useTimestamp ? 365 * 24 * 60 * 60 : blocksPerDay(chainId) * 365;
        const rewardPerYear = rewardTokenPrice * rewardDecimal * rewardTimes;
        totalReward += rewardPerYear;
    }
    const reward = totalReward * rate;
    const apr = reward / capital;
    return apr;
};

export const veiZiBoostAPR = (poolEntry: PoolEntryState, chainId: ChainId, userVLiquidity: number, userCapital: number, userVeiZi: number): number => {
    if (userCapital === 0) {
        return 0;
    }
    const totalVLiquidity = poolEntry.data.vLiquidity;
    const totalValidVeiZi = poolEntry.data.totalValidVeiZi;
    let rate = 0;
    if (totalValidVeiZi > 0) {
        const iZiVLiquidity = userVLiquidity * 0.4 + userVeiZi / totalValidVeiZi * totalVLiquidity * 0.6;
        rate = Math.min(userVLiquidity, iZiVLiquidity) / totalVLiquidity;
    } else {
        rate = userVLiquidity / totalVLiquidity;
    }
    let totalReward = 0;
    for (let i = 0; i < poolEntry.data.rewardTokenPrice?.length ?? 0; i++) {
        const rewardDecimal = Number(poolEntry.data.reward[i][1]);
        const rewardTokenPrice = poolEntry.data.rewardTokenPrice[i];
        const blocksPerYear = blocksPerDay(chainId) * 365;
        const rewardPerYear = rewardTokenPrice * rewardDecimal * blocksPerYear;
        totalReward += rewardPerYear;
    }
    const reward = totalReward * rate;
    const apr = reward / userCapital;
    return apr;
};

export const veiZiNoBoostNewNftAPR = (poolEntry: PoolEntryState, chainId: ChainId, vLiquidity: number, capital: number): number => {
    if (capital === 0) {
        return 0;
    }
    const totalVLiquidity = poolEntry.data.vLiquidity + vLiquidity;
    let rate = vLiquidity / totalVLiquidity;
    const totalValidVeiZi = poolEntry.data.totalValidVeiZi;
    if (poolEntry.meta.veiZiBoost && totalValidVeiZi > 0) {
        rate = rate * 0.4;
    }
    let totalReward = 0;
    for (let i = 0; i < poolEntry.data.rewardTokenPrice?.length ?? 0; i++) {
        const rewardDecimal = Number(poolEntry.data.reward[i][1]);
        const rewardTokenPrice = poolEntry.data.rewardTokenPrice[i];
        const rewardTimes = poolEntry.meta.useTimestamp ? 365 * 24 * 60 * 60 : blocksPerDay(chainId) * 365;
        const rewardPerYear = rewardTokenPrice * rewardDecimal * rewardTimes;
        totalReward += rewardPerYear;
    }
    const reward = totalReward * rate;
    const apr = reward / capital;
    return apr;
};

export const veiZiBoostNewNftAPR = (poolEntry: PoolEntryState, chainId: ChainId, originUserVLiquidity: number, originUserCapital: number, userVeiZi: number, userValidVeiZi: number, vLiquidity: number, capital: number): number => {
    if (originUserCapital + capital === 0) {
        return 0;
    }
    const totalVLiquidity = poolEntry.data.vLiquidity + vLiquidity;
    /*
        validVeiZi = Math.min(veiZi, 2 * totalValidVeiZi * vLiquidity / totalVLiquidity);
        totalValidVeiZi += validVeiZi;
    */
    const userVLiquidity = originUserVLiquidity + vLiquidity;
    const userCapital = originUserCapital + capital;
    let totalValidVeiZi = poolEntry.data.totalValidVeiZi - userValidVeiZi;
    const validVeiZi = Math.min(userVeiZi, 2 * totalValidVeiZi * userVLiquidity / totalVLiquidity);
    totalValidVeiZi += validVeiZi;

    let rate = 0;
    if (totalValidVeiZi > 0 && poolEntry.meta.veiZiBoost) {
        const iZiVLiquidity = userVLiquidity * 0.4 + userVeiZi / totalValidVeiZi * totalVLiquidity * 0.6;
        rate = Math.min(userVLiquidity, iZiVLiquidity) / totalVLiquidity;
    } else {
        rate = userVLiquidity / totalVLiquidity;
    }
    let totalReward = 0;
    for (let i = 0; i < poolEntry.data.rewardTokenPrice?.length ?? 0; i++) {
        const rewardDecimal = Number(poolEntry.data.reward[i][1]);
        const rewardTokenPrice = poolEntry.data.rewardTokenPrice[i];
        const rewardTimes = poolEntry.meta.useTimestamp ? 365 * 24 * 60 * 60 : blocksPerDay(chainId) * 365;
        const rewardPerYear = rewardTokenPrice * rewardDecimal * rewardTimes;
        totalReward += rewardPerYear;
    }
    const reward = totalReward * rate;
    const apr = reward / userCapital;
    return apr;
};

export const veiZiBoostForNewNftDesiredBoost = (desiredBoost: number, poolEntry: PoolEntryState, originUserVLiquidity: number, userValidVeiZi: number, vLiquidity: number): number => {
    const totalVLiquidity = poolEntry.data.vLiquidity + vLiquidity;
    const userVLiquidity = originUserVLiquidity + vLiquidity;
    const totalValidVeiZi = poolEntry.data.totalValidVeiZi - userValidVeiZi;

    const maxValidVeiZi = 2 * totalValidVeiZi / totalVLiquidity * userVLiquidity;

    let boostVLiquidity = userVLiquidity * (desiredBoost - 1) / 1.5;
    if (boostVLiquidity < 0) {
        // console.log('[error]: boostVLiquidity < 0: ', boostVLiquidity);
        boostVLiquidity = 0;
    }
    if (boostVLiquidity > userVLiquidity) {
        // console.log('[error]: boostVLiquidity > userVLiquidity: ', boostVLiquidity);
        boostVLiquidity = userVLiquidity;
    }

    // phase 1. userVeiZi <= maxValidVeiZi
    // userVeiZi / (totalValidVeiZi + userVeiZi) * totalVLiquidity = boostVLiquidity

    if (totalVLiquidity > boostVLiquidity) {
        const userVeiZi = totalValidVeiZi / (totalVLiquidity - boostVLiquidity) * boostVLiquidity;
        if (userVeiZi <= maxValidVeiZi) {
            return userVeiZi;
        }
    }
    // phase 2. userVeiZi >= maxValidVeiZi
    // userVeiZi / (totalValidVeiZi + maxValidVeiZi) * totalVLiquidity = boostVLiquidity
    const userVeiZi = (totalValidVeiZi + maxValidVeiZi) / totalVLiquidity * boostVLiquidity;
    return userVeiZi;
};

export const niZiForDesiredBoost = (vLiquidity: number, totalVLiquidity: number, totalNIZI: number, boost: number): number => {
    if (totalVLiquidity === 0) return 0;
    const v = vLiquidity / totalVLiquidity;
    const w = (boost - 1) * v / 1.5;
    if (w === 1) {
        // boost <= 2.5
        // means v === 1
        return 0;
    }
    const niZi = w * totalNIZI / (1 - w);
    return niZi;
};

export const getWorthOfToken = (
    token: TokenInfoFormatted,
    amount: number,
    tokenPrice: number,
): number => {
    const decimalAmount = amount2Decimal(
        new BigNumber(amount),
        token
    ) ?? 0;
    return decimalAmount * tokenPrice;
};

export const getPoolAPR = (rewardPerBlock: string, chainId: ChainId, liquidity: number, rewardToken: Pair<TokenInfoFormatted, number>): number => {
    const blocksPerYear = blocksPerDay(chainId) * 365;
    const { left: token, right: rewardTokenPrice } = rewardToken;
    const rewardsPerYear = (amount2Decimal(
        new BigNumber(blocksPerYear * Number(rewardPerBlock)),
        token
    ) ?? 0) * rewardTokenPrice;

    return rewardsPerYear / (liquidity > 100 ? liquidity : 100);
};

export const getPoolAPRTimestamp = (rewardPerSecond: string, liquidity: number, rewardToken: Pair<TokenInfoFormatted, number>): number => {
    const secondsPerYear = 365 * 24 * 60 * 60;
    const { left: token, right: rewardTokenPrice } = rewardToken;
    const rewardsPerYear = (amount2Decimal(
        new BigNumber(secondsPerYear * Number(rewardPerSecond)),
        token
    ) ?? 0) * rewardTokenPrice;

    return rewardsPerYear / (liquidity > 100 ? liquidity : 100);
};


export const hex2Dec = (hex: string): string => {
    return (new BigNumber(hex)).toFixed(0);
};


export const getPriceAByBDecimal = (chainId: ChainId, tokenA: TokenInfoFormatted, tokenB: TokenInfoFormatted, sqrtPriceX96: string): number => {
    const tokenADecimal = tokenA.decimal ?? 18;
    const tokenBDecimal = tokenB.decimal ?? 18;

    let price: BigNumber = (new BigNumber(sqrtPriceX96)).div((new BigNumber('2')).pow(96));
    if (tokenA.address.toUpperCase() > tokenB.address.toUpperCase()) {
        // price is B by A
        price = new BigNumber(1).div(price);
    }
    price = price.times(price);
    return Number(price.toString()) * (10 ** tokenADecimal / 10 ** tokenBDecimal);
};

export const getPriceAByB = (tokenA: TokenInfoFormatted, tokenB: TokenInfoFormatted, sqrtPriceX96: string): number => {

    let price: BigNumber = (new BigNumber(sqrtPriceX96)).div((new BigNumber('2')).pow(96));
    if (tokenA.address.toUpperCase() > tokenB.address.toUpperCase()) {
        // price is B by A
        price = new BigNumber(1).div(price);
    }
    price = price.times(price);
    return Number(price.toString());
};

export const getPriceOracleAByB = (sqrtPriceX96: string): number => {
    let price: BigNumber = (new BigNumber(sqrtPriceX96)).div((new BigNumber('2')).pow(96));
    price = price.times(price);
    return Number(price.toString());
};


export const getPriceOracleAByBDecimal = (chainId: ChainId, tokenA: TokenInfoFormatted, tokenB: TokenInfoFormatted, sqrtPriceX96: string): number => {
    const tokenADecimal = tokenA.decimal ?? 18;
    const tokenBDecimal = tokenB.decimal ?? 18;

    let price: BigNumber = (new BigNumber(sqrtPriceX96)).div((new BigNumber('2')).pow(96));
    price = price.times(price);
    const priceDecimal = price.times(10 ** tokenADecimal).div(10 ** tokenBDecimal);
    return Number(priceDecimal.toString());
};

export const getTokenStatus = async (miningContract: any, tokenIds: string[]): Promise<TokenStatus[]> => {
    const tokenStatusMulticallData = tokenIds.map(tokenId => miningContract?.methods.tokenStatus(tokenId).encodeABI());
    const tokenStatusResult: string[] = await miningContract?.methods.multicall(tokenStatusMulticallData).call().then((tokenStatus: string[]) => tokenStatus);
    const tokenStatus: TokenStatus[] = tokenStatusResult.map((m) => {
        const t = decodeMethodResult(miningContract as unknown as any, 'tokenStatus', m);
        const ret = { ...t } as TokenStatus;
        return ret;
    });
    return tokenStatus;
};
