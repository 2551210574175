import { createModel } from '@rematch/core';
import { RootModel } from '../index';
import produce from 'immer';
import React from 'react';

export interface WalletState {
    showNav: boolean,
    modal: React.FC | undefined,
    showModal: (v: any)=>boolean | undefined,
}

export const wallet = createModel<RootModel>()({
    state: {
        showNav: false,
        modal: undefined,
        showModal: undefined as unknown,
    } as WalletState,
    reducers: {
        setShowNav: (state: WalletState, show:boolean) => produce(state, draft => {
            draft.showNav = show;
        }),
        setModal: (state: WalletState, modal:React.FC, showModal: (v: any)=>boolean) => produce(state, draft => {
            draft.modal = modal;
            draft.showModal=showModal;
        })
    },
});
