import React, { useEffect, useState } from 'react';
import {
    HStack,
    AccordionButton,
    AccordionPanel,
    AccordionItem,
    Box,
    AccordionIcon,
    BoxProps,
    Stack,
    Image,
    useColorMode,
    Center,
} from '@chakra-ui/react';
import { useBreakpointValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { getChain } from '../../../config/chains';
import useIsMobile from '../../../hooks/useIsMobile';
import { TokenInfoFormatted, useTokenListFormatted } from '../../../hooks/useTokenListFormatted';
import Card from '../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { i_text_copy, i_text_copy_bold } from '../../../style';
import { FeeRate } from '../../Trade/components/FeeRate';
import { TokenIcons } from '../../Trade/components/TokenIcons';
import CardTagContainer from '../../components/CardTag/Container';
import { CardTag } from '../../components/CardTag/CardTag';

import { getColorThemeSelector, toFeeNumber } from '../../../utils/funcs';
import CustomButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import tokens, { EmptyToken, tokenSymbol2token } from '../../../config/tokens';
import { TokenSymbol } from '../../../types/mod';
import { Dex } from '../../../state/models/liquidity/types';
import { getLiquidityChartInfo, refreshLiquidityChartInfo, ResponseChartInfo, ResponsePoolInfo } from '../../../net/endpoints/izumiMonitor';
import _ from 'lodash';
import { useCustomToast, ToastType } from '../../../components/Toast/Toast';
import { LiquidityChart } from '../../components/LiquidityChart';
import { Loading, LoadingEnum } from '../../components/Loading';

export type PositionListEntryProps = {
    entry: ResponsePoolInfo;
    index: number;
    openList: number[];
    setOpenList: any;
} & BoxProps;

export const PositionListEntry: React.FC<PositionListEntryProps> = (props) => {
    const { entry, index, openList, setOpenList, ...rest } = props;
    const { t } = useTranslation();
    const toast = useCustomToast();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const oneLineMode = useBreakpointValue({ base: false, xxl: true, '2xl': true })!;

    const isMobile = useIsMobile();
    const isOpen = openList.includes(index);
    const [chartData, setChartData] = useState({} as ResponseChartInfo);
    const { tokenList } = useTokenListFormatted(entry.chainId);
    const [networkLoading, setNetworkLoading] = useState(false);

    const getToken = (symbol: string) => {
        const tempToken = {
            ...tokenList.find((e) => e.symbol === symbol),
        };
        if (tokens[symbol as TokenSymbol]) {
            return tokenSymbol2token(symbol, entry.chainId);
        }
        return tempToken as TokenInfoFormatted;
    };
    const tokenX = !_.isEmpty(getToken(entry.tokenX.symbol))
        ? getToken(entry.tokenX.symbol)
        : EmptyToken(entry.tokenX.symbol, entry.chainId, entry.tokenX.address);
    const tokenY = !_.isEmpty(getToken(entry.tokenY.symbol))
        ? getToken(entry.tokenY.symbol)
        : EmptyToken(entry.tokenY.symbol, entry.chainId, entry.tokenY.address);

    useEffect(() => {
        if (isOpen) {
            setNetworkLoading(true);
            getLiquidityChartInfo({ id: entry.id })
                .then((r) => {
                    setChartData(r.data.data);
                })
                .catch((e) => {
                    toast(ToastType.error, e.message);
                })
                .finally(() => {
                    setNetworkLoading(false);
                });
        }
    }, [entry.id, isOpen]);
    const DetailsButton = (
        <AccordionButton
            px={{ base: '5px', sm: '15px' }}
            color="secondary.500"
            w={{ base: 'unset', sm: 'unset' }}
            h="40px"
            className={i_text_copy}
            fontSize="12px !important"
            ml={{ base: '0px !important', sm: '10px !important' }}
            onClick={() => {
                if (isOpen) {
                    setOpenList(openList.filter((item) => item !== index));
                } else {
                    setOpenList(Array.from(new Set([...openList, index])));
                }
            }}
        >
            {!oneLineMode && <Box>{t('Details')}</Box>}
            <AccordionIcon />
        </AccordionButton>
    );

    return entry ? (
        isMobile ? (
            <Card {...rest} position="relative" border={'0'} borderColor="primary.300">
                <HStack spacing="6px" ml="17px">
                    <CardTagContainer>
                        {
                            <CardTag
                                variant="blue"
                                px="5px"
                                text={entry.dex}
                                icon={
                                    <Image
                                        boxSize="12px"
                                        src={
                                            entry.dex === Dex.iZiSwap
                                                ? '/assets/home/iziLogo/logo.svg'
                                                : entry.dex === Dex.UniswapV3
                                                ? '/assets/gallery/uniswap.svg'
                                                : '/assets/liquidity/cake.svg'
                                        }
                                    ></Image>
                                }
                            />
                        }
                    </CardTagContainer>

                    <CardTagContainer>
                        {
                            <CardTag
                                variant="grey"
                                px="5px"
                                text={getChain(entry.chainId)?.name}
                                icon={<Image boxSize="12px" src={getChain(entry.chainId)?.icon}></Image>}
                            />
                        }
                    </CardTagContainer>
                </HStack>

                <AccordionItem border="0">
                    <Stack
                        direction={{ base: 'column', sm: 'row' }}
                        pt={{ base: '20px', sm: '5px' }}
                        pb="17px"
                        px="15px"
                        spacing="15px"
                        minW={{ base: '100%', sm: '660px' }}
                        justifyContent="end"
                        alignItems={{ base: 'start', sm: 'center' }}
                    >
                        <HStack spacing={{ base: '4px', sm: '20px' }} ml={{ base: '0px !important', sm: '20px !important' }}>
                            <TokenIcons tokenA={tokenX} tokenB={tokenY} />
                            <FeeRate tokenA={tokenX} tokenB={tokenY} feeTier={toFeeNumber(entry.fee)} />
                        </HStack>

                        <Center w="100%">{DetailsButton}</Center>
                    </Stack>

                    <AccordionPanel pb="17px">
                        <Stack
                            direction={{ base: 'column', sm: 'row' }}
                            spacing={{ base: '10px', sm: '20px' }}
                            pl={{ base: '0px', sm: '34px' }}
                        >
                            {networkLoading ? (
                                <Center w="100%" h="379px">
                                    <Loading variant={LoadingEnum.purple} text="Loading..." py="30px" />
                                </Center>
                            ) : (
                                <Stack w="100%">
                                    {chartData.liqDist && chartData.liqDist.length > 0 && (
                                        <LiquidityChart
                                            series={[
                                                {
                                                    name: 'Liquidity',
                                                    data: chartData.liqDist,
                                                    amountList: chartData.amountList,
                                                },
                                            ]}
                                            tokenX={tokenX.symbol}
                                            tokenY={tokenY.symbol}
                                            tokenXDecimal={tokenX.decimal}
                                            tokenYDecimal={tokenY.decimal}
                                            tokenYPrice={0}
                                            leftTick={chartData.leftTick}
                                            tickSpacing={chartData.tickSpacing}
                                            currentTick={chartData.currentTickIdx}
                                        ></LiquidityChart>
                                    )}

                                    <HStack w="100%" justifyContent="center" mt="20px !important">
                                        <CustomButton
                                            w="173px"
                                            h="30px"
                                            className={i_text_copy_bold}
                                            variant="primary2"
                                            text="Refresh"
                                            leftIconElement={
                                                <Image
                                                    boxSize="13px"
                                                    src={colorTheme('/assets/liquidity/refresh.svg', '/assets/liquidity/darkRefresh.svg')}
                                                    fallbackSrc={colorTheme(
                                                        '/assets/liquidity/refresh.svg',
                                                        '/assets/liquidity/darkRefresh.svg'
                                                    )}
                                                    mr="10px"
                                                ></Image>
                                            }
                                            onClick={() => {
                                                toast(ToastType.info, 'Ongoing...');
                                                refreshLiquidityChartInfo({ id: entry.id })
                                                    .then((r) => {
                                                        setChartData(r.data.data);
                                                        toast(ToastType.success, 'Refresh successful');
                                                    })
                                                    .catch((e: Error) => {
                                                        toast(ToastType.error, e.message);
                                                    });
                                            }}
                                        ></CustomButton>
                                    </HStack>
                                </Stack>
                            )}
                        </Stack>
                    </AccordionPanel>
                </AccordionItem>
            </Card>
        ) : (
            <Card {...rest} position="relative" border={'0'} borderColor="primary.300">
                <HStack spacing="20px" ml="40px">
                    <CardTagContainer>
                        {
                            <CardTag
                                variant="blue"
                                text={entry.dex}
                                icon={
                                    <Image
                                        boxSize="12px"
                                        src={
                                            entry.dex === Dex.iZiSwap
                                                ? '/assets/home/iziLogo/logo.svg'
                                                : entry.dex === Dex.UniswapV3
                                                ? '/assets/gallery/uniswap.svg'
                                                : '/assets/liquidity/cake.svg'
                                        }
                                    ></Image>
                                }
                            />
                        }
                    </CardTagContainer>

                    <CardTagContainer>
                        {
                            <CardTag
                                variant="grey"
                                text={getChain(entry.chainId)?.name}
                                icon={<Image boxSize="12px" src={getChain(entry.chainId)?.icon}></Image>}
                            />
                        }
                    </CardTagContainer>
                </HStack>

                <AccordionItem border="0">
                    <HStack
                        direction={{ base: 'column', sm: 'row' }}
                        pt={{ base: '20px', sm: '5px' }}
                        pb="17px"
                        pl="30px"
                        pr="20px"
                        spacing="20px"
                        minW={{ base: '100%', sm: '660px' }}
                        justifyContent="space-between"
                        alignItems={{ base: 'start', sm: 'center' }}
                    >
                        <HStack spacing={{ base: '4px', sm: '20px' }} ml={{ base: '0px !important', sm: '20px !important' }}>
                            <TokenIcons tokenA={tokenX} tokenB={tokenY} />
                            <FeeRate tokenA={tokenX} tokenB={tokenY} feeTier={toFeeNumber(entry.fee)} direction="row" />
                        </HStack>

                        {DetailsButton}
                    </HStack>

                    <AccordionPanel pb="17px">
                        <Stack
                            direction={{ base: 'column', sm: 'row' }}
                            spacing={{ base: '10px', sm: '20px' }}
                            pl={{ base: '0px', sm: '34px' }}
                            pr="58px"
                        >
                            {networkLoading ? (
                                <Center w="100%" h="379px">
                                    <Loading variant={LoadingEnum.purple} text="Loading..." py="30px" />
                                </Center>
                            ) : (
                                <Stack w="100%">
                                    {chartData.liqDist && chartData.liqDist.length > 0 && (
                                        <LiquidityChart
                                            series={[
                                                {
                                                    name: 'Liquidity',
                                                    data: chartData.liqDist,
                                                    amountList: chartData.amountList,
                                                },
                                            ]}
                                            tokenX={tokenX.symbol}
                                            tokenY={tokenY.symbol}
                                            tokenXDecimal={tokenX.decimal}
                                            tokenYDecimal={tokenY.decimal}
                                            tokenYPrice={0}
                                            leftTick={chartData.leftTick}
                                            tickSpacing={chartData.tickSpacing}
                                            currentTick={chartData.currentTickIdx}
                                        ></LiquidityChart>
                                    )}

                                    <HStack w="100%" justifyContent="center" mt="20px !important">
                                        <CustomButton
                                            w="173px"
                                            h="30px"
                                            className={i_text_copy_bold}
                                            variant="primary2"
                                            text="Refresh"
                                            leftIconElement={
                                                <Image
                                                    boxSize="13px"
                                                    src={colorTheme('/assets/liquidity/refresh.svg', '/assets/liquidity/darkRefresh.svg')}
                                                    fallbackSrc={colorTheme(
                                                        '/assets/liquidity/refresh.svg',
                                                        '/assets/liquidity/darkRefresh.svg'
                                                    )}
                                                    mr="10px"
                                                ></Image>
                                            }
                                            onClick={() => {
                                                toast(ToastType.info, 'Ongoing...');
                                                refreshLiquidityChartInfo({ id: entry.id })
                                                    .then((r) => {
                                                        setChartData(r.data.data);
                                                        toast(ToastType.success, 'Refresh successful');
                                                    })
                                                    .catch((e: Error) => {
                                                        toast(ToastType.error, e.message);
                                                    });
                                            }}
                                        ></CustomButton>
                                    </HStack>
                                </Stack>
                            )}
                        </Stack>
                    </AccordionPanel>
                </AccordionItem>
            </Card>
        )
    ) : (
        <></>
    );
};

export default PositionListEntry;
