import { HStack, Heading, VStack, Stack } from '@chakra-ui/react';
import PageHeader from '../../components/PageHeader/PageHeader';
import NetworkSelect from '../../components/Select/NetworkSelect/NetworkSelect';
import PageLayout from '../../components/layout/PageLayout';
import DashboardControls from './components/DashboardControls';
import PoolList from './components/PoolList';
import useIsMobile from '../../hooks/useIsMobile';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const Dashboard: React.FC = () => {
    const isMobile = useIsMobile();
    const { t } = useTranslation();
    useEffect(() => {
        document.title = t('Dashboard') + ' | iZUMi.finance';
    }, [t]);
    return isMobile ? (
        <PageLayout
            body={
                <Stack w="100%" spacing="30px">
                    <DashboardControls />
                    <PoolList />;
                </Stack>
            }
            type="mobile"
        />
    ) : (
        <PageLayout
            header={
                <PageHeader
                    headerTitle={
                        <HStack spacing="20px">
                            <Heading level="3" fontSize={'24px'}>
                                {'Dashboard'}
                            </Heading>
                        </HStack>
                    }
                    subtitle={'Manage custom liquidity.'}
                    element={
                        <HStack spacing="10px">
                            <NetworkSelect />
                        </HStack>
                    }
                />
            }
            body={
                <VStack>
                    <DashboardControls />
                    <PoolList />
                </VStack>
            }
        />
    );
};
export default Dashboard;
