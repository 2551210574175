import { ChevronDownIcon, SearchIcon } from '@chakra-ui/icons';
import {
    Flex,
    FormControl,
    FormLabel,
    HStack,
    Input,
    InputGroup,
    InputLeftElement,
    useColorMode,
    InputRightElement,
    Button,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    Image,
} from '@chakra-ui/react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import useIsMobile from '../../../hooks/useIsMobile';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import { Dex } from '../../../net/endpoints/izumiMonitor';
import { RootState, RootDispatch } from '../../../state/store';
import { i_text_copy, i_text_copy_bold, i_text_d } from '../../../style';
import { getColorThemeSelector } from '../../../utils/funcs';
import placeholder from '../../../assets/placeholder.png';
import { Filter } from '../../Farm/Gallery/components/Filter';
import React from 'react';
import NetworkSelect from './NetworkSelect';
const LiquidityControls: React.FC = () => {
    const { t } = useTranslation();
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { pool: myPool } = useSelector(
        (state: RootState) => ({
            pool: state.pool,
        }),
        shallowEqual
    );
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const isMobile = useIsMobile();

    const onSearchKeyChange = useCallback(
        (e: any) => {
            const control = { ...myPool.control };
            control.searchKey = e.target.value;
            dispatch.pool.setControl(control);
        },
        [myPool.control, dispatch.pool]
    );
    const onDexChange = useCallback(
        (e: any) => {
            const control = { ...myPool.control };
            control.dex = e;
            dispatch.pool.setControl(control);
        },
        [myPool.control, dispatch.pool]
    );

    const PlatFormBlock = () => (
        <FormControl display="flex" alignItems="center" w="unset">
            <FormLabel
                className={i_text_copy}
                w={{ base: '55px', xxl: '38px' }}
                textAlign={{ base: 'end', xxl: 'start' }}
                fontSize="12px"
                fontWeight="500"
                pt="6px"
            >
                {t('DEX')}
            </FormLabel>
            <Menu>
                {() => (
                    <>
                        <MenuButton
                            as={Button}
                            rightIcon={<ChevronDownIcon />}
                            className={i_text_d}
                            fontSize="13px"
                            w="88px"
                            leftIcon={
                                !myPool.control.dex ? (
                                    <Image boxSize="20px" src={'/assets/filter/virtualAll.svg'}></Image>
                                ) : (
                                    <Image
                                        boxSize="20px"
                                        src={
                                            myPool.control.dex === Dex.iZiSwap
                                                ? '/assets/home/iziLogo/logo.svg'
                                                : myPool.control.dex === Dex.UniswapV3
                                                ? '/assets/gallery/uniswap.svg'
                                                : myPool.control.dex === Dex.PancakeSwap
                                                ? '/assets/liquidity/cake.svg'
                                                : '/assets/filter/virtualAll.svg'
                                        }
                                    ></Image>
                                )
                            }
                            style={{
                                height: '40px',
                                width: '88px',
                                borderRadius: '6px',
                            }}
                        >
                            {/* {myLiquidity.control.dex ? myLiquidity.control.dex : 'Show All'} */}
                        </MenuButton>
                        <MenuList className={i_text_copy}>
                            <MenuItem
                                as={Button}
                                variant="light"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    borderRadius: '4px',
                                    overflow: 'hidden',
                                    fontSize: '12px',
                                }}
                                leftIcon={
                                    <Image src={'/assets/filter/virtualAll.svg'} boxSize="20px" mr="10px" fallbackSrc={placeholder}></Image>
                                }
                                onClick={() => onDexChange('')}
                            >
                                {t('Show All')}{' '}
                            </MenuItem>
                            <MenuItem
                                as={Button}
                                variant="light"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    borderRadius: '4px',
                                    overflow: 'hidden',
                                    fontSize: '12px',
                                }}
                                leftIcon={<Image src="/assets/home/iziLogo/logo.svg" boxSize="20px" mr="10px" fallbackSrc={placeholder} />}
                                onClick={() => onDexChange(Dex.iZiSwap)}
                            >
                                iZiSwap
                            </MenuItem>
                            <MenuItem
                                as={Button}
                                variant="light"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    borderRadius: '4px',
                                    overflow: 'hidden',
                                    fontSize: '12px',
                                }}
                                leftIcon={<Image src="/assets/gallery/uniswap.svg" boxSize="20px" mr="10px" fallbackSrc={placeholder} />}
                                onClick={() => onDexChange(Dex.UniswapV3)}
                            >
                                {' '}
                                UniSwapV3{' '}
                            </MenuItem>
                            <MenuItem
                                as={Button}
                                variant="light"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    borderRadius: '4px',
                                    overflow: 'hidden',
                                    fontSize: '12px',
                                }}
                                leftIcon={<Image src="/assets/liquidity/cake.svg" boxSize="20px" mr="10px" fallbackSrc={placeholder} />}
                                onClick={() => onDexChange(Dex.PancakeSwap)}
                            >
                                {' '}
                                PancakeSwap{' '}
                            </MenuItem>
                        </MenuList>
                    </>
                )}
            </Menu>
        </FormControl>
    );
    const NetworkBlock = () => (
        <Flex className={i_text_copy} alignItems="center">
            <Text w="55px" textAlign="end" mr="12px">
                {t('Network')}
            </Text>
            <NetworkSelect />
        </Flex>
    );

    return isMobile ? (
        <HStack w="100%" pt="16px" pb="8px" justifyContent="space-between">
            <InputGroup w="274px">
                <InputLeftElement boxSize="20px" mt="4px" ml="10px">
                    <SearchIcon boxSize={5} />
                </InputLeftElement>
                <Input
                    variant="filled"
                    placeholder={t('Search Tokens')}
                    size="lg"
                    h="30px"
                    className={i_text_copy_bold}
                    fontSize="12px"
                    onChange={(e) => onSearchKeyChange(e)}
                    value={myPool.control.searchKey || ''}
                    bg={colorTheme('#FFFFFF', 'rgba(37, 32, 59, 0.5)')}
                    color="tertiary.300"
                    letterSpacing="0.02em"
                    fontFamily="Montserrat"
                />
            </InputGroup>
            <Filter
                filterTitle={t('Liquidity Filter')}
                filterElement={[<PlatFormBlock key={1}></PlatFormBlock>, <NetworkBlock key={2}></NetworkBlock>]}
            ></Filter>
        </HStack>
    ) : (
        <HStack w="100%" alignItems="start" pt={{ base: '12px', sm: '20px' }} pb={{ base: '20px', sm: '34px' }}>
            <InputGroup w={{ base: '275px', sm: '275px' }} h="41px" mt="-4px">
                <Input
                    variant="filled"
                    placeholder={t('Search Tokens')}
                    size="lg"
                    className={i_text_copy_bold}
                    fontSize="12px"
                    onChange={(e) => onSearchKeyChange(e)}
                    value={myPool.control.searchKey || ''}
                />
                <InputRightElement h="100%" mt="4px">
                    <SearchIcon boxSize={5} />
                </InputRightElement>
            </InputGroup>
            <HStack spacing="30px" ml="auto !important">
                <PlatFormBlock></PlatFormBlock>
                <NetworkBlock></NetworkBlock>
            </HStack>
        </HStack>
    );
};

export default React.memo(LiquidityControls);
