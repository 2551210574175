import { ChainId } from '../../types/mod';
import chains from '../chains';

export const VEIZI_ADDRESS: TokenId2ContractAddress = chains.all.reduce<Record<number, string>>((memo, chain) => {
    memo[chain.id] = '';
    if (process.env.REACT_APP_ENV === 'production') {
        if (chain.id === ChainId.EthereumMainnet) {
            memo[chain.id] = '0xB56A454d8DaC2AD4cB82337887717a2a427Fcd00';
        }
    } else {
        if (chain.id === ChainId.BSCTestnet) {
            memo[chain.id] = '0x455562Bf67FC751ca0CC3509C96d7F3E8806D2eF';
        }
    }
    return memo;
}, {});
