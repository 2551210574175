import { TokenInfoFormatted } from "../../../../hooks/useTokenListFormatted";
import { QuoterContract } from "../../../../types/abis/iZiSwap/Quoter";
import { toContractFeeNumber } from "../../../../utils/funcs";
import { getSwapTokenAddress } from "../../../../utils/tokenMath";
import { getTokenChainPath } from "../../trade/funcs";


export const swapAmountAcquired = async(
    sellToken: TokenInfoFormatted,
    earnToken: TokenInfoFormatted,
    fee: FeeTier,
    amount: string,
    quoterContract: QuoterContract
): Promise< {acquire: string, error?: any} > => {
    if (amount === '0') {
        return {acquire: '0'}
    }
    const contractFeeNumber = toContractFeeNumber(fee)
    const tokenChain = getTokenChainPath([sellToken, earnToken], [contractFeeNumber])
    try {
        const response = await quoterContract.methods.swapAmount(amount, tokenChain).call()
        return {acquire: response.acquire}
    } catch (err) {
        return {acquire: '0', error: err}
    }
}

export const swapDesireCost = async(
    sellToken: TokenInfoFormatted,
    earnToken: TokenInfoFormatted,
    fee: FeeTier,
    amount: string,
    quoterContract: QuoterContract
): Promise< {cost: string, error?: any} > => {
    if (amount === '0') {
        return {cost: '0'}
    }
    const contractFeeNumber = toContractFeeNumber(fee)
    const tokenChain = getTokenChainPath([earnToken, sellToken], [contractFeeNumber])
    try {
        const response = await quoterContract.methods.swapDesire(amount, tokenChain).call()
        return {cost: response.cost}
    } catch (err) {
        return {cost: '0', error: err}
    }
}


export const swapAcquired = async(
    tokenA: TokenInfoFormatted,
    tokenB: TokenInfoFormatted,
    fee: FeeTier,
    sellAEarnB: boolean,
    amount: string,
    targetPoint: number,
    currentPoint: number,
    isAmountA: boolean,
    quoterContract: QuoterContract,
): Promise< { acquire: string, error?: any} > => {
    if (amount === '0') {
        return {acquire: '0'}
    }
    const contractFeeNumber = toContractFeeNumber(fee)
    const tokenAAddress = getSwapTokenAddress(tokenA).toLowerCase()
    const tokenBAddress = getSwapTokenAddress(tokenB).toLowerCase()
    const tokenAIsX = tokenAAddress < tokenBAddress
    const x2y = tokenAIsX && sellAEarnB || !tokenAIsX && !sellAEarnB
    const tokenXAddress = tokenAIsX ? tokenAAddress : tokenBAddress
    const tokenYAddress = tokenAIsX ? tokenBAddress : tokenAAddress
    const isDesireMode = sellAEarnB && !isAmountA || !sellAEarnB && isAmountA
    try {
        if (x2y) {
            if (targetPoint < currentPoint) {
                if (!isDesireMode) {
                    const ret = await quoterContract.methods.swapX2Y(tokenXAddress, tokenYAddress, contractFeeNumber, amount, targetPoint).call()
                    return {acquire: ret.amountY}
                } else {
                    const ret = await quoterContract.methods.swapX2YDesireY(tokenXAddress, tokenYAddress, contractFeeNumber, amount, targetPoint).call()
                    return {acquire: ret.amountY}
                }
            }
        } else {
            if (targetPoint > currentPoint) {
                if (!isDesireMode) {
                    const ret = await quoterContract.methods.swapY2X(tokenXAddress, tokenYAddress, contractFeeNumber, amount, targetPoint + 1).call()
                    return {acquire: ret.amountX}
                } else {
                    const ret = await quoterContract.methods.swapY2XDesireX(tokenXAddress, tokenYAddress, contractFeeNumber, amount, targetPoint + 1).call()
                    return {acquire: ret.amountX}
                }
            }
        }
    } catch (err) {
        return {acquire: '0', error: err}
    }
    return {acquire: '0'}
}