import { createModel } from '@rematch/core';
import produce from 'immer';
import { RootModel } from '../..';
export interface TradeModeState {
    isSimpleMode:boolean
}
export const tradeMode = createModel<RootModel>()({
    state: {
        isSimpleMode:true,
        
    } as TradeModeState,
    reducers: {
        setIsSimpleMode: (state: TradeModeState, isSimpleMode: boolean) => produce(state, draft => {
            draft.isSimpleMode=isSimpleMode;
        })
    },
});