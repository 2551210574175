import BigNumber from 'bignumber.js';
import { blocksPerDay } from '../../../../../config/chains';
import { TokenInfoFormatted } from '../../../../../hooks/useTokenListFormatted';

import { ChainId, FarmFixRangeiZiContractVersion } from '../../../../../types/mod';
import { amount2Decimal } from '../../../../../utils/tokenMath';
import { MiningContractInfo, PoolEntryState, RewardTokenPart } from './types'
import {getLiquidityValue} from '../liquidity'
import { point2PriceDecimal } from '../price';
import { getMiningFixRangeiZiContract } from '../../../../../utils/contractHelpers';

import { point2PriceUndecimal, priceUndecimal2PriceDecimal } from '../../../trade/swap/priceFuncs';
import Web3 from 'web3';

export const pendingReward = async (stakedTokenId: string, miningAddress: string, web3: Web3, version: FarmFixRangeiZiContractVersion): Promise<string[]> => {
    const miningContract = getMiningFixRangeiZiContract(miningAddress, web3, version);
    const positionEarned = await miningContract?.methods.pendingReward(stakedTokenId).call();
    const positionEarnedList = positionEarned;
    return positionEarnedList;
};

export const getPoolLiquidity = (
        miningInfo: MiningContractInfo, 
        currentPoint: number, 
        currentLiquidity: string,
        currentLiquidityX: string,
        tokenX: TokenInfoFormatted,
        tokenY: TokenInfoFormatted,
        tokenXPriceDecimal: number,
        tokenYPriceDecimal: number,
    ): number => {
    
    const rangeLen = Number(miningInfo.rewardUpperTick_) - Number(miningInfo.rewardLowerTick_)
    
    const liquidity = new BigNumber(miningInfo.totalVLiquidity_).div(rangeLen).div(rangeLen).times(1e6)

    const {amountX, amountY} = getLiquidityValue(
        liquidity.toFixed(0),
        Number(miningInfo.rewardLowerTick_),
        Number(miningInfo.rewardUpperTick_),
        currentPoint,
        currentLiquidity,
        currentLiquidityX
    )

    const amountXDecimal = amount2Decimal(new BigNumber(amountX), tokenX) ?? 0
    const amountYDecimal = amount2Decimal(new BigNumber(amountY), tokenY) ?? 0

    return amountXDecimal * tokenXPriceDecimal + amountYDecimal * tokenYPriceDecimal

};

export const getVLiquidity = (rewardUpperTick: number, rewardLowerTick: number, upperTick: number, lowerTick: number, liquidity: BigNumber): string => {
    const validRange = Math.max(
        Math.min(rewardUpperTick, upperTick) - Math.max(rewardLowerTick, lowerTick),
        0
    );
    return liquidity.times(validRange * validRange).div(1e6).toFixed(0)
};

export const getPoolAPR = (rewardPerBlock: string, chainId: ChainId, poolCapital: number, rewardToken: {token: TokenInfoFormatted, price: number}): {apr: number, rewardsPerYear: number} => {
   
    const blocksPerYear = blocksPerDay(chainId) * 365;
    const { token, price} = rewardToken;

    const rewardsPerYear = (amount2Decimal(
        new BigNumber(blocksPerYear).times(rewardPerBlock),
        token
    ) ?? 0) * price;

    const apr = (poolCapital === 0)? 0 : rewardsPerYear / poolCapital
    return {apr, rewardsPerYear}
};

export const getPoolAPRTimestamp = (rewardPerSecond: string, poolCapital: number, rewardToken: {token: TokenInfoFormatted, price: number}): {apr: number, rewardsPerYear: number} => {
   
    const { token, price} = rewardToken;
    const secondsPerYear = 365 * 24 * 60 * 60;

    const rewardsPerYear = (amount2Decimal(
        new BigNumber(secondsPerYear).times(rewardPerSecond),
        token
    ) ?? 0) * price;

    const apr = (poolCapital === 0)? 0 : rewardsPerYear / poolCapital
    return {apr, rewardsPerYear}
};

export const veiZiBoostAPR = (poolEntry: PoolEntryState, chainId: ChainId, userVLiquidity: string, userCapital: number, userVeiZi: string): number => {
    if (userCapital === 0) {
        return 0;
    }
    const totalVLiquidity = new BigNumber(poolEntry.data.totalVLiquidity);
    const totalValidVeiZi = new BigNumber(poolEntry.data.totalValidVeiZi);
    const userVLiquidityBN = new BigNumber(userVLiquidity)
    const userVeiZiBN = new BigNumber(userVeiZi)
    let rate = undefined;
    if (totalValidVeiZi.gt(0)) {
        const iZiVLiquidity = userVLiquidityBN.times(0.4).plus(userVeiZiBN.div(totalValidVeiZi).times(totalVLiquidity).times(0.6));
        rate = BigNumber.min(userVLiquidityBN, iZiVLiquidity).div(totalVLiquidity)
    } else {
        rate = userVLiquidityBN.div(totalVLiquidity);
    }
    let totalReward = 0;
    for (let i = 0; i < poolEntry.data.reward?.length ?? 0; i++) {
        const rewardDecimal = Number(poolEntry.data.reward[i].rewardDecimalPerBlock);
        const rewardTokenPrice = poolEntry.data.reward[i].priceDecimal;
        const rewardTimes = poolEntry.meta.useTimestamp ? 365 * 24 * 60 * 60 : blocksPerDay(chainId) * 365;
        const rewardPerYear = rewardTokenPrice * rewardDecimal * rewardTimes;
        totalReward += rewardPerYear;
    }
    const reward = new BigNumber(totalReward).times(rate);
    return Number(reward.div(userCapital));
};

export const veiZiNoBoostNewNftAPR = (poolEntry: PoolEntryState, chainId: ChainId, vLiquidity: number, capital: number): number => {
    if (capital === 0) {
        return 0;
    }
    const totalVLiquidity = new BigNumber(poolEntry.data.totalVLiquidity).plus(vLiquidity);
    const vLiquidityBN = new BigNumber(vLiquidity)
    let rate = vLiquidityBN.div(totalVLiquidity);
    const totalValidVeiZi = poolEntry.data.totalValidVeiZi;
    if (poolEntry.meta.veiZiBoost && totalValidVeiZi !== '0') {
        rate = rate.times(0.4)
    }
    let totalReward = 0;
    for (let i = 0; i < poolEntry.data.reward?.length ?? 0; i++) {
        const rewardDecimal = Number(poolEntry.data.reward[i].rewardDecimalPerBlock);
        const rewardTokenPrice = poolEntry.data.reward[i].priceDecimal;
        const rewardTimes = poolEntry.meta.useTimestamp ? 365 * 24 * 60 * 60 : blocksPerDay(chainId) * 365;
        const rewardPerYear = rewardTokenPrice * rewardDecimal * rewardTimes;
        totalReward += rewardPerYear;
    }
    const reward = new BigNumber(totalReward).times(rate);
    return Number(reward.div(capital));
};

export const veiZiBoostNewNftAPR = (poolEntry: PoolEntryState, chainId: ChainId, originUserVLiquidity: string, originUserCapital: number, userVeiZi: string, userValidVeiZi: string, vLiquidity: string, capital: number): number => {
    if (originUserCapital + capital === 0) {
        return 0;
    }
    const totalVLiquidity = new BigNumber(poolEntry.data.totalVLiquidity).plus(vLiquidity);
    /*
        validVeiZi = Math.min(veiZi, 2 * totalValidVeiZi * vLiquidity / totalVLiquidity);
        totalValidVeiZi += validVeiZi;
    */
    const userVLiquidity = new BigNumber(originUserVLiquidity).plus(vLiquidity);
    const userCapital = originUserCapital + capital;
    let totalValidVeiZi = new BigNumber(poolEntry.data.totalValidVeiZi).minus(userValidVeiZi);
    const userVeiZiBN = new BigNumber(userVeiZi)

    const validVeiZi = BigNumber.min(userVeiZiBN, totalValidVeiZi.times(userVLiquidity).times(2).div(totalVLiquidity))
    totalValidVeiZi = totalValidVeiZi.plus(validVeiZi)

    let rate = undefined;
    if (totalValidVeiZi.gt(0) && poolEntry.meta.veiZiBoost) {
        const iZiVLiquidity = userVLiquidity.times(0.4).plus(userVeiZiBN.div(totalValidVeiZi).times(totalVLiquidity).times(0.6))
        rate = BigNumber.min(userVLiquidity, iZiVLiquidity).div(totalVLiquidity);
    } else {
        rate = userVLiquidity.div(totalVLiquidity);
    }
    let totalReward = 0;
    for (let i = 0; i < poolEntry.data.reward?.length ?? 0; i++) {
        const rewardDecimal = Number(poolEntry.data.reward[i].rewardDecimalPerBlock);
        const rewardTokenPrice = poolEntry.data.reward[i].priceDecimal;
        const blocksPerYear = blocksPerDay(chainId) * 365;
        const rewardDecimalPerYear = rewardTokenPrice * rewardDecimal * blocksPerYear;
        totalReward += rewardDecimalPerYear;
    }
    const reward = new BigNumber(totalReward).times(rate);
    return Number(reward.div(capital));
};

export const veiZiBoostForNewNftDesiredBoost = (desiredBoost: number, poolEntry: PoolEntryState, originUserVLiquidity: string, userValidVeiZi: string, vLiquidity: string): BigNumber => {
    const totalVLiquidity = new BigNumber(poolEntry.data.totalVLiquidity).plus(vLiquidity);
    const userVLiquidity = new BigNumber(originUserVLiquidity).plus(vLiquidity);
    const totalValidVeiZi = new BigNumber(poolEntry.data.totalValidVeiZi).minus(userValidVeiZi);

    const maxValidVeiZi = totalValidVeiZi.times(2).div(totalVLiquidity).times(userVLiquidity)

    let boostVLiquidity = userVLiquidity.times(desiredBoost - 1).div(1.5);
    if (boostVLiquidity.lt(0)) {
        // console.log('[error]: boostVLiquidity < 0: ', boostVLiquidity);
        boostVLiquidity = new BigNumber(0);
    }
    if (boostVLiquidity.gt(userVLiquidity)) {
        // console.log('[error]: boostVLiquidity > userVLiquidity: ', boostVLiquidity);
        boostVLiquidity = userVLiquidity;
    }

    // phase 1. userVeiZi <= maxValidVeiZi
    // userVeiZi / (totalValidVeiZi + userVeiZi) * totalVLiquidity = boostVLiquidity

    if (totalVLiquidity.gt(boostVLiquidity)) {
        const userVeiZi = totalValidVeiZi.div(totalVLiquidity.minus(boostVLiquidity)).times(boostVLiquidity);
        if (userVeiZi.lte(maxValidVeiZi)) {
            return userVeiZi;
        }
    }
    // phase 2. userVeiZi >= maxValidVeiZi
    // userVeiZi / (totalValidVeiZi + maxValidVeiZi) * totalVLiquidity = boostVLiquidity
    const userVeiZi = totalValidVeiZi.plus(maxValidVeiZi).div(totalVLiquidity).times(boostVLiquidity);
    return userVeiZi;
};


export const getPoolRewards = (rewardTokenPart: RewardTokenPart): number => {
    const rewardToken = rewardTokenPart.rewardToken;
    const rewardDecimal = (amount2Decimal(
        new BigNumber(Number(rewardTokenPart.rewardPerBlock)),
        rewardToken
    ) ?? 0);
    return rewardDecimal;
};

export const getPriceRangeDecimal = (tokenA: TokenInfoFormatted, tokenB:TokenInfoFormatted, leftPoint: number, rightPoint: number): {priceLowerDecimalAByB: number, priceUpperDecimalAByB: number} => {
    const price0DecimalAByB = point2PriceDecimal(tokenA, tokenB, leftPoint)
    const price1DecimalAByB = point2PriceDecimal(tokenA, tokenB, rightPoint)
    const priceLowerDecimalAByB = Math.min(price0DecimalAByB, price1DecimalAByB)
    const priceUpperDecimalAByB = Math.max(price0DecimalAByB, price1DecimalAByB)
    return {priceLowerDecimalAByB, priceUpperDecimalAByB}
};

export const getPriceRangeUndecimal = (tokenA: TokenInfoFormatted, tokenB: TokenInfoFormatted, leftPoint: number, rightPoint: number): {priceLowerUndecimalAByB: BigNumber, priceUpperUndecimalAByB: BigNumber} => {
    const price0UndecimalAByB = point2PriceUndecimal(tokenA, tokenB, leftPoint)
    const price1UndecimalAByB = point2PriceUndecimal(tokenA, tokenB, rightPoint)
    const priceLowerUndecimalAByB = BigNumber.min(price0UndecimalAByB, price1UndecimalAByB)
    const priceUpperUndecimalAByB = BigNumber.max(price0UndecimalAByB, price1UndecimalAByB)
    return {priceLowerUndecimalAByB, priceUpperUndecimalAByB}
}

// default only cal valid liquidity
export const getStakeLiquidityWorth = (
    liquidity: string,
    leftPoint: number,
    rewardLeftPoint: number,
    rightPoint: number,
    rewardRightPoint: number,
    currentPoint: number,
    currentLiquidity: string,
    currentLiquidityX: string,
    tokenX: TokenInfoFormatted,
    tokenY: TokenInfoFormatted,
    tokenXPriceDecimal: number,
    tokenYPriceDecimal: number,
    onlyCalValid: boolean
): number => {
    const lp = onlyCalValid? Math.max(leftPoint, rewardLeftPoint) : leftPoint
    const rp = onlyCalValid ? Math.min(rightPoint, rewardRightPoint): rightPoint
    const {amountX, amountY} = getLiquidityValue(liquidity, lp, rp, currentPoint, currentLiquidity, currentLiquidityX)
    
    const amountXDecimal = amount2Decimal(new BigNumber(amountX), tokenX) ?? 0
    const amountYDecimal = amount2Decimal(new BigNumber(amountY), tokenY) ?? 0

    return amountXDecimal * tokenXPriceDecimal + amountYDecimal * tokenYPriceDecimal

};

export const getPriceDecimalAByBFromX96 = (sqrtPriceX96: string, tokenA: TokenInfoFormatted, tokenB: TokenInfoFormatted): number => {
    const q96 = new BigNumber(2).pow(96)
    const poolSqrtPriceUndecimal = new BigNumber(sqrtPriceX96).div(q96)
    const poolPriceUndecimal = poolSqrtPriceUndecimal.times(poolSqrtPriceUndecimal)
    const priceUndecimalAByB = tokenA.address.toLowerCase() < tokenB.address.toLowerCase() ? poolPriceUndecimal : new BigNumber(1).div(poolPriceUndecimal)
    const priceDecimalAByB = priceUndecimal2PriceDecimal(tokenA, tokenB, priceUndecimalAByB)
    return priceDecimalAByB
};

export const getPriceFromX96 = (sqrtPriceX96: string): string => {
    const sqrtPriceX96Num = new BigNumber(sqrtPriceX96);
    return sqrtPriceX96Num.multipliedBy(sqrtPriceX96Num).dividedBy(2 ** (96 * 2)).toString();
};

export const findPoolEntryByPoolKey = (poolEntryList: PoolEntryState[], poolKey: string): PoolEntryState => {
    return poolEntryList.find(p => p.meta.liquidityPoolKey === poolKey) as PoolEntryState;
};

export interface RewardInfos {
    rewardToken: string;
    provider: string;
    accRewardPerShare: string;
    rewardPerBlock: string;
}

export const niZiForDesiredBoost = (vLiquidity: string, totalVLiquidity: string, totalNIZI: string, boost: number): number => {
    if (totalVLiquidity === '0') return 0;
    const v = Number(vLiquidity) / Number(totalVLiquidity);
    const w = (boost - 1) * v / 1.5;
    if (w === 1) {
        // boost <= 2.5
        // means v === 1
        return 0;
    }
    const niZi = w * Number(totalNIZI) / (1 - w);
    return niZi;
};
