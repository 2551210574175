import { createModel } from "@rematch/core";
import { RootModel } from "../..";

export interface ProControlState {
    tradeFlashMode: boolean
    useGasToken: boolean
}


export const proControlState = createModel<RootModel>()({
    state: {
        tradeFlashMode: false,
        useGasToken: true
    } as ProControlState,
    reducers: {

        setNewState: (state: ProControlState, newState: ProControlState) => {
            return {...state, ...newState}
        }

    },

});
