import { createModel } from '@rematch/core';
import { RootModel } from '../../index';
import produce from 'immer';
import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';
import {
    MyLimitOrderControl,
    LimitOrder,
} from './types';
import { NewLimOrderRequest, LimitOrderManagerContract, PoolMetasResponse } from '../../../../types/abis/iZiSwap/LimitOrderManager';
import { ChainId, ListSortByEnum } from '../../../../types/mod';
import BigNumber from 'bignumber.js';
import { buildSendingParams, decodeMethodResult } from '../../../../utils/contractHelpers';
import { tick2Price } from '../../../../utils/tickMath';
import { SetFormTokenParams } from '../utils/types';
import { getSortedTokenAddr } from '../../common/positionPoolHelper';
import { izumiFeeToTickSpacingMapping } from '../../../../utils/tickMath';
import { price2UpperTick } from '../../../../utils/tickMath';
import { toContractFeeNumber, toFeeNumber } from '../../../../utils/funcs';
import { TransactionReceipt } from 'ethereum-abi-types-generator';
import { amount2Decimal } from '../../../../utils/tokenMath';
import { price2PriceDecimal, priceDecimal2Price } from '../../farm/UniswapV3/fixRange/funcs';
import moment from 'moment';
import { TokenInfoFormatted } from '../../../../hooks/useTokenListFormatted';
import { isGasToken } from '../../../../config/tokens';
import { getChain, getTxLink } from '../../../../config/chains';
import { ToastLink } from '../../../../iZUMi-UI-toolkit/src/components/Toast/Toast';

export interface AddLimitOrderForm {
    // always sell X for Y in frontend
    tokenX: TokenInfoFormatted;
    tokenY: TokenInfoFormatted;
    fee: FeeTier;
    pt: number;

    amount: number;
    amountDecimal: number;

    sellPrice: number;
    sellPriceDecimal: number;
}

export interface AddLimitOrderParams {
    account: string;
    limitOrderMgrContract?: LimitOrderManagerContract;
    chainId: ChainId;
    limitOrderForm: AddLimitOrderForm;
    gasPrice?: number;
    onGoingCallback?:  (toastLink?: ToastLink) => void;
}

export interface SellPriceParams {
    sellPriceDecimal: number;
    fee: FeeTier;
}

export interface TradeLimitOrderState {
    limitOrderForm: AddLimitOrderForm;
    activeList: LimitOrder[];
    historyList: LimitOrder[];
    control: MyLimitOrderControl;
    poolStatusLoading: boolean;
}


export interface FetchLimitOrderParams {
    chainId: ChainId;
    web3?: Web3;
    orderManagerContract?: LimitOrderManagerContract;
    account: string;
    tokenList: TokenInfoFormatted[];
}

export const tradeLimitOrder = createModel<RootModel>()({
    state: {
        limitOrderForm: {
            tokenX: {},
            tokenY: {},
            amountDecimal: 0,
        } as AddLimitOrderForm,
        activeList: [] as LimitOrder[],
        historyList: [] as LimitOrder[],
        control: {
            sortBy: ListSortByEnum.Default,
            type: 'active',
            searchKey: '',
            showByPair: false,
        },
        poolStatusLoading: true,
    } as TradeLimitOrderState,
    reducers: {
        clearLimitOrderForm: (state: TradeLimitOrderState)=>produce(state, draft => {
            draft.limitOrderForm =  {
                tokenX: {},
                tokenY: {},
                amountDecimal: 0,
            } as AddLimitOrderForm;
        }),
        setControl: (state: TradeLimitOrderState, control: MyLimitOrderControl) => produce(state, draft => {
            draft.control = { ...control };
        }),
        setLimitOrder: (state: TradeLimitOrderState, { activeList, historyList }: TradeLimitOrderState) => produce(state, draft => {
            draft.activeList = activeList;
            draft.historyList = historyList;
        }),
        setPoolStatusLoading: (state: TradeLimitOrderState, payload: boolean) => produce(state, (s) => {
            s.poolStatusLoading = payload;
        }),
        saveTradeLimitOrder: (state: TradeLimitOrderState, payload: TradeLimitOrderState) => {
            return { ...state, ...payload };
        },
        setLimitOrderFormToken: (state: TradeLimitOrderState, mintTokenParams: SetFormTokenParams) => produce(state, ({ limitOrderForm }) => {
            const { isUpper, tokenInfo } = mintTokenParams;
            if (!isUpper && (!limitOrderForm.tokenX.symbol || tokenInfo.symbol !== limitOrderForm.tokenX.symbol)) {
                limitOrderForm.tokenX = { ...tokenInfo };
            } else if (isUpper && (!limitOrderForm.tokenY.symbol || tokenInfo.symbol !== limitOrderForm.tokenY.symbol)) {
                limitOrderForm.tokenY = { ...tokenInfo };
            }
        }),
        setLimitOrderFormFee: (state: TradeLimitOrderState, fee: FeeTier) => produce(state, ({ limitOrderForm }) => {
            limitOrderForm.fee = fee;
        }),
        setLimitOrderFormAmount: (state: TradeLimitOrderState, amountDecimalFrom: number) => produce(state, ({ limitOrderForm }) => {
            limitOrderForm.amountDecimal = Number(amountDecimalFrom);
            limitOrderForm.amount = limitOrderForm.amountDecimal * 10 ** state.limitOrderForm.tokenX.decimal;
        }),

        setSellPrice: (state: TradeLimitOrderState, { sellPriceDecimal, fee }: SellPriceParams) => produce(state, ({ limitOrderForm }) => {
            const feeNum = fee;
            const sellPrice = priceDecimal2Price(sellPriceDecimal, { left: limitOrderForm.tokenX, right: limitOrderForm.tokenY });
            limitOrderForm.pt = price2UpperTick(sellPrice, feeNum, izumiFeeToTickSpacingMapping);
            limitOrderForm.sellPrice = tick2Price(limitOrderForm.pt);
            limitOrderForm.sellPriceDecimal = sellPriceDecimal;
        }),
        toggleTokenOrder: (state: TradeLimitOrderState) => produce(state, ({ limitOrderForm }) => {
            [limitOrderForm.tokenX, limitOrderForm.tokenY] = [limitOrderForm.tokenY, limitOrderForm.tokenX];
        }),
    },
    effects: (dispatch) => ({
        async fetchLimitOrder(fetchLimitOrderParams: FetchLimitOrderParams): Promise<{activeList: LimitOrder[], historyList: LimitOrder[]}> {
            const { chainId, web3, orderManagerContract, account, tokenList } = fetchLimitOrderParams;
            if (tokenList.length === 0 ) {
                return {activeList: [], historyList:[]};
            }
            if (!chainId || !web3 || !account || !orderManagerContract) { return {activeList: [], historyList:[]}; }
            const startTime = new Date();

            const orderManagerAnyContract = orderManagerContract as unknown as Contract;
            // 1. get active limit order id
            const limitOrderIdMulticallData = [];
            limitOrderIdMulticallData.push(orderManagerContract.methods.getActiveOrders(account).encodeABI());
            limitOrderIdMulticallData.push(orderManagerContract.methods.getDeactiveOrders(account).encodeABI());
            const limitOrderIdListResult: string[] = await orderManagerContract.methods.multicall(limitOrderIdMulticallData).call();
            const { activeIdx: activeOrderIds, activeLimitOrder: activeOrders } = decodeMethodResult(orderManagerAnyContract, 'getActiveOrders', limitOrderIdListResult[0]);
            const deactiveOrders = decodeMethodResult(orderManagerAnyContract, 'getDeactiveOrders', limitOrderIdListResult[1]);

            const allOrders = [...activeOrders, ...deactiveOrders];
            if (allOrders.length <= 0) { return {activeList: [], historyList:[]}; }

            const orderTotal = allOrders.length;
            const activeOrderTotal = activeOrders.length;

            // 2. get limit order detail
            const poolMetaMulticallData = allOrders.map(order => orderManagerContract.methods.poolMetas(order.poolId).encodeABI());
            const poolAddrMulticallData = allOrders.map(order => orderManagerContract.methods.poolAddrs(order.poolId).encodeABI());
            const poolResult: string[] = await orderManagerContract.methods.multicall([...poolMetaMulticallData, ...poolAddrMulticallData]).call();
            const poolMetaList = poolResult.slice(0, orderTotal).map(p => decodeMethodResult(orderManagerAnyContract, 'poolMetas', p)) as PoolMetasResponse[];
            const poolAddressList = poolResult.slice(orderTotal, orderTotal * 2).map(p => decodeMethodResult(orderManagerAnyContract, 'poolAddrs', p)) as string[];

            // 3. fake call get latest pending earn
            const updateOrderMulticallData = activeOrderIds.map((orderId: string) => orderManagerContract.methods.updateOrder(orderId.toString()).encodeABI());

            const updateOrderResult: string[] = await orderManagerContract.methods.multicall([
                ...updateOrderMulticallData,
                orderManagerContract.methods.getActiveOrders(account).encodeABI()
            ]).call({ from: account });
            const { activeLimitOrder: updatedActiveOrders } = decodeMethodResult(orderManagerAnyContract, 'getActiveOrders', updateOrderResult.slice(-1)[0]);

            // 4. compose order list
            const limitOrderList = await Promise.all(allOrders.map(async (order, i) => {
                const poolMeta = poolMetaList[i];
                let tokenX = { ...tokenList.find((e) => e.address.toLowerCase() === poolMeta.tokenX.toLowerCase()) } as TokenInfoFormatted;
                let tokenY = { ...tokenList.find((e) => e.address.toLowerCase() === poolMeta.tokenY.toLowerCase()) } as TokenInfoFormatted;
                if (! tokenX.symbol) {
                    tokenX =  await dispatch.customTokens.fetchAndAddToken({tokenAddr: poolMeta.tokenX, chainId, web3});
                }
                if (! tokenY.symbol) {
                    tokenY = await dispatch.customTokens.fetchAndAddToken({tokenAddr: poolMeta.tokenY, chainId, web3});
                }

                let pending = new BigNumber(0);
                if (i < activeOrderTotal) {
                    pending = new BigNumber(updatedActiveOrders[i].earn).minus(order.earn);
                }
                const amount = new BigNumber(order.amount);
                const filled = amount.minus(order.sellingRemain).minus(order.accSellingDec);

                const limitOrder: LimitOrder = {
                    orderId: (i<activeOrderIds.length ? activeOrderIds[i].toString(): '-1'),
                    tokenX: tokenX,
                    tokenY: tokenY,
                    feeTier: toFeeNumber(Number(poolMeta.fee)),
                    isSellTokenX: order.sellXEarnY,
                    price: tick2Price(Number(order.pt)),
                    priceDecimal: price2PriceDecimal(tick2Price(Number(order.pt)), { left: tokenX, right: tokenY }),
                    poolAddress: poolAddressList[i],
                    amountDecimal: amount2Decimal(amount, order.sellXEarnY ? tokenX : tokenY) ?? 0,
                    amount: amount.toNumber(),
                    filledDecimal: amount2Decimal(filled, order.sellXEarnY ? tokenX : tokenY) ?? 0,
                    filled: filled.toNumber(),
                    pendingDecimal: amount2Decimal(pending, order.sellXEarnY ? tokenY : tokenX) ?? 0,
                    pending: pending.toNumber(),
                    status: (filled.toNumber() > amount.toNumber() * 0.9999) ? 'finished' : 'unknown',
                    createTime: moment(Number(order.timestamp) * 1000).format('YYYY-MM-DD HH:mm:ss')
                };
                return limitOrder;
            }));

            const activeOrderList = limitOrderList.slice(0, activeOrderTotal).filter(order => order.tokenX.symbol && order.tokenX.symbol);
            const deactiveOrderList = limitOrderList.slice(activeOrderTotal, orderTotal).filter(order => order.tokenX.symbol && order.tokenX.symbol);
            dispatch.tradeLimitOrder.setLimitOrder({ activeList: activeOrderList, historyList: deactiveOrderList } as TradeLimitOrderState);
            console.log(`fetchLimitOrder end, ${(new Date()).getTime() - startTime.getTime()} ms`);
            return { activeList: activeOrderList, historyList: deactiveOrderList }
        },

        async addLimitOrder(params: AddLimitOrderParams, rootState): Promise<TransactionReceipt | null> {
            if (!params || !params.limitOrderForm || !params.account || !params.limitOrderMgrContract || !params.chainId || !params.gasPrice) {
                return new Promise<TransactionReceipt>((_, reject) => reject('Check swap fail'));
            }
            const { account, limitOrderMgrContract, limitOrderForm, chainId ,onGoingCallback} = params;
            const [tokenLowerAddr, tokenUpperAddr] = getSortedTokenAddr(limitOrderForm.tokenX.address, limitOrderForm.tokenY.address);
            const isFlipped = tokenLowerAddr !== limitOrderForm.tokenX.address;


            const orderId = await limitOrderMgrContract.methods.getDeactiveSlot(account).call();

            const feeContractFormat = toContractFeeNumber(limitOrderForm.fee);

            const amount = new BigNumber(limitOrderForm.amount).toFixed(0);

            const newLimOrderRequest: NewLimOrderRequest = {
                tokenX: tokenLowerAddr,
                tokenY: tokenUpperAddr,
                fee: feeContractFormat,
                //TODO: double check!!
                pt: isFlipped ? -limitOrderForm.pt : limitOrderForm.pt,
                amount,
                sellXEarnY: !isFlipped,
                deadline: String(Math.floor((new Date()).valueOf() / 1000) + (rootState.tradeSwap.swapForm.maxDelay ?? 0) * 60)
            };
            const value = isGasToken(limitOrderForm.tokenX, chainId) ? amount : '0';
            console.log('addLimitOrderRequest', newLimOrderRequest);
            console.log('limitOrderForm', limitOrderForm);

            const chain  = getChain(chainId);
            const toastLink = {} as ToastLink;

            if (value === '0') {
                return limitOrderMgrContract.methods.newLimOrder(orderId, newLimOrderRequest).send(buildSendingParams(chainId, {from: account}, params.gasPrice) as any).on(
                    'transactionHash',
                    (hash: string) => {
                        if (chain) {
                            toastLink.title = "View on " + chain.name;
                            toastLink.link = getTxLink(hash, chain);
                        }
                        if(typeof onGoingCallback !='undefined'){
                        onGoingCallback(toastLink);
                        }
                    }
                );
            } else {
                const multicallData = [];
                multicallData.push(limitOrderMgrContract.methods.newLimOrder(orderId, newLimOrderRequest).encodeABI());
                multicallData.push(limitOrderMgrContract.methods.refundETH().encodeABI());
                return limitOrderMgrContract.methods.multicall(multicallData).send(buildSendingParams(chainId, { from: account, value }, params.gasPrice) as any)
                .on(
                    'transactionHash',
                    (hash: string) => {
                        if (chain) {
                            toastLink.title = "View on " + chain.name;
                            toastLink.link = getTxLink(hash, chain);
                        }
                        if(typeof onGoingCallback !='undefined'){
                        onGoingCallback(toastLink);
                        }
                    }
                );
            }
        },

    })
});
