import { createModel } from "@rematch/core";
import BigNumber from "bignumber.js";
import { TransactionReceipt } from "ethereum-abi-types-generator";
import Web3 from "web3";
import { RootModel } from "../..";
import { IZUMI_SWAP_CONFIG } from "../../../../config/bizConfig";
import { getChain, getTxLink } from "../../../../config/chains";
import { isGasToken, tokenSymbol2token } from "../../../../config/tokens";
import { TokenInfoFormatted } from "../../../../hooks/useTokenListFormatted";
import { LimitOrderWithSwapManagerContract, NewLimOrderRequest, MethodPayableReturnContext } from "../../../../types/abis/iZiSwap/LimitOrderWithSwapManager";
import { QuoterContract } from "../../../../types/abis/iZiSwap/Quoter";
import { ChainId, TokenSymbol } from "../../../../types/mod";
import { getLiquidityManagerContract } from "../../../../utils/contractFactory";
import { buildSendingParams } from "../../../../utils/contractHelpers";
import { toContractFeeNumber } from "../../../../utils/funcs";
import { izumiFeeToTickSpacingMapping } from "../../../../utils/tickMath";
import { amount2Decimal, decimal2Amount, getSwapTokenAddress } from "../../../../utils/tokenMath";
import { pointDeltaRoundingDown, pointDeltaRoundingUp } from "../../farm/iZiSwap/price";
import { fetchPoolState, fetchPoolStateByAddress } from "../../trade/pools/controllers";
import { PoolState } from "../../trade/pools/types";
import { point2PriceDecimal, point2PriceUndecimal, priceDecimal2Point, PriceRoundingType } from "../../trade/utils/priceMath";
import { getBaseQuoteOrder } from "./funcs";
import { swapAcquired } from "../quoter/controllers";
import { ToastLink } from "../../../../iZUMi-UI-toolkit/src/components/Toast/Toast";

export interface OrderFormState {
    chainId: ChainId,
    baseToken: TokenInfoFormatted
    quoteToken: TokenInfoFormatted
    poolAddress?: string,
    fee: FeeTier
    baseTokenAmount: BigNumber
    quoteTokenAmount: BigNumber
    isLockBaseToken: boolean
    priceDecimalBaseByQuote: number
    point: number,
    isBuy: boolean,
    poolInfoUpdateTs: number,
    poolStateUpdateTs: number,
    poolState: PoolState
    swapMinAcquired: string
    isQueryingQuoter: boolean
}

export interface SetPoolParams {
    tokenA: TokenInfoFormatted
    tokenB: TokenInfoFormatted
    poolAddress: string
    fee: FeeTier
    point: number
    chainId: ChainId
    poolInfoUpdateTs: number
    poolState: PoolState
}

export interface SetPoolStateParams {
    poolStateUpdateTs: number
    poolState: PoolState
}

export interface SetTempTokenAmountParams {
    tempTokenAmountDecimal: number,
    tempIsLockBaseToken: boolean
}

export interface SetTokenPairParams {
    tokenA: TokenInfoFormatted
    tokenB: TokenInfoFormatted
    fee: FeeTier
    chainId: ChainId
    web3: Web3
}

export interface SetAmountParams {
    amountDecimal: number
    baseAmountBalance: BigNumber
    quoteAmountBalance: BigNumber
    quoterContract: QuoterContract
    flashMode: boolean
}

export interface SetPriceParams {
    priceDecimalBaseByQuote: number
    baseAmountBalance: BigNumber
    quoteAmountBalance: BigNumber
    quoterContract: QuoterContract
    flashMode: boolean
}

export interface SetValueRatioParams {
    ratio: number // 0 .. 100
    baseAmountBalance: BigNumber
    quoteAmountBalance: BigNumber
    flashMode: boolean
}

export interface RefreshPoolStateParams {
    web3: Web3
    chainId: ChainId
}

export interface NewLimitOrderParams {
    limitOrderWithSwapContract: LimitOrderWithSwapManagerContract
    limitOrderWithSwapAddress: string
    account: string
    web3: Web3
    chainId: ChainId
    gasPrice: number
    maxDelayMinutes: number
    flashMode: boolean
    realBaseToken: TokenInfoFormatted
    realQuoteToken: TokenInfoFormatted
    onGoingCallback?:  (toastLink?: ToastLink) => void
}

export const proOrderFormState = createModel<RootModel>()({
    state: {
        chainId: ChainId.None,
        baseToken: {} as TokenInfoFormatted,
        quoteToken: {} as TokenInfoFormatted,
        poolAddress: undefined as unknown as string,
        fee: 0.2 as FeeTier,
        baseTokenAmount: new BigNumber(0),
        quoteTokenAmount: new BigNumber(0),
        priceDecimalBaseByQuote: 0,
        point: 0,
        isLockBaseToken: false,
        isBuy: true,
        poolInfoUpdateTs: 0,
        poolStateUpdateTs: 0,
        poolState: {} as PoolState,
        swapMinAcquired: '0',
    } as OrderFormState,
    reducers: {

        // cleanPool: (state: OrderFormState) => {
        //     return {
        //         chainId: state.chainId,
        //         baseToken: {} as TokenInfoFormatted,
        //         quoteToken: {} as TokenInfoFormatted,
        //         poolAddress: undefined as unknown as string,
        //         fee: 0.2 as FeeTier,
        //         baseTokenAmount: new BigNumber(0),
        //         quoteTokenAmount: new BigNumber(0),
        //         priceDecimalBaseByQuote: 0,
        //         point: 0,
        //         isLockBaseToken: false,
        //         isBuy: true,
        //         poolInfoUpdateTs: new Date().getTime()
        //     }
        // },

        setPool: (state: OrderFormState, params: SetPoolParams) => {
            if (params.poolInfoUpdateTs <= state.poolInfoUpdateTs) {
                return state
            }
            const {fee, tokenA, tokenB, point: pointRaw, chainId, poolAddress, poolInfoUpdateTs} = params
            const {quoteToken, baseToken} = getBaseQuoteOrder(tokenA, tokenB, chainId)
            const pointDelta = izumiFeeToTickSpacingMapping[fee]
            const point1 = pointDeltaRoundingDown(pointRaw, pointDelta)
            const point = (pointRaw - point1 > pointDelta / 2) ? point1 + pointDelta : point1
            const priceDecimalBaseByQuote = point2PriceDecimal(baseToken, quoteToken, point)
            return {
                ...state,
                chainId,
                fee, baseToken, quoteToken,
                baseTokenAmount: new BigNumber(0),
                quoteTokenAmount: new BigNumber(0),
                poolAddress,
                priceDecimalBaseByQuote,
                point,
                isLockBaseToken: false,
                poolInfoUpdateTs,
                poolState: params.poolState,
                isQueryingQuoter: false,
            } as OrderFormState
        },

        setPoolState: (state: OrderFormState, params: SetPoolStateParams) => {
            if (params.poolStateUpdateTs <= state.poolStateUpdateTs || params.poolStateUpdateTs <= state.poolInfoUpdateTs) {
                return state
            }
            if (params.poolState.poolKey !== state.poolState.poolKey) {
                return state
            }
            return {
                ...state,
                poolStateUpdateTs: params.poolStateUpdateTs,
                poolState: params.poolState
            } as OrderFormState
        },

        setOrderFormState: (state: OrderFormState, payload: OrderFormState) => {
            return {...state, ...payload}
        },

        setIsBuy: (state: OrderFormState, isBuy: boolean) => {
            if (isBuy !== state.isBuy) {
                return {...state, 
                    isBuy, 
                    baseTokenAmount: new BigNumber(0),
                    quoteTokenAmount: new BigNumber(0),
                    isLockBaseToken: false,
                    isQueryingQuoter: false,
                } as OrderFormState
            }
        },

        refreshForm: (state: OrderFormState, isBuy: boolean) => {
            return {...state, 
                isBuy, 
                baseTokenAmount: new BigNumber(0),
                quoteTokenAmount: new BigNumber(0),
                isLockBaseToken: false,
                isQueryingQuoter: false,
            } as OrderFormState
        },

        setTempPriceValue: (state: OrderFormState, tempPriceValue: number) => {
            return {...state, tempPriceValue, isQueryingQuoter: false}
        },

    },
    effects: (dispatch) => ({

        async setBaseTokenAmount(params: SetAmountParams, rootState): Promise<boolean> {
            const currentPoint = Number(rootState.proOrderFormState.poolState.currentPoint)
            const baseToken = rootState.proOrderFormState.baseToken
            const baseTokenAmount = decimal2Amount(new BigNumber(params.amountDecimal), baseToken) as BigNumber
            if (baseTokenAmount.gt(params.baseAmountBalance) && !rootState.proOrderFormState.isBuy) {
                return false
            }
            const priceDecimalBaseByQuote = rootState.proOrderFormState.priceDecimalBaseByQuote
            if (priceDecimalBaseByQuote === 0) {
                dispatch.proOrderFormState.setOrderFormState({
                    baseTokenAmount,
                    isLockBaseToken: true,
                    quoteTokenAmount: new BigNumber(0),
                    swapMinAcquired: '0',
                    isQueryingQuoter: false,
                } as OrderFormState)
                return true
            }
            const quoteToken = rootState.proOrderFormState.quoteToken
            const quoteAmountDecimal = params.amountDecimal * priceDecimalBaseByQuote
            const quoteTokenAmount = decimal2Amount(new BigNumber(quoteAmountDecimal), quoteToken) as BigNumber
            if (quoteTokenAmount.gt(params.quoteAmountBalance) && rootState.proOrderFormState.isBuy) {
                return false
            }

            let swapMinAcquired = '0'

            dispatch.proOrderFormState.setOrderFormState({
                baseTokenAmount,
                isLockBaseToken: true,
                quoteTokenAmount,
                swapMinAcquired,
                isQueryingQuoter: !params.flashMode
            } as OrderFormState)

            if (!params.flashMode) {
                const fee = rootState.proOrderFormState.fee
                const sellBaseEarnQuote = !rootState.proOrderFormState.isBuy
                const {acquire, error} = await swapAcquired(
                    baseToken, quoteToken, fee, sellBaseEarnQuote, 
                    baseTokenAmount.toFixed(0), 
                    rootState.proOrderFormState.point, 
                    currentPoint, 
                    true,
                    params.quoterContract
                )
                swapMinAcquired = acquire
                if (error) {
                    console.log('[error] error while swap quote: ', error)
                }
                dispatch.proOrderFormState.setOrderFormState({
                    baseTokenAmount,
                    isLockBaseToken: true,
                    quoteTokenAmount,
                    swapMinAcquired,
                    isQueryingQuoter: false
                } as OrderFormState)
            }
            return true
        },

        async setQuoteTokenAmount(params: SetAmountParams, rootState): Promise<boolean> {
            const currentPoint = Number(rootState.proOrderFormState.poolState.currentPoint)
            const quoteToken = rootState.proOrderFormState.quoteToken
            const quoteTokenAmount = decimal2Amount(new BigNumber(params.amountDecimal), quoteToken) as BigNumber
            if (quoteTokenAmount.gt(params.quoteAmountBalance) && rootState.proOrderFormState.isBuy) {
                console.log('quite')
                return false
            }
            const priceDecimalBaseByQuote = rootState.proOrderFormState.priceDecimalBaseByQuote
            if (priceDecimalBaseByQuote === 0) {
                dispatch.proOrderFormState.setOrderFormState({
                    quoteTokenAmount,
                    isLockBaseToken: false,
                    baseTokenAmount: new BigNumber(0),
                    swapMinAcquired: '0',
                    isQueryingQuoter: false,
                } as OrderFormState)
                return true
            }
            const baseToken = rootState.proOrderFormState.baseToken
            const baseAmountDecimal = params.amountDecimal / priceDecimalBaseByQuote
            const baseTokenAmount = decimal2Amount(new BigNumber(baseAmountDecimal), baseToken) as BigNumber
            if (baseTokenAmount.gt(params.baseAmountBalance) && !rootState.proOrderFormState.isBuy) {
                return false
            }

            let swapMinAcquired = '0'
            dispatch.proOrderFormState.setOrderFormState({
                quoteTokenAmount,
                isLockBaseToken: false,
                baseTokenAmount,
                swapMinAcquired,
                isQueryingQuoter: !params.flashMode,
            } as OrderFormState)
            if (!params.flashMode) {
                const fee = rootState.proOrderFormState.fee
                const sellBaseEarnQuote = !rootState.proOrderFormState.isBuy
                const {acquire, error} = await swapAcquired(
                    baseToken, quoteToken, fee, sellBaseEarnQuote, 
                    quoteTokenAmount.toFixed(0), 
                    rootState.proOrderFormState.point, 
                    currentPoint, 
                    false,
                    params.quoterContract
                )
                swapMinAcquired = acquire
                if (error) {
                    console.log('[error] error while swap quote: ', error)
                }
                dispatch.proOrderFormState.setOrderFormState({
                    quoteTokenAmount,
                    isLockBaseToken: false,
                    baseTokenAmount,
                    swapMinAcquired,
                    isQueryingQuoter: false,
                } as OrderFormState)
            }
            return true
        },

        async setPrice(setPriceParams: SetPriceParams, rootState): Promise<boolean> {
            const currentPoint = Number(rootState.proOrderFormState.poolState.currentPoint)
            const baseToken = rootState.proOrderFormState.baseToken
            const quoteToken = rootState.proOrderFormState.quoteToken
            
            const pointRaw = priceDecimal2Point(baseToken, quoteToken, setPriceParams.priceDecimalBaseByQuote, PriceRoundingType.PRICE_ROUNDING_NEAREST)
            const pointDelta = izumiFeeToTickSpacingMapping[rootState.proOrderFormState.fee]
            const point1 = pointDeltaRoundingDown(pointRaw, pointDelta)
            const point = (pointRaw - point1 > pointDelta / 2) ? point1 + pointDelta : point1

            const priceDecimalBaseByQuote = point2PriceDecimal(baseToken, quoteToken, point)
            if (rootState.proOrderFormState.isLockBaseToken) {
                const baseTokenAmount = rootState.proOrderFormState.baseTokenAmount
                const priceUndecimalBaseByQuote = point2PriceUndecimal(baseToken, quoteToken, point)
                const quoteTokenAmount = new BigNumber(baseTokenAmount.times(priceUndecimalBaseByQuote).toFixed(0, 2))
                if (quoteTokenAmount.gt(setPriceParams.quoteAmountBalance) && rootState.proOrderFormState.isBuy) {
                    return false
                }

                let swapMinAcquired = '0'
                dispatch.proOrderFormState.setOrderFormState({
                    quoteTokenAmount,
                    isLockBaseToken: true,
                    point,
                    priceDecimalBaseByQuote,
                    swapMinAcquired,
                    isQueryingQuoter: !setPriceParams.flashMode,
                } as OrderFormState)
                if (!setPriceParams.flashMode) {
                    const fee = rootState.proOrderFormState.fee
                    const sellBaseEarnQuote = !rootState.proOrderFormState.isBuy
                    const {acquire, error} = await swapAcquired(
                        baseToken, quoteToken, fee, sellBaseEarnQuote, 
                        baseTokenAmount.toFixed(0), 
                        rootState.proOrderFormState.point, 
                        currentPoint, 
                        true,
                        setPriceParams.quoterContract
                    )
                    swapMinAcquired = acquire
                    if (error) {
                        console.log('[error] error while swap quote: ', error)
                    }
                    dispatch.proOrderFormState.setOrderFormState({
                        quoteTokenAmount,
                        isLockBaseToken: true,
                        point,
                        priceDecimalBaseByQuote,
                        swapMinAcquired,
                        isQueryingQuoter: false
                    } as OrderFormState)
                }

                return true
            } else {
                const quoteTokenAmount = rootState.proOrderFormState.quoteTokenAmount
                const priceUndecimalBaseByQuote = point2PriceUndecimal(baseToken, quoteToken, point)
                const baseTokenAmount = new BigNumber(quoteTokenAmount.div(priceUndecimalBaseByQuote).toFixed(0, 2))
                if (baseTokenAmount.gt(setPriceParams.baseAmountBalance) && !rootState.proOrderFormState.isBuy) {
                    return false
                }

                let swapMinAcquired = '0'
                dispatch.proOrderFormState.setOrderFormState({
                    baseTokenAmount,
                    isLockBaseToken: false,
                    point,
                    priceDecimalBaseByQuote,
                    swapMinAcquired,
                    isQueryingQuoter: !setPriceParams.flashMode,
                } as OrderFormState)
                if (!setPriceParams.flashMode) {
                    const fee = rootState.proOrderFormState.fee
                    const sellBaseEarnQuote = !rootState.proOrderFormState.isBuy
                    const {acquire, error} = await swapAcquired(
                        baseToken, quoteToken, fee, sellBaseEarnQuote, 
                        quoteTokenAmount.toFixed(0), 
                        rootState.proOrderFormState.point, 
                        currentPoint, 
                        false,
                        setPriceParams.quoterContract
                    )
                    swapMinAcquired = acquire
                    if (error) {
                        console.log('[error] error while swap quote: ', error)
                    }
                    dispatch.proOrderFormState.setOrderFormState({
                        baseTokenAmount,
                        isLockBaseToken: false,
                        point,
                        priceDecimalBaseByQuote,
                        swapMinAcquired,
                        isQueryingQuoter: false
                    } as OrderFormState)
                }

                return true
            }
        },

        async setTokenPair(params: SetTokenPairParams, rootState): Promise<boolean> {
            if (!params.chainId || !params.web3) {
                return false
            }
            const poolInfoUpdateTs = new Date().getTime()
            const tokenA = params.tokenA
            const tokenB = params.tokenB
            const fee = params.fee
            const liquidityManagerContract = getLiquidityManagerContract(params.chainId, params.web3)
            const poolState = await fetchPoolState({
                chainId: params.chainId,
                web3: params.web3,
                baseContract: liquidityManagerContract,
                tokenA,
                tokenB,
                fee
            })
            if (!poolState) {
                return false
            }
            const setPoolParams = {
                tokenA,
                tokenB,
                fee,
                poolAddress: poolState.poolAddress,
                point: Number(poolState.currentPoint),
                chainId: params.chainId,
                poolInfoUpdateTs,
                poolState
            }
            dispatch.proOrderFormState.setPool(setPoolParams)
            return true
        },

        async refreshPoolState(params: RefreshPoolStateParams,rootState): Promise<void> {
            const address = rootState.proOrderFormState.poolState.poolAddress
            if (!address || !params.chainId || !params.web3) {
                return
            }
            const currentTimestamp = new Date().getTime()
            const poolState = await fetchPoolStateByAddress(address, params.chainId, params.web3)
            if (!poolState) {
                return
            }
            dispatch.proOrderFormState.setPoolState({
                poolStateUpdateTs: currentTimestamp,
                poolState: {
                    ...poolState,
                    poolAddress: address,
                    poolKey: rootState.proOrderFormState.poolState.poolKey
                } as PoolState
            } as SetPoolStateParams)
        },

        async newLimitOrder(params: NewLimitOrderParams, rootState): Promise<TransactionReceipt> {
            if (!params || !params.account || !params.limitOrderWithSwapContract || !params.web3 || !params.chainId) {
                return new Promise<TransactionReceipt>((_, reject) => reject('Check New LimitOrder fail'));
            }
            const chain  = getChain(params.chainId);
            const toastLink = {} as ToastLink;
            const baseTokenAddress = getSwapTokenAddress(rootState.proOrderFormState.baseToken).toLowerCase()
            const quoteTokenAddress = getSwapTokenAddress(rootState.proOrderFormState.quoteToken).toLowerCase()

            const [tokenX, tokenY] = baseTokenAddress < quoteTokenAddress ? [baseTokenAddress, quoteTokenAddress] : [quoteTokenAddress, baseTokenAddress]

            const point = rootState.proOrderFormState.point
            const isBuy = rootState.proOrderFormState.isBuy
            const isLockBaseToken = rootState.proOrderFormState.isLockBaseToken
            const isDesireMode = isBuy ? isLockBaseToken : !isLockBaseToken
            const sellXEarnY = isBuy ? baseTokenAddress > quoteTokenAddress : baseTokenAddress < quoteTokenAddress
            const baseTokenAmount = rootState.proOrderFormState.baseTokenAmount
            const quoteTokenAmount = rootState.proOrderFormState.quoteTokenAmount
            const currentTs = new Date().getTime() / 1000
            const deadline = Math.round(currentTs + params.maxDelayMinutes * 60)
            let swapMinAcquired = '0'
            if (!params.flashMode) {
                swapMinAcquired = new BigNumber(rootState.proOrderFormState.swapMinAcquired).times(IZUMI_SWAP_CONFIG.DESIRED_AMOUNT_TO_MIN_AMOUNT_FACTOR).toFixed(0, 1)
            }

            const inputToken = isBuy ? params.realQuoteToken: params.realBaseToken
            const outputToken = isBuy ? params.realBaseToken: params.realQuoteToken

            const inputIsGasToken = isGasToken(inputToken, params.chainId)
            const outputIsGasToken = isGasToken(outputToken, params.chainId)

            const recipient = outputIsGasToken ? params.limitOrderWithSwapAddress : params.account
            const payAmount = isBuy ? quoteTokenAmount.toFixed() : baseTokenAmount.toFixed()
            const msgValue = inputIsGasToken ? payAmount: '0'

            const request: NewLimOrderRequest = {
                recipient,
                tokenX,
                tokenY,
                fee: toContractFeeNumber(rootState.proOrderFormState.fee),
                pt: point,
                isDesireMode,
                amount: isLockBaseToken ? baseTokenAmount.toFixed(0) : quoteTokenAmount.toFixed(0),
                sellXEarnY,
                swapMinAcquired,
                deadline: String(deadline),
            }
            const slotIdx = await params.limitOrderWithSwapContract.methods.getDeactiveSlot(params.account).call();
            const multicall = [] as MethodPayableReturnContext[]
            const newLimOrderCalling = params.limitOrderWithSwapContract.methods.newLimOrder(slotIdx, request)
            multicall.push(newLimOrderCalling)

            if (inputIsGasToken) {
                const refundCalling = params.limitOrderWithSwapContract.methods.refundETH()
                multicall.push(refundCalling)
            }

            if (outputIsGasToken) {
                const unwrapCalling = params.limitOrderWithSwapContract.methods.unwrapWETH9('0', params.account)
                multicall.push(unwrapCalling)
            }

            let calling = undefined as unknown as MethodPayableReturnContext

            if (multicall.length === 1) {
                calling = multicall[0]
            } else {
                const multicallStr = multicall.map((e)=>{return e.encodeABI()})
                calling = params.limitOrderWithSwapContract.methods.multicall(multicallStr)
            }

            const onGoingCallback = params.onGoingCallback

            console.log('msgvalue: ', msgValue)
            
            return calling.send(buildSendingParams(
                    params.chainId, 
                    {
                        from: params.account, 
                        maxFeePerGas: params.gasPrice,
                        value: msgValue
                    },
                    params.gasPrice
                ) as any)
            .on(
                'transactionHash',
                (hash: string) => {
                    if (chain) {
                        toastLink.title = "View on " + chain.name;
                        toastLink.link = getTxLink(hash, chain);
                    }
                    if(typeof onGoingCallback !='undefined'){
                        onGoingCallback(toastLink);
                    }
                }
            );
        
        },

    })

});
