import { ChainId, TokenSymbol } from "../../../../../types/mod";
import { BasePreQueryPlugin } from "../BaseDexPlugin";
import { PANCAKE_FACTORY_ADDRESS, PANCAKE_FEE_RATE, PANCAKE_MULTIHOP_MID_TOKEN_SYMBOL, PANCAKE_TOKEN_BLACK_LIST } from "./config";
import { Contract } from 'web3-eth-contract'
import { getContract } from "../../../../../utils/contractFactory";
import Web3 from "web3";

import factoryABI from '../../../../../config/abi/pancake/factory.json'
import pairABI from '../../../../../config/abi/pancake/pair.json'
import { TokenInfoFormatted } from "../../../../../hooks/useTokenListFormatted";
import { tokenSymbol2token } from "../../../../../config/tokens";
import { PancakePairState, PancakePreQueryResult } from "./types";
import { DagNode, Path, PreQueryResult } from "../utils";
import { decodeMethodResult } from "../../../../../utils/contractHelpers";
import { BigNumber } from "bignumber.js";

interface Pair {
    tokenA: TokenInfoFormatted
    tokenB: TokenInfoFormatted
}

function getFactoryContract(chainId: ChainId, web3: Web3): Contract {
    const address = PANCAKE_FACTORY_ADDRESS[chainId]
    return getContract<Contract>(factoryABI, address, web3)
}

function getPairContract(address: string, web3: Web3): Contract {
    return getContract<Contract>(pairABI, address, web3)
}

export class PancakePreQueryPlugin extends BasePreQueryPlugin {
    private chainId: ChainId

    private factoryAddress: string
    private factoryContract: Contract
    private fakePairContract: Contract

    private pairsOfCalling: Pair[] = undefined as unknown as Pair[]
    private knownPairs: Pair[] = undefined as unknown as Pair[]
    private knownPairAddress: string[] = undefined as unknown as string[]

    private responsePairAddress: string[] = undefined as unknown as string[]
    private unknownPairState: PancakePairState[] = undefined as unknown as PancakePairState[]
    private knownPairState: PancakePairState[] = undefined as unknown as PancakePairState[]

    private allLinks: TokenInfoFormatted[] = undefined as unknown as TokenInfoFormatted[]
    private dagNodes: DagNode[] = undefined as unknown as DagNode[]

    private tokenA: TokenInfoFormatted = undefined as unknown as TokenInfoFormatted
    private tokenB: TokenInfoFormatted = undefined as unknown as TokenInfoFormatted

    private feeRate: number

    public constructor(preQueryResult: PancakePreQueryResult, chainId: ChainId, web3: Web3) {
        super(preQueryResult)
        this.chainId = chainId
        this.factoryAddress = PANCAKE_FACTORY_ADDRESS[chainId]
        this.factoryContract = getFactoryContract(chainId, web3)
        this.fakePairContract = getPairContract(this.factoryAddress, web3)
        this.feeRate = PANCAKE_FEE_RATE[chainId]
    }

    private getSwapPairKey(tokenA: TokenInfoFormatted, tokenB: TokenInfoFormatted) : string {
        const tokenASymbol = tokenA.symbol.toUpperCase()
        const tokenBSymbol = tokenB.symbol.toUpperCase()
        if (tokenASymbol < tokenBSymbol) {
            return tokenASymbol + '-' + tokenBSymbol
        } else {
            return tokenBSymbol + '-' + tokenASymbol
        }
    }

    private hasPair(tokenA: TokenInfoFormatted, tokenB: TokenInfoFormatted) : boolean {
        const lastPreQueryResult = this.preQueryResult as PancakePreQueryResult
        if (this.chainId != lastPreQueryResult.lastChainId) {
            return false
        }
        const key = this.getSwapPairKey(tokenA, tokenB)
        return lastPreQueryResult.pairAddress.has(key)
    }

    override getPreQueryDag(tokenA: TokenInfoFormatted, tokenB: TokenInfoFormatted): DagNode[] {
        
        this.tokenA = {...tokenA}
        this.tokenB = {...tokenB}

        const tokenBList: TokenInfoFormatted[] = []
        const tokenBSymbols = PANCAKE_MULTIHOP_MID_TOKEN_SYMBOL[this.chainId]
 
        tokenBSymbols.forEach((s)=>{
            if (s === tokenA.symbol || s === tokenB.symbol) {
                return
            }
            const token = tokenSymbol2token(s, this.chainId)
            if (token.address) {
                tokenBList.push(token)
            }
        })
        
        this.allLinks = [...tokenBList]
        tokenBList.push({...tokenA})
        tokenBList.push({...tokenB})

        this.pairsOfCalling = []
        this.knownPairs = []
        

        const pairCalling = [] as string[]

        //pair address calling
        for (let i = 0; i < tokenBList.length; i ++) {
            for (let j = i + 1; j < tokenBList.length; j ++) {
                const middlePair = (i < tokenBList.length - 2 && j < tokenBList.length - 2)
                if (!this.hasPair(tokenBList[i], tokenBList[j])) {
                    const pairKey = this.getSwapPairKey(tokenBList[i], tokenBList[j])
                    pairCalling.push(this.factoryContract.methods.getPair(tokenBList[i].address, tokenBList[j].address).encodeABI())
                    this.pairsOfCalling.push({
                        tokenA: tokenBList[i],
                        tokenB: tokenBList[j],
                    } as Pair)
                } else {
                    this.knownPairs.push({
                        tokenA: tokenBList[i],
                        tokenB: tokenBList[j],
                    } as Pair)
                }
            }
        }
        this.responsePairAddress = []
        this.unknownPairState = []

        for (let i = 0; i < this.pairsOfCalling.length; i ++) {
            this.responsePairAddress.push('')
            this.unknownPairState.push(undefined as unknown as PancakePairState)
        }
        
        const pairCallingNodes: DagNode[] =pairCalling.map((calling: string, idx: number)=>{
            return {
                calling,
                preIdx: undefined,
                targetAddress: this.factoryAddress,
                parseCallingResponse: (response: string): void => {
                    const address = decodeMethodResult(this.factoryContract, 'getPair', response)
                    this.responsePairAddress[idx] = address
                }
            } as DagNode
        })

        const knownPairStateCalling = [] as string[]
        this.knownPairState = []
        for (let i = 0; i < this.knownPairs.length; i ++) {
            this.knownPairState.push(undefined as unknown as PancakePairState)
        }
        // state calling of knownpairs
        const preQueryResult = this.preQueryResult as PancakePreQueryResult

        this.knownPairAddress = []
        for (const pair of this.knownPairs) {
            const pairKey = this.getSwapPairKey(pair.tokenA,pair.tokenB)
            const pairAddress = preQueryResult.pairAddress.get(pairKey) as string
            knownPairStateCalling.push(this.fakePairContract.methods.getReserves().encodeABI())
            this.knownPairAddress.push(pairAddress)
        }

        const knownPairStateCallingNodes: DagNode[] = knownPairStateCalling.map((calling: string, idx: number)=>{
            const pairAddress = this.knownPairAddress[idx]
            return {
                calling,
                preIdx: undefined,
                targetAddress: pairAddress,
                parseCallingResponse: (response: string): void => {
                    const {_reserve0, _reserve1} = decodeMethodResult(this.fakePairContract as unknown as Contract, 'getReserves', response)
                    this.knownPairState[idx] = {reserve0: _reserve0, reserve1: _reserve1} as PancakePairState
                }
            }
        })

        this.unknownPairState = []
        for (let i = 0; i < this.pairsOfCalling.length; i ++) {
            this.unknownPairState.push(undefined as unknown as PancakePairState)
        }
        // state calling of unknown pairs
        const unknownPairStateCallingNodes: DagNode[] = this.pairsOfCalling.map((pair: Pair, idx: number) => {
            return {
                preIdx: [idx],
                getCallingAndTargetAddress: (): {targetAddress: string, calling: string} => {
                    const pairAddress = this.responsePairAddress[idx]
                    if (new BigNumber(pairAddress).eq(0)) {
                        return undefined as unknown as {targetAddress: string, calling: string}
                    }
                    const calling = this.fakePairContract.methods.getReserves().encodeABI()
                    return {targetAddress: pairAddress, calling}
                },
                parseCallingResponse: (response: string): void => {
                    const {_reserve0, _reserve1} = decodeMethodResult(this.fakePairContract as unknown as Contract, 'getReserves', response)
                    this.unknownPairState[idx] = {reserve0: _reserve0, reserve1: _reserve1} as PancakePairState
                }
            }
        })

        this.dagNodes = [...pairCallingNodes, ...knownPairStateCallingNodes, ...unknownPairStateCallingNodes]

        return this.dagNodes
    }

    override getQueryResult(): PreQueryResult {

        const lastPreQueryResult = this.preQueryResult as PancakePreQueryResult
        const preQueryResult = {
            path: [],
            lastChainId: lastPreQueryResult.lastChainId,
            pairAddress: lastPreQueryResult.pairAddress,
            pairState: lastPreQueryResult.pairState
        } as PancakePreQueryResult
        if (preQueryResult.lastChainId !== this.chainId) {
            preQueryResult.pairAddress = new Map<string, string>()
            preQueryResult.pairState = new Map<string, PancakePairState>()
        } else if (preQueryResult.lastChainId === this.chainId && this.dagNodes.length === 0) {
            preQueryResult.path = lastPreQueryResult.path
            // no new prequerys
            return preQueryResult
        }
        for (let i = 0; i < this.responsePairAddress.length; i ++) {
            if (this.responsePairAddress[i] === '') {
                continue
            }
            const pairAddress = this.responsePairAddress[i]
            if (new BigNumber(pairAddress).eq(0)) {
                continue
            }
            const pairPair = this.pairsOfCalling[i]

            const pairKey = this.getSwapPairKey(pairPair.tokenA, pairPair.tokenB)
            preQueryResult.pairAddress.set(pairKey, pairAddress)

            preQueryResult.pairState.set(pairKey, this.unknownPairState[i])
        }

        for (let i = 0; i < this.knownPairs.length; i ++) {
            const pairPair = this.knownPairs[i]
            const pairKey = this.getSwapPairKey(pairPair.tokenA, pairPair.tokenB)
            preQueryResult.pairState.set(pairKey, this.knownPairState[i])
        }

        const paths = [] as Path[]

        for (const i in this.allLinks) {
            const firstKey = this.getSwapPairKey(this.tokenA, this.allLinks[i])
            if (!preQueryResult.pairAddress.has(firstKey)) {
                continue
            }
            for (const j in this.allLinks) {
                const lastKey = this.getSwapPairKey(this.allLinks[j], this.tokenB)
                if (!preQueryResult.pairAddress.has(lastKey)) {
                    continue
                }
                if (this.allLinks[i].symbol === this.allLinks[j].symbol) {
                    paths.push({
                        tokenChain: [{...this.tokenA}, {...this.allLinks[i]}, {...this.tokenB}],
                        feeRate: [this.feeRate, this.feeRate]
                    } as Path)
                } else {

                    const middleKey = this.getSwapPairKey(this.allLinks[i], this.allLinks[j])
                    if (!preQueryResult.pairAddress.has(middleKey)) {
                        continue
                    }
                    paths.push({
                        tokenChain: [{...this.tokenA}, {...this.allLinks[i]}, {...this.allLinks[j]}, {...this.tokenB}],
                        feeRate: [this.feeRate, this.feeRate, this.feeRate]
                    } as Path)
                }
            }
        }


        const directKey = this.getSwapPairKey(this.tokenA, this.tokenB)
        if (preQueryResult.pairAddress.has(directKey)) {
            paths.push({
                tokenChain: [{...this.tokenA}, {...this.tokenB}],
                feeRate: [this.feeRate]
            } as Path)
        }
        preQueryResult.path = paths

        return preQueryResult
    }
}