import {
    useColorMode,
    useOutsideClick,
    Flex,
    Popover,
    HStack,
    PopoverTrigger,
    PopoverContent,
    VStack,
    BoxProps,
    Text,
    PopoverCloseButton,
    Divider,
    Image,
} from '@chakra-ui/react';
import React, { ReactElement, useState } from 'react';
import useIsMobile from '../../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';
import { i_text_copy_bold } from '../../../../style';
import { getColorThemeSelector } from '../../../../utils/funcs';
type FilterProps = {
    filterTitle: string;
    filterElement: ReactElement[];
} & BoxProps;

export const Filter: React.FC<FilterProps> = (props) => {
    const { filterTitle, filterElement, ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const isMobile = useIsMobile();

    const [showPopOver, setShowPopOver] = useState(false);
    const inputFocusRef = React.useRef<any | null>(null);
    useOutsideClick({
        ref: inputFocusRef,
        handler: () => {
            setShowPopOver(false);
        },
    });
    return (
        <Flex cursor="pointer" {...rest}>
            <Popover initialFocusRef={inputFocusRef}>
                <HStack position="relative" display="inline-block">
                    <PopoverTrigger>
                        <Image
                            src={
                                isMobile
                                    ? colorTheme('/assets/filter/mobileFilter.svg', '/assets/filter/mobileFilterDark.svg')
                                    : process.env.PUBLIC_URL + '/assets/filter/filter.svg'
                            }
                        ></Image>
                    </PopoverTrigger>
                </HStack>
                <PopoverContent
                    hidden={showPopOver}
                    p="12px 12px 17px 12px "
                    color={colorTheme('tertiary.400', 'tertiary.300')}
                    w="270px"
                    autoFocus={false}
                    bg={colorTheme('#FFFFFF', '#24193C')}
                    _focus={{
                        boxShadow: colorTheme('0px 0px 27px 5px rgba(29, 6, 51, 0.25)', 'none'),
                    }}
                    border="none"
                >
                    <VStack w="100%">
                        <HStack w="100%" my="5px">
                            <Text
                                className={i_text_copy_bold}
                                color={colorTheme('#2B1166', '#FFFFFF')}
                                fontSize="12px"
                                letterSpacing="0.02em"
                            >
                                {filterTitle}
                            </Text>
                            <PopoverCloseButton top="13px" />
                        </HStack>

                        <Divider borderWidth="1px"></Divider>
                        <VStack w="100%" spacing="10px" alignItems="end">
                            {filterElement &&
                                filterElement.length > 0 &&
                                filterElement.map((item) => {
                                    return item;
                                })}
                        </VStack>
                    </VStack>
                </PopoverContent>
            </Popover>
        </Flex>
    );
};
