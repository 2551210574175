import BigNumber from 'bignumber.js';
import Web3 from 'web3';
import { blocksPerDay } from '../../../config/chains';
import { tokenSymbol2token } from '../../../config/tokens';
import { VEIZI_ADDRESS } from '../../../config/veizi/veiziContracts';
import { ChainId, TokenSymbol } from '../../../types/mod';
import { getVeiZiContract } from '../../../utils/contractHelpers';
import { amount2Decimal } from '../../../utils/tokenMath';
import { VeiZiGlobalData, VeiZiNft } from './types';

export const getVeiZiGlobalData = async (veiZiAddress: string, chainId?: ChainId, web3?: Web3): Promise<VeiZiGlobalData> => {
    const ret = {
        stakeiZiAmount: '0',
        rewardPerBlock: '0',
        totaliZiLocked: '0',
        totaliZiLockedDecimal: 0,
        totalVeiZi: '0',
        totalVeiZiDecimal: 0,
        currentTimestamp: 0,
        curveTimestamps: [],
        totalVeiZiCurve: [],
        seconds4Year: (4 * 365 + 1) * 24 * 3600,
        avgLockYear: 0,
    } as VeiZiGlobalData;
    if (!chainId || !web3) {
        return ret;
    }
    const veiZiContract = getVeiZiContract(veiZiAddress, web3);
    ret.currentTimestamp = Math.round((new Date()).getTime() / 1000);
    ret.totaliZiLocked = await veiZiContract?.methods.supply().call() ?? '0';
    ret.totaliZiLockedDecimal = amount2Decimal(new BigNumber(ret.totaliZiLocked), tokenSymbol2token(TokenSymbol.IZI, chainId)) ?? 0;
    ret.totalVeiZi = await veiZiContract?.methods.totalVeiZiAt(ret.currentTimestamp).call() ?? '0';
    ret.stakeiZiAmount = await veiZiContract?.methods.stakeiZiAmount().call() ?? '0';
    const rewardInfo = await veiZiContract?.methods.rewardInfo().call();
    ret.rewardPerBlock = rewardInfo?.rewardPerBlock ?? '0';

    ret.totalVeiZiDecimal = amount2Decimal(new BigNumber(ret.totalVeiZi), tokenSymbol2token(TokenSymbol.IZI, chainId)) ?? 0;
  
    return ret;
};

export const getVeiZiNft = async (
    nftId: string, 
    currentTimestamp: number, 
    seconds4Year: number,
    chainId: ChainId,
    web3: Web3
) : Promise<VeiZiNft> => {
    if (!chainId || !web3) {
        return undefined as unknown as VeiZiNft;
    }
    const veiZiAddress = VEIZI_ADDRESS[chainId];
    const veiZiContract = getVeiZiContract(veiZiAddress, web3);
    const nftLocked = await veiZiContract?.methods.nftLocked(nftId).call();
    if (nftLocked.amount === '0') {
        return undefined as unknown as VeiZiNft;
    }
    const lockAmountDecimal = amount2Decimal(new BigNumber(nftLocked.amount), tokenSymbol2token(TokenSymbol.IZI, chainId)) ?? 0;
    const endTimestamp = Number(nftLocked.end);
    const veiZiDecimal = lockAmountDecimal * Math.max(endTimestamp - currentTimestamp, 0) / seconds4Year;
    let owner = await veiZiContract?.methods.ownerOf(nftId).call();
    let isStaked = false;
    if (owner.toLowerCase() === veiZiAddress.toLowerCase()) {
        isStaked = true;
        owner = await veiZiContract?.methods.stakedNftOwners(nftId).call();
    }
    return {
        lockAmountDecimal,
        veiZiDecimal,
        owner,
        endTimestamp,
        nftId,
        isStaked,
    } as VeiZiNft;
};

export const getChartTimeStamps = (
    currentDate: Date,
): number[] => {
    const lastMonth = new Date(currentDate);
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    const timestamp = [] as number[];

    timestamp.push(Math.round(lastMonth.getTime() / 1000));
    timestamp.push(Math.round(currentDate.getTime() / 1000));

    const date = new Date(currentDate);
    for (let i = 0; i < 48; i ++) {
        date.setMonth(date.getMonth() + 1);
        timestamp.push(Math.round(date.getTime() / 1000));
    }

    return timestamp;
};

export const getTotalVeiZiCurve = async (veiZiAddress: string, timestamp: number[], chainId?: ChainId, web3?: Web3): Promise<number[]> => {
    const ret = [] as number[];
    if (!chainId || !web3) {
        for (let i = 0; i < timestamp.length; i ++) {
            ret.push(0);
        }
        return ret;
    }
    if (timestamp.length > 0) {
        const veiZiContract = getVeiZiContract(veiZiAddress, web3);
        const callings = [] as string[];
        for (let i = 0; i < timestamp.length; i ++) {
            callings.push(veiZiContract?.methods.totalVeiZiAt(timestamp[i]).encodeABI());
        }
        const multicallRes = await veiZiContract?.methods.multicall(callings).call();
        for (let i = 0; i < multicallRes.length; i ++) {
            const totalVeiZiDecimal = amount2Decimal(new BigNumber(multicallRes[i]), tokenSymbol2token(TokenSymbol.IZI, chainId)) ?? 0;
            ret.push(totalVeiZiDecimal);
        }
    }
    return ret;
};

export const getVeiZiNftCurve = async (veiZiAddress: string, nftId: string, timestamp: number[], chainId?: ChainId, web3?: Web3): Promise<number[]> => {
    const ret = [] as number[];
    if (!chainId || !web3 || !veiZiAddress) {
        for (let i = 0; i < timestamp.length; i ++) {
            ret.push(0);
        }
        return ret;
    }
    if (timestamp.length > 0) {
        const veiZiContract = getVeiZiContract(veiZiAddress, web3);
        const callings = [] as string[];
        for (let i = 0; i < timestamp.length; i ++) {
            callings.push(veiZiContract?.methods.nftVeiZiAt(nftId, timestamp[i]).encodeABI());
        }
        const multicallRes = await veiZiContract?.methods.multicall(callings).call();
        for (let i = 0; i < multicallRes.length; i ++) {
            const veiZiDecimal = amount2Decimal(new BigNumber(multicallRes[i]), tokenSymbol2token(TokenSymbol.IZI, chainId)) ?? 0;
            ret.push(veiZiDecimal);
        }
    }
    return ret;
};

export const getStakeAPR = (rewardPerBlock: string, stakeAmount:string, lockAmount: string, veiZiAmount: string, chainId: ChainId): number => {
    if (lockAmount === '0') {
        return 0;
    }
    const blocksPerYear = blocksPerDay(chainId) * 365;
    const totalAmount = new BigNumber(stakeAmount).plus(lockAmount);
    const apr = Number(new BigNumber(rewardPerBlock).times(blocksPerYear).div(totalAmount).times(veiZiAmount).div(lockAmount));
    return apr;
};