import {
    HStack,
    Text,
    useColorMode,
    Stack,
    Input,
    Button,
    FormControl,
    FormLabel,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Flex,
    Accordion,
    Image,
} from '@chakra-ui/react';
import { i_text_copy, i_text_copy_bold, i_text_d } from '../../style';
import { getColorThemeSelector } from '../../utils/funcs';
import { useState, useEffect } from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import NetworkSelect from './components/NetworkSelect';
import { ChainId } from '../../types/mod';
import PositionListEntry from '../Dashboard/components/PositionListEntry';
import CustomButton from '../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { useRematchDispatch } from '../../hooks/useRematchDispatch';
import { RootDispatch } from '../../state/store';
import { Dex } from '../../state/models/liquidity/types';
import { addLiquidity, LiquidityInfo, LiquidityRequest, searchLiquidityInfo } from '../../net/endpoints/izumiMonitor';
import placeholder from '../../assets/placeholder.png';
import { Loading, LoadingEnum } from '../components/Loading';
import EmptyBlock from './components/EmptyBlock';
import { useCustomToast, ToastType } from '../../components/Toast/Toast';

const SearchBlock: React.FC = () => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { t } = useTranslation();
    const bg1 = colorTheme('#EBEAEC', 'tertiary.700');
    const bg2 = colorTheme('#F5F5F5', 'tertiary.800');
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));

    const toast = useCustomToast();
    const [networkLoading, setNetworkLoading] = useState(false);

    const [nftID, setNftID] = useState('');
    const [dex, setDex] = useState(Dex.iZiSwap);
    const [networkID, setNetworkID] = useState(process.env.REACT_APP_ENV === 'production' ? ChainId.BSC : ChainId.BSCTestnet);

    const [entry, setEntry] = useState({} as LiquidityInfo);
    useEffect(() => {
        document.title = t('Search') + ' | iZUMi.finance';
    }, [t]);
    return (
        <Stack spacing="23px">
            <HStack spacing="0px">
                <Text className={i_text_copy} w="80px">
                    NFT ID
                </Text>
                <Input
                    w="310px"
                    h="40px"
                    variant="filled"
                    placeholder={'NFT ID'}
                    size="lg"
                    className={i_text_copy_bold}
                    fontSize="12px"
                    type="number"
                    onChange={(e: any) => {
                        setNftID(e.target.value);
                    }}
                />
            </HStack>
            <HStack>
                <FormControl display="flex" alignItems="center" w="unset">
                    <FormLabel w="80px" className={i_text_copy} textAlign="start" fontSize="12px" fontWeight="500" m="0px">
                        {t('DEX')}
                    </FormLabel>
                    <Menu>
                        {() => (
                            <>
                                <MenuButton
                                    as={Button}
                                    rightIcon={<ChevronDownIcon boxSize="24px" m="9px" background={bg1} borderRadius="5px" />}
                                    className={i_text_d}
                                    fontSize="13px"
                                    style={{
                                        height: '40px',
                                        width: '200px',
                                        padding: '0',
                                        background: { bg2 } as unknown as string,
                                        borderRadius: '6px',
                                    }}
                                    leftIcon={
                                        <Image
                                            src={'/assets/dexIcon/' + dex + '.svg'}
                                            boxSize="24px"
                                            ml="17px"
                                            mr="7px"
                                            fallbackSrc={placeholder}
                                            borderRadius="4px"
                                        />
                                    }
                                >
                                    {dex}
                                </MenuButton>
                                <MenuList className={i_text_copy}>
                                    <MenuItem
                                        as={Button}
                                        variant="light"
                                        size="md"
                                        leftIcon={
                                            <Image
                                                src="/assets/dexIcon/iziswap.svg"
                                                boxSize="20px"
                                                mr="10px"
                                                fallbackSrc={placeholder}
                                                borderRadius="4px"
                                            />
                                        }
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-start',
                                            borderRadius: '4px',
                                            overflow: 'hidden',
                                            fontSize: '12px',
                                        }}
                                        onClick={() => setDex(Dex.iZiSwap)}
                                    >
                                        iZiSwap
                                    </MenuItem>
                                    <MenuItem
                                        as={Button}
                                        variant="light"
                                        size="md"
                                        leftIcon={
                                            <Image
                                                src="/assets/dexIcon/UniswapV3.svg"
                                                boxSize="20px"
                                                mr="10px"
                                                fallbackSrc={placeholder}
                                                borderRadius="4px"
                                            />
                                        }
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-start',
                                            borderRadius: '4px',
                                            overflow: 'hidden',
                                            fontSize: '12px',
                                        }}
                                        onClick={() => setDex(Dex.UniswapV3)}
                                    >
                                        {' '}
                                        UniSwap{' '}
                                    </MenuItem>
                                    <MenuItem
                                        as={Button}
                                        variant="light"
                                        size="md"
                                        leftIcon={
                                            <Image
                                                src="/assets/dexIcon/PancakeSwap.svg"
                                                boxSize="20px"
                                                mr="10px"
                                                fallbackSrc={placeholder}
                                                borderRadius="4px"
                                            />
                                        }
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-start',
                                            borderRadius: '4px',
                                            overflow: 'hidden',
                                            fontSize: '12px',
                                        }}
                                        onClick={() => setDex(Dex.PancakeSwap)}
                                    >
                                        PancakeSwap{' '}
                                    </MenuItem>
                                </MenuList>
                            </>
                        )}
                    </Menu>
                </FormControl>
            </HStack>
            <HStack>
                <Flex className={i_text_copy} alignItems="center">
                    <Text w="80px">{t('Network')}</Text>
                    <NetworkSelect networkID={networkID} setNetworkID={setNetworkID} />
                </Flex>
            </HStack>
            {networkLoading ? (
                <Loading variant={LoadingEnum.purple} text="Loading..." py="30px" />
            ) : entry.lpid ? (
                <Accordion allowMultiple={true} allowToggle={true} w="100%" py="20px">
                    <PositionListEntry entry={entry}></PositionListEntry>
                </Accordion>
            ) : (
                <EmptyBlock></EmptyBlock>
            )}

            <HStack spacing="20px">
                <CustomButton
                    w="360px"
                    h="51px"
                    variant="purple"
                    className={i_text_copy_bold}
                    text="Search"
                    bg="#0166FF"
                    _hover={{ bg: '#5999fa' }}
                    _focus={{ bg: '#5999fa' }}
                    leftIconElement={<Image boxSize="14px" src="/assets/search/search.svg" mr="12px"></Image>}
                    _disabled={{ bg: '#0166FF', opacity: 0.3 }}
                    onClick={() => {
                        if (Number(nftID)) {
                            setNetworkLoading(true);
                            searchLiquidityInfo({
                                dex: dex,
                                chainId: networkID,
                                lpid: Number(nftID),
                            } as LiquidityRequest)
                                .then((r) => {
                                    setEntry(r.data.data);
                                })
                                .catch((e: Error) => {
                                    toast(ToastType.error, e.message);
                                })
                                .finally(() => {
                                    setNetworkLoading(false);
                                });
                        }
                    }}
                    disabled={!Number(nftID)}
                ></CustomButton>

                <CustomButton
                    w="360px"
                    h="51px"
                    variant="purple"
                    className={i_text_copy_bold}
                    text="Add To List"
                    rightIcon={<Image boxSize="11px" src="/assets/search/add.svg" ml="10px"></Image>}
                    _disabled={{ bg: '#7F4AFE', opacity: 0.3 }}
                    onClick={() => {
                        if (entry.lpid) {
                            toast(ToastType.info, 'Ongoing ...');
                            addLiquidity({ dex: entry.dex, chainId: entry.chainId, lpid: entry.lpid } as LiquidityRequest)
                                .then((r) => {
                                    dispatch.liquidity.addId(r.data.data.id);
                                    toast(ToastType.success, 'Added successfully');
                                })
                                .catch((e: Error) => {
                                    toast(ToastType.error, e.message);
                                });
                        }
                    }}
                    disabled={!entry.lpid}
                ></CustomButton>
            </HStack>
        </Stack>
    );
};
export default SearchBlock;
