import { createModel } from '@rematch/core';
import BigNumber from 'bignumber.js';
import { isAddress } from 'ethers/lib/utils';
import produce from 'immer';

import { Contract } from 'web3-eth-contract';

import { RootModel } from '../../..';
import { tokenAddr2Token, tokenSymbol2token } from '../../../../../config/tokens';
import { parallelCollect } from '../../../../../net/contractCall/parallel';
import { StateResponse } from '../../../../../types/abis/iZiSwap/Pool';
import { ChainId, FarmDynamicRangeiZiContractVersion, TokenSymbol } from '../../../../../types/mod';
import { decodeMethodResult, getMiningDynamicRangeiZiBoostContract, getiZiSwapPoolContract, getVeiZiContract, getMulticallContract, getMiningDynamicRangeTimestampiZiBoostContract } from '../../../../../utils/contractHelpers';
import { amount2Decimal } from '../../../../../utils/tokenMath';
import { MiningContractInfo, MiningDynamicRangeMetaInfo, OraclePrice, RewardInfos } from './miningDynamicRangeContractHelper';
import { getPositionPoolKey } from '../../../common/positionPoolHelper';
import { addIZIBoostAPR, findPoolEntryByPoolKey, getPoolAPR, getPoolAPRTimestamp, getTokenStatus, symbol2Decimal } from './funcs';
import { getLiquidityValue } from '../liquidity'
import { getChain } from '../../../../../config/chains';

import {
    FarmDynamicRangeState,
    FarmControl,
    PoolEntryState,
    InitPoolListMetaParams,
    MiningPoolData,
    MiningPoolUserData,
    InitPoolListDataParams,
    MiningPoolMeta,
    InitPositionParams,
    PositionEntry,
    PositionDetail,
    RefreshPoolListDataAndPositionParams,
    TokenStatusResponse
} from './types';
import { VEIZI_ADDRESS } from '../../../../../config/veizi/veiziContracts';
import { point2PriceDecimal, point2PriceUndecimal } from '../price';
import { getLiquidityManagerContract } from '../../../../../utils/contractFactory';
import { MULTICALL_ADDRESS } from '../../../../../config/multicall/multicallContracts';
import { LIQUIDITY_MANAGER_ADDRESS } from '../../../../../config/trade/tradeContracts';

export const farmDynamicRangeiZi = createModel<RootModel>()({
    state: {
        positionManagerContract: undefined,
        poolEntryList: [],
        farmView: {
            isFarmDataLoading: false,
            isUserDataLoading: false,
        },
        farmControl: {
            sortBy: undefined,
            stakedOnly: false,
            searchKey: undefined,
            type: 'live',
        }
    } as FarmDynamicRangeState,
    reducers: {
        setFarmState: (state: FarmDynamicRangeState, payload: FarmDynamicRangeState) => {
            return { ...state, ...payload };
        },
        setFarmControl: (state: FarmDynamicRangeState, farmControl: FarmControl) => produce(state, draft => {
            draft.farmControl = { ...farmControl };
        }),
        setPoolEntryList: (state: FarmDynamicRangeState, payload: PoolEntryState[]) => produce(state, draft => {
            // void freeze object when initPoolListByMeta set meta state first
            draft.poolEntryList = JSON.parse(JSON.stringify(payload));
        }),
        setPoolEntryListMeta: (state: FarmDynamicRangeState, { chainId, poolEntryList }: { chainId: ChainId, poolEntryList: PoolEntryState[] }) => {
            return { ...state, poolEntryList, currentFarmChainId: chainId };
        },
        setPoolEntryListData: (state: FarmDynamicRangeState, payload: PoolEntryState[]) => produce(state, draft => {
            for (const poolEntry of payload) {
                const draftPoolEntry = findPoolEntryByPoolKey(draft.poolEntryList, poolEntry.meta.positionPoolKey);
                draftPoolEntry.data = poolEntry.data;
            }
        }),
        setPositionData: (state: FarmDynamicRangeState, payload: PoolEntryState[]) => produce(state, draft => {
            const positionPoolKeySet = new Set();
            for (const poolEntry of payload) {
                const draftPoolEntry = findPoolEntryByPoolKey(draft.poolEntryList, poolEntry.meta.positionPoolKey);
                draftPoolEntry.userData = poolEntry.userData;
                draftPoolEntry.positionList = poolEntry.positionList;
                draftPoolEntry.stakedPositionList = poolEntry.stakedPositionList;

                positionPoolKeySet.add(poolEntry.meta.positionPoolKey);
            }
            // clean other
            draft.poolEntryList.filter(p => !positionPoolKeySet.has(p.meta.positionPoolKey)).forEach(p => {
                if (p.meta.twoRewards) {
                    p.userData.earned = ['0', '0'];
                } else {
                    p.userData.earned = ['0'];
                }
                p.positionList = [];
                p.stakedPositionList = [];
            });
        }),
        setFarmDataLoading: (state: FarmDynamicRangeState, isLoading: boolean) => produce(state, draft => {
            draft.farmView.isFarmDataLoading = isLoading;
        }),
        setUserDataLoading: (state: FarmDynamicRangeState, isLoading: boolean) => produce(state, draft => {
            draft.farmView.isUserDataLoading = isLoading;
        }),
        togglePoolMetaInitialToggle: (state: FarmDynamicRangeState, positionPoolKey: string) => produce(state, draft => {
            const draftPoolEntry = findPoolEntryByPoolKey(draft.poolEntryList, positionPoolKey);
            draftPoolEntry.meta.initialToggle = !draftPoolEntry.meta.initialToggle;
        }),
    },
    effects: (dispatch) => ({

        async initPoolListMeta(initPoolListMetaParams: InitPoolListMetaParams): Promise<void> {
            const { chainId, metaList } = initPoolListMetaParams;
            if (!chainId) { return; }
            const startTime = new Date();

            const poolEntryList = [] as PoolEntryState[];
            // TODO filter
            // TODO contract multicall or web3 batch request or parallel
            for (const configMeta of metaList ?? []) {
                const data = {} as MiningPoolData;
                const contractVersion = configMeta.contractVersion ?? FarmDynamicRangeiZiContractVersion.V1;
                const additionalKey = configMeta.additionalKey ?? '01';
                const positionPoolKey = getPositionPoolKey(configMeta.tokenA.address, configMeta.tokenB.address, configMeta.feeTier, contractVersion, additionalKey);
                const useTimestamp = configMeta.useTimestamp ?? false

                const leftRangeRatio = configMeta.priceRangeRatio ?? configMeta.leftRangeRatio
                const rightRangeRatio = configMeta.priceRangeRatio ?? configMeta.rightRangeRatio

                if (configMeta.priceRangeRatio) {
                    delete configMeta.priceRangeRatio
                }

                const meta = { ...configMeta, positionPoolKey, contractVersion, useTimestamp, leftRangeRatio, rightRangeRatio } as MiningPoolMeta;

                const userData = {} as MiningPoolUserData;
                if (meta.twoRewards) {
                    userData.earned = ['0', '0'];
                } else {
                    userData.earned = ['0'];
                }
                const poolEntryState = { meta, data, userData } as PoolEntryState;
                poolEntryList.push(poolEntryState);
            }
            dispatch.farmDynamicRangeiZi.setPoolEntryListMeta({ chainId, poolEntryList });
            // sync init meta data for render basic view
            console.log(`initPoolListMeta end, ${(new Date()).getTime() - startTime.getTime()} ms`);
        },
        async initPoolListData(initPoolListDataParams: InitPoolListDataParams, rootState): Promise<void> {
            const { chainId, web3, positionManagerContract } = initPoolListDataParams;
            if (!chainId || !web3 || !positionManagerContract) { return; }
            if (rootState.farmDynamicRangeiZi.poolEntryList.length === 0) { return; }

            const blockDeltaU = getChain(chainId)?.blockDeltaU ?? 10;

            const poolEntryDataList = [] as PoolEntryState[];
            const asyncProcessList: Promise<void>[] = [];

            const baseCalling = [] as string[]
            const baseCallingAddress = [] as string[]
            const allMiningContracts = [] as Contract[]
            const allSwapPoolContracts = [] as Contract[]

            const baseCallingStart = [] as number[]

            for (let poolEntryIdx = 0; poolEntryIdx < rootState.farmDynamicRangeiZi.poolEntryList.length; poolEntryIdx ++) {
                baseCallingStart.push(baseCalling.length)
                const poolEntry = rootState.farmDynamicRangeiZi.poolEntryList[poolEntryIdx]
                const miningContract = poolEntry.meta.useTimestamp? getMiningDynamicRangeTimestampiZiBoostContract(poolEntry.meta.miningContract, web3, poolEntry.meta.contractVersion) : getMiningDynamicRangeiZiBoostContract(poolEntry.meta.miningContract, web3, poolEntry.meta.contractVersion)
                allMiningContracts.push(miningContract as Contract)
                const swapPoolContract = getiZiSwapPoolContract(poolEntry.meta.iZiSwapAddress, web3)
                allSwapPoolContracts.push(swapPoolContract as Contract)
                
                const metaContractInfoCalling = miningContract?.methods.getMiningContractInfo().encodeABI()
                baseCalling.push(metaContractInfoCalling)
                baseCallingAddress.push(poolEntry.meta.miningContract)

                const oraclePriceCalling = miningContract.methods.getOraclePrice().encodeABI()
                baseCalling.push(oraclePriceCalling)
                baseCallingAddress.push(poolEntry.meta.miningContract)

                const pointRangeLeftCalling = miningContract.methods.pointRangeLeft().encodeABI()
                baseCalling.push(pointRangeLeftCalling)
                baseCallingAddress.push(poolEntry.meta.miningContract)

                const pointRangeRightCalling = miningContract.methods.pointRangeRight().encodeABI()
                baseCalling.push(pointRangeRightCalling)
                baseCallingAddress.push(poolEntry.meta.miningContract)

                const stateCalling = swapPoolContract?.methods.state().encodeABI()
                baseCalling.push(stateCalling)
                baseCallingAddress.push(poolEntry.meta.iZiSwapAddress)

                const rewardLen = poolEntry.meta.twoRewards ? 2 : 1
                for (let rewardIdx = 0; rewardIdx < rewardLen; rewardIdx ++) {
                    const rewardInfoCalling = miningContract.methods.rewardInfos(rewardIdx).encodeABI()
                    baseCalling.push(rewardInfoCalling)
                    baseCallingAddress.push(poolEntry.meta.miningContract)
                }
            }

            const multicallContract = getMulticallContract(MULTICALL_ADDRESS[chainId], web3)
            const baseCallingResult = await multicallContract.methods.multicall(baseCallingAddress, baseCalling).call()

            for (let poolEntryIdx = 0; poolEntryIdx < rootState.farmDynamicRangeiZi.poolEntryList.length; poolEntryIdx ++) {
                const poolEntry = rootState.farmDynamicRangeiZi.poolEntryList[poolEntryIdx]
                const { meta } = poolEntry;
                const data = {} as MiningPoolData;
                const poolEntryData = {
                    meta: { positionPoolKey: meta.positionPoolKey } as MiningPoolMeta,
                    data
                } as PoolEntryState;

                poolEntryDataList.push(poolEntryData);

                const asyncProcess = async () => {
                    // 1. get mining contract info
                    const miningContract = allMiningContracts[poolEntryIdx]
                    const swapPoolContract = allSwapPoolContracts[poolEntryIdx]
                    const callingStart = baseCallingStart[poolEntryIdx]
                    const miningContractInfo: MiningContractInfo = decodeMethodResult(miningContract, 'getMiningContractInfo', baseCallingResult.results[callingStart])
                    const oraclePrice: OraclePrice = decodeMethodResult(miningContract, 'getOraclePrice', baseCallingResult.results[callingStart + 1])
                    const pointRangeLeft: number = Number(decodeMethodResult(miningContract, 'pointRangeLeft', baseCallingResult.results[callingStart + 2]))
                    const pointRangeRight: number = Number(decodeMethodResult(miningContract, 'pointRangeRight', baseCallingResult.results[callingStart + 3]))
                    const state: StateResponse = decodeMethodResult(swapPoolContract, 'state', baseCallingResult.results[callingStart + 4])

                    const rewardLen = poolEntry.meta.twoRewards ? 2 : 1
                    const rewardInfos = [] as RewardInfos[]
                    for (let rewardIdx = 0; rewardIdx < rewardLen; rewardIdx ++) {
                        const rewardInfo = decodeMethodResult(miningContract, 'rewardInfos', baseCallingResult.results[callingStart + 5 + rewardIdx])
                        rewardInfos.push({...rewardInfo, rewardToken: tokenAddr2Token(rewardInfo.rewardToken, chainId)});
                    }
                    const miningDynamicRangeMetaInfo: MiningDynamicRangeMetaInfo = {
                        miningContractInfo: miningContractInfo,
                        rewardInfos,
                        oraclePrice,
                        pointRangeLeft,
                        pointRangeRight
                    }

                    // 3. get swap and reward token price and set price data
                    const tokenPriceAB = await parallelCollect(
                        dispatch.token.fetchTokenPriceIfMissing(meta.tokenA),
                        dispatch.token.fetchTokenPriceIfMissing(meta.tokenB),
                        dispatch.token.fetchTokenPriceIfMissing(tokenSymbol2token(TokenSymbol.IZI, chainId))
                    );
                    const tokenPriceB: number = tokenPriceAB[1];
                    const tokenPriceA: number = tokenPriceAB[0];
                    const tokenPriceiZi: number = tokenPriceAB[2];

                    if (!meta.useTimestamp) {
                        data.isEnded = Number(miningDynamicRangeMetaInfo.miningContractInfo.endBlock_) < Number(rootState.block.currentBlock);
                        data.endBlock = Number(miningDynamicRangeMetaInfo.miningContractInfo.endBlock_);
                        data.secondsLeft = data.isEnded? 0: (Number(miningDynamicRangeMetaInfo.miningContractInfo.endBlock_) - Number(rootState.block.currentBlock)) * blockDeltaU;
                    } else {
                        const currentTime = new Date().getTime() / 1000
                        data.isEnded = Number(miningDynamicRangeMetaInfo.miningContractInfo.endTime_) < currentTime
                        data.endTime = Number(miningDynamicRangeMetaInfo.miningContractInfo.endTime_)
                        data.secondsLeft = data.isEnded? 0: Number(miningDynamicRangeMetaInfo.miningContractInfo.endTime_) - currentTime
                    }

                    data.tokenPriceIZIDecimal = await dispatch.token.fetchTokenPriceIfMissing(tokenSymbol2token(TokenSymbol.IZI, chainId));

                    data.tokenAPriceDecimal = tokenPriceA;
                    data.tokenBPriceDecimal = tokenPriceB;

                    data.iZiPriceDecimal = data.tokenPriceIZIDecimal;

                    const tokenAAmount = new BigNumber(miningDynamicRangeMetaInfo.miningContractInfo.totalTokenX_);
                    const tokenBAmount = new BigNumber(miningDynamicRangeMetaInfo.miningContractInfo.totalTokenY_);

                    data.tokenAAmountDecimal = amount2Decimal(tokenAAmount, meta.tokenA) ?? 0;
                    data.tokenBAmountDecimal = amount2Decimal(tokenBAmount, meta.tokenB) ?? 0;

                    data.vLiquidity = Number(miningDynamicRangeMetaInfo.miningContractInfo.totalVLiquidity_);
                    data.totalNIZI = Number(miningDynamicRangeMetaInfo.miningContractInfo.totalNIZI_ ?? 0);
                    data.totalValidVeiZi = Number(miningDynamicRangeMetaInfo.miningContractInfo.totalValidVeiZi_ ?? 0);

                    data.tokenAWorth = data.tokenAAmountDecimal * data.tokenAPriceDecimal;
                    data.tokenBWorth = data.tokenBAmountDecimal * data.tokenBPriceDecimal;

                    data.capital = data.tokenAWorth + data.tokenBWorth;
                    data.tvl = data.capital + (amount2Decimal(new BigNumber(data.totalNIZI), tokenSymbol2token(TokenSymbol.IZI, chainId)) ?? 0) * tokenPriceiZi;

                    let apr = 0;
                    data.reward = [];
                    data.rewardTokens = [];
                    data.rewardTokenPrice = [];
                    for (const rewardInfo of miningDynamicRangeMetaInfo.rewardInfos) {
                        const rewardToken = rewardInfo.rewardToken;

                        const rewardTokenPrice = await dispatch.token.fetchTokenPriceIfMissing(rewardToken);
                        if (data.capital > 0) {
                            apr += meta.useTimestamp ? getPoolAPRTimestamp(rewardInfo.rewardPerSecond as string, chainId, data.capital, { left: rewardToken.symbol, right: rewardTokenPrice }) : getPoolAPR(rewardInfo.rewardPerBlock as string, chainId, data.capital, { left: rewardToken.symbol, right: rewardTokenPrice });
                        }
                        const rewardPerTime = rewardInfo.rewardPerBlock ?? rewardInfo.rewardPerSecond
                        const rewardDecimal = (amount2Decimal(
                            new BigNumber(rewardPerTime as string),
                            rewardToken
                        ) ?? 0);
                        data.reward.push([rewardToken, Number(rewardDecimal)]);
                        data.rewardTokens.push(rewardToken);
                        data.rewardTokenPrice.push(rewardTokenPrice);
                    }

                    if (meta.iZiBoost || meta.veiZiBoost) {
                        data.apr=[apr/2.5, apr];
                    } else{
                        data.apr = [apr];
                    }

                    // 4. set position data
                    data.positionPoolContract = meta.iZiSwapAddress;
                    data.positionSqrtPriceX96 = state.sqrtPrice_96;
                    data.positionTick = Number(state.currentPoint);
                    data.currentTick = Number(state.currentPoint);
                    data.currentLiquidity = state.liquidity
                    data.currentLiquidityX = state.liquidityX
                    
                    data.priceAByBDecimal = point2PriceDecimal(meta.tokenA, meta.tokenB, data.currentTick)
                    data.priceAByB = Number(point2PriceUndecimal(meta.tokenA, meta.tokenB, data.currentTick))


                    const oracleTick = Number(miningDynamicRangeMetaInfo.oraclePrice.avgPoint);
                    data.oracleTick = oracleTick;
                    
                    data.tickLeft = oracleTick - miningDynamicRangeMetaInfo.pointRangeLeft;
                    data.tickRight = oracleTick + miningDynamicRangeMetaInfo.pointRangeRight;

                    data.oraclePriceAByB = Number(point2PriceUndecimal(meta.tokenA, meta.tokenB, oracleTick))
                    data.oraclePriceAByBDecimal = point2PriceDecimal(meta.tokenA, meta.tokenB, oracleTick)

                };
                asyncProcessList.push(asyncProcess());
            }
            await Promise.all(asyncProcessList);

            dispatch.farmDynamicRangeiZi.setPoolEntryListData(poolEntryDataList);

        },
        async initPoolList(initPoolListParams: InitPoolListMetaParams & InitPoolListDataParams): Promise<void> {
            await dispatch.farmDynamicRangeiZi.initPoolListMeta(initPoolListParams);
            await dispatch.farmDynamicRangeiZi.initPoolListData(initPoolListParams);
        },
        async initPosition(initPositionParams: InitPositionParams, rootState): Promise<void> {

            const { chainId, web3, positionManagerContract, account } = initPositionParams;

            if (!chainId || !web3 || !account || !positionManagerContract || !rootState.farmDynamicRangeiZi.poolEntryList) { return; }
            if (rootState.farmDynamicRangeiZi.poolEntryList.length === 0) { return; }

            /// check data consistency
            const positionManagerContractExpected = getLiquidityManagerContract(chainId, web3);
            if (
                !positionManagerContractExpected ||
                positionManagerContractExpected.options.address !== positionManagerContract.options.address
            ) { return; }

            const baseCalling = [] as string[]
            const baseCallingAddress = [] as string[]
            const allMiningContracts = [] as Contract[]

            for (let poolEntryIdx = 0; poolEntryIdx < rootState.farmDynamicRangeiZi.poolEntryList.length; poolEntryIdx ++) {
                const poolEntry = rootState.farmDynamicRangeiZi.poolEntryList[poolEntryIdx]

                const miningContract = poolEntry.meta.useTimestamp ? 
                    getMiningDynamicRangeTimestampiZiBoostContract(poolEntry.meta.miningContract, web3, poolEntry.meta.contractVersion)
                    : getMiningDynamicRangeiZiBoostContract(poolEntry.meta.miningContract, web3, poolEntry.meta.contractVersion)
                allMiningContracts.push(miningContract as Contract)

                const pendingRewardsCalling = miningContract?.methods.pendingRewards(account).encodeABI()
                baseCalling.push(pendingRewardsCalling)
                baseCallingAddress.push(poolEntry.meta.miningContract)

                const tokenIdsCalling = miningContract?.methods.getTokenIds(account).encodeABI()
                baseCalling.push(tokenIdsCalling)
                baseCallingAddress.push(poolEntry.meta.miningContract)
            }

            const veiZiAddress = VEIZI_ADDRESS[chainId];
            const veiZiContract = getVeiZiContract(veiZiAddress, web3);
            if (veiZiContract) {
                const stakingInfoCalling = veiZiContract?.methods.stakingInfo(account).encodeABI()
                baseCalling.push(stakingInfoCalling)
                baseCallingAddress.push(veiZiAddress)
            }

            const multicallContract = getMulticallContract(MULTICALL_ADDRESS[chainId], web3)
            const baseCallingResult = await multicallContract.methods.multicall(baseCallingAddress, baseCalling).call()

            const stakingInfo = veiZiContract ? decodeMethodResult(veiZiContract, 'stakingInfo', baseCallingResult.results[baseCallingResult.length - 1]) : undefined

            const veiZi = Number(stakingInfo?.amount ?? 0);
            const veiZiNftId = stakingInfo?.nftId ?? '0';

            const veiZiDecimal = amount2Decimal(new BigNumber(stakingInfo?.amount ?? 0), tokenSymbol2token(TokenSymbol.IZI, chainId)) ?? 0;

            const allPoolEarnedList = [] as string[][]
            const allPoolTokenIds = [] as string[][]

            const tokenId2Idx = new Map<string, number>()
            const tokenId2PoolIdx = new Map<string, number>()
            const allTokenId = [] as string[]

            for (let poolEntryIdx = 0; poolEntryIdx < rootState.farmDynamicRangeiZi.poolEntryList.length; poolEntryIdx ++) {
                const miningContract = allMiningContracts[poolEntryIdx]
                const poolEarnedList = decodeMethodResult(miningContract, 'pendingRewards', baseCallingResult.results[poolEntryIdx * 2])
                allPoolEarnedList.push(poolEarnedList)
                const tokenIds = decodeMethodResult(miningContract, 'getTokenIds', baseCallingResult.results[poolEntryIdx * 2 + 1])
                allPoolTokenIds.push(tokenIds)
                for (const tokenId of tokenIds) {
                    tokenId2Idx.set(tokenId, allTokenId.length)
                    tokenId2PoolIdx.set(tokenId, poolEntryIdx)
                    allTokenId.push(tokenId)
                }
            }
            
            const secondCalling = [] as string[]
            const secondCallingAddress = [] as string[]

            for (const tokenId of allTokenId) {
                const poolIdx = tokenId2PoolIdx.get(tokenId) as number
                const miningContract = allMiningContracts[poolIdx]
                const tokenStatusCalling = miningContract.methods.tokenStatus(tokenId).encodeABI()
                secondCalling.push(tokenStatusCalling)
                const poolEntry = rootState.farmDynamicRangeiZi.poolEntryList[poolIdx]
                secondCallingAddress.push(poolEntry.meta.miningContract)
            }

            for (const tokenId of allTokenId) {
                const liquiditiesCalling = positionManagerContract.methods.liquidities(tokenId).encodeABI()
                secondCalling.push(liquiditiesCalling)
                secondCallingAddress.push(LIQUIDITY_MANAGER_ADDRESS[chainId])
            }

            const tokenId2PendingRewardSecondCallingIdx = new Map<string, number>()
            const tokenId2TokenStatusSecondCallingIdx = new Map<string, number>()

            for (const tokenId of allTokenId) {
                const poolIdx = tokenId2PoolIdx.get(tokenId) as number
                const miningContract = allMiningContracts[poolIdx]
                const poolEntry = rootState.farmDynamicRangeiZi.poolEntryList[poolIdx]
                if (poolEntry.meta.contractVersion === FarmDynamicRangeiZiContractVersion.V1) {
                    const pendingRewardCalling = miningContract.methods.pendingReward(tokenId).encodeABI()
                    tokenId2PendingRewardSecondCallingIdx.set(tokenId, secondCalling.length)
                    secondCalling.push(pendingRewardCalling)
                    secondCallingAddress.push(poolEntry.meta.miningContract)

                    const tokenStatusCalling = miningContract.methods.tokenStatus(tokenId).encodeABI()
                    tokenId2TokenStatusSecondCallingIdx.set(tokenId, secondCalling.length)
                    secondCalling.push(tokenStatusCalling)
                    secondCallingAddress.push(poolEntry.meta.miningContract)

                }
            }
            const secondCallingResult = await multicallContract.methods.multicall(secondCallingAddress, secondCalling).call()

            const stakedTokenIdList: string[] = [];
            // const stakedTokenStatusList: TokenStatusResponse[] = [];

            type miningPoolValueType = { tokenIdSet: Set<string>, address: string, poolEntry: PoolEntryState }
            const miningPoolData = {} as { [index: string]: miningPoolValueType };


            for (let poolEntryIdx = 0; poolEntryIdx < rootState.farmDynamicRangeiZi.poolEntryList.length; poolEntryIdx ++) {
                const poolEntryState = rootState.farmDynamicRangeiZi.poolEntryList[poolEntryIdx]

                const positionPoolKey = poolEntryState.meta.positionPoolKey;

                // const miningContract = allMiningContracts[poolEntryIdx]

                const poolEarnedList = allPoolEarnedList[poolEntryIdx]

                const tokenIds = allPoolTokenIds[poolEntryIdx]

                const userData = {} as MiningPoolUserData;
                userData.earned = poolEarnedList;
                userData.veiZi = veiZi;
                userData.veiZiNftId = veiZiNftId;
                userData.veiZiDecimal = veiZiDecimal;
                userData.vLiquidity = 0;
                userData.capital = 0;

                const twoRewards = poolEntryState.meta.twoRewards;
                const contractVersion = poolEntryState.meta.contractVersion;

                const poolEntry = {
                    meta: { positionPoolKey, twoRewards, contractVersion } as MiningPoolMeta,
                    userData: userData,
                    positionList: [] as PositionEntry[],
                    stakedPositionList: [] as PositionEntry[],
                } as PoolEntryState;
                // const tokenStatus: TokenStatusResponse[] = await getTokenStatus(miningContract, tokenIds);

                miningPoolData[poolEntryState.meta.miningContract] = { tokenIdSet: new Set(tokenIds), address: poolEntryState.meta.miningContract, poolEntry };

                stakedTokenIdList.push(...tokenIds);
                // stakedTokenStatusList.push(...tokenStatus);

            }

            // all positions are staked position !!!!!
            // const allTokenStatus = [...stakedTokenStatusList];
            const positionResult: string[] = secondCallingResult.results.slice(allTokenId.length, allTokenId.length * 2)
            const positions: PositionDetail[] = positionResult.map((p, i) => {
                const position: PositionDetail = decodeMethodResult(positionManagerContract, 'liquidities', p);
                position.tokenId = allTokenId[i];
                return position;
            });

            const stakedPositionList = positions;

            // get pendingReward for each stakedPosition
            for (const stakedPosition of stakedPositionList) {
                const stakedTokenId = stakedPosition.tokenId;
                const miningPool = Object.values(miningPoolData).filter(d => d.tokenIdSet.has(stakedTokenId))[0];
                if (miningPool.poolEntry.meta.contractVersion === FarmDynamicRangeiZiContractVersion.V1) {
                    const pendingRewardCallingIdx = tokenId2PendingRewardSecondCallingIdx.get(stakedTokenId) as number
                    const poolIdx = tokenId2PoolIdx.get(stakedTokenId) as number
                    const miningContract = allMiningContracts[poolIdx]
                    stakedPosition.earned = decodeMethodResult(miningContract, 'pendingReward', secondCallingResult.results[pendingRewardCallingIdx])

                    const tokenStatusCallingIdx = tokenId2TokenStatusSecondCallingIdx.get(stakedTokenId) as number
                    stakedPosition.tokenStatus = decodeMethodResult(miningContract, 'tokenStatus', secondCallingResult.results[tokenStatusCallingIdx])
                }
            }
            
            const tokeniZi = tokenSymbol2token(TokenSymbol.IZI, chainId)

            // 8. pure function calculate data
            for (const position of positions) {

                // let miningPool: miningPoolValueType;
                const miningPool = Object.values(miningPoolData).find(d => d.tokenIdSet.has(position.tokenId.toString())) as miningPoolValueType;

                const { meta, data } = rootState.farmDynamicRangeiZi.poolEntryList.find(p => p.meta.miningContract === miningPool.address) as PoolEntryState;
                const poolEntry = miningPool.poolEntry;
                const [tokenPriceA, tokenPriceB, tokeniZiPrice] = await parallelCollect(
                    dispatch.token.fetchTokenPriceIfMissing(meta.tokenA),
                    dispatch.token.fetchTokenPriceIfMissing(meta.tokenB),
                    dispatch.token.fetchTokenPriceIfMissing(tokeniZi)
                );
                // let amountLock = 0;
                const positionEntry = { nftId: position.tokenId, isStaked: true } as PositionEntry;

                positionEntry.vLiquidity = Number(position.tokenStatus.vLiquidity);
                const tokenAAmount = new BigNumber(position.tokenStatus.amountX);
                const tokenBAmount = new BigNumber(position.tokenStatus.amountY);
                positionEntry.tokenAAmountDecimal = amount2Decimal(tokenAAmount , meta.tokenA) ?? 0;
                positionEntry.tokenBAmountDecimal = amount2Decimal(tokenBAmount , meta.tokenB) ?? 0;

                positionEntry.tokenAWorth = positionEntry.tokenAAmountDecimal * tokenPriceA;
                positionEntry.tokenBWorth = positionEntry.tokenBAmountDecimal * tokenPriceB;

                positionEntry.capital = positionEntry.tokenAWorth + positionEntry.tokenBWorth;
                positionEntry.tvl = positionEntry.capital

                if (meta.iZiBoost) {
                    const tokeniZiAmount = new BigNumber(position.tokenStatus.nIZI ?? '0')
                    const tokeniZiAmountDecimal = amount2Decimal(tokeniZiAmount, tokeniZi) ?? 0
                    const tokeniZiWorth = tokeniZiAmountDecimal * tokeniZiPrice
                    positionEntry.tvl += tokeniZiWorth
                }

                positionEntry.liquidity = position.liquidity;
                positionEntry.tickLower = position.leftPt;
                positionEntry.tickUpper = position.rightPt;

                positionEntry.minPrice = Math.pow(1.0001, Number(position.leftPt));
                positionEntry.maxPrice = Math.pow(1.0001, Number(position.rightPt));
                const tokenADecimal: number = symbol2Decimal(chainId, meta.tokenA.symbol) ?? 0;
                const tokenBDecimal: number = symbol2Decimal(chainId, meta.tokenB.symbol) ?? 0;

                positionEntry.minPriceDecimal = positionEntry.minPrice * ((10 ** tokenADecimal) / (10 ** tokenBDecimal));
                positionEntry.maxPriceDecimal = positionEntry.maxPrice * ((10 ** tokenADecimal) / (10 ** tokenBDecimal));

                positionEntry.earnedDecimal = [];
                positionEntry.earnedWorth = [];

                positionEntry.earned = [];
                if (miningPool.poolEntry.meta.contractVersion === FarmDynamicRangeiZiContractVersion.V1) {
                    for (const idx in data.rewardTokens) {
                        const rewardToken = data.rewardTokens[idx];
                        const earnedDecimal = amount2Decimal(new BigNumber(position.earned[idx]), rewardToken) ?? 0;
                        positionEntry.earned.push(Number(position.earned[idx]));
                        positionEntry.earnedDecimal.push(earnedDecimal);
                        const rewardTokenPrice = data.rewardTokenPrice[idx];
                        const earnedWorth: number = rewardTokenPrice * earnedDecimal;
                        positionEntry.earnedWorth.push(earnedWorth);
                    }
                }

                positionEntry.niZi = Number(position.tokenStatus.nIZI ?? 0);
                positionEntry.niZiDecimal = amount2Decimal(new BigNumber(position.tokenStatus.nIZI ?? 0), tokenSymbol2token(TokenSymbol.IZI, chainId)) ?? 0;
                positionEntry.iZiWorth = positionEntry.niZiDecimal * data.iZiPriceDecimal;

                // veiZi version has no apr for each nft
                if (positionEntry.vLiquidity > 0 && meta.contractVersion === FarmDynamicRangeiZiContractVersion.V1) {
                    positionEntry.apr = addIZIBoostAPR({data, meta} as PoolEntryState, chainId, positionEntry.vLiquidity, positionEntry.capital, positionEntry.niZi, 0);
                    // todo: more fine calculate
                    // if (positionEntry.apr < data.apr[0]) {
                    //     positionEntry.apr = data.apr[0];
                    // }
                } else {
                    positionEntry.apr = 0;
                }

                const {amountX: amountAInUniswap, amountY: amountBInUniswap} = getLiquidityValue(position.liquidity, Number(position.leftPt), Number(position.rightPt), data.currentTick, data.currentLiquidity, data.currentLiquidityX);
                positionEntry.amountAInUniswapDecimal = amount2Decimal(new BigNumber(amountAInUniswap), meta.tokenA) ?? 0;
                positionEntry.amountBInUniswapDecimal = amount2Decimal(new BigNumber(amountBInUniswap), meta.tokenB) ?? 0;
                positionEntry.worthAInUniswap = positionEntry.amountAInUniswapDecimal * tokenPriceA;
                positionEntry.worthBInUniswap = positionEntry.amountBInUniswapDecimal * tokenPriceB;


                // const [fee0InUniswap, fee1InUniswap] = get
                poolEntry.stakedPositionList.push(positionEntry);
                poolEntry.userData.capital += positionEntry.capital;
                poolEntry.userData.vLiquidity += positionEntry.vLiquidity;

            }

            // 9. miningContract is approved
            const miningContractList = Object.keys(miningPoolData);
            // const isApprovedMulticallData = miningContractList.map(miningContract => positionManagerContract.methods.isApprovedForAll(account, miningContract).encodeABI());
            // const isApprovedResult: boolean[] = await positionManagerContract.methods.multicall(isApprovedMulticallData).call()
            //     .then((isApprovedList: string[]) => isApprovedList.map(isApproved => Number(isApproved) !== 0));
            for (const i in miningContractList) {
                // miningPoolData[miningContractList[i]].poolEntry.userData.isApprovedForAll = isApprovedResult[i];
                const { meta, data } = rootState.farmDynamicRangeiZi.poolEntryList.find(p => p.meta.miningContract === miningContractList[i]) as PoolEntryState;
                if (meta.contractVersion === FarmDynamicRangeiZiContractVersion.VEIZI) {
                    // todo veizi dynamic range izi swap
                    // const miningDynamicRangeBoostVeiZi = getMiningDynamicRangeVeiZiContract(miningContractList[i], web3);
                    // const userStatus = await miningDynamicRangeBoostVeiZi?.methods.userStatus(account).call();
                    // const userData = miningPoolData[miningContractList[i]].poolEntry.userData;
                    // userData.vLiquidity = Number(userStatus.vLiquidity);
                    // userData.validVeiZi = Number(userStatus.validVeiZi);
                    // userData.apr = veiZiBoostAPR({data} as PoolEntryState, chainId, userData.vLiquidity, userData.capital, meta.veiZiBoost? userData.veiZi : 0);
                }
            }

            // TODO save positions and positionManagerContract address
            dispatch.farmDynamicRangeiZi.setPositionData(Object.values(miningPoolData).map(m => m.poolEntry));
        },
        async refreshPoolListDataAndPosition(refreshPoolListDataAndPositionParams: RefreshPoolListDataAndPositionParams): Promise<void> {
            await dispatch.farmDynamicRangeiZi.initPoolListData(refreshPoolListDataAndPositionParams as InitPoolListDataParams);
            await dispatch.farmDynamicRangeiZi.initPosition(refreshPoolListDataAndPositionParams as InitPositionParams);
        },
        async cleanPositionIfExist(account: string | null | undefined, rootState): Promise<void> {
            if (!isAddress(account as string)) { return; }
            if (!rootState.farmDynamicRangeiZi.poolEntryList.find(p => p.positionList?.length || p.stakedPositionList?.length)) { return; }
            // clean user data
            dispatch.farmDynamicRangeiZi.setPositionData([]);
            console.info('cleanPosition end');
        },
    })
});