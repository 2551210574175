import BigNumber from "bignumber.js"
import { TokenInfoFormatted } from "../../../../hooks/useTokenListFormatted"
import { getSwapTokenAddress } from "../../common/positionPoolHelper"

/**
 * transform decimal price to undecimal price
 * @param tokenA tokenA info in format of [[`TokenInfoFormatted`]], only decimal field is needed
 * @param tokenB tokenB info in format of [[`TokenInfoFormatted`]], only decimal field is needed
 * @param priceDecimalAByB decimal-price how much tokenB (decimal amount) for 1 tokenA
 * @returns undecimal price
 */
 export const priceDecimal2PriceUndecimal = (
    tokenA: TokenInfoFormatted,
    tokenB: TokenInfoFormatted,
    priceDecimalAByB: number) : BigNumber => {
    
    // priceDecimalAByB * amountADecimal = amountBDecimal
    // priceDecimalAByB * (amountA / 10^decimalA) = amountB / 10^decimalB
    // priceDecimalAByB / 10^decimalA * 10^decimalB * amountA = amountB

    return new BigNumber(priceDecimalAByB).times(10 ** tokenB.decimal).div(10 ** tokenA.decimal)
}

export const priceUndecimal2PriceDecimal = (
    tokenA: TokenInfoFormatted,
    tokenB: TokenInfoFormatted,
    priceUndecimalAByB: BigNumber): number => {
    // priceUndecimalAByB * amountA = amountB
    // priceUndecimalAByB * amountADecimal * 10^decimalA = amountBDecimal * 10^decimalB
    // priceUndecimalAByB * 10^decimalA / 10^decimalB * amountADecimal = amountBDecimal
    return Number(priceUndecimalAByB.times(10 ** tokenA.decimal).div(10 **tokenB.decimal))
}


export const point2PoolPriceUndecimalSqrt = (point: number) : number => {
    return (1.0001 ** point) ** 0.5;
}

export const swapTokenIsSmaller = (tokenA: TokenInfoFormatted, tokenB: TokenInfoFormatted) : boolean => {
    const tokenASwapToken = getSwapTokenAddress(tokenA)
    const tokenBSwapToken = getSwapTokenAddress(tokenB)
    return tokenASwapToken.toLowerCase() < tokenBSwapToken.toLowerCase()
}

export const point2PriceUndecimal = (
    tokenA: TokenInfoFormatted,
    tokenB: TokenInfoFormatted,
    point: number
): BigNumber => {
    // if (tokenA.address.toLowerCase() < tokenB.address.toLowerCase()) {
    if (swapTokenIsSmaller(tokenA, tokenB)) {
        return new BigNumber(1.0001 ** point)
    } else {
        return new BigNumber(1).div(1.0001 ** point)
    }
}

export const getTokenXYFromToken = (tokenA: TokenInfoFormatted, tokenB: TokenInfoFormatted): {tokenX: TokenInfoFormatted, tokenY: TokenInfoFormatted} => {
    // if (tokenA.address.toLowerCase() < tokenB.address.toLowerCase()) {
    
    if (swapTokenIsSmaller(tokenA, tokenB)) {
        return {
            tokenX: {...tokenA},
            tokenY: {...tokenB}
        }
    } else {
        return {
            tokenX: {...tokenB},
            tokenY: {...tokenA}
        }
    }
}

export const point2PriceDecimal = (
    tokenA: TokenInfoFormatted,
    tokenB: TokenInfoFormatted,
    point: number
): number => {
    let priceDecimal = 0;
    let needReverse = false;
    const {tokenX, tokenY} = getTokenXYFromToken(tokenA, tokenB)

    if (point > 0) {
        priceDecimal = priceUndecimal2PriceDecimal(tokenX, tokenY, new BigNumber(1.0001 ** point))
        needReverse = !swapTokenIsSmaller(tokenA, tokenB)
    } else {
        priceDecimal = priceUndecimal2PriceDecimal(tokenY, tokenX, new BigNumber(1.0001 ** (-point)))
        needReverse = swapTokenIsSmaller(tokenA, tokenB)
    }
    if (needReverse) {
        priceDecimal = 1 / priceDecimal
    }
    return priceDecimal
}
