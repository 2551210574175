import { ChainId } from '../../types/mod';
import chains from '../chains';

export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES = chains.all.reduce<Record<number, string>>((memo, chain) => {
    memo[chain.id] = '0xC36442b4a4522E871399CD717aBDD847Ab11FE88';
    if (chain.id === ChainId.BSCTestnet) {
        memo[chain.id] = '0x512f0d70d9b06E4Bd45fAD199eFfabCFd10325A9';
    }
    if (chain.id === ChainId.Goerli) {
        memo[chain.id] = '0xc36442b4a4522e871399cd717abdd847ab11fe88';
    }
    if (chain.id === ChainId.BSC) {
        delete memo[chain.id];
    }
    if (chain.id === ChainId.Aurora) {
        delete memo[chain.id];
    }
    return memo;
}, {});

export const UNIV3_SWAP_ROUTER = chains.all.reduce<Record<number, string>>((memo, chain) => {
    memo[chain.id] = '0xE592427A0AEce92De3Edee1F18E0157C05861564';
    if (chain.id === ChainId.BSCTestnet || chain.id === ChainId.BSC || chain.id === ChainId.Aurora || chain.id === ChainId.AuroraTestnet) {
        // todo deploy quoter v2 on bsc testnet
        delete memo[chain.id];
    }
    return memo;
}, {});

export const UNIV3_QUOTERV2 = chains.all.reduce<Record<number, string>>((memo, chain) => {
    memo[chain.id] = '0x61fFE014bA17989E743c5F6cB21bF9697530B21e';
    if (chain.id === ChainId.BSCTestnet || chain.id === ChainId.BSC || chain.id === ChainId.Aurora || chain.id === ChainId.AuroraTestnet) {
        // todo deploy quoter v2 on bsc testnet
        delete memo[chain.id];
    }
    return memo;
}, {});

export const FACTORY_ADDRESS: any = {
    [ChainId.EthereumMainnet]: '0x1f98431c8ad98523631ae4a59f267346ea31f984',
    [ChainId.Arbitrum]: '0x1f98431c8ad98523631ae4a59f267346ea31f984',
    [ChainId.Rinkeby]: '0x1f98431c8ad98523631ae4a59f267346ea31f984',
    [ChainId.Matic]: '0x1f98431c8ad98523631ae4a59f267346ea31f984',
    [ChainId.BSCTestnet]: '0x308934C923c3F7bc082AeE9818C033aFAf358510',
    [ChainId.Goerli]: '0x1F98431c8aD98523631AE4a59f267346ea31F984',
};
