import { BoxProps, Center, Text } from '@chakra-ui/react';
import Card from '../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { i_text_16_bold } from '../../../style';

const EmptyBlock: React.FC<BoxProps> = (props) => {
    const { ...rest } = props;
    return (
        <Card w="100%" h="114px" {...rest}>
            <Center w="100%" h="100%">
                <Text className={i_text_16_bold} color="#83789B">
                    Oh, there&apos;s nothing here !
                </Text>
            </Center>
        </Card>
    );
};
export default EmptyBlock;
