import BigNumber from "bignumber.js";
import { TokenInfoFormatted } from "../../../../hooks/useTokenListFormatted";
import { LimitOrdersResponse, PoolContract } from "../../../../types/abis/iZiSwap/Pool";
import { amount2Decimal } from "../../../../utils/tokenMath";
import { getSwapTokenAddress } from "../../common/positionPoolHelper";
import { point2PriceDecimal, pointDeltaRoundingDown, pointDeltaRoundingUp } from "../../farm/iZiSwap/price";
import { getAmountXNoRound, getAmountYNoRound, liquidity2AmountXAtPoint, liquidity2AmountYAtPoint } from "../../trade/liquidity/funcs";
import { priceDecimal2Point, PriceRoundingType } from "../../trade/utils/priceMath";
import { FetchOrderBookParams, OrderBookData } from "./types";

async function getLiquidityAndLimitOrderSnapshot(
    poolContract: PoolContract,
    rawLeftPoint: number,
    rawRightPoint: number,
    currentPoint: number,
    pointDelta: number,
    currentLiquidity: string
): Promise<{leftPoint: number, rightPoint: number, liquiditySnapshot: BigNumber[], limitOrderSnapshot: LimitOrdersResponse[]}> {

    const leftPoint = pointDeltaRoundingDown(rawLeftPoint, pointDelta)
    const rightPoint = pointDeltaRoundingUp(rawRightPoint, pointDelta)

    const liquidityDeltaSnapshotRaw = await poolContract.methods.liquiditySnapshot(leftPoint, rightPoint).call()

    const liquiditySnapshot = [new BigNumber(0)] as BigNumber[]

    for (let i = 1; i < liquidityDeltaSnapshotRaw.length; i ++) {
        const liquidityDelta = new BigNumber(liquidityDeltaSnapshotRaw[i])
        const lastLiquidity = liquiditySnapshot[i - 1]
        liquiditySnapshot.push(lastLiquidity.plus(liquidityDelta))
    }
    let liquidityOffset = undefined as unknown as BigNumber
    for (let i = 0; i < liquiditySnapshot.length; i ++) {
        const currentLeftPoint = leftPoint + i * pointDelta
        if (currentLeftPoint <= currentPoint && currentPoint < currentLeftPoint + pointDelta) {
            liquidityOffset = new BigNumber(currentLiquidity).minus(liquiditySnapshot[i])
            break;
        }
    }
    for (let i = 0; i < liquiditySnapshot.length; i ++) liquiditySnapshot[i] = liquiditySnapshot[i].plus(liquidityOffset)

    const limitOrderSnapshot = await poolContract.methods.limitOrderSnapshot(leftPoint, rightPoint).call()

    return { leftPoint, rightPoint, liquiditySnapshot, limitOrderSnapshot}
}

function getLimitOrderVolumn(
    leftPoint: number, 
    rightPoint: number, 
    lowPoint: number, 
    upPoint: number, 
    pointDelta: number,
    limitOrderSnapshot: LimitOrdersResponse[]
): {sellingX: BigNumber, sellingY: BigNumber, payX: BigNumber, payY: BigNumber} {
    let sellingX = new BigNumber(0)
    let sellingY = new BigNumber(0)
    let payX = new BigNumber(0)
    let payY = new BigNumber(0)
    lowPoint = Math.max(lowPoint, leftPoint)
    const lowIdx = Math.ceil((lowPoint - leftPoint) / pointDelta)
    const upIdx = Math.min(Math.ceil((upPoint - leftPoint) / pointDelta), limitOrderSnapshot.length)
    for (let i = lowIdx; i < upIdx; i ++) {
        const limitOrder = limitOrderSnapshot[i]
        const point = leftPoint + i * pointDelta
        const poolPriceUndecimalXByY = 1.0001 ** point
        // const sellingX = new BigNumber(limitOrder.sellingX)
        // const sellingY = new BigNumber(limitOrder.sellingY)
        // const payYStr = sellingX.times(poolPriceUndecimalXByY).toFixed(0, 2)
        // const payXStr = sellingY.div(poolPriceUndecimalXByY).toFixed(0, 2)
        sellingX = sellingX.plus(limitOrder.sellingX)
        sellingY = sellingY.plus(limitOrder.sellingY)

        const payYStr = new BigNumber(limitOrder.sellingX).times(poolPriceUndecimalXByY).toFixed(0, 2)
        const payXStr = new BigNumber(limitOrder.sellingY).div(poolPriceUndecimalXByY).toFixed(0, 2)
        payX = payX.plus(payXStr)
        payY = payY.plus(payYStr)
    }
    return {sellingX, sellingY, payX, payY}
}

function getLiquidityVolum(
    leftPoint: number,
    rightPoint: number,
    lowPoint: number,
    upPoint: number,
    pointDelta: number,
    liquiditySnapshot: BigNumber[]
): {amountX: BigNumber, amountY: BigNumber} {
    let amountX = new BigNumber(0)
    let amountY = new BigNumber(0)
    if (lowPoint > upPoint) {
        return {amountX, amountY}
    }
    lowPoint = Math.max(lowPoint, leftPoint)
    const lowIdx = Math.ceil((lowPoint - leftPoint) / pointDelta)
    const upIdx = Math.min(Math.ceil((upPoint - leftPoint) / pointDelta), liquiditySnapshot.length)

    const lowIdxPoint = leftPoint + lowIdx * pointDelta
    const sqrtRate = Math.sqrt(1.0001)
    if (lowPoint < lowIdxPoint) {
        const currentUpPoint = Math.min(upPoint, lowIdxPoint)
        const ax = getAmountXNoRound(liquiditySnapshot[lowIdx - 1], lowPoint, currentUpPoint, Math.sqrt(1.0001 ** currentUpPoint), sqrtRate)
        const ay = getAmountYNoRound(liquiditySnapshot[lowIdx - 1], Math.sqrt(1.0001 ** lowPoint), Math.sqrt(1.0001 ** currentUpPoint), sqrtRate)
        amountX = amountX.plus(ax)
        amountY = amountY.plus(ay)
    }
    for (let i = lowIdx; i < upIdx; i ++) {
        const lowPoint = leftPoint + i * pointDelta
        const upperPoint = Math.min(lowPoint + pointDelta, upPoint)
        const ax = getAmountXNoRound(liquiditySnapshot[i], lowPoint, upperPoint, Math.sqrt(1.0001 ** upperPoint), sqrtRate)
        const ay = getAmountYNoRound(liquiditySnapshot[i], Math.sqrt(1.0001 ** lowPoint), Math.sqrt(1.0001 ** upperPoint), sqrtRate)
        amountX = amountX.plus(ax)
        amountY = amountY.plus(ay)
    }
    amountX = new BigNumber(amountX.toFixed(0, 1))
    amountY = new BigNumber(amountY.toFixed(0, 1))
    return {amountX, amountY}
}

export const fetchOrderBook = async(
    params: FetchOrderBookParams
): Promise<OrderBookData> => {

    // const currentPriceDecimalBaseByQuote = point2PriceDecimal(params.baseToken, params.quoteToken, params.currentPoint)
    // const priceDecimalBaseByQuote = Math.round(currentPriceDecimalBaseByQuote / params.deltaPrice) * params.deltaPrice

    const priceDecimalBaseByQuote = point2PriceDecimal(params.baseToken, params.quoteToken, params.currentPoint)

    const rawLeftPoint = params.currentPoint - params.lowNum * params.orderBookPointDelta
    const rawRightPoint = params.currentPoint + params.upNum * params.orderBookPointDelta

    const baseTokenAddress = getSwapTokenAddress(params.baseToken).toLowerCase()
    const quoteTokenAddress = getSwapTokenAddress(params.quoteToken).toLowerCase()

    const baseIsX = baseTokenAddress < quoteTokenAddress
    const {leftPoint, rightPoint, liquiditySnapshot, limitOrderSnapshot} = await getLiquidityAndLimitOrderSnapshot(
        params.poolContract,
        rawLeftPoint,
        rawRightPoint,
        params.currentPoint,
        params.pointDelta,
        params.currentLiquidity
    )

    const prices = [] as number[]
    for (let i = leftPoint; i < rightPoint; i ++) {
        prices.push(point2PriceDecimal(params.baseToken, params.quoteToken, i))
    }
    const lowQuantity = []
    const lowPriceDecimal = []
    const upQuantity = []
    const upPriceDecimal = []

    if (baseIsX) {
        // buy orders
        let lowPoint = params.currentPoint - params.orderBookPointDelta
        let upPoint = params.currentPoint + 1

        while (lowPoint >= rawLeftPoint) {

            const {payX: amountXLimitOrder} = getLimitOrderVolumn(
                leftPoint, rightPoint, lowPoint, upPoint, params.pointDelta, limitOrderSnapshot
            )
            const hasCurrentPoint = upPoint === params.currentPoint + 1
            const liquidityUpPoint = hasCurrentPoint ? upPoint - 1 : upPoint
            const {amountX: amountXLiquidity} = getLiquidityVolum(
                leftPoint, rightPoint, lowPoint, liquidityUpPoint, params.pointDelta, liquiditySnapshot
            )
            const currentPointBaseAmount = hasCurrentPoint ? liquidity2AmountYAtPoint(
                new BigNumber(params.currentLiquidity).minus(params.currentLiquidityX),
                Math.sqrt(1.0001 ** params.currentPoint),
                false
            ).div(1.0001 ** params.currentPoint).toFixed(0, 1) : 0

            const buyOrderBaseAmount = amountXLimitOrder.plus(amountXLiquidity).plus(currentPointBaseAmount)
            const buyOrderBaseAmountDecimal = amount2Decimal(buyOrderBaseAmount, params.baseToken) ?? 0

            const currentLowPrice = point2PriceDecimal(params.baseToken, params.quoteToken, lowPoint)
            if (buyOrderBaseAmountDecimal > 0) {
                lowQuantity.push(buyOrderBaseAmountDecimal)
                lowPriceDecimal.push(currentLowPrice)
            }
            upPoint = lowPoint
            lowPoint = lowPoint - params.orderBookPointDelta
        }

        // sell orders
        upPoint = params.currentPoint + params.orderBookPointDelta
        lowPoint = params.currentPoint

        while (upPoint <= rawRightPoint) {
            const {sellingX: amountXLimitOrder} = getLimitOrderVolumn(
                leftPoint, rightPoint, lowPoint, upPoint, params.pointDelta, limitOrderSnapshot
            )
            const hasCurrentPoint = lowPoint === params.currentPoint
            const liquidityLowPoint = hasCurrentPoint ? lowPoint + 1 : lowPoint
            const {amountX: amountXLiquidity} = getLiquidityVolum(
                leftPoint, rightPoint, liquidityLowPoint, upPoint, params.pointDelta, liquiditySnapshot
            )
            const currentPointBaseAmount = hasCurrentPoint ? liquidity2AmountXAtPoint(
                new BigNumber(params.currentLiquidityX),
                Math.sqrt(1.0001 ** params.currentPoint),
                false
            ): 0

            const sellOrderBaseAmount = amountXLimitOrder.plus(amountXLiquidity).plus(currentPointBaseAmount)
            const sellOrderBaseAmountDecimal = amount2Decimal(sellOrderBaseAmount, params.baseToken) ?? 0
            const currentLowPrice = point2PriceDecimal(params.baseToken, params.quoteToken, lowPoint)
            if (sellOrderBaseAmountDecimal > 0) {
                upQuantity.push(sellOrderBaseAmountDecimal)
                upPriceDecimal.push(currentLowPrice)
            }
            lowPoint = upPoint
            upPoint += params.orderBookPointDelta
        }
    } else {
        // buy orders (sellX)
        let upPoint = params.currentPoint + params.orderBookPointDelta + 1
        let lowPoint = params.currentPoint

        while (upPoint <= rawRightPoint + 1) {
            const {payY: amountYLimitOrder} = getLimitOrderVolumn(
                leftPoint, rightPoint, lowPoint, upPoint, params.pointDelta, limitOrderSnapshot
            )
            const hasCurrentPoint = lowPoint === params.currentPoint
            const liquidityLowPoint = hasCurrentPoint ? lowPoint + 1 : lowPoint
            const {amountY: amountYLiquidity} = getLiquidityVolum(
                leftPoint, rightPoint, liquidityLowPoint, upPoint, params.pointDelta, liquiditySnapshot
            )
            const currentPointBaseAmount = hasCurrentPoint ? liquidity2AmountXAtPoint(
                new BigNumber(params.currentLiquidityX),
                Math.sqrt(1.0001 ** params.currentPoint),
                false
            ).times(1.0001 ** params.currentPoint).toFixed(0, 1): 0

            const buyOrderBaseAmount = amountYLimitOrder.plus(amountYLiquidity).plus(currentPointBaseAmount)
            const buyOrderBaseAmountDecimal = amount2Decimal(buyOrderBaseAmount, params.baseToken) ?? 0
            const currentPriceForUpPoint = point2PriceDecimal(params.baseToken, params.quoteToken, upPoint - 1)
            if (buyOrderBaseAmountDecimal > 0) {
                lowQuantity.push(buyOrderBaseAmountDecimal)
                lowPriceDecimal.push(currentPriceForUpPoint)
            }
            lowPoint = upPoint
            upPoint = lowPoint + params.orderBookPointDelta // not + 1
        }

        // sell orders
        lowPoint = params.currentPoint - params.orderBookPointDelta + 1
        upPoint = params.currentPoint + 1

        while (lowPoint >= rawLeftPoint) {

            const {sellingY: amountYLimitOrder} = getLimitOrderVolumn(
                leftPoint, rightPoint, lowPoint, upPoint, params.pointDelta, limitOrderSnapshot
            )
            const hasCurrentPoint = upPoint === params.currentPoint + 1
            const liquidityUpPoint = hasCurrentPoint ? upPoint - 1 : upPoint
            const {amountY: amountYLiquidity} = getLiquidityVolum(
                leftPoint, rightPoint, lowPoint, liquidityUpPoint, params.pointDelta, liquiditySnapshot
            )
            const currentPointBaseAmount = hasCurrentPoint ? liquidity2AmountYAtPoint(
                new BigNumber(params.currentLiquidity).minus(params.currentLiquidityX),
                Math.sqrt(1.0001 ** params.currentPoint),
                false
            ) : 0

            const sellOrderBaseAmount = amountYLimitOrder.plus(amountYLiquidity).plus(currentPointBaseAmount)
            const sellOrderBaseAmountDecimal = amount2Decimal(sellOrderBaseAmount, params.baseToken) ?? 0
            const currentPriceForUpPoint = point2PriceDecimal(params.baseToken, params.quoteToken, upPoint - 1)
            if (sellOrderBaseAmountDecimal > 0) {
                upQuantity.push(sellOrderBaseAmountDecimal)
                upPriceDecimal.push(currentPriceForUpPoint)
            }
            upPoint = lowPoint
            lowPoint -= params.orderBookPointDelta // not + 1
        }
    }

    return {priceDecimalBaseByQuote, lowQuantity, upQuantity, lowPriceDecimal, upPriceDecimal} as OrderBookData

}