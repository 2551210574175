import axios from 'axios';
import mem from 'mem/dist';
import { useEffect, useState } from 'react';
import { ChainId } from '../types/mod';

const tokenListUrl =
    process.env.REACT_APP_ENV === 'production'
        ? [
              'https://raw.githubusercontent.com/izumiFinance/izumi-tokenList/main/build/tokenList.json',
              'https://assets.izumi.finance/assets/tokenList/tokenList.json',
          ]
        : [
              'https://raw.githubusercontent.com/izumiFinance/izumi-tokenList/main/build/tokenListDev.json',
              'https://assets.izumi.finance/assets/tokenList/tokenListDev.json',
          ];

export interface TokenInfo {
    chains: number[];
    name: string;
    symbol: string;
    icon: string;
    contracts: Partial<Record<ChainId, { address: string; decimal: number }>>;
}

export const getTokenListConfig = async (): API.Response<TokenInfo[]> => {
    //return axios.get(tokenListUrl);
    return Promise.any(tokenListUrl.map((url) => axios.get(url)));
};

export const memGetTokenListConfig = mem(getTokenListConfig, {
    maxAge: 1 * 60 * 60 * 1000,
});

export type TokenConfigData = {
    loading: boolean;
    tokenConfig: TokenInfo[];
};

const TOKEN_CONFIG_CONTEXT: TokenConfigData = {
    loading: true,
    tokenConfig: [],
};

export const useTokenList = (): {
    loading: boolean;
    tokenList: TokenInfo[];
} => {
    const [loading, setLoading] = useState<boolean>(TOKEN_CONFIG_CONTEXT.loading);

    useEffect(() => {
        const fetchData = async () => {
            if (TOKEN_CONFIG_CONTEXT.tokenConfig.length !== 0) {
                return;
            }

            getTokenListConfig()
                .then((response) => {
                    console.log('fetch tokenList');
                    TOKEN_CONFIG_CONTEXT.tokenConfig = response.data;
                    TOKEN_CONFIG_CONTEXT.loading = false;
                    setLoading(false);
                })
                .catch((e) => {
                    console.log(e);
                });
        };

        fetchData();
    }, []);

    return {
        loading: loading && TOKEN_CONFIG_CONTEXT.loading,
        tokenList: TOKEN_CONFIG_CONTEXT.tokenConfig,
    };
};

export const getTokenList = (): {
    loading: boolean;
    tokenList: TokenInfo[];
} => {
    let loading = true;

    const fetchData = async () => {
        if (TOKEN_CONFIG_CONTEXT.tokenConfig.length !== 0) {
            return;
        }

        getTokenListConfig()
            .then((response) => {
                console.log('fetch tokenList');
                TOKEN_CONFIG_CONTEXT.tokenConfig = response.data;
                TOKEN_CONFIG_CONTEXT.loading = false;
                loading = false;
            })
            .catch((e) => {
                console.log(e);
            });
    };

    fetchData();

    return {
        loading: loading && TOKEN_CONFIG_CONTEXT.loading,
        tokenList: TOKEN_CONFIG_CONTEXT.tokenConfig,
    };
};
