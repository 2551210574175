import { createModel } from '@rematch/core';
import { RootModel } from '../index';
import produce from 'immer';


export interface LiquidityState {
    ids: number[],
}

export const liquidity = createModel<RootModel>()({
    state: {
        ids: [],
    } as LiquidityState,
    reducers: {
        addId: (state: LiquidityState, id: number) => produce(state, draft => {
            draft.ids.push(id);
        }),
        deleteId: (state: LiquidityState, id: number) => produce(state, draft => {
            draft.ids = state.ids.filter(item => item !== id);
        }),
      
       
    },
    
});
