export const config = {
    pages: [
        {
            name: 'Dashboard',
            img: 'Dashboard.svg',
            address: '/dashboard',
            marker: 'new',
            children: [],
        },
        {
            name: 'Search',
            img: 'Search.svg',
            address: '/search',
            children: [],
        },
        {
            name: 'Liquidity',
            img: 'Liquidity.svg',
            address: '/liquidity',
            children: [],
        },
    ],
} as { pages: PageConfig[] };
