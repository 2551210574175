import React from 'react';
import { Text, useColorMode, BoxProps, Flex, HStack, Stack, Image } from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';
import { i_text_copy, i_text_copy_bold } from '../../../style';
import { getColorThemeSelector } from '../../../utils/funcs';
import { formatNumber } from '../../../utils/tokenMath';
import { TokenInfoFormatted } from '../../../hooks/useTokenListFormatted';

type LiquidityProps = {
    tokenX: TokenInfoFormatted;
    tokenY: TokenInfoFormatted;
    remainTokenX: number;
    remainTokenY: number;
    tokenXValue: number;
    tokenYValue: number;
    totalValue: number;
} & BoxProps;

export const Liquidity: React.FC<LiquidityProps> = ({
    tokenX,
    tokenY,
    remainTokenX,
    remainTokenY,
    tokenXValue,
    tokenYValue,
    totalValue,
    ...rest
}) => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { t } = useTranslation();
    return (
        <Flex
            w={{ base: '100%', sm: '350px' }}
            h="68px"
            bg={colorTheme('#F9F9FB', 'tertiary.700')}
            direction="row"
            alignItems="center"
            pr={{ base: '10px', sm: '18px' }}
            borderRadius="6px"
            {...rest}
        >
            <Flex flex={20} direction="column" alignItems="left" mt="-10px" pl={{ base: '0px', sm: '10px' }}>
                <Text pl="12px" pt="10px" className={i_text_copy}>
                    {t('Liquidity')}
                </Text>

                <Text pl="12px" pt="10px" className={i_text_copy_bold}>
                    {'$ ' + formatNumber(totalValue, 2, 4)}
                </Text>
            </Flex>

            <Stack spacing="10px">
                <HStack>
                    <Image w="16px" h="16px" borderRadius="4px" src={tokenX.icon} />
                    {/* <Text className={i_text_copy_bold} textAlign="left" ml="auto">
                        {tokenX.symbol}:
                    </Text> */}
                    <Text className={i_text_copy} mr="8px !important">
                        {formatNumber(remainTokenX, 2, 4)}
                    </Text>
                    <Text className={i_text_copy} ml="auto !important">
                        {(Number(remainTokenX) + Number(remainTokenY)
                            ? (Number(remainTokenX * tokenXValue) * 100) / totalValue
                            : 0
                        ).toFixed() + '%'}
                    </Text>
                </HStack>
                <HStack>
                    <Image w="16px" h="16px" borderRadius="4px" src={tokenY.icon} />
                    {/* <Text className={i_text_copy_bold} textAlign="left" ml="auto">
                        {tokenY.symbol}:
                    </Text> */}
                    <Text className={i_text_copy} mr="8px !important">
                        {formatNumber(remainTokenY, 2, 4)}
                    </Text>
                    <Text className={i_text_copy} ml="auto !important">
                        {(Number(remainTokenX) + Number(remainTokenY)
                            ? (Number(remainTokenY * tokenYValue) * 100) / totalValue
                            : 0
                        ).toFixed() + '%'}
                    </Text>
                </HStack>
            </Stack>
        </Flex>
    );
};
