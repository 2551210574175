import { Tag, BoxProps, useColorMode, HStack, Text } from '@chakra-ui/react';
import React from 'react';
import { getColorThemeSelector } from '../../../utils/funcs';

type props = {
    variant: 'blue' | 'purple' | 'orange' | 'grey' | 'green' | 'yellow';
    text: string | undefined;
    icon?: any;
} & BoxProps;

export const CardTag: React.FC<props> = (props) => {
    const { variant, text, icon, ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const theme = {
        blue: {
            color: colorTheme('primary.500', 'primary.50'),
            bg: colorTheme('primary.100', '#2252CC'),
            border: colorTheme('0.5px solid #5F9EFF', ''),
        },
        purple: {
            color: colorTheme('secondary.500', 'secondary.50'),
            bg: colorTheme('secondary.100', 'secondary.400'),
            border: colorTheme('1px', '0'),
        },
        orange: {
            color: colorTheme('primary.500', 'primary.50'),
            bg: colorTheme('primary.100', '#2252CC'),
            border: colorTheme('1px', '0'),
        },
        grey: {
            color: colorTheme('#52466A', '#EBEAEC'),
            bg: colorTheme('#F5F5F5', '#52466A'),
            border: '0.5px solid #52466A',
        },
        green: {
            color: colorTheme('#52466A', '#E1FFE6'),
            bg: colorTheme('#F4FFF2', '#335637'),
            border: colorTheme('0.5px solid #0C7B17', '0px'),
        },
        yellow: {
            color: colorTheme('#BB420F', '#FFFFFF'),
            bg: colorTheme('#FFF4DF', '#9D6666'),
            border: colorTheme('0.5px solid #BB420F', '0px'),
        },
    };

    return (
        <Tag
            px="18px"
            // position="absolute"
            // top="0"
            // left="32px"
            // transform="translateY(-50%)"
            color={theme[variant].color}
            bg={theme[variant].bg}
            borderColor="#5F9EFF"
            border={theme[variant].border}
            fontSize="12px"
            lineHeight="15px"
            fontWeight="600"
            fontFamily="Montserrat"
            {...rest}
        >
            <HStack spacing="6px">
                {icon}
                <Text>{text}</Text>
            </HStack>
        </Tag>
    );
};
