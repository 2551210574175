import { createModel } from '@rematch/core';
import { RootModel } from '../index';
import produce from 'immer';
import { announceConfig } from '../../../config/announceBar/config';

export interface AnnounceBarState {
    showBar: boolean[],
}

export const announceBar = createModel<RootModel>()({
    state: {
        showBar: new Array(announceConfig.length).fill(true),
    } as AnnounceBarState,
    reducers: {
        setShowBar: (state: AnnounceBarState, show:boolean, index:number) => produce(state, draft => {
            draft.showBar[index] = show;
        }),
    },
});
