import React from 'react';
import MediaIcon from '../MediaIcon/MediaIcon';

interface Props {
    isHovering: boolean;
}

const RedditIcon: React.FC<Props> = ({ isHovering }) => {
    return (
        <MediaIcon
            isHovering={isHovering}
            circleClassMap={{
                norm: 'reddit-1',
                hover: 'reddit-1-h',
            }}
            pathClassesMap={{
                norm: ['reddit-2'],
                hover: ['reddit-2-h'],
            }}
            paths={[
                'M23.1,15.3c0-1.2-0.9-2.1-2.1-2.1l0,0c-0.5,0-0.9,0.2-1.2,0.4c-1.3-0.8-2.7-1.2-4.2-1.3l0.5-3c0-0.1,0.1-0.2,0.2-0.2h0.1L19,9.9c0.1,0.9,0.9,1.6,1.8,1.5s1.6-0.9,1.5-1.8S21.4,8,20.5,8.1c-0.4,0-0.9,0.3-1.1,0.6l-2.6-0.8c-0.1,0-0.3-0.1-0.4-0.1c-0.7,0-1.3,0.5-1.4,1.2l-0.5,3.1c-1.6,0-3.1,0.5-4.5,1.3C9.6,13.1,9.2,13,8.8,13c-1.1,0-2.1,0.9-2.1,2c0,0.7,0.3,1.3,0.9,1.7c0,0.1,0,0.3,0,0.4c0,2.8,3.3,5,7.5,5s7.5-2.2,7.5-5c0-0.1,0-0.3,0-0.4C22.8,16.6,23.1,16,23.1,15.3zM10.6,16.2c0-0.7,0.6-1.2,1.2-1.2s1.2,0.6,1.2,1.2c0,0.7-0.6,1.2-1.2,1.2l0,0C11.1,17.4,10.6,16.9,10.6,16.2z M17.4,19.9c0,0.1-0.1,0.2-0.2,0.3c-1.5,0.8-3.4,0.8-4.9,0c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0-0.2,0.1-0.3l0.2-0.3c0.1-0.2,0.4-0.2,0.5-0.1c1.2,0.6,2.5,0.6,3.7,0c0.2-0.1,0.4,0,0.5,0.1l0.2,0.3C17.4,19.7,17.4,19.8,17.4,19.9L17.4,19.9z M17.6,17.4c-0.7,0-1.2-0.6-1.2-1.2c0-0.7,0.6-1.2,1.2-1.2c0.7,0,1.2,0.6,1.2,1.2C18.9,16.9,18.3,17.4,17.6,17.4z',
            ]}
        />
    );
};

export default RedditIcon;
