import { Flex } from '@chakra-ui/react';
import { ReactElement } from 'react';
import './AppLayout.css';

export type AppLayoutProps = {
    header?: ReactElement;
    left?: ReactElement;
    center: ReactElement;
    right?: ReactElement;
    bottom?: ReactElement;
};

const AppLayout: React.FC<AppLayoutProps> = (props) => {
    return (
        <Flex direction={props.header ? 'column' : { base: 'column', sm: 'row' }} minH="100vh" w="100%" className="AppLayout">
            {props.header}
            {props.left}
            {props.center}
            {props.right}
            {props.bottom}
        </Flex>
    );
};

export default AppLayout;
