import { tick2PriceDecimal, tick2Price, liquidity2TokenAmount } from '../../../../../utils/tickMath';
import { blocksPerDay } from '../../../../../config/chains';
import { ChainId, FarmFixRangeContractVersion } from '../../../../../types/mod';
import { amount2Decimal } from '../../../../../utils/tokenMath';
import { tokenAddr2Token } from '../../../../../config/tokens';
import BigNumber from 'bignumber.js';
import { MiningContractKeyInfo, PoolEntryState, PositionDetail, RewardTokenPart } from './types';
import { Slot0Response } from '../../../../../types/abis/UniswapV3/UniswapV3Pool';
import Web3 from 'web3';
import { getMiningFixRangeBoostContract, getMiningFixRangeBoostV2Contract } from '../../../../../utils/contractHelpers';
import { TokenInfoFormatted } from '../../../../../hooks/useTokenListFormatted';

export const getPoolLiquidity = (
        miningInfo: MiningContractKeyInfo, 
        slot0: Slot0Response, 
        tokenPriceAB: Pair<number, number>
    ): number => {

    // inverse function of VLiquidity as if all positions covers full reward range
    // VLiquidity = positionLiquidity * (rewardUpper - rewardLower)**2 / 1e6
    const liquidity = Number(miningInfo.totalVLiquidity) /
        (Number(miningInfo.rewardUpperTick) - Number(miningInfo.rewardLowerTick)) /
        (Number(miningInfo.rewardUpperTick) - Number(miningInfo.rewardLowerTick)) * 1e6;

    const tokenAmount = liquidity2TokenAmount(
        liquidity,
        Number(miningInfo.rewardLowerTick),
        Number(miningInfo.rewardUpperTick),
        Number(slot0.tick)
    );

    const token0DecimalAmount = amount2Decimal(
        new BigNumber(tokenAmount[0]),
        miningInfo.token0
    ) ?? 1;

    const token1DecimalAmount = amount2Decimal(
        new BigNumber(tokenAmount[1]),
        miningInfo.token1
    ) ?? 1;

    const token0Price = tokenPriceAB.left;
    const token1Price = tokenPriceAB.right;

    return token0DecimalAmount * token0Price + token1DecimalAmount * token1Price;
};

export const getPositionVLiquidity = (rewardUpperTick: number, rewardLowerTick: number, upperTick: number, lowerTick: number, liquidity: number): number => {
    const validRange = Math.max(
        Math.min(rewardUpperTick, upperTick) - Math.max(rewardLowerTick, lowerTick),
        0
    );
    return validRange * validRange * liquidity / 1e6;
};

export const getPoolAPR = (rewardPerBlock: string, chainId: ChainId, liquidity: number, rewardToken: Pair<TokenInfoFormatted, number>): number[] => {
    const liquidityWithMinimum = liquidity > 100 ? liquidity : 100;
    const blocksPerYear = blocksPerDay(chainId) * 365;
    const { left: token, right: rewardTokenPrice } = rewardToken;

    const rewardsPerYear = (amount2Decimal(
        new BigNumber(blocksPerYear * Number(rewardPerBlock)),
        token
    ) ?? 0) * rewardTokenPrice;

    return [rewardsPerYear / (liquidityWithMinimum ?? 1), rewardsPerYear];
};


export const veiZiBoostAPR = (poolEntry: PoolEntryState, userVLiquidity: number, userCapital: number, userVeiZi: number): number => {
    if (userCapital === 0) {
        return 0;
    }
    const totalVLiquidity = poolEntry.data.totalVLiquidity;
    const totalValidVeiZi = poolEntry.data.totalValidVeiZi;
    let rate = 0;
    if (totalValidVeiZi > 0) {
        const iZiVLiquidity = userVLiquidity * 0.4 + userVeiZi / totalValidVeiZi * totalVLiquidity * 0.6;
        rate = Math.min(userVLiquidity, iZiVLiquidity) / totalVLiquidity;
    } else {
        rate = userVLiquidity / totalVLiquidity;
    }
    const totalReward = poolEntry.data.totalRewardWorthPerYear;
    const reward = totalReward * rate;
    const apr = reward / userCapital;
    return apr;
};

export const veiZiNoBoostNewNftAPR = (poolEntry: PoolEntryState, vLiquidity: number, capital: number): number => {
    if (capital === 0) {
        return 0;
    }
    const totalVLiquidity = poolEntry.data.totalVLiquidity + vLiquidity;
    let rate = vLiquidity / totalVLiquidity;
    const totalValidVeiZi = poolEntry.data.totalValidVeiZi;
    if (poolEntry.meta.veiZiBoost && totalValidVeiZi > 0) {
        rate = rate * 0.4;
    }
    const totalReward = poolEntry.data.totalRewardWorthPerYear;
    const reward = totalReward * rate;
    const apr = reward / capital;
    return apr;
};

export const veiZiBoostNewNftAPR = (poolEntry: PoolEntryState, originUserVLiquidity: number, originUserCapital: number, userVeiZi: number, userValidVeiZi: number, vLiquidity: number, capital: number): number => {
    if (originUserCapital + capital === 0) {
        return 0;
    }
    const totalVLiquidity = poolEntry.data.totalVLiquidity + vLiquidity;
    const userVLiquidity = originUserVLiquidity + vLiquidity;
    const userCapital = originUserCapital + capital;
    let totalValidVeiZi = poolEntry.data.totalValidVeiZi - userValidVeiZi;
    const validVeiZi = Math.min(userVeiZi, 2 * totalValidVeiZi * userVLiquidity / totalVLiquidity);
    totalValidVeiZi += validVeiZi;

    let rate = 0;
    if (totalValidVeiZi > 0 && poolEntry.meta.veiZiBoost) {
        const iZiVLiquidity = userVLiquidity * 0.4 + userVeiZi / totalValidVeiZi * totalVLiquidity * 0.6;
        rate = Math.min(userVLiquidity, iZiVLiquidity) / totalVLiquidity;
    } else {
        rate = userVLiquidity / totalVLiquidity;
    }
    const totalReward = poolEntry.data.totalRewardWorthPerYear;
    const reward = totalReward * rate;
    const apr = reward / userCapital;
    return apr;
};

export const veiZiBoostForNewNftDesiredBoost = (desiredBoost: number, poolEntry: PoolEntryState, originUserVLiquidity: number, userValidVeiZi: number, vLiquidity: number): number => {
    const totalVLiquidity = poolEntry.data.totalVLiquidity + vLiquidity;
    const userVLiquidity = originUserVLiquidity + vLiquidity;
    const totalValidVeiZi = poolEntry.data.totalValidVeiZi - userValidVeiZi;

    const maxValidVeiZi = 2 * totalValidVeiZi / totalVLiquidity * userVLiquidity;

    let boostVLiquidity = userVLiquidity * (desiredBoost - 1) / 1.5;
    if (boostVLiquidity < 0) {
        console.log('[error]: boostVLiquidity < 0: ', boostVLiquidity);
        boostVLiquidity = 0;
    }
    if (boostVLiquidity > userVLiquidity) {
        console.log('[error]: boostVLiquidity > userVLiquidity: ', boostVLiquidity);
        boostVLiquidity = userVLiquidity;
    }

    // phase 1. userVeiZi <= maxValidVeiZi
    // userVeiZi / (totalValidVeiZi + userVeiZi) * totalVLiquidity = boostVLiquidity

    if (totalVLiquidity > boostVLiquidity) {
        const userVeiZi = totalValidVeiZi / (totalVLiquidity - boostVLiquidity) * boostVLiquidity;
        if (userVeiZi <= maxValidVeiZi) {
            return userVeiZi;
        }
    }
    // phase 2. userVeiZi >= maxValidVeiZi
    // userVeiZi / (totalValidVeiZi + maxValidVeiZi) * totalVLiquidity = boostVLiquidity
    const userVeiZi = (totalValidVeiZi + maxValidVeiZi) / totalVLiquidity * boostVLiquidity;
    console.log('maxValidVeiZi: ', maxValidVeiZi);
    console.log('boostVL: ', userVeiZi / (totalValidVeiZi + maxValidVeiZi) * totalVLiquidity);
    console.log('boostVL another: ', userVeiZi / (totalValidVeiZi + userVeiZi) * totalVLiquidity);
    console.log('userVeiZi: ', userVeiZi);
    console.log('total valid veizi before: ', totalValidVeiZi);
    return userVeiZi;
};

export const getPoolRewards = (rewardTokenPart: RewardTokenPart): number => {
    const rewardToken = rewardTokenPart.rewardToken;
    const rewardDecimal = (amount2Decimal(
        new BigNumber(Number(rewardTokenPart.rewardPerBlock)),
        rewardToken
    ) ?? 0);
    return rewardDecimal;
};

export const getPoolRewardRangeDecimal = (miningInfo: MiningContractKeyInfo): number[] => {
    const tokenADecimal = miningInfo.token0.decimal ?? 18;
    const tokenBDecimal = miningInfo.token1.decimal ?? 18;
    const priceLowerDecimal = tick2PriceDecimal(Number(miningInfo.rewardLowerTick), tokenADecimal, tokenBDecimal);
    const priceUpperDecimal = tick2PriceDecimal(Number(miningInfo.rewardUpperTick), tokenADecimal, tokenBDecimal);

    return [priceLowerDecimal, priceUpperDecimal];
};

export const getPoolRewardRange = (miningInfo: MiningContractKeyInfo): number[] => {
    const priceLower = tick2Price(Number(miningInfo.rewardLowerTick));
    const priceUpper = tick2Price(Number(miningInfo.rewardUpperTick));

    return [priceLower, priceUpper];
};

// default only cal valid liquidity
export const getPositionLiquidity = (
    position: PositionDetail,
    positionTick: number,
    chainId: ChainId,
    tokenPriceAB: Pair<number, number>,
    onlyCalValid = true,
    rewardTick: Pair<number, number>,
): number => {
    const liquidity = Number(position.liquidity);
    const tokenAmount = liquidity2TokenAmount(
        liquidity,
        onlyCalValid ? Math.max(Number(position.tickLower), rewardTick.left) : Number(position.tickLower),
        onlyCalValid ? Math.min(Number(position.tickUpper), rewardTick.right) : Number(position.tickUpper),
        positionTick
    );

    const token0DecimalAmount = amount2Decimal(
        new BigNumber(tokenAmount[0]),
        position.token0
    ) ?? 1;

    const token1DecimalAmount = amount2Decimal(
        new BigNumber(tokenAmount[1]),
        position.token1
    ) ?? 1;

    const token0Price = tokenPriceAB.left;
    const token1Price = tokenPriceAB.right;

    return token0DecimalAmount * token0Price + token1DecimalAmount * token1Price;

};

export const getPositionPriceRangeDecimal = (position: PositionDetail): number[] => {
    const tokenADecimal = position.token0.decimal?? 18;
    const tokenBDecimal = position.token1.decimal?? 18;
    const priceLowerDecimal = tick2PriceDecimal(Number(position.tickLower), tokenADecimal, tokenBDecimal);
    const priceUpperDecimal = tick2PriceDecimal(Number(position.tickUpper), tokenADecimal, tokenBDecimal);

    return [priceLowerDecimal, priceUpperDecimal];
};

export const getPositionPriceRange = (position: PositionDetail): number[] => {
    const priceLower = tick2Price(Number(position.tickLower));
    const priceUpper = tick2Price(Number(position.tickUpper));

    return [priceLower, priceUpper];
};

export const getPriceDecimalFromX96 = (sqrtPriceX96: string, tokenPair: Pair<TokenInfoFormatted, TokenInfoFormatted>): string => {
    const sqrtPriceX96Num = new BigNumber(sqrtPriceX96);
    const tokenADecimal = tokenPair.left.decimal ?? 18;
    const tokenBDecimal = tokenPair.right.decimal ?? 18;
    return sqrtPriceX96Num.multipliedBy(sqrtPriceX96Num).multipliedBy(10 ** tokenADecimal).dividedBy(10 ** tokenBDecimal).dividedBy(2 ** (96 * 2)).toString();
};

export const getPriceFromX96 = (sqrtPriceX96: string): string => {
    const sqrtPriceX96Num = new BigNumber(sqrtPriceX96);
    return sqrtPriceX96Num.multipliedBy(sqrtPriceX96Num).dividedBy(2 ** (96 * 2)).toString();
};

export const priceDecimal2Price = (priceDecimal: number, tokenPair: Pair<TokenInfoFormatted, TokenInfoFormatted>): number => {
    const priceDecimalNum = new BigNumber(priceDecimal);
    const tokenADecimal = tokenPair.left.decimal;
    const tokenBDecimal = tokenPair.right.decimal;
    return parseFloat(priceDecimalNum.multipliedBy(10 ** tokenBDecimal).dividedBy(10 ** tokenADecimal).toString());
};

export const price2PriceDecimal = (price: number, tokenPair: Pair<TokenInfoFormatted, TokenInfoFormatted> ): number => {
    const priceNum = new BigNumber(price);
    const tokenADecimal = tokenPair.left.decimal;
    const tokenBDecimal = tokenPair.right.decimal;
    const np = priceNum.times(10 ** tokenADecimal).div(10 ** tokenBDecimal)
    return parseFloat(priceNum.multipliedBy(10 ** tokenADecimal).dividedBy(10 ** tokenBDecimal).toString());
};

export const findPoolEntryByPoolKey = (poolEntryList: PoolEntryState[], poolKey: string): PoolEntryState => {
    return poolEntryList.find(p => p.meta.positionPoolKey === poolKey) as PoolEntryState;
};

export interface RewardInfos {
    rewardToken: string;
    provider: string;
    accRewardPerShare: string;
    rewardPerBlock: string;
}
export interface MiningContractInfo {
    token0_: string;
    token1_: string;
    fee_: string;
    rewardInfos_: RewardInfos[];
    iziTokenAddr_?: string;
    veiZiAddress_?: string;
    rewardUpperTick_: string;
    rewardLowerTick_: string;
    lastTouchBlock_: string;
    totalVLiquidity_: string;
    totalValidVeiZi_?: string;
    startBlock_: string;
    endBlock_: string;
}

export const buildMiningContractKeyInfo = (pool: MiningContractInfo, chainId: number): MiningContractKeyInfo => {
    return {
        totalVLiquidity: pool.totalVLiquidity_,
        totalValidVeiZi: pool.totalValidVeiZi_,
        token0: tokenAddr2Token(pool.token0_, chainId),
        token1: tokenAddr2Token(pool.token1_, chainId),
        endBlock: pool.endBlock_,
        rewardLowerTick: pool.rewardLowerTick_,
        rewardUpperTick: pool.rewardUpperTick_,
        rewardTokenList:
            pool.rewardInfos_.map((item) => {
                return {
                    rewardToken: tokenAddr2Token(item.rewardToken, chainId),
                    rewardPerBlock: item.rewardPerBlock
                };
            })

    } as MiningContractKeyInfo;
};

export const getMiningContractInfo = async (miningAddress: string, chainId: number, web3: Web3, contractVersion: FarmFixRangeContractVersion): Promise<MiningContractKeyInfo> => {
    const miningContract = getMiningFixRangeBoostContract(miningAddress, web3, contractVersion);
    const miningInfo = await miningContract?.methods.getMiningContractInfo().call();
    const miningKeyInfo = buildMiningContractKeyInfo({ ...miningInfo } as MiningContractInfo, chainId);
    return miningKeyInfo;
};

export const pendingReward = async (stakedTokenId: string, miningAddress: string, web3: Web3): Promise<string[]> => {
    const miningContract = getMiningFixRangeBoostV2Contract(miningAddress, web3);
    const positionEarned = await miningContract?.methods.pendingReward(stakedTokenId).call();
    const positionEarnedList = positionEarned;
    return positionEarnedList;
};