import { ChainId } from '../../types/mod';
import chains from '../chains';

export const PRIZE_ADDRESSES = chains.all.reduce<Record<number, string>>((memo, chain) => {
    memo[chain.id] = '';
    if (chain.id === ChainId.BSC) {
        memo[chain.id] = '0xf052Ceaa69e2B5cFBC1C516EcAab8042a80F8Ff3';
    }
    if (chain.id === ChainId.BSCTestnet) {
        memo[chain.id] = '0xCe61Bc9C2C33D65E60e2fa80e07691BcB1636310';
    }
    return memo;
}, {});
