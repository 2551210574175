import { ChainId } from "../../../../../types/mod";
import { Path, PreQueryResult } from "../utils";


export interface PancakePairState {
    reserve0: string
    reserve1: string
}
export interface PancakePreQueryResult extends PreQueryResult {
    lastChainId?: ChainId
    pairState: Map<string, PancakePairState>
    pairAddress: Map<string, string>
    path: Path[]
}

export const initPancakePreQueryResult = (chainId: ChainId): PancakePreQueryResult => {
    return {
        lastChainId: chainId,
        pairState: new Map<string, PancakePairState>(),
        pairAddress: new Map<string, string>(),
        path: []
    } as PancakePreQueryResult
}