import { createModel } from '@rematch/core';
import produce from 'immer';
import { RootModel } from '..';
import { MyHistoryControl } from './types';
export interface TradeHistoryState {
    isSimpleMode:boolean;
    historyData:[];
    control: MyHistoryControl;
}
export const historyOrder = createModel<RootModel>()({
    state: {
        isSimpleMode:true,
        historyData:[],
        control: {
            searchKey: '',
            timeRemain:30,
            sortBy:'-time',
        }
    } as TradeHistoryState,
    reducers: {
        setControl: (state: TradeHistoryState, control: MyHistoryControl) => produce(state, draft => {
            draft.control = { ...control };
        }),
    },
});