import { createModel } from '@rematch/core';
import { RootModel } from '../../../../index';
import produce from 'immer';
import { 
    findPoolEntryByPoolKey,
    getLiquidityIZIBoostAPR,
    veiZiBoostForNewNftDesiredBoost,
    veiZiBoostNewNftAPR,
    veiZiNoBoostNewNftAPR,
} from '../funcs';

import { ChainId, TokenSymbol } from '../../../../../../types/mod';
import tokens, { tokenSymbol2token } from '../../../../../../config/tokens';
import BigNumber from 'bignumber.js';
import { amount2Decimal } from '../../../../../../utils/tokenMath';


export interface MintForm {
    tokenA: TokenSymbol,
    tokenB: TokenSymbol,
    fee: FeeTier,

    positionPoolKey: string,

    tokenUniAmount: number,
    tokenUniAmountDecimal: number,

    lockBoost: boolean,
    lockBoostMultiplier: number,
    tokenLockAmount: number,
    tokenLockAmountDecimal: number,

    iZiBoost: boolean,
    iZiAmount: number,
    iZiAmountDecimal: number,

    veiZiBoost: boolean,
    veiZi: number,
    validVeiZi: number,
    userVLiquidity: number,
    userCapital: number,

    apr: number,
    aprBoostIZI: number,
    aprBoostVeiZi: number,
    veiZiForFullBoostDecimal: number,
}

export interface farmOneSideAddLiquidityState {
    mintForm: MintForm;
}


export const farmOneSideiZiAddLiquidity = createModel<RootModel>()({
    state: {
        mintForm: {} as MintForm
    } as farmOneSideAddLiquidityState,

    reducers: {
        setMintForm: (state: farmOneSideAddLiquidityState, mintForm: MintForm) => produce(state, draft => {
            draft.mintForm = mintForm;
        }),
    },

    effects: (dispatch) => ({

        async refreshMintForm(positionPoolKey: string, rootState): Promise<any> {
            const poolEntry = findPoolEntryByPoolKey(rootState.farmOneSideiZi.poolEntryList, positionPoolKey);
            const mintForm = {
                positionPoolKey: poolEntry.meta.positionPoolKey,
                tokenUniAmount:0,
                tokenUniAmountDecimal:0,
                tokenLockAmount:0,
                tokenLockAmountDecimal:0,
                iZiAmountDecimal: 0,
                iZiAmount: 0,
                apr: 0,
                aprBoostIZI: 0,
            } as MintForm;
            dispatch.farmOneSideiZiAddLiquidity.setMintForm(mintForm);
        },
        updateTokenUniAmount(params: { amountDecimal: number, positionPoolKey?: any, chainId: number }, rootState): void {
            const { amountDecimal, positionPoolKey, chainId } = params;
            const poolEntry = findPoolEntryByPoolKey(rootState.farmOneSideiZi.poolEntryList, positionPoolKey);

            const amountUniDecimal = amountDecimal;

            let price = poolEntry.data.oraclePriceAByB;
            if (poolEntry.meta.tokenUni.symbol === poolEntry.meta.tokenA.symbol) {
                price = 1 / price;
            }

            const tokenUniDecimal = poolEntry.meta.tokenUni.decimal;
            const tokenLockDecimal = poolEntry.meta.tokenLock.decimal;

            const amountUni = amountUniDecimal * 10 ** tokenUniDecimal;
            const amountLock = amountUni / price * poolEntry.data.lockBoostMultiplier;
            const amountLockDecimal = amountLock / 10 ** tokenLockDecimal;

            const mintFormNew = {...rootState.farmOneSideiZiAddLiquidity.mintForm};
            mintFormNew.tokenUniAmount = amountUni;
            mintFormNew.tokenUniAmountDecimal = amountUniDecimal;
            mintFormNew.tokenLockAmount = amountLock;
            mintFormNew.tokenLockAmountDecimal = amountLockDecimal;

            const vLiquidity = mintFormNew.tokenUniAmount * poolEntry.data.lockBoostMultiplier;

            const iZiAmount = rootState.farmOneSideiZiAddLiquidity.mintForm.iZiAmount;
            
            const capital = (amountUniDecimal * poolEntry.data.tokenPriceUniDecimal) * (poolEntry.data.lockBoostMultiplier + 1);

            mintFormNew.apr = getLiquidityIZIBoostAPR(poolEntry, chainId, vLiquidity, capital, 0);
            
            mintFormNew.aprBoostIZI = getLiquidityIZIBoostAPR(poolEntry, chainId, vLiquidity, capital, iZiAmount);
            dispatch.farmOneSideiZiAddLiquidity.setMintForm(mintFormNew);
        },
        updateTokenLockAmount(params: { amountDecimal: number, positionPoolKey?: any, chainId: number }, rootState): void {
            const { amountDecimal, positionPoolKey, chainId } = params;
            const poolEntry = findPoolEntryByPoolKey(rootState.farmOneSideiZi.poolEntryList, positionPoolKey);

            const amountLockDecimal = amountDecimal;

            let price = poolEntry.data.oraclePriceAByB;
            if (poolEntry.meta.tokenUni.symbol === poolEntry.meta.tokenA.symbol) {
                price = 1 / price;
            }

            const tokenUniDecimal = poolEntry.meta.tokenUni.decimal;
            const tokenLockDecimal = poolEntry.meta.tokenLock.decimal;

            const amountLock = amountLockDecimal * 10 ** tokenLockDecimal;
            const amountUni = amountLock * price / poolEntry.data.lockBoostMultiplier;
            const amountUniDecimal = amountUni / 10 ** tokenUniDecimal;

            const mintFormNew = {...rootState.farmOneSideiZiAddLiquidity.mintForm};
            mintFormNew.tokenUniAmount = amountUni;
            mintFormNew.tokenUniAmountDecimal = amountUniDecimal;
            mintFormNew.tokenLockAmount = amountLock;
            mintFormNew.tokenLockAmountDecimal = amountLockDecimal;

            const vLiquidity = mintFormNew.tokenUniAmount * poolEntry.data.lockBoostMultiplier;

            const iZiAmount = rootState.farmOneSideiZiAddLiquidity.mintForm.iZiAmount;

            
            const capital = (amountUniDecimal * poolEntry.data.tokenPriceUniDecimal) * (poolEntry.data.lockBoostMultiplier + 1);

            mintFormNew.apr = getLiquidityIZIBoostAPR(poolEntry, chainId, vLiquidity, capital, 0);
            
            mintFormNew.aprBoostIZI = getLiquidityIZIBoostAPR(poolEntry, chainId, vLiquidity, capital, iZiAmount);
            dispatch.farmOneSideiZiAddLiquidity.setMintForm(mintFormNew);
        },
        updateTokeniZiAmount(params: {amountDecimal: number, positionPoolKey: string, chainId: number}, rootState): void {
            const {amountDecimal, positionPoolKey, chainId} = params;
            const poolEntry = findPoolEntryByPoolKey(rootState.farmOneSideiZi.poolEntryList, positionPoolKey);
            const tokeniZiDecimal = tokens[TokenSymbol.IZI].contracts[chainId as ChainId]?.decimal ?? 18;
            const iZiAmountDecimal = amountDecimal;
            const iZiAmount = iZiAmountDecimal * 10 ** tokeniZiDecimal;
            const mintFormNew = {...rootState.farmOneSideiZiAddLiquidity.mintForm};
            mintFormNew.iZiAmount = iZiAmount;
            mintFormNew.iZiAmountDecimal = iZiAmountDecimal;


            const vLiquidity = mintFormNew.tokenUniAmount * poolEntry.data.lockBoostMultiplier;

            const amountUniDecimal = rootState.farmOneSideiZiAddLiquidity.mintForm.tokenUniAmountDecimal;
            
            const capital = (amountUniDecimal * poolEntry.data.tokenPriceUniDecimal) * (poolEntry.data.lockBoostMultiplier + 1);

            mintFormNew.apr = getLiquidityIZIBoostAPR(poolEntry, chainId, vLiquidity, capital, 0);

            mintFormNew.aprBoostIZI = getLiquidityIZIBoostAPR(poolEntry, chainId, vLiquidity, capital, iZiAmount);

            dispatch.farmOneSideiZiAddLiquidity.setMintForm(mintFormNew);
        },
    })
});


export const veiZiFarmOneSideiZiAddLiquidity = createModel<RootModel>()({
    state: {
        mintForm: {} as MintForm
    } as farmOneSideAddLiquidityState,

    reducers: {
        setMintForm: (state: farmOneSideAddLiquidityState, mintForm: MintForm) => produce(state, draft => {
            draft.mintForm = mintForm;
        }),
    },

    effects: (dispatch) => ({

        async refreshMintForm(positionPoolKey: string, rootState): Promise<any> {
            const poolEntry = findPoolEntryByPoolKey(rootState.farmOneSideiZi.poolEntryList, positionPoolKey);
            const mintForm = {
                positionPoolKey: poolEntry.meta.positionPoolKey,
                tokenUniAmount:0,
                tokenUniAmountDecimal:0,
                tokenLockAmount:0,
                tokenLockAmountDecimal:0,
                iZiAmountDecimal: 0,
                iZiAmount: 0,
                apr: 0,
                aprBoostVeiZi: 0,

                veiZiBoost: poolEntry.meta.veiZiBoost,
                veiZi: poolEntry.userData.veiZi,
                validVeiZi: poolEntry.userData.validVeiZi,
                userVLiquidity: poolEntry.userData.vLiquidity,
                userCapital: poolEntry.userData.capital,

            } as MintForm;
            dispatch.veiZiFarmOneSideiZiAddLiquidity.setMintForm(mintForm);
        },
        updateTokenUniAmount(params: { amountDecimal: number, positionPoolKey?: any, chainId: number }, rootState): void {
            const { amountDecimal, positionPoolKey, chainId } = params;
            const poolEntry = findPoolEntryByPoolKey(rootState.farmOneSideiZi.poolEntryList, positionPoolKey);

            const amountUniDecimal = amountDecimal;

            let price = poolEntry.data.oraclePriceAByB;
            if (poolEntry.meta.tokenUni.symbol === poolEntry.meta.tokenA.symbol) {
                price = 1 / price;
            }

            const tokenUniDecimal = poolEntry.meta.tokenUni.decimal;
            const tokenLockDecimal = poolEntry.meta.tokenLock.decimal;

            const amountUni = amountUniDecimal * 10 ** tokenUniDecimal;
            const amountLock = amountUni / price * poolEntry.data.lockBoostMultiplier;
            const amountLockDecimal = amountLock / 10 ** tokenLockDecimal;

            const mintFormNew = {...rootState.veiZiFarmOneSideiZiAddLiquidity.mintForm};
            mintFormNew.tokenUniAmount = amountUni;
            mintFormNew.tokenUniAmountDecimal = amountUniDecimal;
            mintFormNew.tokenLockAmount = amountLock;
            mintFormNew.tokenLockAmountDecimal = amountLockDecimal;

            const vLiquidity = mintFormNew.tokenUniAmount * poolEntry.data.lockBoostMultiplier;
            
            const capital = (amountUniDecimal * poolEntry.data.tokenPriceUniDecimal) * (poolEntry.data.lockBoostMultiplier + 1);

            mintFormNew.apr = veiZiNoBoostNewNftAPR(poolEntry, chainId, vLiquidity, capital);
            const apr0 = veiZiBoostNewNftAPR(poolEntry, chainId, mintFormNew.userVLiquidity, mintFormNew.userCapital, 0, mintFormNew.validVeiZi, vLiquidity, capital);
            mintFormNew.aprBoostVeiZi = veiZiBoostNewNftAPR(poolEntry, chainId, mintFormNew.userVLiquidity, mintFormNew.userCapital, mintFormNew.veiZi, mintFormNew.validVeiZi, vLiquidity, capital);
            const veiZiForFullBoost = veiZiBoostForNewNftDesiredBoost(2.5, poolEntry, mintFormNew.userVLiquidity, mintFormNew.validVeiZi, vLiquidity);
            mintFormNew.veiZiForFullBoostDecimal = Number(amount2Decimal(new BigNumber(veiZiForFullBoost), tokenSymbol2token(TokenSymbol.IZI, chainId)));
            console.log('veiZiForFullBoostDecimal: ', mintFormNew.veiZiForFullBoostDecimal);
            console.log('apr0: ', apr0);
            console.log('base apr: ', mintFormNew.apr);

            dispatch.veiZiFarmOneSideiZiAddLiquidity.setMintForm(mintFormNew);
        },
        updateTokenLockAmount(params: { amountDecimal: number, positionPoolKey?: any, chainId: number }, rootState): void {
            const { amountDecimal, positionPoolKey, chainId } = params;
            const poolEntry = findPoolEntryByPoolKey(rootState.farmOneSideiZi.poolEntryList, positionPoolKey);

            const amountLockDecimal = amountDecimal;

            let price = poolEntry.data.oraclePriceAByB;
            if (poolEntry.meta.tokenUni.symbol === poolEntry.meta.tokenA.symbol) {
                price = 1 / price;
            }

            const tokenUniDecimal = poolEntry.meta.tokenUni.decimal;
            const tokenLockDecimal = poolEntry.meta.tokenLock.decimal;

            const amountLock = amountLockDecimal * 10 ** tokenLockDecimal;
            const amountUni = amountLock * price / poolEntry.data.lockBoostMultiplier;
            const amountUniDecimal = amountUni / 10 ** tokenUniDecimal;

            const mintFormNew = {...rootState.veiZiFarmOneSideiZiAddLiquidity.mintForm};
            mintFormNew.tokenUniAmount = amountUni;
            mintFormNew.tokenUniAmountDecimal = amountUniDecimal;
            mintFormNew.tokenLockAmount = amountLock;
            mintFormNew.tokenLockAmountDecimal = amountLockDecimal;

            const vLiquidity = mintFormNew.tokenUniAmount * poolEntry.data.lockBoostMultiplier;
            
            const capital = (amountUniDecimal * poolEntry.data.tokenPriceUniDecimal) * (poolEntry.data.lockBoostMultiplier + 1);

            mintFormNew.apr = veiZiNoBoostNewNftAPR(poolEntry, chainId, vLiquidity, capital);
            mintFormNew.aprBoostVeiZi = veiZiBoostNewNftAPR(poolEntry, chainId, mintFormNew.userVLiquidity, mintFormNew.userCapital, mintFormNew.veiZi, mintFormNew.validVeiZi, vLiquidity, capital);
            const veiZiForFullBoost = veiZiBoostForNewNftDesiredBoost(2.5, poolEntry, mintFormNew.userVLiquidity, mintFormNew.validVeiZi, vLiquidity);
            mintFormNew.veiZiForFullBoostDecimal = Number(amount2Decimal(new BigNumber(veiZiForFullBoost), tokenSymbol2token(TokenSymbol.IZI, chainId)));
            
            dispatch.veiZiFarmOneSideiZiAddLiquidity.setMintForm(mintFormNew);
        },
    })
});