import Web3 from "web3"
import { FACTORY_ADDRESS } from "../../../../../config/miscellaneous/uniswapContracts"
import { tokenSymbol2token } from "../../../../../config/tokens"
import { TokenInfoFormatted } from "../../../../../hooks/useTokenListFormatted"
import { UniswapV3FactoryContract } from "../../../../../types/abis/UniswapV3/UniswapV3Factory"
import { Slot0Response, UniswapV3PoolContract } from "../../../../../types/abis/UniswapV3/UniswapV3Pool"
import { ChainId } from "../../../../../types/mod"
import { decodeMethodResult, getFactoryContract, getPositionPoolContract } from "../../../../../utils/contractHelpers"
import { toContractFeeNumber } from "../../../../../utils/funcs"
import { BasePreQueryPlugin } from "../BaseDexPlugin"
import { DagNode, Path, PreQueryResult } from "../utils"
import { MULTIHOP_MID_TOKEN_SYMBOL, SUPPORTED_001_POOL, SupportFeeTiers } from "./config"
import { UniV3PreQueryResult } from "./types"
import { Contract } from 'web3-eth-contract'
import { isAddress } from "ethersv5/lib/utils"
import { BigNumber } from "bignumber.js"

interface PoolPair {
    tokenA: TokenInfoFormatted
    tokenB: TokenInfoFormatted
    feeContractNumber: number
}


interface Link {
    tokenB: TokenInfoFormatted;
    feeContractNumber: number;
}

export class UniV3PreQueryPlugin extends BasePreQueryPlugin {

    private chainId: ChainId
    private factoryContract: UniswapV3FactoryContract
    private factoryAddress: string
    // only for encode abi of calling and decode method result
    private fakePoolContract: UniswapV3PoolContract

    private pairsOfCalling: PoolPair[] = undefined as unknown as PoolPair[]
    private knownPairs: PoolPair[] = undefined as unknown as PoolPair[]
    private knownPoolAddress: string[] = undefined as unknown as string[]


    private knownPoolSqrtPriceX96: string[] = undefined as unknown as string[]
    private unknownPoolSqrtPriceX96: string[] = undefined as unknown as string[]
    
    private allLinks: Link[] = undefined as unknown as Link[]

    private dagNodes: DagNode[] = undefined as unknown as DagNode[]
    private responsePoolAddress: string[] = undefined as unknown as string[]

    private tokenA: TokenInfoFormatted = undefined as unknown as TokenInfoFormatted
    private tokenB: TokenInfoFormatted = undefined as unknown as TokenInfoFormatted

    public constructor(preQueryResult: UniV3PreQueryResult, chainId: ChainId, web3: Web3) {
        super(preQueryResult)
        this.chainId = chainId
        this.factoryContract = getFactoryContract(chainId, web3) as unknown as  UniswapV3FactoryContract 
        this.factoryAddress = FACTORY_ADDRESS[chainId]
        this.fakePoolContract = getPositionPoolContract(this.factoryAddress, web3) as unknown as UniswapV3PoolContract
    }

    private getSwapPoolKey(tokenA: TokenInfoFormatted, tokenB: TokenInfoFormatted, feeContractNumber: number) : string {
        const tokenASymbol = tokenA.symbol.toUpperCase()
        const tokenBSymbol = tokenB.symbol.toUpperCase()
        if (tokenASymbol < tokenBSymbol) {
            return tokenASymbol + '-' + tokenBSymbol + '-' + String(feeContractNumber)
        } else {
            return tokenBSymbol + '-' + tokenASymbol + '-' + String(feeContractNumber)
        }
    }

    private hasPool(tokenA: TokenInfoFormatted, tokenB: TokenInfoFormatted, feeContractNumber: number) : boolean {
        const lastPreQueryResult = this.preQueryResult as UniV3PreQueryResult
        if (this.chainId != lastPreQueryResult.lastChainId) {
            return false
        }
        const key = this.getSwapPoolKey(tokenA, tokenB, feeContractNumber)
        return lastPreQueryResult.pool.has(key)
    }

    override getPreQueryDag(tokenA: TokenInfoFormatted, tokenB: TokenInfoFormatted) : DagNode[] {

        this.tokenA = tokenA
        this.tokenB = tokenB

        const tokenBList: TokenInfoFormatted[] = []
        const tokenBSymbols = MULTIHOP_MID_TOKEN_SYMBOL[this.chainId] ?? []

        tokenBSymbols.forEach((s)=>{
            if (s === tokenA.symbol || s === tokenB.symbol) {
                return
            }
            const token = tokenSymbol2token(s, this.chainId)
            if (token.address) {
                tokenBList.push(token)
            }
        })

        this.allLinks = []

        const supportFeeTiers = SupportFeeTiers[this.chainId] ?? []
        for (const tokenB of tokenBList) {
            for (const feeTier of supportFeeTiers) {
                this.allLinks.push({
                    tokenB,
                    feeContractNumber: toContractFeeNumber(feeTier)
                })
            }
        }

        tokenBList.push({...tokenA})
        tokenBList.push({...tokenB})


        this.pairsOfCalling = []
        this.knownPairs = []

        const poolCalling = [] as string[]

        // pool address calling
        for (let i = 0; i < tokenBList.length; i ++) {
            for (let j = i + 1; j < tokenBList.length; j ++) {
                for (const feeTier of supportFeeTiers) {
                    const middlePool = (i < tokenBList.length - 2 && j < tokenBList.length - 2)
                    if (middlePool && feeTier !== 0.01 && feeTier !== 0.04) { continue; }
                    const feeContractNumber = toContractFeeNumber(feeTier)
                    if (!this.hasPool(tokenBList[i], tokenBList[j], feeContractNumber)) {
                        poolCalling.push(this.factoryContract.methods.getPool(tokenBList[i].address, tokenBList[j].address, feeContractNumber).encodeABI())
                        this.pairsOfCalling.push({
                            tokenA: tokenBList[i],
                            tokenB: tokenBList[j],
                            feeContractNumber
                        } as PoolPair)
                    } else {
                        this.knownPairs.push({
                            tokenA: tokenBList[i],
                            tokenB: tokenBList[j],
                            feeContractNumber
                        } as PoolPair)
                    }
                }
            }
        }
        this.responsePoolAddress = []
        this.unknownPoolSqrtPriceX96 = []
        for (let i = 0; i < this.pairsOfCalling.length; i ++) {
            this.responsePoolAddress.push('')
            this.unknownPoolSqrtPriceX96.push('')
        }
        const preQueryResult = this.preQueryResult as UniV3PreQueryResult

        const poolCallingNodes: DagNode[] = poolCalling.map((calling: string, idx: number)=>{
            return {
                calling,
                preIdx: undefined,
                targetAddress: this.factoryAddress,
                parseCallingResponse: (response: string): void => {
                    const address = decodeMethodResult(this.factoryContract as unknown as Contract, 'getPool', response)
                    this.responsePoolAddress[idx] = address
                }
            } as DagNode
        })

        const knownPairStateCalling = [] as string[]
        this.knownPoolSqrtPriceX96 = []
        for (let i = 0; i < this.knownPairs.length; i ++) {
            this.knownPoolSqrtPriceX96.push('')
        }
        // state calling of known pairs
        this.knownPoolAddress = []
        for (const pair of this.knownPairs) {
            const poolKey = this.getSwapPoolKey(pair.tokenA, pair.tokenB, pair.feeContractNumber)
            const poolAddress = preQueryResult.pool.get(poolKey) as string
            knownPairStateCalling.push(this.fakePoolContract.methods.slot0().encodeABI())
            this.knownPoolAddress.push(poolAddress)
        }

        const knownPairStateCallingNodes: DagNode[] = knownPairStateCalling.map((calling: string, idx: number)=>{
            const poolAddress = this.knownPoolAddress[idx]
            return {
                calling,
                preIdx: undefined,
                targetAddress: poolAddress,
                parseCallingResponse: (response: string): void => {
                    const slot0: Slot0Response = decodeMethodResult(this.fakePoolContract as unknown as Contract, 'slot0', response) as Slot0Response
                    this.knownPoolSqrtPriceX96[idx] = slot0.sqrtPriceX96
                }
            }
        })

        // state calling of unknown pairs
        const unknownPairStateCallingNodes: DagNode[] = this.pairsOfCalling.map((poolPair: PoolPair, idx: number) => {
            return {
                preIdx: [idx],
                getCallingAndTargetAddress: (): {targetAddress: string, calling: string} => {
                    const poolAddress = this.responsePoolAddress[idx]
                    if (!isAddress(poolAddress) || new BigNumber(poolAddress).eq(0)) {
                        return undefined as unknown as {targetAddress: string, calling: string}
                    }
                    const calling = this.fakePoolContract.methods.slot0().encodeABI()
                    return {targetAddress: poolAddress, calling}
                },
                parseCallingResponse: (response: string): void => {
                    const slot0: Slot0Response = decodeMethodResult(this.fakePoolContract as unknown as Contract, 'slot0', response) as Slot0Response
                    this.unknownPoolSqrtPriceX96[idx] = slot0.sqrtPriceX96
                }
            }
        })

        this.dagNodes = [...poolCallingNodes, ...knownPairStateCallingNodes, ...unknownPairStateCallingNodes]

        return this.dagNodes

    }

    override getQueryResult(): PreQueryResult {
        const lastPreQueryResult = this.preQueryResult as UniV3PreQueryResult
        const preQueryResult = {
            pathWithFee100: [],
            pathWithOutFee100: [],
            lastChainId: lastPreQueryResult.lastChainId,
            pool: lastPreQueryResult.pool,
            poolSqrtPriceX96: lastPreQueryResult.poolSqrtPriceX96
        } as UniV3PreQueryResult
        if (preQueryResult.lastChainId !== this.chainId) {
            preQueryResult.pool = new Map<string, string>()
            preQueryResult.poolSqrtPriceX96 = new Map<string, string>()
        } else if (preQueryResult.lastChainId === this.chainId && this.dagNodes.length === 0) {
            preQueryResult.pathWithFee100 = lastPreQueryResult.pathWithFee100
            preQueryResult.pathWithOutFee100 = lastPreQueryResult.pathWithOutFee100
            // no new prequerys
            return preQueryResult
        }
        for (let i = 0; i < this.responsePoolAddress.length; i ++) {
            if (this.responsePoolAddress[i] === '') {
                continue
            }
            const poolAddress = this.responsePoolAddress[i]
            if (!isAddress(poolAddress) || new BigNumber(poolAddress).eq(0)) {
                continue
            }
            const poolPair = this.pairsOfCalling[i]

            const poolKey = this.getSwapPoolKey(poolPair.tokenA, poolPair.tokenB, poolPair.feeContractNumber)
            preQueryResult.pool.set(poolKey, poolAddress)

            preQueryResult.poolSqrtPriceX96.set(poolKey, this.unknownPoolSqrtPriceX96[i])
        }

        for (let i = 0; i < this.knownPairs.length; i ++) {
            const poolPair = this.knownPairs[i]
            const poolKey = this.getSwapPoolKey(poolPair.tokenA, poolPair.tokenB, poolPair.feeContractNumber)
            preQueryResult.poolSqrtPriceX96.set(poolKey, this.knownPoolSqrtPriceX96[i])
        }

        const paths = [] as Path[]

        const supportFeeTiers = SupportFeeTiers[this.chainId]

        for (const i in this.allLinks) {
            const firstKey = this.getSwapPoolKey(this.tokenA, this.allLinks[i].tokenB, this.allLinks[i].feeContractNumber)
            if (!preQueryResult.pool.has(firstKey)) {
                continue
            }
            for (const j in this.allLinks) {
                const lastKey = this.getSwapPoolKey(this.allLinks[j].tokenB, this.tokenB, this.allLinks[j].feeContractNumber)
                if (!preQueryResult.pool.has(lastKey)) {
                    continue
                }
                if (this.allLinks[i].tokenB.symbol === this.allLinks[j].tokenB.symbol) {
                    const feeAB = this.allLinks[i].feeContractNumber
                    const feeBC = this.allLinks[j].feeContractNumber
                    paths.push({
                        tokenChain: [{...this.tokenA}, {...this.allLinks[i].tokenB}, {...this.tokenB}],
                        feeContractNumber: [feeAB, feeBC]
                    } as Path)
                } else {

                    for (const middleFeeTier of supportFeeTiers) {
                        const middleFeeContractNumber = toContractFeeNumber(middleFeeTier)
                        const middleKey = this.getSwapPoolKey(this.allLinks[i].tokenB, this.allLinks[j].tokenB, middleFeeContractNumber)
                        if (!preQueryResult.pool.has(middleKey)) {
                            continue
                        }
                        paths.push({
                            tokenChain: [{...this.tokenA}, {...this.allLinks[i].tokenB}, {...this.allLinks[j].tokenB}, {...this.tokenB}],
                            feeContractNumber: [this.allLinks[i].feeContractNumber, middleFeeContractNumber, this.allLinks[j].feeContractNumber]
                        } as Path)
                    }
                    
                }
            }
        }


        for (const fee of supportFeeTiers) {
            const feeContractNumber = toContractFeeNumber(fee)
            const directKey = this.getSwapPoolKey(this.tokenA, this.tokenB, feeContractNumber)
            if (!preQueryResult.pool.has(directKey)) {
                continue
            }
            paths.push({
                tokenChain: [{...this.tokenA}, {...this.tokenB}],
                feeContractNumber: [feeContractNumber]
            } as Path)
        }


        const fee001Pools = SUPPORTED_001_POOL[this.chainId] ?? [];
        const pathWithoutFee100 = [] as Path[]
        const pathWithFee100 = [] as Path[]
        for (const path of paths) {
            let ok = true
            let valid = true
            for (let i=0;  i<path.feeContractNumber.length; i ++) {
                const fee = path.feeContractNumber[i];
                if (fee === 100) {
                    ok = false
                    const tokenA = path.tokenChain[i];
                    const tokenB = path.tokenChain[i+1];
                    const pool = fee001Pools.find((p:any)=> 
                        (p.tokenA.symbol === tokenA.symbol && p.tokenB.symbol === tokenB.symbol) ||
                        (p.tokenB.symbol === tokenA.symbol && p.tokenA.symbol === tokenB.symbol) 
                    )
                    if (!pool){
                        valid = false;
                        break;
                    }
                }
            }
            if (path.feeContractNumber.length === 1) { ok= true; }
            if (ok) {
                pathWithoutFee100.push(path)
            } else {
                if (valid) {
                    pathWithFee100.push(path)
                }
            }
        }

        preQueryResult.pathWithFee100 = pathWithFee100
        preQueryResult.pathWithOutFee100 = pathWithoutFee100

        return preQueryResult
    }

}
