import { createModel } from '@rematch/core';
import { RootModel } from '../index';
import produce from 'immer';
import { Dex, ResponsePoolInfo } from '../../../net/endpoints/izumiMonitor';
import { ChainId } from '../../../types/mod';
export interface PoolControl {
    searchKey: any;
    dex: Dex | undefined;
    network:ChainId|undefined;

}
export interface PoolState {
    pools:ResponsePoolInfo[],
    control: PoolControl,
}

export const pool = createModel<RootModel>()({
    state: {
        pools:[],
        control: {
            searchKey: undefined,
            dex: undefined,
            network:undefined,
        } ,
    } as PoolState,
    reducers: {
        setPoolData: (state: PoolState, pools: ResponsePoolInfo[]) => produce(state, draft => {
                draft.pools= [...pools];
        }),
       
        setControl: (state: PoolState, control: PoolControl) => produce(state, draft => {
            draft.control = { ...control };
        }),
       
    },
    
});
