import { BoxProps, useColorMode, Image } from '@chakra-ui/react';
import React from 'react';
import { getColorThemeSelector } from '../../../utils/funcs';
import { CardTag } from './CardTag';
import { useTranslation } from 'react-i18next';

type RangeProps = {
    variant: 'InRange' | 'OutOfRange' | 'Closed';
} & BoxProps;

export const RangeTag: React.FC<RangeProps> = (props) => {
    const { variant, ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { t } = useTranslation();

    return variant === 'InRange' ? (
        <CardTag
            variant="green"
            text={t('In Range')}
            icon={<Image boxSize="12px" src={colorTheme('/assets/cardTag/InRange.svg', '/assets/cardTag/darkRange.svg')}></Image>}
            {...rest}
        />
    ) : variant === 'OutOfRange' ? (
        <CardTag
            variant="yellow"
            text={t('Out Of Range')}
            icon={<Image boxSize="12px" src={colorTheme('/assets/cardTag/OutOfRange.svg', '/assets/cardTag/darkRange.svg')}></Image>}
            {...rest}
        />
    ) : (
        <CardTag
            variant="grey"
            text={t('Closed')}
            icon={<Image boxSize="12px" src={colorTheme('/assets/cardTag/closed.svg', '/assets/cardTag/closed.svg')}></Image>}
            color={colorTheme('#92908F', '#EBEAEC')}
            {...rest}
        />
    );
};
