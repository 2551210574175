import { createModel } from '@rematch/core';
import BigNumber from 'bignumber.js';
import produce from 'immer';
import { RootModel } from '../../../..';
import { tokenSymbol2token } from '../../../../../../config/tokens';
import { ChainId, TokenSymbol } from '../../../../../../types/mod';
import { amount2Decimal } from '../../../../../../utils/tokenMath';
import { findPoolEntryByPoolKey, veiZiBoostForNewNftDesiredBoost, veiZiBoostNewNftAPR, veiZiNoBoostNewNftAPR } from '../funcs';

export interface MintForm {
    nftId: string,

    veiZiBoost: boolean,
    veiZi: number,
    validVeiZi: number,
    userVLiquidity: number,
    userCapital: number,

    apr: number,
    aprBoostVeiZi: number,
    veiZiForFullBoostDecimal: number,
}

export interface FarmFixRangeStakeState {
    mintForm: MintForm,
}


export const veiZiFarmFixRangeStake = createModel<RootModel>()({
    state: {
        mintForm: {} as MintForm
    } as FarmFixRangeStakeState,

    reducers: {
        setMintForm: (state: FarmFixRangeStakeState, mintForm: MintForm) => produce(state, draft => {
            draft.mintForm = mintForm;
        }),
    },

    effects: (dispatch) => ({

        refreshMintForm(params: {positionPoolKey: string}, rootState): void {
            const { positionPoolKey } = params;
            const poolEntry = findPoolEntryByPoolKey(rootState.farmFixRange.poolEntryList, positionPoolKey);
            const mintForm = {
                veiZiBoost: poolEntry.meta.veiZiBoost,
                veiZi: poolEntry.userData.veiZi,
                validVeiZi: poolEntry.userData.validVeiZi,
                userVLiquidity: poolEntry.userData.vLiquidity,
                userCapital: poolEntry.userData.capital,
                apr: 0,
                aprBoostVeiZi: 0,
            } as MintForm;
            dispatch.veiZiFarmFixRangeStake.setMintForm(mintForm);
        },
        updateStakeNft(params: { nftId: string, vLiquidity: number, capital: number, chainId: ChainId, positionPoolKey: string }, rootState): void {
            const mintFormNew = {...rootState.veiZiFarmFixRangeStake.mintForm};
            const {nftId, vLiquidity, capital, positionPoolKey, chainId} = params;

            mintFormNew.nftId = nftId;
            
            const poolEntry = findPoolEntryByPoolKey(rootState.farmFixRange.poolEntryList, positionPoolKey);
            const apr = veiZiNoBoostNewNftAPR(poolEntry, vLiquidity, capital);
            const aprBoostVeiZi = veiZiBoostNewNftAPR(poolEntry, mintFormNew.userVLiquidity, mintFormNew.userCapital, mintFormNew.veiZi, mintFormNew.validVeiZi, vLiquidity, capital);
            const veiZiForFullBoost = veiZiBoostForNewNftDesiredBoost(2.5, poolEntry, mintFormNew.userVLiquidity, mintFormNew.validVeiZi, vLiquidity);

            mintFormNew.apr = apr;
            mintFormNew.aprBoostVeiZi = aprBoostVeiZi;
            mintFormNew.veiZiForFullBoostDecimal = Number(amount2Decimal(new BigNumber(veiZiForFullBoost),  tokenSymbol2token(TokenSymbol.IZI, chainId)));


            dispatch.veiZiFarmFixRangeStake.setMintForm(mintFormNew);
        },
    })
});