import BigNumber from 'bignumber.js';
import { BIG_TEN } from './bigNumber';
import tokenContracts, { tokenSymbol2token } from '../config/tokens';
import { ChainId, TokenSymbol } from '../types/mod';
import { TokenInfoFormatted } from '../hooks/useTokenListFormatted';

/**
 * Take a formatted amount with decimal, e.g. 15 BNB and convert it to full amount value, e.g. 15000000000000000
 */
export const decimal2Amount = (decimal: BigNumber, token: TokenInfoFormatted): BigNumber | null => {
    if (!token || !token.symbol) {
        return null;
    }

    return new BigNumber(new BigNumber(decimal).times(BIG_TEN.pow(token.decimal)).toFixed(0, 2));
};

export const amount2Decimal = (amount: BigNumber, token: TokenInfoFormatted): number | undefined => {
    if (!token || !token.symbol) {
        return undefined;
    }
    return new BigNumber(amount).dividedBy(BIG_TEN.pow(token.decimal)).toNumber();
};

export const formatAmount = (number?: number, minPrecision = 2, maxPrecision = 2): string => {
    const defaultValue = '-';
    if (!number) {
        return defaultValue;
    }
    if (number === 0) {
        return '0';
    }
    try {
        let text = '';
        if (number > 1000000000) {
            text = 'B';
            number = number / 1000000000;
        } else if (number > 1000000) {
            text = 'M';
            number = number / 1000000;
        } else if (number > 1000) {
            text = 'K';
            number = number / 1000;
        }

        if (number) {
            const options = {
                minimumFractionDigits: minPrecision,
                maximumFractionDigits: maxPrecision,
            };
            return number.toLocaleString(undefined, options) + text;
        } else {
            return number.toPrecision(2) + text;
        }
    } catch (e) {
        return defaultValue;
    }
};

export const formatNumber = (number: number, minPrecision = 2, maxPrecision = 2, auto = false): string => {
    if (number === 0) {
        return '0';
    }
    try {
        if (auto) {
            const options = {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            };
            if (number > 100000) {
                options.maximumFractionDigits = 0;
                options.minimumFractionDigits = 0;
            } else if (number > 10000) {
                options.maximumFractionDigits = 1;
                options.minimumFractionDigits = 1;
            } else if (number > 1000) {
                options.maximumFractionDigits = 2;
                options.minimumFractionDigits = 2;
            } else if (number > 100) {
                options.maximumFractionDigits = 3;
                options.minimumFractionDigits = 2;
            } else if (number > 10) {
                options.maximumFractionDigits = 4;
                options.minimumFractionDigits = 2;
            } else if (number > 1) {
                options.maximumFractionDigits = 5;
                options.minimumFractionDigits = 2;
            } else if (number > 0.1) {
                options.maximumFractionDigits = 6;
                options.minimumFractionDigits = 2;
            } else if (number > 0.01) {
                options.maximumFractionDigits = 7;
                options.minimumFractionDigits = 2;
            } else {
                options.maximumFractionDigits = 7;
                options.minimumFractionDigits = 2;
            }

            return number.toLocaleString(undefined, options);
        } else {
            //if (number > 1) {
            if (number) {
                const options = {
                    minimumFractionDigits: minPrecision,
                    maximumFractionDigits: maxPrecision,
                };
                return number.toLocaleString(undefined, options);
            } else {
                return number.toPrecision(2);
            }
        }
    } catch (e) {
        return String(number);
    }
};

const getTokenAddressOnSwapPool = (symbol: TokenSymbol, chainId: ChainId): string => {
    const contracts = tokenContracts[symbol].contracts[chainId];
    const address = contracts?.wrapTokenAddress ?? contracts?.address ?? '';
    return address;
};

export const getSwapTokenAddress = (token: TokenInfoFormatted): string => {
    return token.wrapTokenAddress ?? token.address;
};

export const sortedToken = (
    tokenA: TokenSymbol,
    tokenB: TokenSymbol,
    chainId: ChainId
): {
    tokenA: TokenInfoFormatted;
    tokenB: TokenInfoFormatted;
    initialToggle: boolean;
} => {
    const addressA = getTokenAddressOnSwapPool(tokenA, chainId).toLowerCase();
    const addressB = getTokenAddressOnSwapPool(tokenB, chainId).toLowerCase();
    if (addressA > addressB) {
        return {
            tokenA: tokenSymbol2token(tokenB, chainId),
            tokenB: tokenSymbol2token(tokenA, chainId),
            initialToggle: true,
        };
    } else {
        return {
            tokenA: tokenSymbol2token(tokenA, chainId),
            tokenB: tokenSymbol2token(tokenB, chainId),
            initialToggle: false,
        };
    }
};
